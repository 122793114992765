import React, {useState} from 'react';
import OrganizationsFilter from "../organization/components/filter";
import OrganizationList from "../organization/components/list";
import RootLayout from "../../layout/root";
import HeaderCard from "./component/header";
import {useListOrganization} from "../organization/api";
import useQueryString from "../../core/hooks/useQueryString";
import {useGetOrganizationByUserName} from "./api";
import {useNavigate, useParams} from "react-router-dom";
import {fullName} from "../../core/helper/fullName";
import {LockOnOrganizationPageDomainAdmin, LockOnOrganizationPageId} from "../../core/constants/constants";

function OrganizationJoinPage(props) {
    const queryStringFN = useQueryString()
    const {username} = useParams()
    const navigate = useNavigate();
    const image = {
        url: '/images/default.png',
        mime: 'image/png',
    }
    const [test, setTest] = useState({})

    const saveData = (organizationId,domainAdmin) =>{
        if (!!organizationId) {
            window.localStorage.setItem(LockOnOrganizationPageId, organizationId)
        }
        if (!!domainAdmin){
            window.localStorage.setItem(LockOnOrganizationPageDomainAdmin, domainAdmin)
        }
        navigate('/app')
    }

    const {
        data: organizationData,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isFetching,
    } = useGetOrganizationByUserName(username);



    return (
        <RootLayout title={"نوجهان"} navigateTo={'/app/profile'}>
            <div className={'flex flex-col gap-8 px-8 lg:px-4  py-20'}>
                <HeaderCard organizationData={'organizationData'}
                            image={organizationData?.data?.logoDetail ?? image}
                            onClick={() => saveData(organizationData?.data?.id, organizationData?.data?.domainAdmin)}
                            name={organizationData?.data?.name}
                            slogan={organizationData?.data?.settingsPretty?.slogan}
                            description={organizationData?.data?.settingsPretty?.description}
                            province={organizationData?.data?.provinceDetail?.name}
                            city={organizationData?.data?.cityDetail?.name}
                            owner={fullName(organizationData?.data?.ownerDetail?.firstName,organizationData?.data?.ownerDetail?.lastName)}/>
            </div>
        </RootLayout>
    );
}

export default OrganizationJoinPage;