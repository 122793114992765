import {useRef, useState} from "react";
import {twMerge} from "tailwind-merge";
import {generateAvatarBG} from "./color";
import AvatarCropper from "./cropper";
import RenderIf from "../render-if";
import Typo from "../typo";
import Spinner from "../spinner";
import IconButton from "../icon-button";
import {CommonIcon} from "../icon";
import Compressor from "compressorjs";
import { getFirstLetters } from "../../../utils/helper";

const Avatar = ({
                    image,
                    name,
                    className,
                    avatarClassName = "",
                    size = "base",
                    isEditable = false,
                    isLoading = false,
                    onClick,
                    onCropComplete,
                }) => {
    const [newAvatar, setNewAvatar] = useState(undefined);

    const refInput = useRef(null);

    const getSize = () => {
        if (size === "3xs") return "w-8 h-8";
        else if (size === "2xs") return "w-10 h-10";
        else if (size === "xs") return "w-12 h-12";
        else if (size === "sm") return "w-14 h-14";
        else if (size === "base") return "w-16 h-16";
        else if (size === "lg") return "w-20 h-20";
        else return "w-24 h-24";
    };

    const getTextSize = () => {
        if (size === "2xs") return "2xs";
        if (size === "xs") return "xs";
        if (size === "sm") return "sm";
        else return "base";
    };

    const handleChange = (event) => {
        const temp = event.target.files?.item(0);
        if (!!temp) {
            new Compressor(temp, {
                quality: 0.8,
                maxWidth: 1024,
                success: (result) => {
                    setNewAvatar(URL.createObjectURL(result));
                },
            });
        }
    };

    const handleCloseModal = () => {
        setNewAvatar(undefined);
        refInput.current.value = "";
    };

    const handleOnCropComplete = (file) => {
        onCropComplete?.(file);
        setNewAvatar(undefined);
    };

    return (
        <div className={twMerge("relative w-fit", className)}>
            <div
                onClick={() => onClick?.()}
                className={twMerge("flex items-center justify-center overflow-hidden rounded-full", avatarClassName, getSize())}
                style={{
                    backgroundColor: image === undefined ? generateAvatarBG(name) : "white",
                }}
            >
                <RenderIf cond={!!image && !isLoading}>
                    <img src={image} alt={name} className="block" loading="lazy"/>
                </RenderIf>
                <RenderIf cond={!image && !isLoading}>
                    <Typo as="p" size={getTextSize()}>
                        {getFirstLetters(name)}
                    </Typo>
                </RenderIf>
                <RenderIf cond={isLoading}>
                    <Spinner type="accent"/>
                </RenderIf>
            </div>
            <RenderIf cond={isEditable}>
                <IconButton onClick={() => refInput.current.click()}
                            className="absolute bottom-0 right-0 bg-white shadow">
                    <CommonIcon icon="edit"/>
                </IconButton>
                <AvatarCropper
                    image={newAvatar}
                    isOpen={!!newAvatar}
                    onClose={handleCloseModal}
                    onCropComplete={handleOnCropComplete}
                />
                <input type="file" className="hidden" ref={refInput} onChange={handleChange} accept="image/*"/>
            </RenderIf>
        </div>
    );
};

export default Avatar;
