import {useParams} from "react-router-dom";
import ScrollView from "../../../../core/components/scroll-view";
import {useAuthContext} from "../../../Auth/provider/AuthProvider";
import ProfileHeaderBox from "../../components/box";

const ProfileHeaderPerson = () => {
    const {uuid} = useParams();
    const {user, dashboard} = useAuthContext();


    if (!user) return <></>;
    return (
        <ScrollView className="-mx-4 gap-4 px-4">
            <ProfileHeaderBox count={dashboard?.missionCount ?? 0} type="mission" isMe={true}/>
            <ProfileHeaderBox count={dashboard?.courseCount} type="club" isMe={true}/>
        </ScrollView>
    );
};

export default ProfileHeaderPerson;
