import {generalRequest} from '../services'

export async function apiPostAuthenticate(data) {
    return generalRequest.post('auth/login', data)
}
export async function apiPostAuthenticateRecovery(data) {
    return generalRequest.post('auth/recovery', data)
}

export async function apiGetCurrentUser(config = {}) {
    return generalRequest.get(`auth/me`, config)
}

export async function apiSendWebappToken(data) {
    return generalRequest.post(`auth/webapp`, data)
}

export async function apiDeleteWebappToken(token) {
    return generalRequest.delete(`auth/webapp/${token}`)
}

export async function apiPostGenerateOtp(data) {
    return generalRequest.post('auth/otp', data)
}

export async function apiGetOtpDetail(mobile, otpToken) {
    return generalRequest.get('auth/otp?mobile=' + mobile + '&otp_token=' + otpToken)
}

export async function apiPostLoginByOtp(data) {
    return generalRequest.post('auth/login/otp', data)
}

export async function apiPostRegister(data) {
    return generalRequest.post('auth/register', data)
}

export async function apiRecoveryAccount(data) {
    return generalRequest.post('auth/recovery', data)
}

export async function apiUpdateProfile(data) {
    return generalRequest.put('auth/me', data)
}

export async function apiGetDashboard() {
    return generalRequest.get('student/home')
}
