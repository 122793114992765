import React, {Fragment, useState} from 'react';
import {useListExplore} from "../api";
import useQueryString from "../../../core/hooks/useQueryString";
import LoadMore from "../../../core/components/loadmore";
import RootLayout from "../../../layout/root";
import PostCardEXP from "../components/postCardEXP";
import {useParams} from "react-router-dom";
import {MiniLoading} from "../../../core/components/loading";
import RenderIf from "../../../core/components/render-if";
import MainFooter from "../../../layout/mainLayout/mainFooter";

function ReportView(props) {
    const [loadingMore, setLoadingMore] = useState()
    const {id}= useParams()
    const {queryStringFN} = useQueryString();
    const {
        data: listExplore,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isFetching,
    } = useListExplore({
        open: id,
        page: 1,
        perPage: 8,
        search: queryStringFN.get("name") ?? '',
    });

    return (
        <RootLayout title={'جهان‌نما'} navigateTo={'/app/explore'}>
            <div className="max-w-3xl mx-auto pt-16">
                <RenderIf cond={!listExplore}>
                    <div className="flex justify-center my-8">
                        <MiniLoading />
                    </div>
                </RenderIf>
                {listExplore?.pages.map((data, i) => (
                    <Fragment key={'singlePostExplore' + i}>
                        {data?.data?.map((post, i) => (
                            <PostCardEXP key={'explorer-view' + post?.id + '-' + i} post={post} />
                        ))}
                    </Fragment>
                ))}

                {loadingMore && (
                    <div className="flex justify-center py-8">
                        <div
                            className="w-8 h-8 border-4 border-gray-200 border-t-gray-800 rounded-full animate-spin"></div>
                    </div>
                )}
                <LoadMore
                    auto
                    fetchNextPage={fetchNextPage}
                    hasNextPage={hasNextPage === true}
                    isFetchingNextPage={isFetchingNextPage}
                />
            </div>
            <MainFooter />
        </RootLayout>
    );
}

export default ReportView;