import { Fragment, useEffect } from "react";
import NewsCard from "./components/news-card";
import NewsCardLoading from "./components/news-card/loading";
import RenderIf from "../../../core/components/render-if";
import LoadMore from "../../../core/components/loadmore";
import {useGetPosts} from "../api/post";
import {getJalaliDate} from "../../../core/utils/date";
import RootHeader from "../../../layout/root/header";
import __ from "../../../core/utils/translate/translate";

const NewsListPage = () => {

  const{
      data,
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage
  }= useGetPosts(`news`)


  useEffect(() => {
    if (!data) (__("Receiving information..."));
    else (data.pages.at(0)?.data?.at(0)?.category?.name ?? "-");
  }, [data]);

  return (
      <>
          <RootHeader/>
          <div className="flex flex-col gap-4 py-20">
              <div className="grid gap-x-4 gap-y-4 px-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                  <RenderIf cond={!data}>
                      {Array.from({ length: 4 }).map((_, i) => (
                          <NewsCardLoading key={'NewsCardLoading' + 'newsList' + i} />
                      ))}
                  </RenderIf>
                  {data?.pages.map((group, iG) => (
                      <Fragment key={'Fragment' + 'newsList' + iG}>
                          {group.data?.map((news, i) => (
                              <NewsCard
                                  key={'newsCard' + news?.id + '-' + i}
                                  image={news?.coverDetail}
                                  title={news?.title}
                                  summary={news?.subtitle}
                                  createdAt={getJalaliDate(news?.createdAt)}
                                  url={news?.url}
                                  id={news?.id}
                              />
                          ))}
                      </Fragment>
                  ))}
              </div>
              <LoadMore
                  auto
                  fetchNextPage={fetchNextPage}
                  hasNextPage={hasNextPage === true}
                  isFetchingNextPage={isFetchingNextPage}
              />
          </div>
      </>

  );
};

export default NewsListPage;
