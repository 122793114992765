import {useEffect} from 'react';
import {useAuthContext} from "../../feature/Auth/provider/AuthProvider";

import {clarity} from 'react-microsoft-clarity';

export const ClarityProjectId = "pm3b6p8pf9"

export default function ClarityIdentify() {

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return;
    }

    const {user} = useAuthContext()

    useEffect(() => {

        clarity.init(ClarityProjectId);

        if (!!user && user?.id) {
            clarity.identify((user?.id).toString(), {})
        }


    }, [user]);

    return null
}
