export function fullName(firstName, lastName){
    let x = ''
    if(!!firstName)
    {
        x += firstName
    }

    if(!!lastName)
    {
        x += ' ' + lastName
    }

    return x.trim()

}