import {twMerge} from "tailwind-merge";
import Spinner from "../spinner";
import RenderIf from "../render-if";

const Button = ({
                    type = "primary",
                    children,
                    size = "base",
                    onClick,
                    isLoading = false,
                    scale = "full",
                    className,
                    containerClassName,
                    disabled = false,
                    icon,
                    hidden = false,
                }) => {
    const getScale = () => {
        return scale === "fit" ? "w-fit" : "w-full";
    };

    const getSize = () => {
        if (size === "xl") return "text-xl px-4";
        if (size === "sm") return "text-sm px-4";
        else if (size === "xs") return "text-xs px-2";
        else return "text-base px-6";
    };

    const getWrapperSize = () => {
        if (size === "sm") return "h-9";
        else if (size === "xs") return "h-8";
        else return "h-10";
    };

    const getBgColor = () => {
        if (type === "primary") return "bg-purple-1 bg-linear-purple-7 text-white";
        else if (type === "secondary") return "bg-orange-1 bg-linear-orange-0 text-white";
        else if (type === "danger") return "bg-red text-white";
        else return "bg-white text-purple-1";
    };

    const getWrapperBgColor = () => {
        if (type === "primary") return "bg-purple-1 bg-linear-purple-7";
        else if (type === "secondary") return "bg-orange-1 bg-linear-orange-0";
        else if (type === "danger") return "bg-red";
        else return "bg-purple-1 bg-linear-purple-7";
    };

    const handleClick = (event) => {
        if (disabled) return;
        if (isLoading) return;
        onClick?.(event);
    };

    return (
        <div
            className={twMerge(
                "mx-auto items-center justify-center rounded-full p-0.5 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.10)] transition-all",
                isLoading ? "w-10" : getScale(),
                scale === "fit" ? "mx-0" : "mx-auto",
                disabled ? "opacity-50" : "opacity-100 active:scale-90",
                getWrapperBgColor(),
                getWrapperSize(),
                containerClassName,
                hidden ? "hidden" : "flex"
            )}
            onClick={handleClick}
        >
            <button
                className={twMerge(
                    "flex h-full w-full items-center justify-center gap-1 whitespace-nowrap rounded-full border-purple-1",
                    getBgColor(),
                    getSize(),
                    isLoading && "px-0",
                    className
                )}
                disabled={disabled}
            >
                <RenderIf
                    cond={!isLoading}
                    falsyElement={<Spinner size="sm" type={type === "primary" ? "primary" : "accent"}/>}
                >
                    {icon}
                    {children}
                </RenderIf>
            </button>
        </div>
    );
};

export default Button;
