import {twMerge} from "tailwind-merge";
import Typo from "../../../../core/components/typo";
import {getJalaliDate} from "../../../../core/utils/date";
import NotificationIcon from "../icons";

const BaseNotification = ({ children, notification, buttons }) => {
    return (
        <div
            className={twMerge(
                "flex flex-col gap-2 p-6 md:flex-row md:items-center",
                notification?.readAt ? "bg-transparent hover:bg-white/30" : "bg-white"
            )}
        >
            <div className="md:flex-1">
                <div className={`flex gap-2`}>
                    <NotificationIcon notification={notification}/>
                    {children}
                </div>
                <Typo type="secondary" size="xs">
                    {/*{notification?.createdAt?.toDuration()}*/}
                    {getJalaliDate(notification?.createdAt)}
                </Typo>
            </div>
            <div className="flex justify-end gap-2">{buttons}</div>
        </div>
    );
};

export default BaseNotification;
