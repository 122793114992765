import Typo from "../../typo";
import {Fragment} from "react";
import {CommentCard} from "../../card";
import LoadMore from "../../loadmore";
import __ from "../../../utils/translate/translate";
import {useListComments} from "../api/comment";
import {getJalaliDate} from "../../../utils/date";
import {useAuthContext} from "../../../../feature/Auth/provider/AuthProvider";


const CommentList = ({ primaryKey, type }) => {

    const {user} = useAuthContext()


  let argument = {}
  if (type === "news") {
      argument = {post_id: primaryKey}
  }
  if (type === "mission"){
      argument = {mission_id: primaryKey}
  }
  const {
      data,
      hasNextPage,
      isFetchingNextPage,
      fetchNextPage,
      refetch
  } = useListComments(argument);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center">
        <Typo weight="bold">{__("Comments")}</Typo>
        <Typo size="xs" type="secondary" className="px-1">
          ({data?.pages.at(0)?.meta?.total ?? 0})
        </Typo>
      </div>
      <div className="flex flex-col gap-4">
        {data?.pages.map((group, iG) => (
          <Fragment key={'fragment' + 'listComments' + primaryKey + '-' + iG}>
            {group.data.map((comment, i) => (
              <CommentCard
                key={"commentCard" + comment?.id + "-" + i}
                id={comment?.id}
                name={comment?.user?.firstName + " " + comment?.user?.lastName}
                comment={comment?.comment}
                teamName={comment?.owner?.team?.name}
                location={comment?.owner?.city?.displayName}
                avatar={comment?.user?.avatarDetail?.url}
                createdAt={getJalaliDate(comment?.createdAt)}
                isMine={comment?.user?.id === user?.id}
                refetch={refetch}
              />
            ))}
          </Fragment>
        ))}
      </div>
      <LoadMore
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage === true}
        isFetchingNextPage={isFetchingNextPage}
      />
    </div>
  );
};

export default CommentList;
