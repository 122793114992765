import {useLocation, useNavigate} from "react-router-dom";
import {twMerge} from "tailwind-merge";
import {useAuthContext} from "../../feature/Auth/provider/AuthProvider";
import {CommonIcon} from "../../core/components/icon";
import Typo from "../../core/components/typo";
import mainLayout from "./mainLayout";
import __ from "../../core/utils/translate/translate";

const MainFooter = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {complete} = useAuthContext();
    // const { t } = useTranslation("component.footer");

    const menu = [
        // {
        //
        //     icon: "profile",
        //     hoverIcon: "profile-hover",
        //     pathes: ["/app/profile"],
        //     title: __("Profile"),
        //     to: "/app/profile",
        // },
        {
            icon: "award",
            hoverIcon: "award-hover",
            pathes: ["/app/awards", "/awards/see-more"],
            title: __("Awards"),
            to: "/app/awards",
        },
        {
            icon: "club",
            hoverIcon: "club-hover",
            pathes: ["/app/club", "/club/see-more"],
            title: 'یادگیری',
            to: "/app/club?view=activities",
        },

        {
            icon: "home",
            hoverIcon: "home-hover",
            pathes: ["/app"],
            title: __("Home"),
            to: "/app",
        },
        {
            icon: "mission",
            hoverIcon: "mission-hover",
            pathes: ["/app/missions", "/app/missions/see-more"],
            title: __("Mission"),
            to: "/app/missions?view=missions",
        },
        {
            icon: "ravi",
            hoverIcon: "ravi-hover",
            pathes: ["/app/explore"],
            title: "جهان‌نما",
            to: "/app/explore",
        },

    ];

    return (
        <>
            <div
                className="fixed bottom-0 right-1/2 z-[2] flex w-full max-w-[512px] translate-x-1/2 flex-col items-end gap-3 p-2">
                <div
                    className="mx-auto flex max-h-[70px] w-full max-w-[500px] items-end justify-between rounded-full bg-white px-8  py-3 shadow-[0px_4px_20px_0px_rgba(0,0,0,0.20)] transition-all">
                    {menu.map((menu, i) => (
                        <div
                            className={twMerge(
                                "flex cursor-pointer flex-col items-center gap-0 transition-all",
                                menu.pathes.includes(location.pathname) && "gap-2"
                            )}
                            key={'menu' + 'mainLayout' + i}
                            onClick={() => {
                                navigate(menu.to);
                            }}
                        >
                            <div
                                className={twMerge(
                                    "rounded-full p-3 transition-all",
                                    menu.pathes.includes(location.pathname) && "bg-linear-purple-7 shadow-[0px_10px_15px_0px_#A352E1]"
                                )}
                            >
                                <CommonIcon
                                    icon={menu.pathes.includes(location.pathname) ? menu.hoverIcon : menu.icon}/>
                            </div>
                            <Typo
                                as="p"
                                size="xs"
                                weight={menu.pathes.includes(location.pathname) ? "bold" : "normal"}
                                type={menu.pathes.includes(location.pathname) ? "primary" : "secondary"}
                            >
                                {menu.title}
                            </Typo>
                        </div>
                    ))}
                </div>
            </div>
            {/*<ProfileNotCompleteModal isOpen={modal === "profile-not-complete"} onClose={() => setModal("idle")}/>*/}
            {/*<DoFirstMissionModal isOpen={modal === "do-first-mission"} onClose={() => setModal("idle")}/>*/}
        </>
    );
};

export default MainFooter;
