import { useState } from 'react'


export const TestScreen = () => {

  const [params, setParams] = useState({
    page: 1,
    per_page: 10,
  })


  return (
    <div className="w-lvw h-lvh">
      <div className="flex flex-col w-full h-full justify-start items-center">
        <div className="flex flex-col gap-2 w-96 items-center justify-center"></div>

      </div>
    </div>
  )
}
