import {twMerge} from "tailwind-merge";
import AwardsFilter from "./components/filter";
import AwardsHorizontalLis from "./components/horizontal-list";
import VerticalBox from "../../../../core/components/box/vertical";
import useQueryString from "../../../../core/hooks/useQueryString";
import RenderIf from "../../../../core/components/render-if";
import {useAuthContext} from "../../../Auth/provider/AuthProvider";
import {useGetAwards} from "../../api/award";
import AwardsListList from "./components/list";
import __ from "../../../../core/utils/translate/translate";

const AwardsList = () => {
    const {queryStringFN} = useQueryString();
    // const { team, group, club, uuid: myUUID } = useUser();
    const {organisation, team} = useAuthContext();

    return (
        <VerticalBox className={twMerge("relative -mt-4 w-full  px-4 pb-40")}>
            <div className=" mt-4 flex flex-col justify-between gap-2">
                <div className="flex flex-col gap-8">
                    {/*<RenderIf cond={!!organisation}>*/}
                    {/*    <div className="-mx-4">*/}
                    {/*        <AwardsHorizontalLis/>*/}
                    {/*    </div>*/}
                    {/*</RenderIf>*/}
                    <div className="xl:px-52">
                        <AwardsFilter title={!!organisation ? __("Awards") : __("Awards")}/>
                    </div>
                    <div>
                        <AwardsListList/>
                        {/*<RenderIf cond={queryStringFN.get("view", "awards-list") === "awards-list"}>*/}
                        {/*    <AwardsListList/>*/}
                        {/*</RenderIf>*/}
                        {/*<RenderIf cond={queryStringFN.get("view") === "my-awards-list"}>*/}
                        {/*    <AwardsMyAwardsList userUUID={""}/>*/}
                        {/*</RenderIf>*/}
                        {/*<RenderIf cond={queryStringFN.get("view") === "team-awards-list"}>*/}
                        {/*    <AwardsMyAwardsList communityUUID={team?.uuid}/>*/}
                        {/*</RenderIf>*/}
                        {/*/!*<RenderIf cond={queryStringFN.get("view") === "group-awards-list"}>*!/*/}
                        {/*/!*  <AwardsMyAwardsList communityUUID={group?.uuid} />*!/*/}
                        {/*/!*</RenderIf>*!/*/}
                        {/*<RenderIf cond={queryStringFN.get("view") === "organization-awards-list"}>*/}
                        {/*    <AwardsMyAwardsList communityUUID={organisation?.uuid}/>*/}
                        {/*</RenderIf>*/}
                    </div>
                </div>
            </div>
        </VerticalBox>
    );
};

export default AwardsList;
