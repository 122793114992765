import {useEffect} from "react";
import {Navigate, useNavigate} from "react-router-dom";
import {useLayout} from "../../core/providers/LayoutProvider";
import {useAuthContext} from "../../feature/Auth/provider/AuthProvider";
import MainHeader from "./mainHeader";
import MainFooter from "./mainFooter";

function MainLayout({children}) {

    const {openNavigation, setOpenNavigation, sidebarItems} = useLayout();
    const navigate = useNavigate();
    const {user} = useAuthContext();
    useEffect(() => {
        if (!user) return (<Navigate to="/login"/>)
    }, [user]);


    return (
        <>
            <MainHeader/>
            <div className="min-h-[100svh] bg-purple-5">
                {children}
            </div>
            <MainFooter/>
        </>
    )
}

export default MainLayout

