import {useQuery} from "@tanstack/react-query";
import {apiGetLocations} from "../../../data/api/location";

export const useGetLocations = ({parentId, enable}) => {
    return useQuery({
        queryKey: ['locations', parentId],
        queryFn: () => apiGetLocations({
            parent_id: parentId
        }),
        enabled: enable
    })
}