import React, {useEffect, useState} from 'react';
import RenderIf from "../../../../../../../core/components/render-if";
import {CommonIcon} from "../../../../../../../core/components/icon";
import Typo from "../../../../../../../core/components/typo";
import __ from "../../../../../../../core/utils/translate/translate";

function ReviewBox({userContent, type}) {

    const [review, setReview] = useState({})
    useEffect(() => {
        if(userContent?.reviews)
        {
            setReview(userContent.reviews);
        }
    }, [userContent]);

    let reviewDetail = '';
    if(review && review.length > 0)
    {
        reviewDetail = review.map(function (r, i){
            return <RenderIf cond={r?.advice}>
                <div><Typo key={'divTypo' + i + '-' + r?.id + '-' + r?.advice} size="xs"> {__('Advice')}: {r.advice}</Typo></div>
            </RenderIf>
        })

    }

    if(type === 'advice')
    {
        return reviewDetail;
    }

    return (
        <>
            <RenderIf cond={!userContent}>
                <div className="flex w-full items-center gap-1 rounded-full bg-orange-1/10 p-1">
                    <CommonIcon icon="chevron" fill="#0EA9A9" stroke="transparent" className="h-4 w-4"/>
                    <Typo size="xs">هنوز گزارشی ارسال نکردید!</Typo>
                </div>
            </RenderIf>
            <RenderIf cond={userContent?.status === "accept"}>
                <div className="flex w-full items-center gap-1 rounded-full bg-green/10 p-1">
                    <CommonIcon icon="tick-circle" fill="#0EA9A9" stroke="transparent" className="h-4 w-4"/>
                    <Typo size="xs">{__("Your answer is confirmed!")}</Typo>


                </div>

            </RenderIf>
            <RenderIf cond={userContent?.status === "reject"}>
                <div className="flex w-full items-center gap-1 rounded-full bg-red/10 p-1">
                    <CommonIcon icon="danger" fill="red" stroke="transparent" className="h-4 w-4"/>
                    <Typo size="xs">گزارش شما رد شده است. مجددا بارگذاری کنید.</Typo>

                </div>

            </RenderIf>
            <RenderIf cond={userContent?.status === "pending"}>
                <div className="flex w-full items-center gap-1 rounded-full bg-orange-1/10 p-1">
                    <CommonIcon icon="alert_clock" fill="none" stroke="#2394fc" className="h-4 w-4"/>
                    <Typo size="xs">{__('Your answer is being reviewed.')}</Typo>
                </div>
            </RenderIf>

        </>
    );
}

export default ReviewBox;