import {generalRequest} from '../services'

export async function apiLeftOrganisation(organisationUserId) {
    return generalRequest.delete(`student/organization/${organisationUserId}`)
}

export async function apiJoinOrganisation(data) {
    let args = {}

    if(data?.organization_id) {
        args.organization_id = data.organization_id
    }
    if(data?.invite_code)
    {
        args.invite_code = data.invite_code
    }
    return generalRequest.post(`student/organization`, args)
}
