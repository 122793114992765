import {useState} from "react";
import {Link} from "react-router-dom";
import LogoutModal from "../logout";
import Skeleton from "../../../../../../core/components/skeleton";
import RenderIf from "../../../../../../core/components/render-if";
import Avatar from "../../../../../../core/components/avatar";
import {useAuthContext} from "../../../../../Auth/provider/AuthProvider";
import Typo from "../../../../../../core/components/typo";
import ErrorReportModal from "../../../../../../core/components/modal/error-report";
import {getJalaliDate} from "../../../../../../core/utils/date";
import {EDUCATIONS} from "../../../../../../core/constants/constants";
import __ from "../../../../../../core/utils/translate/translate";

const ProfilePersonBio = () => {
    const {user} = useAuthContext();
    // const [complete, setComplete] = useState()
    const [modal, setModal] = useState("idle");

    // const { data: userData } = useUserGet({ props: { uuid: uuid! } });
    if (!user)
        return (
            <div className="grid w-full grid-cols-1 gap-x-4 gap-y-4 md:grid-cols-3 lg:grid-cols-4">
                <Skeleton className="h-48"/>

                <Skeleton className="flex flex-col gap-4 rounded-xl bg-white p-4 md:col-span-2 lg:col-span-3"/>
            </div>
        );

    return (
        <>
            <div className="flex w-full flex-col gap-4">

                <RenderIf cond={true}>
                    <div className="grid grid-cols-1 gap-x-4 gap-y-4 md:grid-cols-3 lg:grid-cols-4">
                        <div className="flex flex-col items-center gap-2 rounded-xl bg-white p-4">
                            <Link to={'edit'}>
                            <Avatar name={`${user?.firstName} ${user.lastName}`} image={user?.avatarDetail?.url} size="xl"/>
                            </Link>
                            <div className="flex flex-col items-center">
                                <Typo weight="bold">{user.name}</Typo>
                            </div>
                        </div>
                        <div className="flex flex-col gap-4 rounded-xl bg-white p-4 md:col-span-2 lg:col-span-3">
                            <div className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2 xl:grid-cols-4">
                                <RenderIf cond={user?.mobile}>
                                    <div>
                                        <Typo type="secondary" size="xs">
                                             تلفن همراه:
                                        </Typo>
                                        <Typo>{user?.mobile}</Typo>
                                    </div>
                                </RenderIf>
                                <RenderIf cond={user?.email}>
                                    <div>
                                        <Typo type="secondary" size="xs">
                                            {__("Email")}:
                                        </Typo>
                                        <Typo>{user.email}</Typo>
                                    </div>
                                </RenderIf>
                                <div>
                                    <Typo type="secondary" size="xs">
                                        {__("Brith date")}:
                                    </Typo>
                                    <Typo>{user?.birthDate ? getJalaliDate(user?.birthDate) : ""}</Typo>
                                </div>
                                <div>
                                    <Typo type="secondary" size="xs">
                                        {__("Location")}:
                                    </Typo>
                                    <Typo>{user?.provinceDetail?.name} {user?.cityDetail?.name ? ' - ' + user?.cityDetail?.name : null}</Typo>
                                </div>
                                <div>
                                    <Typo type="secondary" size="sm">
                                        {__("Education level")}:
                                    </Typo>
                                    <Typo>{user?.education && EDUCATIONS[user?.education]}</Typo>
                                </div>
                            </div>

                            <RenderIf cond={user?.biography?.length > 0}>
                                <div>
                                    <Typo type="secondary" size="xs">
                                        {__("Biography")}:
                                    </Typo>
                                    <Typo>{user?.biography}</Typo>
                                </div>
                            </RenderIf>
                        </div>
                    </div>
                </RenderIf>
                <div className="flex justify-end gap-4">
                    <RenderIf
                        cond={true}
                        falsyElement={
                            <Typo size="xs" type="danger" className="cursor-pointer"
                                  onClick={() => setModal("error-report")}>
                                {__("User profile report")}
                            </Typo>
                        }
                    >
                        <Link to="edit">
                            <Typo size="xs" className="cursor-pointer">
                                {__("Edit profile")}
                            </Typo>
                        </Link>
                        {/*<Link to="">*/}
                        {/*    <Typo size="xs" className="cursor-pointer">*/}
                        {/*        ویرایش رمز عبور*/}
                        {/*    </Typo>*/}
                        {/*</Link>*/}
                        <Typo size="xs" type="danger" onClick={() => setModal("logout")} className="cursor-pointer">
                            {__("Sign out")}
                        </Typo>
                    </RenderIf>
                </div>
            </div>
            {/*<ErrorReportModal*/}
            {/*    isOpen={modal === "error-report"}*/}
            {/*    onClose={() => setModal("idle")}*/}
            {/*    referenceType={"user"}*/}
            {/*    referencePK={user.id}*/}
            {/*/>*/}
            <LogoutModal isOpen={modal === "logout"} onClose={() => setModal("idle")}/>
        </>
    );
};

export default ProfilePersonBio;
