import React, {useRef, useState} from 'react'
import {usePostAuthenticateRecovery, useSendOtp} from './api/auth'
import {OTPInput, TextInput} from "../../core/components/input";
import Button from "../../core/components/button";
import AuthenticateFooter from "./components/footer";
import handleError from "../../core/helper/handelError";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import __ from "../../core/utils/translate/translate";
import Typo from "../../core/components/typo";
import {saveTokens} from "./services/tokenService";

export function RecoveryPasswordScreen() {

    const navigate = useNavigate();
    let mobileRef = useRef(null);
    let passwordRef = useRef(null);
    let rePasswordRef = useRef(null);
    let refInputsOtp = useRef(null);
    const [mobile, setMobile] = useState('')
    const [otpToken, setOtpToken] = useState('')
    const [password, setPassword] = useState('')
    const [screenMode, setScreenMode] = useState('password')
    const {
        mutate: recovery,
        isPending,
        isError,
        error,
        isSuccess,
        data
    } = usePostAuthenticateRecovery()
    const {
        mutate: sendOtp,
        isPending: isPendingOtp,
        isError: isErrorOtp,
        error: errorOtp,
        isSuccess: isSuccessOtp,
        data: dataOtp
    } = useSendOtp()

    const submitForm = () => {
        if (screenMode === 'password') {
            if (!mobileRef.current.getValue()) {
                toast.error(__("Please enter your mobile number!"))
                mobileRef.current.focus()
                return;
            }

            if (!rePasswordRef.current.getValue()) {
                toast.error(__("Please enter your password again!"))
                rePasswordRef.current.focus()
                return;
            }

            if (passwordRef.current.getValue().length <= 8) {
                toast.error(__("Password must be larger than 8 characters!"))
                passwordRef.current.focus()
                return;
            }

            if (!passwordRef.current.getValue()) {
                toast.error(__("Please enter your password!"))
                passwordRef.current.focus()
                return;
            }
            if (rePasswordRef.current.getValue() !== passwordRef.current.getValue()) {
                toast.error(__("Password and re-password does not match!"))
                passwordRef.current.focus()
                return;
            }
        }

        if (screenMode === 'otp' && !refInputsOtp.current.getValue()) {
            toast.error(__("Please enter OTP code!"))
            return;
        }


        if (screenMode === 'password') {
            sendOtp({mobile: mobileRef.current.getValue()}, {
                onSuccess: (data) => {
                    setMobile(mobileRef.current.getValue())
                    setOtpToken(data?.data?.otpToken)
                    setPassword(passwordRef.current.getValue())
                    setScreenMode('otp')

                },
                onError: (error) => {
                    handleError(error)
                }
            })
        }


        if (screenMode === 'otp') {
            recovery({
                mobile: mobile,
                password: password,
                password_confirmation: password,
                otp: refInputsOtp.current.getValue(),
                otp_token: otpToken,
            }, {
                onSuccess: (data) => {
                    saveTokens({
                        accessToken: data.data?.accessToken, refreshToken: data.data?.refreshToken, rememberMe: true
                    });
                    navigate('/app', {replace: true})
                }, onError: (error) => {
                    handleError(error);
                }
            })
        }


    }

    return (
        <>
            {screenMode === 'password' && <>
                <TextInput
                    name={__("Mobile number")}
                    label={__("Enter your mobile number.")}
                    placeholder="09xx-xxx-xxxx"
                    inputMode="tel"
                    dir="ltr"
                    autoFocus
                    maxLength={11}
                    rules={[
                        {rule: "size", cond: 11},
                        {rule: "startsWith", cond: "09"},
                    ]}
                    required={true}
                    ref={mobileRef}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            submitForm();
                        }
                    }}
                />
                <TextInput
                    name={__("Password")}
                    label={__("Enter your password.")}

                    type={'password'}
                    dir="ltr"
                    required={true}
                    ref={passwordRef}
                />
                <TextInput
                    name={__("Re-enter Password")}
                    label={__("Re-enter your password.")}

                    type={'password'}
                    dir="ltr"
                    required={true}
                    ref={rePasswordRef}
                />

                <div>
                    <Typo
                        as="span"
                        size="xs"
                        weight=""
                        type="accent"
                        className={'mx-1'}
                        onClick={() => navigate('/authenticate/passwd')}
                    >
                        {__("Remember your password?")}
                    </Typo>

                </div>
            </>
            }

            {screenMode === 'otp' && <>
                <OTPInput
                    name={__("Verification code")}
                    label={__("Please enter the code sent by SMS.")}

                    ref={refInputsOtp}
                    required

                />

                <Typo as="p" size="xs" type="accent" onClick={() => navigate(-1)}>
                    {__("Not my number")}
                    {' '}
                    {mobile}
                </Typo>

            </>}


            <div className="flex-1"/>
            <div className="flex flex-col items-center gap-2">
                <Button onClick={submitForm} isLoading={isPending}>
                    {screenMode === 'password' ? __("Send OTP") : __("Update password")}
                </Button>
            </div>
            <AuthenticateFooter/>
        </>
    )
}


