import { twMerge } from "tailwind-merge";
import Avatar from "../avatar";
import Typo from "../typo";

const Member = ({ avatar, name, avatarClassName, onClick, description, className }) => {
  return (
    <div className={twMerge("flex items-center gap-2 p-4", className)}>
      <Avatar
        onClick={() => onClick?.()}
        name={name}
        image={avatar}
        avatarClassName={twMerge("border-4 border-white shadow-lg", avatarClassName)}
      />
      <div className="flex flex-col gap-1">
        <Typo as="h2" weight="bold" className="ui-clamp-1" onClick={() => onClick?.()}>
          {name}
        </Typo>
        <Typo as="p" size="sm" type="secondary">
          {description}
        </Typo>
      </div>
    </div>
  );
};

export default Member;
