import {forwardRef, useImperativeHandle, useRef, useState} from "react";
import {twMerge} from "tailwind-merge";
import useOutsideClick from "../../hooks/useOutSideClick";

const DropDownBox = (
    {items, onChange, initOpen = false, className, itemClassName},
    ref
) => {
    const [open, setOpen] = useState(initOpen);

    const refWrapper = useRef(null);

    useOutsideClick(refWrapper, () => setOpen(false));

    const handleOnClick = (item) => {
        setOpen(false);
        onChange(item);
    };

    useImperativeHandle(ref, () => ({
        setVisibility: (visible) => {
            setOpen(visible);
        },
        toggleVisibility: () => {
            setOpen(!open);
        },
    }));

    if (!open) return <></>;

    return (
        <ul
            ref={refWrapper}
            className={twMerge("absolute z-[2] flex flex-col rounded-xl bg-white shadow-lg overflow-hidden", className)}
        >
            {items
                .filter((item) => item.visible === undefined || item.visible)
                .map((item, iItem) => (
                    <li
                        key={iItem}
                        onClick={() => handleOnClick(item)}
                        className={twMerge(
                            "flex cursor-pointer items-center gap-1 p-2 px-4 hover:bg-primary-light whitespace-nowrap",
                            itemClassName,
                            item.className
                        )}
                    >
                        {item.icon && item.icon}
                        <div
                            className={twMerge("text-sm text-center text-primary", item.titleClassName)}>{item.title}</div>
                    </li>
                ))}
        </ul>
    );
};

export default forwardRef(DropDownBox);
