import React from "react";
import {useNavigate} from "react-router-dom";
import VerticalBox from "../../../../../../core/components/box/vertical";
import Member from "../../../../../../core/components/member";
import {twMerge} from "tailwind-merge";
import RenderIfCMP from "../../../../../../core/components/RenderIfCMP";
import __ from "../../../../../../core/utils/translate/translate";

const ProfileTeammate = ({team}) => {
    const navigate = useNavigate();
    const types = {
        "head": __("Team head"),
        "mentor": __("Mentor"),
        "player": __("Student")
    }


    const handleNavigate = (user) => {

    };

    return (
        <RenderIfCMP cond={team !== undefined}>
            <VerticalBox icon="two-user">
                <div className="grid w-full md:grid-cols-2 xl:grid-cols-3">
                    {(team?.users ?? [])
                        .filter((teamUser) => teamUser.status === "accepted")
                        .map((teamUser, i) => {
                            let displayName = ''
                            let avatar = {}

                            if(teamUser?.organizationUser?.avatarDetail) {
                                avatar = teamUser?.organizationUser?.avatarDetail
                            }
                            else
                            {
                                if(teamUser?.user?.avatarDetail)
                                {
                                    avatar = teamUser?.user?.avatarDetail
                                }

                            }

                            if(teamUser?.organizationUser?.displayName) {
                                displayName = teamUser?.organizationUser?.displayName
                            }
                            else
                            {
                                if(teamUser?.user?.firstName) {displayName += teamUser?.user?.firstName;}
                                if(teamUser?.user?.lastName) {displayName += ' ' +  teamUser?.user?.lastName;}
                                if(!teamUser?.user?.firstName && !teamUser?.user?.lastName){
                                    displayName += __("NO name")
                                }
                            }

                            return <Member
                                key={'Member' + teamUser?.id + '-' + i}
                                description={types[teamUser.teamRole] ?? __("Student")}
                                name={displayName}
                                avatar={avatar?.url}
                                avatarClassName={twMerge(
                                    teamUser.teamRole === "head" && "border-red",
                                    teamUser.teamRole === "mentor" && "border-orange-1"
                                )}
                                onClick={() => handleNavigate(teamUser.user)}
                            />
                        })}
                </div>
            </VerticalBox>
        </RenderIfCMP>
    );
};

export default ProfileTeammate;
