import {toast} from "react-toastify";

export default function handleError(error, notify = true) {
    const errorValue = {} /*  {message , statusCode , errorField }   */
    if (!error?.response) {
        // console.error('Network error:', error?.message)
        errorValue.message = error?.message || 'Network error. Please try again later.'
        return errorValue
    }

    const {status, data} = error.response
    errorValue.statusCode = status
    errorValue.errorField = {}
    if (notify)
        toast.error(data.message)
}
