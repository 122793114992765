import { createContext, useContext, useMemo, useState } from 'react'
import __ from "../utils/translate/translate";

export const LayoutContext = createContext({})

export function LayoutProvider({ children }) {
  const [openNavigation, setOpenNavigation] = useState(true)
  const [sidebarItems, setSidebarItems] = useState([
    { name: __("Dashboard"), path: '/', icon: 'icon-grid-2' },
    {
      name: __("Members"),
      path: '/users',
      icon: 'icon-users',
      children: [
        { name: __("Users"), path: '' },
        { name: __("Mentors"), path: '/teachers' },
      ],
    },
    {
      name: __('Financial management'),
      path: '/payments',
      icon: 'icon-money-bill',
      children: [
        { name: __("Payments"), path: '/payments' },
        { name: __("Bills"), path: '/invoice' },
        { name: __("Financial"), path: '/financial' },
      ],
    },
    { name: __("Profile"), path: '/profile', icon: 'icon-user' },
  ])

  const value = useMemo(
    () => ({
      openNavigation,
      setOpenNavigation,
      sidebarItems,
    }),
    [openNavigation]
  )

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
}

export const useLayout = () => {
  return useContext(LayoutContext)
}
