import moment from 'jalali-moment'
import dayjs from 'dayjs'

export function getJalaliDate(date) {
  if (!date) {
    return ''
  }
  return moment(date.toString()).format('jYYYY/jMM/jDD')
}
export function getCurrentTimestamp() {
  return new Date().toISOString().replace("Z", "000000Z");
}


export function getJalaliDateTime(date) {
  if (!date) {
    return ''
  }
  return moment(date.toString()).format('HH:mm - jYYYY/jMM/jDD ')
}


export function getJalaliDateString(date) {
  if (!date) {
    return ''
  }
  return moment(date.toString()).format('jDD jMMMM jYYYY')
}


// datepicker
export function convertToServerDate(date) {
  if (!date) {
    return ''
  }
  return moment(date.toString()).format('YYYY-MM-DD')
}

export function convertToServerDateTime(date) {
  if (!date) {
    return ''
  }
  return moment(date.toString()).format('YYYY-MM-DD HH:mm:ss')
}

export function convertToDayJS(date) {
  if (date && date.isValid) {
    return date
  }
  return dayjs(date.toString(), { jalali: true })
}
