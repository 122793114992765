import { EffectCards } from "swiper/modules";
import { Swiper } from "swiper/react";
import "swiper/css";
import React from "react";
import { TSwiperCardProps } from "./index.type";

const SwipperCardCarousel = ({ children, loop = false, perSlideOffset = 8, className }) => {
  return (
    <Swiper
      modules={[EffectCards]}
      loop={loop}
      grabCursor
      effect="cards"
      cardsEffect={{ perSlideOffset, slideShadows: false }}
      className={className}
    >
      {children}
    </Swiper>
  );
};

export default SwipperCardCarousel;
