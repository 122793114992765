import { Swiper } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import { TSwiperSimpleProps } from "./index.type";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./index.css";

const SwipperCardCarousel = ({
  children,
  loop = false,
  pagination = false,
  navigation,
  className,
  onChangeSlide,
  OnInit,
}) => {
  const modules = [];
  if (pagination) modules.push(Pagination);
  if (navigation) modules.push(Navigation);
  return (
    <Swiper
      modules={modules}
      loop={loop}
      grabCursor
      onSlideChange={(swiper) => onChangeSlide?.(swiper.realIndex)}
      onInit={(swiper) => OnInit?.(swiper.realIndex)}
      pagination={{ clickable: pagination }}
      navigation={navigation}
      className={className}
    >
      {children}
    </Swiper>
  );
};

export default SwipperCardCarousel;
