import {useInfiniteQuery, useMutation} from "@tanstack/react-query";
import {
    apiAcceptNotification,
    apiListNotification,
    apiMarkAsRead,
    apiRejectNotification
} from "../../../data/api/notification";
export const useListNotification = (args, config) => {
    return useInfiniteQuery({
        queryKey: ['notification', args],
        queryFn: ({pageParam = 1}) => apiListNotification({...args, page: pageParam}),
        ...config,
        getNextPageParam: lastPage =>
            lastPage.meta.currentPage < lastPage.meta.lastPage ? lastPage.meta.currentPage + 1 : undefined,
    })
}
export const useMarkAsRead = () => {
    return useMutation({
        mutationFn:({id}) => apiMarkAsRead(id)
    })
}
export const useAcceptNotification = () => {
    return useMutation({
        mutationFn:({id}) => apiAcceptNotification(id)
    })
}
export const useRejectNotification = () => {
    return useMutation({
        mutationFn:({id}) => apiRejectNotification(id)
    })
}