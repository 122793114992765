

const ClubHeader = () => {
  // const { uuid } = useUser();
  // const { data: userStatisticsData } = useUserStatisticsGet({ uuid: uuid });

  return (
    <div className="relative  flex min-h-[16rem] items-center overflow-hidden bg-linear-rose-0 p-4">
      <img alt="jet" src="/images/club-header-2.svg" className="absolute right-20 top-[3rem] z-[1]" />
      {/* <ScrollView className="z-[1] -mx-4 gap-4 px-4">
        {userStatisticsData?.data
          .filter((data) =>
            [
              EnumGameType.COURSE,
              EnumGameType.CAMP,
              EnumGameType.BOOK,
              EnumGameType.MATCH,
              EnumGameType.ONLINE,
            ].includes(data.gameType)
          )
          .filter((data) => data.playing > 0)
          .map((data, i) => <ClubHeaderBox type={data.gameType} count={data.playing} key={i} isActive />)}
      </ScrollView> */}
    </div>
  );
};

export default ClubHeader;
