import {useQuery} from "@tanstack/react-query";
import {apiGetMissionRunners} from "../../../data/api/missionRunner";

export const useGetMissionRunners = ({missionType, startType, status, mode, config}) => {
    return useQuery({
        queryKey: ['missions', missionType, startType, status, mode],
        queryFn: () => apiGetMissionRunners({
            params: {
                mission_type: missionType,
                start_type: startType,
                status: status,
                mode: mode,
            }
        }),
        ...config,
    })
}