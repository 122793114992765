import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {

    apiKey: "AIzaSyDRE3OsefrYFOZJJFUzIx-99npeSeCPb88",
    authDomain: "nojahan-student.firebaseapp.com",
    projectId: "nojahan-student",
    storageBucket: "nojahan-student.firebasestorage.app",
    messagingSenderId: "733920336382",
    appId: "1:733920336382:web:3d399427098b9f960548af",

};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging };