import React, {useEffect, useRef, useState} from "react";
import {twMerge} from "tailwind-merge";
import {FileInput, InputLabel, TextArea} from "../../../../../../../core/components/input";
import {ScoreBadge} from "../../../../../../../core/components/badge";
import Typo from "../../../../../../../core/components/typo";
import RenderIf from "../../../../../../../core/components/render-if";
import FileView from "../../../../../../../core/components/file-view";
import LinearProgressbar from "../../../../../../../core/components/progressbar/linear";
import Button from "../../../../../../../core/components/button";
import VerticalBox from "../../../../../../../core/components/box/vertical";
import {
    useDeleteMissionRunnerReport,
    useGetMissionRunnerDetail,
    useSendMissionRunnerReport
} from "../../../../../api/missionRunner";
import {useParams} from "react-router-dom";
import ReviewBox from "./review-box";
import {toast} from "react-toastify";
import __ from "../../../../../../../core/utils/translate/translate";
import {CommonIcon} from "../../../../../../../core/components/icon";
import {toMBString} from "../../../../../../../utils/helper";
import handleError from "../../../../../../../core/helper/handelError";
import {useAuthContext} from "../../../../../../Auth/provider/AuthProvider";
import {fullName} from "../../../../../../../core/helper/fullName";

const GameStepRequirementInput = ({requirement,missionType}) => {
    const {id, stepId, runnerId} = useParams();
    const {team, teamUser} = useAuthContext()
    const [userContent, setUserContent] = useState({});
    const [progress, setProgress] = useState(0);
    const refTextSolution = useRef(null);
    const refFileSolution = useRef(null);
    const CanUpload = ((missionType === 'team' && team?.teamRole === 'head') || missionType !== 'team')
    const {data: missionRunnerData , refetch} = useGetMissionRunnerDetail({runnerId});
    const {mutate: sendReport,isPending, isError, error, isSuccess, data} = useSendMissionRunnerReport();
    const {mutate: deleteReport,isPending:pendingDelete} = useDeleteMissionRunnerReport();



    const handleStore = () => {
        let file = (['text', 'form'].includes(requirement?.subtype) || !(requirement?.subtype)) ? null : !!refFileSolution.current.getFile() ? refFileSolution.current.getFile() : userContent?.file;
        let contentText = ['text', 'form', undefined].includes(requirement?.subtype) ? refTextSolution.current.getValue() : null;
        sendReport({
            runnerId: missionRunnerData?.data?.id,
            stepId: stepId,
            contentId: requirement?.id,
            content: contentText,
            fileId: file?.id
        }, {
            onSuccess : function(result) {
                refetch()
                setProgress(0)
                if(result?.data?.status === 'accept')
                {
                    toast.success(__('Your answer has been confirmed.'))
                }
                else
                {
                    if (result.responseCode === 'UPDATED') {
                        toast.success(__('Your answer has been updated.'))
                    } else {
                        toast.success(__('Your answer saved'))
                    }
                }

            },
            onError : function (result)
            {
                if(result?.response?.data?.response_code === 'EMPTY_BODY')
                {
                    toast.error(__('You have not sent any reply'))
                }
            },

        });
    };

    const handleDelete = function (){
        deleteReport({
            reportId: userContent?.id,
        }, {
            onSuccess : function(result) {
                refetch()
                if(result?.data?.status === 'accept')
                {
                    toast.success('گزارش شما با موفقیت حذف شد.')
                }

            },
            onError : function (result)
            {
                handleError(result)
            },

        });
    }
    useEffect(() => {
        if (!missionRunnerData?.data) return;
        const contents = missionRunnerData?.data?.reports.filter((report) => report.missionContentId === requirement.id)
        setUserContent(contents?.at(0));
    }, [missionRunnerData?.data]);
    useEffect(() => {
        if (!userContent) return;

        if (!['file', 'image', 'video', 'audio', 'voice'].includes(requirement.subtype)) {
            refTextSolution.current.setValue(userContent?.content);
        }
    }, [userContent]);


    const getAccept = () => {
        switch (requirement.subtype) {
            case "image":
                return "image/*";
            case "video":
                return "video/*";
            case "voice":
                return "audio/*";
            default:
                return "*/*";
        }
    };
    return (
        <VerticalBox icon="..." iconStroke className="p-3" containerClassName="gap-2 h-full" iconPlace="top-right">
            <div className="flex w-full items-center gap-2">
                <div className="flex-1">
                    <InputLabel label={requirement.title} required={requirement.config.required} withoutColon/>
                </div>

                <div className="flex justify-end">
                    <ScoreBadge type="xp" amount={requirement?.experience} withBack size="xs" hiddenOnZero/>
                    <ScoreBadge type="score" amount={requirement?.score} withBack size="xs" hiddenOnZero/>
                </div>
            </div>

            <Typo size="xs" className="bg-purple-5 p-1 rounded" type="accent">{requirement.content}</Typo>

            <RenderIf cond={true}>
                <TextArea
                    disabled={!CanUpload}
                    name={requirement.title}
                    className={twMerge("flex-1", !['file', 'image', 'video', 'audio', 'voice'].includes(requirement.subtype) ? "flex" : "hidden")}
                    inputClassName="flex-1"
                    placeholder={'اینجا بنویسید...'}
                    required={requirement.required}
                    ref={refTextSolution}
                    // value={solutionsData?.data?.at(0)?.solution?.contents}
                    maxLength={500}
                />

                <RenderIf cond={!userContent || userContent?.status === 'reject'}>
                    <div className={twMerge(
                        "flex w-full flex-col items-center gap-1",
                        ['file', 'image', 'video', 'audio', 'voice'].includes(requirement.subtype) ? "visible" : "hidden"
                    )}>
                        <FileInput
                            name={requirement.title}
                            accept={getAccept()}
                            afterUpload={handleStore}
                            className={"min-h-[200px] flex-1"}
                            inputClassName="flex-1"
                            required={requirement.required}
                            ref={refFileSolution}
                            missionType={missionType}
                            setProgress={setProgress}
                        />
                    </div>
                </RenderIf>
            </RenderIf>
            <RenderIf cond={false}>
                <div
                    className={twMerge("flex items-center gap-2.5 rounded-xl p-3 bg-white w-full lg:w-1/3 border border-purple-4")}>
                    <a
                        href={userContent?.fileDetail?.url}
                        target={"_blank"}
                        download={1}
                        className={twMerge("flex cursor-pointer items-center justify-center rounded-xl p-2")}
                    >
                        <CommonIcon icon="document-download"/>
                    </a>

                    <div className="flex flex-col items-start gap-[5px]">
                        <Typo weight="bold" size={'sm'}>دانلود فایل رد شده</Typo>

                        <Typo type="secondary" size="sm">
                            {toMBString(userContent?.fileDetail?.size ?? 0)}
                        </Typo>
                    </div>
                </div>
            </RenderIf>
            <RenderIf cond={!!userContent && requirement.type !== "text" && userContent?.status !== 'reject'}>

                <FileView
                    {...userContent?.fileDetail}
                    initValue={userContent?.fileDetail}
                    thumbnail={undefined}
                    controls
                    className="aspect-4/3 w-full rounded-3xl bg-purple-5"
                    fileClassName="h-20"
                />
            </RenderIf>


            <RenderIf cond={requirement.type !== "text"}>
                <LinearProgressbar value={progress} hiddenOnZero max={100} className="w-full" withPercent/>
            </RenderIf>

            <div className="mt-auto flex w-full items-center justify-end gap-2">

                <RenderIf cond={CanUpload}>
                    <RenderIf cond={!!userContent}>
                        <RenderIf cond={(!userContent || userContent?.status !== "accept") && (!!(requirement?.subtype) && requirement.subtype !== "text")}>
                            <Button
                                scale="fit"
                                size="xs"
                                onClick={userContent?.status === 'pending' ? handleDelete : handleStore}
                                isLoading={isPending || pendingDelete}
                                disabled={isPending || pendingDelete}
                                type={userContent?.status === "pending" ? 'danger' : undefined}
                            >
                                {userContent?.status === "reject" ? 'ارسال مجدد' : 'حذف پاسخ'}
                            </Button>
                        </RenderIf>
                    </RenderIf>
                    <RenderIf cond={(!userContent || userContent?.status !== "accept") && (requirement?.subtype === "text" || !(requirement?.subtype)) }>
                        <Button
                            scale="fit"
                            size="xs"
                            onClick={handleStore}
                            isLoading={isPending}
                            disabled={isPending}
                        >
                            {
                                !!userContent ? __('Edit answer') : __('Send answer')
                            }
                        </Button>
                    </RenderIf>
                </RenderIf>
                <ReviewBox userContent={userContent} type={'notification'}></ReviewBox>
            </div>
            <ReviewBox userContent={userContent} type={'advice'}></ReviewBox>
        </VerticalBox>
    );
};

export default GameStepRequirementInput;
