import {useState} from "react";
import {useParams} from "react-router-dom";
import Typo from "../../../../../core/components/typo";
import StarRatings from "react-star-ratings";
import __ from "../../../../../core/utils/translate/translate";

const GameReportCardRate = () => {
  const { uuid } = useParams();
  const [gameData, setGameData] = useState()

  const [rating, setRating] = useState(0); // Initial value

  // const { mutate: storeMutate } = useRateStore();

  // const { data: gameData } = useGameGet({ props: { uuid: uuid } });

  // useRateGetAll({
  //   props: {
  //     userUUID: userUUID,
  //     referencePK: uuid,
  //     referenceType: EnumRateReferenceType.GAME,
  //   },
  //   onSuccess: (data) => {
  //     if (data.length === 0) return;
  //     setRating(data.at(-1)?.rate ?? 0);
  //   },
  // });

  const handleChange = (_rate) => {
    // setRating(_rate);
    // storeMutate({ rate: _rate, referencePK: uuid, referenceType: EnumRateReferenceType.GAME });
  };

  return (
    <div className="flex flex-col items-center gap-2">
      <Typo as="h1" weight="bold">{gameData?.data?.type === 'course' ? __('How do you rate this course?') : __("How do you rate this mission?")}</Typo>
      {/* <StarRatings initValue={rating} onChange={handleChange} inActiveColor="transparent" strokeWidth={2} /> */}
      <StarRatings
        numberOfStars={5}
        rating={rating}
        starRatedColor="#FFC403"
        starHoverColor="#FFC403"
        changeRating={handleChange}
        svgIconPath="M29.1463 4.46603L33.3699 12.9829C33.9458 14.1685 35.4816 15.3057 36.7775 15.5234L44.4326 16.8058C49.3281 17.6285 50.48 21.2094 46.9524 24.742L41.001 30.7425C39.9931 31.7587 39.4412 33.7186 39.7532 35.1219L41.457 42.55C42.8008 48.4295 39.7052 50.7039 34.5457 47.6311L27.3705 43.3484C26.0747 42.5742 23.9389 42.5742 22.619 43.3484L15.4438 47.6311C10.3084 50.7039 7.18875 48.4053 8.53261 42.55L10.2364 35.1219C10.5484 33.7186 9.99644 31.7587 8.98856 30.7425L3.03721 24.742C-0.4664 21.2094 0.661476 17.6285 5.55693 16.8058L13.2121 15.5234C14.4839 15.3057 16.0198 14.1685 16.5957 12.9829L20.8192 4.46603C23.123 -0.155343 26.8666 -0.155343 29.1463 4.46603Z"
        name="1"
      />
    </div>
  );
};

export default GameReportCardRate;
