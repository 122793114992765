import {useEffect, useRef, useState} from "react";
import {CommonIcon} from "../icon";
import {twMerge} from "tailwind-merge";

const AudioPlayer = ({autoPlay = false, source, controls = false}) => {
    const [currentTime, setCurrentTime] = useState(0);
    const [isPause, setIsPause] = useState(!autoPlay);

    const refAudio = useRef(null);
    const refProgressbarInterval = useRef(null);
    const refProgressbar = useRef(null);

    const startInterval = () => {
        refProgressbarInterval.current = setInterval(() => {
            if (refAudio?.current?.paused) return;
            const timer = Math.round((refAudio.current?.currentTime / refAudio.current?.duration) * 100);
            if (timer) {
                refProgressbar.current.style.width = `${timer}%`;
            }
            if (refAudio?.current) setCurrentTime(refAudio.current?.currentTime);
        }, 1000);
    };

    const secondsToHMS = (second) => {
        var h = Math.floor(second / 3600);
        var m = Math.floor((second % 3600) / 60);
        var s = Math.floor((second % 3600) % 60);
        return h > 0
            ? `${String("0" + h).slice(-2)}:${String("0" + m).slice(-2)}:${String("0" + s).slice(-2)}`
            : `${String("0" + m).slice(-2)}:${String("0" + s).slice(-2)}`;
    };

    const handleChangeSeekbar = (e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const currentTime = ((e.clientX - rect.left) * refAudio.current.duration) / rect.width;
        refAudio.current.currentTime = currentTime;
        const timer = Math.round((currentTime / refAudio.current.duration) * 100);
        refProgressbar.current.style.width = `${timer}%`;
        setCurrentTime(currentTime);
    };

    const handlePause = () => {
        if (isPause) refAudio.current.play();
        else refAudio.current.pause();
        setIsPause(!isPause);
    };

    useEffect(() => {
        startInterval();
        return () => {
            if (refProgressbarInterval.current) clearInterval(refProgressbarInterval.current);
        };
    }, []);

    return (
        <>
            <div className="relative flex w-full items-center justify-center gap-2 overflow-hidden">
                <div
                    className="bg-secondary-p flex h-10 w-10 shrink-0 cursor-pointer items-center justify-center rounded-xl"
                    onClick={handlePause}
                >
                    {isPause ? (
                        <CommonIcon icon="play-circle" stroke="#191B23"/>
                    ) : (
                        <CommonIcon icon="pause" stroke="#191B23"/>
                    )}
                </div>

                <div className={twMerge("h-full w-full flex-col justify-center gap-2.5", controls ? "flex" : "hidden")}>
                    <div className="relative cursor-pointer" onClick={(e) => handleChangeSeekbar(e)}>
                        <div className="h-1.5 w-full rounded-lg bg-black/20"/>
                        <div
                            className="absolute left-0 top-0 h-1.5 rounded-lg bg-blue-600"
                            ref={refProgressbar}
                            style={{width: "0%"}}
                        >
                            <div
                                className="relative -right-0.5 -top-[3px] flex h-3 w-3 rounded-full bg-white shadow"></div>
                        </div>
                    </div>
                    <div className="font-iransansfa flex min-w-[72px] gap-1 text-xs text-black">
                        <div>{refAudio.current ? secondsToHMS(refAudio.current.duration) : "00:00"}</div>
                        <div>/</div>
                        <div>{secondsToHMS(currentTime)}</div>
                    </div>
                </div>
            </div>
            <audio
                // onLoadStart={() => setIsLoad(false)}
                // onCanPlay={() => setIsLoad(true)}
                hidden
                ref={refAudio}
                src={source}
                autoPlay={autoPlay}
                controls

                // autoPlay
            />
        </>
    );
};

export default AudioPlayer;
