import {useInfiniteQuery, useMutation} from "@tanstack/react-query";
import {apiListOrganization} from "../../../data/api/organisation";
import {apiJoinOrganisation} from "../../../data/api/organisationUser";

export const useListOrganization = (args, config) => {
    return useInfiniteQuery({
        queryKey: ['organizationList', args],
        queryFn: ({pageParam = 1}) => apiListOrganization({...args, page: pageParam}),
        ...config,
        getNextPageParam: lastPage =>
            lastPage.meta.currentPage < lastPage.meta.lastPage ? lastPage.meta.currentPage + 1 : undefined,
    })
}
export const useJoinOrganisationWithSearch = () => {
    return useMutation({
        mutationFn: ({organisationId, code}) =>
            apiJoinOrganisation({
                organization_id: organisationId,
                invite_code: code,
                withSearch: true
            }),
    })
}