import {generalRequest} from '../services'

export async function getAwards(params) {
    return generalRequest.get('student/awards', params)
}

export async function getAwardDetail(id) {
    return generalRequest.get('student/award/' + id)
}
export async function getTopList(params){
    return generalRequest.get('student/awards/rank',params)
}