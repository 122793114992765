import AwardsHeader from "./components/header/index";
import AwardsList from "./components/awards-list";
import MainLayout from "../../layout/mainLayout/mainLayout";
import ProfileNotCompleteModal from "../../core/components/modal/profile-not-complete";
import {useAuthContext} from "../Auth/provider/AuthProvider";
import {useState} from "react";

const AwardsPage = () => {
    const {user} = useAuthContext()
    const [modal, setModal] = useState(!user?.firstName ? "profile-not-complete" : "idle");
  return (
      <MainLayout>
          <ProfileNotCompleteModal isOpen={modal === "profile-not-complete"} onClose={() => setModal("idle")}/>
      <AwardsHeader />
      <AwardsList />
    </MainLayout>
  );
};

export default AwardsPage;
