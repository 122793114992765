import {useMutation, useQuery} from "@tanstack/react-query";
import {
    apiDeleteMissionRunnerReport,
    apiGetMissionRunner,
    apiGetMissionRunnerDetail,
    apiPostMissionRunnerReport, apiPostVote, apiRepeatMissionRunner,
    apiStartMissionRunner
} from "../../../data/api/missionRunner";


export const useGetMissionRunnerDetail = ({runnerId, config}) => {
    return useQuery({
        queryKey: ['missionRunnerDetail', runnerId],
        queryFn: () => apiGetMissionRunnerDetail(runnerId),
        ...config,
    })
}
export const useGetMissionRunner = ({missionId, config}) => {
    return useQuery({
        queryKey: ['missionRunner', missionId],
        queryFn: () => apiGetMissionRunner(missionId),
        ...config,
    })
}
export const useStartMissionRunner = () => {
    return useMutation({
        mutationFn: ({missionId}) =>
            apiStartMissionRunner(missionId),
    })
}

export const useRepeatMissionRunner = () => {
    return useMutation({
        mutationFn: ({missionRunnerId}) =>
            apiRepeatMissionRunner(missionRunnerId),
    })
}
export const useSendMissionRunnerReport = () => {
    return useMutation({
        mutationFn: ({runnerId, stepId, contentId, fileId, content, userAnswer}) =>
            apiPostMissionRunnerReport(runnerId, stepId, {
                content_id: contentId,
                file: fileId,
                content: content,
                json: userAnswer ? JSON.stringify({user_answer: userAnswer}) : null
            }),
    })
}

export const useDeleteMissionRunnerReport = () => {
    return useMutation({
        mutationFn: ({reportId}) =>
            apiDeleteMissionRunnerReport(reportId),
    })
}
export const useSendVote = () =>{
    return useMutation({
        mutationFn: ({runnerId, vote}) => apiPostVote(runnerId,vote)
    })
}