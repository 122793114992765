import Skeleton from "../../../../../core/components/skeleton";

const GameReportCardLoading = () => {

  return (
    <div className="flex flex-col items-center gap-8 px-4 py-20">
      <Skeleton className="w-72 aspect-video" />
      <Skeleton className="w-60 h-4" />
      <div className="flex flex-col md:flex-row gap-4 w-full md:w-2/3">
        <Skeleton className="w-full h-36 rounded-3xl" />
        <Skeleton className="w-full h-36 rounded-3xl" />
      </div>
    </div>
  );
};

export default GameReportCardLoading;
