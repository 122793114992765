import { useEffect, useState } from "react";
import RenderIf from "../../../core/components/render-if";
import Typo from "../../../core/components/typo";
import Spinner from "../../../core/components/spinner";
import Timer from "../../../core/components/timer";
import { useGetOtp, useSendOtp } from "../api/auth";
import handleError from "../../../core/helper/handelError";
import { useNavigate } from "react-router-dom";
import __ from "../../../core/utils/translate/translate";

const AuthOTPTimer = ({ mobile, otpToken }) => {
  const [isComplete, setIsComplete] = useState(false);
  const { mutate: sendOtp, isLoading, isError, error, isSuccess, data } = useSendOtp();
  const { mutate: getOtp, isError: isErrorGet, error: errorGet, isSuccess: isSuccessGet } = useGetOtp();
  const navigate = useNavigate();
  const [myTime, setMyTime] = useState(300);

  const handleRetry = () => {
    if (!isComplete) return;
    sendOtp({ mobile: mobile }, {
      onSuccess: (data) => {
        // TODO Need to fix this!
        navigate(`/authenticate/otp/${mobile}/${data?.data?.otpToken}`);
        navigate(0)
      },
      onError: (error) => {
        handleError(error);
      },
    });
  };

  useEffect(() => {
      getOtp({ mobile, otpToken }, {
        onSuccess: (data) => {
          if(data?.data?.timeLeft)
          {
            setMyTime(data?.data?.timeLeft);
          }
        },
      })
  }, [mobile, otpToken ]);

  useEffect(() => {
    if(!isSuccessGet)
    {
      setMyTime(1)
    }
  }, [isSuccessGet]);

  const timerRenderFN = ({ seconds, minutes }) => {
    return (
      <div className="flex">
        <span>{seconds.toString().padStart(2, "0")}</span>
        <span>:</span>
        <span>{minutes.toString().padStart(2, "0")}</span>
      </div>
    );
  };

  return (
    <div className="flex w-full items-center justify-between gap-2">
      <div className={'mb-2'}>
        <RenderIf cond={!isLoading}>
          {!isComplete ?
            <Typo as="p" size="sm">
              {__("Until resend the code")}
            </Typo>
            :
            <Typo as="p" size="sm" className={'font-bold'} onClick={handleRetry}>
              {__("Retry")}
            </Typo>}

        </RenderIf>
      </div>

      <RenderIf cond={isLoading}>
        <Typo as="p" size="sm">
          {__("Sending code")}
        </Typo>
        <Spinner type="accent" size="sm" />
      </RenderIf>
      <RenderIf cond={!isComplete}>
        <Timer second={myTime} renderFN={timerRenderFN}
               onComplete={() => setIsComplete(true)} />
      </RenderIf>
    </div>
  );
};

export default AuthOTPTimer;
