import LinearProgressbar from "../linear";
import RenderIf from "../../render-if";
import {CommonIcon} from "../../icon";
import Typo from "../../typo";
import __ from "../../../utils/translate/translate";

const GameProgressbar = ({ gameUserStatus, progress }) => {
  if (!gameUserStatus) return <></>;
  return null
    /**
     * Maybe later!
     */
  return (
    <div className="flex items-center gap-4 rounded-full bg-purple-5 px-3 py-2">
      <div className="flex items-center gap-1">
        <RenderIf
          cond={['failed', 'left'].includes(gameUserStatus)}
          falsyElement={<CommonIcon icon="tick-circle" stroke="transparent" fill="#0EA9A9" />}
        >
          <CommonIcon icon="add-circle" className="rotate-45" />
        </RenderIf>
        <Typo as="p" size="xs">
            {
                [
                    'signup',
                    'doing',
                    'ready',
                ].includes(gameUserStatus)
                    ? __("In progress")
                    : [
                        'finish',
                        'success',
                        'report_card',
                        'evaluation',
                        'failed',
                        'left',
                    ].includes(gameUserStatus)
                    ? __("Successful ending")
                    : __("Unsuccessful end")
            }
        </Typo>
      </div>

      <LinearProgressbar className="flex-1" value={progress} />
    </div>
  );
};

export default GameProgressbar;
