import {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import GameLoading from "./loading";
import Typo from "../../core/components/typo";
import {ScoreBadge} from "../../core/components/badge";
import RenderIf from "../../core/components/render-if";
import {useGetMission} from "./api/mission";
import RootLayout from "../../layout/root";
import TextToHTML from "../../core/components/text-to-html";
import GameAttributes from "./components/attributes";
import GameLessons from "./components/game-lessons";
import CommentList from "../../core/components/comment/list"
import {useGetMissionRunner, useGetMissionRunnerDetail, useStartMissionRunner} from "./api/missionRunner";
import Button from "../../core/components/button";
import handleError from "../../core/helper/handelError";
import ContentViewer from "./mission-step/content-viewer";
import {toast} from "react-toastify";
import {useAuthContext} from "../Auth/provider/AuthProvider";
import ProfileNotCompleteModal from "../../core/components/modal/profile-not-complete";
import GameSelfFooter from "./components/self-footer";
import GameTeamFooter from "./components/team-footer";
import __ from "../../core/utils/translate/translate";


const MissionPage = () => {
    const {id} = useParams()
    const navigator = useNavigate()
    const {user, team} = useAuthContext()
    const [show, setShow] = useState(false)
    const [modal, setModal] = useState(!user?.firstName ? "profile-not-complete" : "idle")

    const {data: missionData, isError: isErrorMission, error: errorMission} = useGetMission({id});
    const {
        data: runnerData,
        error: runnerError,
        isLoading: runnerLoading,
        isSuccess: runnerSuccess,
        isError: runnerIsError,
        refetch: refetchRunner,
    } = useGetMissionRunner({
        missionId: id, config: {
            retry: false
        }
    });

    const {
        data: runnerDetailData,
        error: runnerDetailError,
        isLoading: runnerDetailLoading,
        isSuccess: runnerDetailSuccess,
        isError: runnerDetailIsError,
        refetch: refetchRunnerDetail,
    } = useGetMissionRunnerDetail({
        runnerId: runnerData?.data?.id, config: {
            retry: false,
            enabled: !!runnerData?.data?.id
        }
    });

    const {
        mutate: startMissionRunner,
        isPending: startMissionRunnerLoading,
        isSuccess: startMissionRunnerSuccess,
        isError: startMissionRunnerIsError,
    } = useStartMissionRunner();

    const [firstStepId, setFirstStepId] = useState();

    useEffect(() => {
        if (!missionData) return;
        setFirstStepId(missionData?.data?.steps?.sort((a, b) => a?.sort - b?.sort)?.[0]?.id)
    }, [missionData]);

    const [passedSteps, setPassedSteps] = useState([]);
    useEffect(() => {
        let steps = [];
        if (!runnerDetailData?.data) return;
        missionData?.data?.steps?.forEach(step => {
            runnerDetailData?.data?.steps?.forEach(runnerStep => {
                if (step?.id === runnerStep?.missionStepId) {
                    steps = [...steps, step?.id]
                }
            })
        })
        setPassedSteps(steps)
    }, [runnerDetailData]);

    useEffect(() => {
        if (missionData?.data?.type !== "team"){
            setShow(true)
        }
    }, [missionData]);


    useEffect(() => {
        if(errorMission?.response?.data?.response_code === "INVALID_MISSION")
        {
            toast.error(errorMission?.response?.data?.message)
            navigator('/app')
        }
    }, [errorMission]);


    if (!missionData) return <GameLoading/>;

    return (
        <RootLayout title={missionData.data?.title ?? ""} >
            <div className={"flex flex-col gap-8 px-4 py-20"}>


                <RenderIf cond={!runnerIsError && runnerData !== null}>

                    {/*<GameProgressbar*/}
                    {/*    gameUserStatus={runnerData.data?.status}*/}
                    {/*    progress={Math.min(runnerData.data?.progress, 100)}*/}
                    {/*/>*/}
                </RenderIf>

                {/*<RenderSwitch*/}
                {/*    conds={[*/}
                {/*        !!missionData.data?.secondsToStart && missionData.data?.secondsToStart < 0,*/}
                {/*        !!missionData.data?.secondsToExpire && missionData.data?.secondsToExpire < 0,*/}
                {/*    ]}*/}
                {/*>*/}
                {/*    {[*/}
                {/*        <div className="col-span-2 flex flex-col gap-1 md:col-span-3 xl:col-span-1" key={0}>*/}
                {/*            <Typo size="xs">تا شروع</Typo>*/}
                {/*            <Timer second={-missionData.data?.secondsToStart} renderFN={GameAttributeTimer} withDays/>*/}
                {/*        </div>,*/}
                {/*        <div className="col-span-2 flex flex-col gap-1 md:col-span-3 xl:col-span-1" key={1}>*/}
                {/*            <Typo size="xs">تا پایان</Typo>*/}
                {/*            <Timer second={-missionData.data?.secondsToExpire} renderFN={GameAttributeTimer} withDays/>*/}
                {/*        </div>,*/}
                {/*    ]}*/}
                {/*</RenderSwitch>*/}

                <div className="flex flex-col gap-2 md:flex-row">
                    <Typo as="h1" weight="bold" className="flex-1">
                        {missionData.data?.title}
                    </Typo>
                    <div className="flex items-center gap-1 self-end">
                        <ScoreBadge type="xp" amount={missionData?.data?.experience} size="sm" withBack hiddenOnZero/>
                        <ScoreBadge type="score" amount={missionData?.data?.score} size="sm" withBack hiddenOnZero/>
                    </div>
                </div>
                <RenderIf cond={!!missionData?.data?.contents}>
                    {missionData?.data?.contents.map((content, i) => (
                        <ContentViewer content={content} key={'missionView' + content?.id + '-' + i} hiddenTitle={true} />
                    ))}
                </RenderIf>


                <div className="grid gap-y-8 xl:grid-cols-3">
                    <div className="xl:order-2 xl:border-r xl:border-purple-3 xl:p-3">
                        <GameAttributes/>
                    </div>

                    <div className="flex flex-col gap-8 xl:order-1 xl:col-span-2 xl:pl-12">
                        <TextToHTML>{missionData.data?.description}</TextToHTML>
                    </div>
                </div>

                <RenderIf cond={missionData.data?.type === 'course'}>
                    <GameLessons passedSteps={passedSteps} runnerId={runnerData?.data?.id}/>
                </RenderIf>

                {/*<div*/}
                {/*    className="mx-auto flex w-full max-w-sm items-center justify-center gap-4 rounded-3xl bg-purple-5 p-4">*/}
                {/*    <RenderIf cond={!['self', 'team'].includes(missionData.data?.type)}>*/}
                {/*        <FancyBox count={missionData.data?.players ?? 0} title="شرکت کننده" type="purple"*/}
                {/*                  textWeight="light"/>*/}
                {/*    </RenderIf>*/}
                {/*    <div className="relative">*/}
                {/*        <FancyBox count={4} title="امتیاز" type="purple" textWeight="light"/>*/}
                {/*        <CommonIcon icon="star" className="absolute -left-6 top-0 h-8 w-8 drop-shadow-lg"/>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <RenderIf cond={!!user?.id}>
                    <RenderIf cond={missionData?.data?.type === "team"} falsyElement={<></>}>
                        <GameTeamFooter runnerData={runnerData?.data} missionData={missionData?.data} setShow={setShow} />
                    </RenderIf>
                </RenderIf>

                <RenderIf cond={runnerIsError}>
                    <RenderIf cond={missionData?.data?.type === "team" ? team?.team?.freeze === true : true}>
                        <div className="flex">
                            <div>
                                <Button
                                    isLoading={startMissionRunnerLoading}
                                    onClick={() => {
                                        startMissionRunner({missionId: id}, {
                                            onSuccess: (data) => {
                                                refetchRunner();
                                            }, onError: (error) => {
                                                handleError(error)
                                            }
                                        })
                                    }} key={16}>
                                    {__("Sign up and start")}
                                </Button>
                            </div>
                        </div>
                    </RenderIf>
                </RenderIf>
                <RenderIf
                    cond={
                    !runnerIsError
                        && missionData.data?.type !== "course"
                        && runnerSuccess
                        && runnerData?.data?.status !== "voting"
                        && runnerData?.data?.status !=="pending_mentor"
                        && runnerData?.data?.status !== "voting_rejected"
                        && runnerData?.data?.status !== "mentor_rejected"
                        && show}>
                    <div className="flex">
                        <div>
                            <Link
                                to={firstStepId ? `/app/missions/${missionData?.data?.id}/${runnerData?.data?.id}/step/${firstStepId}` : 0}>
                                <Button>
                                    {__("Continue")}
                                </Button>
                            </Link>
                        </div>
                    </div>
                </RenderIf>
                <RenderIf cond={!runnerIsError && runnerData?.data?.status === "voting" && runnerSuccess && show}>
                    <div className="flex">
                        <div>
                            <Link
                                to={`/app/missions/${missionData?.data?.id}/${runnerData?.data?.id}/voting`}>
                                <Button>
                                    {__("Voting")}
                                </Button>
                            </Link>
                        </div>
                    </div>
                </RenderIf>

                <RenderIf cond={true}>
                    <CommentList primaryKey={missionData.data?.id} type={'mission'}/>
                </RenderIf>
            </div>
            {/*<RenderIf cond={1}>*/}
            <ProfileNotCompleteModal isOpen={modal === "profile-not-complete"} onClose={() => setModal("idle")}/>
            {/*<DoFirstMissionModal isOpen={modal === "do-first-mission"} onClose={() => setModal("idle")}/>*/}
            {/*</RenderIf>*/}
        </RootLayout>
    );
};

export default MissionPage;
