import {twMerge} from "tailwind-merge";
import {useAuthContext} from "../../../Auth/provider/AuthProvider";
import {useGetPosts} from "../../api/home";
import Skeleton from "../../../../core/components/skeleton";
import {SwiperSimpleCarousel} from "../../../../core/components/swiper";
import {SwiperSlide} from "swiper/react";
import FileView from "../../../../core/components/file-view";
import ScrollView from "../../../../core/components/scroll-view";

const HomeSlider = ({tag, type}) => {

    const {data} = useGetPosts({type: type});

    const handleNavigate = (url) => {
        if (url) window.open(url, "_self");
    };

    if (!data)
        return (
            <div className="flex items-center justify-center">
                <Skeleton className="aspect-4/3 h-64 rounded-xl"/>
            </div>
        );

    if (data?.data?.length === 0) return <></>;

    return (
        <div className="px-4">
            <div className="sm:hidden">
                <SwiperSimpleCarousel loop pagination navigation className="w-full">
                    {data?.data?.map((slide, i) => (
                        <SwiperSlide
                            key={`slide-${i}` + slide?.id}
                            className="w-full overflow-hidden rounded-xl"
                            onClick={() => handleNavigate(slide.url)}
                        >
                            <FileView {...slide?.coverDetail} thumbnail={slide?.coverDetail?.url}
                                      controls className="h-full w-full bg-cover"/>
                        </SwiperSlide>
                    ))}
                </SwiperSimpleCarousel>
            </div>
            <div className="hidden sm:block">
                <ScrollView className="-mx-4 gap-4 px-4">
                    {data?.data?.map((slide, i) => (
                        <div
                            onClick={() => handleNavigate(slide.url)}
                            key={'FileView' + slide?.id +'-'+ i}
                            className={twMerge("w-96 shrink-0", !!slide.url ? "cursor-pointer" : "")}
                        >
                            <FileView
                                {...slide.coverDetail}
                                thumbnail={slide.coverDetail?.url}
                                controls
                                className="w-96 overflow-hidden rounded-xl"
                            />
                        </div>
                    ))}
                </ScrollView>
            </div>
        </div>
    );
};

export default HomeSlider;
