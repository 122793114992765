import {SimpleBadge} from "../../../../core/components/badge";
import RenderIf from "../../../../core/components/render-if";
import Avatar from "../../../../core/components/avatar";
import Rank from "../../../../core/components/rank";
import {useEffect, useState} from "react";
import Typo from "../../../../core/components/typo";
import RankCard from "../rank-card";
import {useAuthContext} from "../../../Auth/provider/AuthProvider";
import {useGetAwards, useGetTopList} from "../../api/award";
import Spinner from "../../../../core/components/spinner";

const AwardsHeader = () => {
    const {team} = useAuthContext()
    const [topTeamRank, setTopTeamRank] = useState([])
    const [myTeamRank, setMyTeamRank] = useState({})
    const [topUserRank, setTopUserRank] = useState([])
    const [myRank, setMyRank] = useState({})
    const [entity, setEntity] = useState("user")
    const [basedOn, setBasedOn] = useState()

    const {
        data: topListData,
        error: topListError,
        isLoading: topListLoading,
        isSuccess: topListSuccess,
        isError: topListIsError,
    } = useGetTopList()

    useEffect(() => {
        setMyRank(topListData?.data?.user?.you)
        setTopUserRank(topListData?.data?.user?.list)
        setMyTeamRank(topListData?.data?.team?.you)
        setTopTeamRank(topListData?.data?.team?.list)
    }, [topListData]);

    useEffect(() => {
        if (topTeamRank?.length > 0){
            setEntity("team")
        }
    }, [topTeamRank]);


    const fullName = function (firstName, lastName){
        let x = ''
        if(!!firstName)
        {
            x += firstName
        }

        if(!!lastName)
        {
            x += ' ' + lastName
        }

        return x.trim()

    }


    return (
        <div className="relative bg-linear-purple-7 px-4 pb-8 pt-20 overflow-hidden" >
            <img src="/images/awards-header.svg" alt="cover" className="absolute -top-0 right-1/2 translate-x-1/2"/>

            <div className="relative z-[1] mx-auto mt-44 flex w-full max-w-md flex-col gap-4">
                <RenderIf cond={!!topListLoading}>
                    <div className={'flex justify-center'}>
                        <Spinner />
                    </div>
                </RenderIf>
                <RenderIf cond={topTeamRank?.length > 0 || topUserRank?.length > 0}>
                    <div className="flex min-h-[328px] flex-col gap-8 rounded-3xl bg-purple-5 p-4">
                        <RenderIf cond={entity === "team"}>
                            <div className="-mt-14 grid grid-cols-3 items-end">
                                <RenderIf cond={true}>
                                    <div className="flex flex-col items-center gap-2">
                                        <RenderIf cond={!!(topTeamRank?.at(1))}>
                                            <Avatar
                                                size="lg"
                                                name={topTeamRank?.at(1)?.name ?? ""}
                                                image={topTeamRank?.at(1)?.logoDetail?.url}
                                                avatarClassName="border-4 border-white"
                                            />
                                            <Rank
                                                title={topTeamRank?.at(1)?.name ?? ""}
                                                place={2}
                                                scoreAmount={topTeamRank?.at(1)?.totalScore ?? 0}
                                                scoreType="score"
                                            />
                                        </RenderIf>
                                    </div>
                                </RenderIf>
                                <RenderIf cond={!!(topTeamRank?.at(0))}>
                                    <div className="flex flex-col items-center gap-2">
                                        <Avatar
                                            size="xl"
                                            name={topTeamRank?.at(0)?.name ?? ""}
                                            image={topTeamRank?.at(0)?.logoDetail?.url}
                                            avatarClassName="border-4 border-white"
                                        />
                                        <Rank
                                            title={topTeamRank?.at(0)?.name ?? ""}
                                            place={1}
                                            scoreAmount={topTeamRank?.at(0)?.totalScore ?? 0}
                                            scoreType="score"
                                        />
                                    </div>
                                </RenderIf>
                                <RenderIf cond={!!(topTeamRank?.at(2))}>
                                    <div className="flex flex-col items-center gap-2">
                                        <Avatar
                                            size="lg"
                                            name={topTeamRank?.at(2)?.name ?? ""}
                                            image={topTeamRank?.at(2)?.logoDetail?.url}
                                            avatarClassName="border-4 border-white"
                                        />
                                        <Rank
                                            title={topTeamRank?.at(2)?.name ?? ""}
                                            place={3}
                                            scoreAmount={topTeamRank?.at(2)?.totalScore ?? 0}
                                            scoreType="score"
                                        />
                                    </div>
                                </RenderIf>
                            </div>

                            <RenderIf cond={!team}>
                                <Typo size="sm" className="text-center">
                                    هنوز عضو تیمی نیستی!
                                </Typo>
                            </RenderIf>
                            <div className="flex flex-col gap-4">
                                <RenderIf cond={!!team}>
                                    <RankCard
                                        // key={i}
                                        XP={0}
                                        name={myTeamRank?.name}
                                        place={myTeamRank?.rank}
                                        score={myTeamRank?.totalScore}
                                        isMe={true}
                                        avatar={myTeamRank?.logoDetail?.url}
                                    />
                                </RenderIf>
                            </div>
                        </RenderIf>
                        <RenderIf cond={entity === "user"}>
                            <div className="-mt-14 grid grid-cols-3 items-end">
                                <RenderIf cond={true}>
                                    <div className="flex flex-col items-center gap-2">
                                        <RenderIf cond={!!(topUserRank?.at(1))}>
                                            <Avatar
                                                size="lg"
                                                name={fullName(topUserRank?.at(1)?.firstName, topUserRank?.at(1)?.lastName)}
                                                image={topUserRank?.at(1)?.avatarDetail?.url}
                                                avatarClassName="border-4 border-white"
                                            />
                                            <Rank
                                                title={fullName(topUserRank?.at(1)?.firstName, topUserRank?.at(1)?.lastName)}
                                                place={2}
                                                scoreAmount={topUserRank?.at(1)?.totalExperience ?? 0}
                                                scoreType="xp"
                                            />
                                        </RenderIf>
                                    </div>
                                </RenderIf>
                                <RenderIf cond={!!(topUserRank?.at(0))}>
                                    <div className="flex flex-col items-center gap-2">
                                        <Avatar
                                            size="xl"
                                            name={fullName(topUserRank?.at(0)?.firstName, topUserRank?.at(0)?.lastName)}
                                            image={topUserRank?.at(0)?.avatarDetail?.url}
                                            avatarClassName="border-4 border-white"
                                        />
                                        <Rank
                                            title={fullName(topUserRank?.at(0)?.firstName, topUserRank?.at(0)?.lastName)}
                                            place={1}
                                            scoreAmount={topUserRank?.at(0)?.totalExperience ?? 0}
                                            scoreType="xp"
                                        />
                                    </div>
                                </RenderIf>
                                <RenderIf cond={!!(topUserRank?.at(2))}>
                                    <div className="flex flex-col items-center gap-2">
                                        <Avatar
                                            size="lg"
                                            name={fullName(topUserRank?.at(2)?.firstName, topUserRank?.at(2)?.lastName)}
                                            image={topUserRank?.at(2)?.avatarDetail?.url}
                                            avatarClassName="border-4 border-white"
                                        />
                                        <Rank
                                            title={fullName(topUserRank?.at(2)?.firstName, topUserRank?.at(2)?.lastName)}
                                            place={3}
                                            scoreAmount={topUserRank?.at(2)?.totalExperience ?? 0}
                                            scoreType="xp"
                                        />
                                    </div>
                                </RenderIf>
                            </div>

                            <div className="flex flex-col gap-4">
                                <RenderIf cond={!!myRank}>
                                    <RankCard
                                        // key={i}
                                        XP={myRank?.totalExperience}
                                        name={fullName(myRank?.firstName, myRank?.lastName)}
                                        place={myRank?.rank}
                                        score={myRank?.totalScore}
                                        isMe={true}
                                        avatar={myRank?.avatarDetail?.url}
                                    />
                                </RenderIf>
                                {/*{myUsersRanksData?.data*/}
                                {/*    .slice(0, 3)*/}
                                {/*    .map((_user, i) => (*/}
                                {/*        <RankCard*/}
                                {/*            key={i}*/}
                                {/*            XP={_user?.totalExperience}*/}
                                {/*            name={_user?.name}*/}
                                {/*            place={_user?.rank}*/}
                                {/*            score={0}*/}
                                {/*            isMe={_user?.uuid === myUUID}*/}
                                {/*            avatar={_user?.avatarDetail}*/}
                                {/*        />*/}
                                {/*    ))}*/}
                            </div>
                        </RenderIf>

                        <div className="mt-auto flex w-full flex-wrap items-center justify-center gap-2">
                            <RenderIf cond={topTeamRank?.length > 0}>
                                <SimpleBadge inActiveColor="bg-white" isActive={entity === "team"}
                                             onClick={() => setEntity("team")}>
                                    تیمی
                                </SimpleBadge>
                            </RenderIf>
                            <RenderIf cond={topUserRank?.length > 0}>
                                <SimpleBadge inActiveColor="bg-white" isActive={entity === "user"}
                                             onClick={() => setEntity("user")}>
                                    فردی
                                </SimpleBadge>
                            </RenderIf>

                            <RenderIf cond={false}>
                                <div>|</div>
                                <SimpleBadge
                                    inActiveColor="bg-white"
                                    isActive={basedOn === "nojahan"}
                                    onClick={() => setBasedOn("nojahan")}
                                >
                                    نوجهان
                                </SimpleBadge>
                                <SimpleBadge inActiveColor="bg-white" isActive={basedOn === "club"}
                                             onClick={() => setBasedOn("club")}>
                                    کانون
                                </SimpleBadge>
                            </RenderIf>

                            {/*<Button size="sm" scale="fit" onClick={() => navigate("ROUTES.AWARD.RANKS()")}>*/}
                            {/*    رتبه بندی*/}
                            {/*</Button>*/}
                        </div>
                    </div>
                </RenderIf>
                {/*<div*/}
                {/*    className="relative flex justify-center gap-8 overflow-hidden rounded-3xl bg-white bg-linear-orange-45 p-4">*/}
                {/*    <img*/}
                {/*        src="/images/pattern01.jpg"*/}
                {/*        alt="pattern"*/}
                {/*        className="absolute inset-0 h-full w-full bg-cover object-cover opacity-10 mix-blend-screen"*/}
                {/*        loading="lazy"*/}
                {/*    />*/}
                {/*    <RenderIf cond={1}>*/}
                {/*        <FancyBox count={team?.totalScore ?? 0} title="امتیاز تیم" type="orange"/>*/}
                {/*    </RenderIf>*/}
                {/*    <FancyBox count={1} title="تجربه‌های تو" type="orange"/>*/}
                {/*    <RenderIf cond={1}>*/}
                {/*        <FancyBox count={group?.totalScore ?? 0} title={`امتیاز ${group?.label}`} type="orange"/>*/}
                {/*    </RenderIf>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default AwardsHeader;
