import React, {useState} from 'react';
import Button from "../../../../core/components/button";
import __ from "../../../../core/utils/translate/translate";
import Modal from "../../../../core/components/modal";
import Typo from "../../../../core/components/typo";
import {CommonIcon} from "../../../../core/components/icon";
import RenderIf from "../../../../core/components/render-if";

export default function JoinTeamModal({onClick, name, freeze}) {
    const [modal, setModal] = useState("idle");

    return (
        <>

            <RenderIf cond={!freeze}>
                <Button size={'xs'} containerClassName="self-end" scale="fit" onClick={() => {
                    setModal("join")
                }}>
                    درخواست عضویت
                    <CommonIcon icon={'unlock_lucide'} stroke="white" className={'h-5 w-5'} />
                </Button>
            </RenderIf>

            <RenderIf cond={!!freeze}>
                <Button size={'xs'} containerClassName="self-end" scale="fit" type={'default'}>
                    یارگیری تیم بسته شده
                    <CommonIcon icon={'lock_lucide'} stroke="black" className={'h-5 w-5'} />
                </Button>
            </RenderIf>
            <Modal
                isOpen={modal === "join"}
                onClose={() => setModal("idle")}
                title={'عضویت در تیم'}
                className="flex flex-col gap-2"
            >
                <Typo>{`مطمئنی میخوای عضو تیم ${name} بشی؟`}</Typo>
                <div className="flex justify-center gap-4">
                    <Button
                        type="danger"
                        onClick={onClick}
                    >
                        {__("Yes")}
                    </Button>
                    <Button onClick={() => setModal("idle")} type="danger-light">
                        {__("No")}
                    </Button>
                </div>
            </Modal>
        </>
    );
}