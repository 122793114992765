import React from 'react';
import {CommonIcon} from "../../../../core/components/icon";
import RenderIf from "../../../../core/components/render-if";

export default function NotificationIcon({notification}) {
    return (
        <>
            <CommonIcon icon="add-circle" stroke="transparent" fill="#0EA9A9" />
                <RenderIf cond={false}>
                    <CommonIcon icon="add" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="add-circle" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="add-user" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="arrow" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="attachment" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="award" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="award-hover" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="award2" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="calendar" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="chart" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="chevron" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="club" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="club-hover" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="danger" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="document" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="document-copy" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="document-download" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="document-upload" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="edit" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="edit2" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="eye" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="flag" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="home" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="home-hover" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="lamp" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="lock" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="logout" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="message" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="message-hover" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="mission" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="mission-hover" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="notification" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="pause" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="people" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="play" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="play-circle" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="profile" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="profile-hover" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="quote" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="refresh" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="search" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="send" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="score" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="score-white" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="star" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="tick-circle" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="two-user" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="xp" stroke="transparent" fill="#0EA9A9" />
                    <CommonIcon icon="xp-white" stroke="transparent" fill="#0EA9A9" />
                </RenderIf>
        </>
    );
}