import {useQuery} from "@tanstack/react-query";
import {getAwardDetail, getAwards, getTopList} from "../../../data/api/award";

export const useGetAwards = () => {
    return useQuery({
        queryKey: ['home', 'awards'],
        queryFn: () => getAwards(),
    })
}

export const useGetAwardDetail = (id) => {
    return useQuery({
        queryKey: ['home', 'award', id],
        queryFn: () => getAwardDetail(id),
    })
}

export const useGetTopList = () => {
    return useQuery({
        queryKey: ['topList'],
        queryFn: () => getTopList()
    })
}