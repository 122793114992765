import __ from "../../../../core/utils/translate/translate";

export const NOJAHAN_LEVEL_DATA = [
    {
        position: 1,
        title: __("Invite"),
        description: __("In the first stage, you get to know newbies and invite your friends to join together to form a strong team."),
        image: "/images/intro-invite.svg",
    },
    {
        position: 2,
        title: __("Make a team"),
        description: __("At this stage, the number of team members is determined exactly and Nojahan, an expert adult coach, adds to your team."),
        image: "/images/intro-make-team.svg",
    },
    {
        position: 3,
        title: __("Experience"),
        description:__("In this stage, you must choose at least 7 topics from among the topics and experience at least one cool mission (of your choice) in each."),
        image: "/images/intro-take-experience.svg",
    },
    {
        position: 4,
        title: __("Be professional"),
        description:__("When you get here, you become a professional; you get specialized training and do important research. At this stage, its time to go to your special mission!"),
        image: "/images/intro-be-professional.svg",
    },
];
