import {useNavigate} from "react-router-dom";
import {twMerge} from "tailwind-merge";
import {useScrollDirection} from "../../core/hooks/useScrollDirection";
import {CommonIcon} from "../../core/components/icon";
import Typo from "../../core/components/typo";
import HumberMenu from "../../core/providers/HumberMenu";

const RootHeader = ({title, navigateTo}) => {
    const scrollDirection = useScrollDirection();
    const navigate = useNavigate();

    return (
        <div
            className={twMerge(
                "fixed top-0 z-[2] w-full rounded-b-3xl bg-white px-4 py-2.5 shadow-[0px_4px_30px_0px_rgba(3,64,160,0.15)] transition-all",
                scrollDirection === "down" ? "-top-full" : "top-0"
            )}
        >
            <div className="flex items-center justify-between">
                <CommonIcon icon="arrow" stroke="#191B23" onClick={() => navigate(navigateTo ?? -1, {replace: true})}
                            className="rotate-180 cursor-pointer"/>
                <Typo size="sm" weight="semi-bold" className="ui-clamp-1">
                    {title}
                </Typo>
                <div>
                    <HumberMenu></HumberMenu>
                </div>
            </div>
        </div>
    );
};

export default RootHeader;
