import { twMerge } from "tailwind-merge";
import RenderIf from "../../render-if";
import {CommonIcon} from "../../icon";
import {CounterBadge} from "../../badge";
import Typo from "../../typo";

const RoadMapStepRight = ({ index, currentIndex, isLast = false, title }) => {
  const getBackgroundColor = () => {
    if (index < currentIndex) return ["bg-linear-green-125", "#0EA9A9"];
    else if (index === currentIndex) return ["bg-linear-purple-345", "#301466"];
    else return ["bg-white", "#FFF"];
  };

  const getTextType = () => {
    if (index > currentIndex) return "accent";
    else return "on-accent";
  };

  return (
    <div className="flex flex-col gap-8">
      <div className="pl-8">
        <div
          className={twMerge(
            "relative flex h-16 min-w-[8rem] shrink-0 items-center justify-center rounded-lg rounded-br-none px-2 drop-shadow md:h-20 md:w-48 md:min-w-[12rem]",
            getBackgroundColor()[0]
          )}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="53"
            height="17"
            viewBox="0 0 53 17"
            fill={getBackgroundColor()[1]}
            className="absolute right-0 top-full"
          >
            <path d="M0.208984 0C15.5 0 18.9919 17 34.5 17C46.5 17 51.5011 6.5 53.0011 0H0.208984Z" />
          </svg>
          <RenderIf cond={index < currentIndex}>
            <CommonIcon icon="tick-circle" className="absolute -bottom-3 right-2" stroke="transparent" fill="#FFF" />
          </RenderIf>
          <RenderIf cond={index === currentIndex}>
            <CommonIcon icon="play" fill="#FFF" className="absolute -bottom-3 right-2" />
          </RenderIf>
          <RenderIf cond={index > currentIndex}>
            <CounterBadge type="accent" size="sm" className="absolute -bottom-2 right-3">
              {index + 1}
            </CounterBadge>
          </RenderIf>
          <Typo type={getTextType()} weight="bold" className="ui-clamp-2">
            {title}
          </Typo>
        </div>
      </div>

      <div className="flex items-center">
        <div
          className={twMerge(
            "w-4 border-t-4 border-dashed border-white",
            index === 0 && "invisible",
            index <= currentIndex && "border-solid border-purple-1 "
          )}
        />
        <div
          className={twMerge(
            "h-4 w-4 rounded-full border-2 border-purple-1 bg-white",
            index < currentIndex && "bg-purple-1"
          )}
        />
        <div
          className={twMerge(
            "flex-1 border-t-4 border-dashed border-white",
            isLast && "invisible",
            index < currentIndex && "border-solid border-purple-1 "
          )}
        />
      </div>
    </div>
  );
};

export default RoadMapStepRight;
