import { twMerge } from "tailwind-merge";
import Typo from "../../../../core/components/typo";
import Avatar from "../../../../core/components/avatar";
import {ScoreBadge} from "../../../../core/components/badge";

const RankCard = ({ XP, name, place, score, isMe = false, avatar, isLight = false }) => {
  return (
    <div className="flex items-stretch gap-2">
      <div
        className={twMerge(
          "flex w-14 shrink-0 items-center justify-center rounded-xl",
          isMe ? "bg-purple-1" : isLight ? "bg-purple-5/50" : "bg-purple-5"
        )}
      >
        <Typo weight="bold" size="2xl" type={isMe || isLight ? "on-accent" : "secondary"}>
          {place}
        </Typo>
      </div>
      <div
        className={twMerge(
          "flex flex-1 items-center gap-2 rounded-full p-1 pl-4",
          isMe ? "bg-white shadow" : isLight ? "bg-purple-5/50" : "bg-purple-5"
        )}
      >
        <Avatar name={name} image={avatar} size="sm" />
        <Typo type={!isMe && isLight ? "on-accent" : "primary"} size="xs" weight="bold" className="flex-1">
          {name}
        </Typo>
        <ScoreBadge type="xp" amount={XP} hiddenOnZero color={isMe || !isLight ? "primary" : "white"} />
        <ScoreBadge type="score" amount={score} hiddenOnZero color={isMe || !isLight ? "primary" : "white"} />
      </div>
    </div>
  );
};

export default RankCard;
