import {useNavigate} from "react-router-dom";
import {CommonIcon} from "../../../../icon";
import RenderIf from "../../../../render-if";
import {CounterBadge} from "../../../../badge";
import {useAuthContext} from "../../../../../../feature/Auth/provider/AuthProvider";


const HeaderMainNotification = () => {
  const navigate = useNavigate();
  const {dashboard} = useAuthContext();


  return (
    <div className="relative cursor-pointer" onClick={() => navigate('/app/notification')}>
      <CommonIcon icon="notification" />
      <RenderIf cond={dashboard?.countNotification > 0}>
        <CounterBadge className="absolute -right-1 -top-1">{dashboard?.countNotification}</CounterBadge>
      </RenderIf>
    </div>
  );
};

export default HeaderMainNotification;
