import DeleteComment from "./components/delete";
import CommentCardErrorReport from "./components/error-report";
import UpdateComment from "./components/update";
import Typo from "../../typo";
import RenderIf from "../../render-if";
import Avatar from "../../avatar";
import __ from "../../../utils/translate/translate";

const CommentCard= ({
  name = "کاربر حذف شده",
  location,
  teamName,
  comment,
    avatar,
  createdAt,
  refetch,
  isMine = false,
  id,
}) => {
  let displayName = ''
  if(!!name) {
    displayName = name
  }
  else
  {
    if(!name){
      displayName = __("No name")
    }
  }
  return (
    <div className="flex flex-col gap-5 rounded-xl bg-white p-2 px-3">
      <div className="flex items-center gap-3">
        <Avatar image={avatar} name={displayName} size="xs" />
        <div className="flex flex-col">
          <Typo as="h2" size="sm" weight="medium">
            {displayName}
          </Typo>
          <Typo as="p" size="xs" type="secondary">
            {[teamName, location].filter((text) => !!text).join(" | ")}
          </Typo>
        </div>
      </div>
      <Typo as="p" size="xs" className={'overflow-scroll'}>
        {comment}
      </Typo>
      <div className="flex items-center justify-end gap-2">
        <Typo type="secondary" as="p" size="xs" className="text-left">
          {/* {`${createdAt.format("jDD jMMMM jYYYY")} ساعت ${createdAt.format("HH:mm")}`} */}
          {createdAt}
        </Typo>
        <RenderIf cond={isMine}>
          <UpdateComment id={id} comment={comment} refetch={refetch} />
          <DeleteComment id={id} refetch={refetch}/>
        </RenderIf>
        <RenderIf cond={!isMine}>
          <CommentCardErrorReport relatedId={id} />
        </RenderIf>
      </div>
    </div>
  );
};

export default CommentCard;
