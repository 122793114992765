import Skeleton from "../../skeleton";

const SimpleGameCardLoading = () => {
  return (
    <div className="flex aspect-square w-52 shrink-0 flex-col gap-1 rounded-xl bg-white p-2">
      <div className="flex items-center justify-between">
        <div className="flex w-1/2 items-center gap-1">
          <Skeleton className="h-8 w-8 shrink-0 rounded-full" />
          <Skeleton className="h-3 flex-1" />
        </div>
        <Skeleton className="h-7 w-16 rounded-full" />
      </div>
      <Skeleton className="aspect-4/3 w-full rounded-xl" />
      <Skeleton className="h-4 w-1/2 rounded" />
    </div>
  );
};

export default SimpleGameCardLoading;
