import Typo from "../../../../../core/components/typo";

const StepSchedule = ({title, items}) => {
    return (
        <div className="mb-2 mt-2 flex w-full flex-col items-start gap-3">
            <Typo weight="bold" size="lg">
                {title}
            </Typo>

            <div className="flex w-full flex-col items-start gap-3 border border-[#D6D7FD] rounded-lg">
                <div
                     className="flex w-full items-center justify-between bg-purple-4  border-b border-[#D6D7FD] p-1 rounded-t">
                    <Typo size="sm" className="flex-1 font-semibold">
                        عنوان
                    </Typo>

                    <Typo size="sm" className={'font-semibold'}>زمان</Typo>
                </div>
                {items?.map((item, index) => (
                    <div key={index}
                         className={`flex w-full items-center justify-between ${items?.length === (index + 1) ? '' : 'border-b'} border-[#D6D7FD] p-1`}>
                        <Typo size="sm" className="flex-1">
                            {item.title}
                        </Typo>

                        <Typo size="sm">{item.time}</Typo>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default StepSchedule;
