import Typo from "../../../../../core/components/typo";
import FileView from "../../../../../core/components/file-view";
import RenderIf from "../../../../../core/components/render-if";

const StepDescription = ({title, description, file}) => {

    return (
        <div className="flex flex-col items-start">
            <Typo size="lg" weight="bold">
                {title}
            </Typo>

            <Typo type="secondary" className="whitespace-pre-wrap">{description}</Typo>
            <RenderIf cond={!!file}>
                <div
                    className="grid gap-x-4 gap-y-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 items-start ">
                    <FileView {...file} className="rounded-xl"/>
                </div>
            </RenderIf>
        </div>
    );
};

export default StepDescription;
