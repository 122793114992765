import { useSearchParams } from "react-router-dom";

const useQueryString = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const init = (props) => {
        setSearchParams(props, { replace: true });
    };

    const get = (key, _default = null) => {
        return searchParams.get(key) ?? _default;
    };
    const set = (key, value) => {
        if (typeof value === typeof null || typeof value === typeof undefined) searchParams.delete(key);
        else searchParams.set(key, `${value}`);
        setSearchParams(searchParams, { replace: true });
    };
    const getAll = (key, _default) => {
        const temp = searchParams.getAll(key);
        return temp.length > 0 ? temp : _default;
    };

    const setAll = (key, values = []) => {
        searchParams.delete(key);
        values.forEach((value) => searchParams.append(key, value));
        setSearchParams(searchParams, { replace: true });
    };

    const remove = (key) => {
        searchParams.delete(key);
        setSearchParams(searchParams);
    };

    return {
        queryString: searchParams.toString(),
        queryStringFN: {
            init,
            get,
            set,
            getAll,
            setAll,
            remove,
        },
    };
};

export default useQueryString;
