import React from 'react';
import {useNavigate} from "react-router-dom";
import Modal from "../index";
import Typo from "../../typo";
import Button from "../../button";
import {useAuthContext} from "../../../../feature/Auth/provider/AuthProvider";

export default function NewNotification() {
    const navigate = useNavigate();
    const{youHaveNewMessage, setYouHaveNewMessage} = useAuthContext();

    const handleOnClick = () => {
        setYouHaveNewMessage(false);
        navigate('/app/notification')
        // onClose();
    };
    const seenLater = () => {
        setYouHaveNewMessage(false);
    }
    return (
        <Modal isOpen={youHaveNewMessage} title={'پیام سیستم'} className="flex flex-col gap-2" onClose={seenLater}>
            <Typo className="text-center flex flex-col">
                <span> حواست هست؟</span>
                <span>چند تا پیام جدید داری که هنوز نخوندی!</span>
            </Typo>
            <div className={'flex justify-center gap-2'}>
                <Button onClick={handleOnClick} containerClassName={'max-w-[10rem] m-0'}>ببینم</Button>
                <Button onClick={seenLater} type={'default'} containerClassName={'max-w-[5rem] m-0'}>بی‌خیال</Button>
            </div>
        </Modal>
    );
}

