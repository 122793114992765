import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { motion } from "framer-motion";
import { InputLabel } from "..";
import RenderIf from "../../render-if";
import Typo from "../../typo";

const OTPInput = (
  {
    maxLength = [1, 1, 1, 1, 1],
    autoFocus = false,
    label = "",
    inputMode = "decimal",
    required = false,
    name = "",
    liveValidate = false,
    onComplete,
    onKeyDown,
  },
  ref
) => {
  const [value, setValue] = useState("");
  const [error, setError] = useState("");

  const refInputs = maxLength.map(() => useRef(null));

  const generateValue = () => refInputs.map((refInput) => refInput.current.value).join("");

  const handleOnChange = (event, index) => {
    if (liveValidate) {
      const { error } = validateOTP();
      setError(error);
    } else if (error.length > 0) setError("");
    if (event.target.value.length >= maxLength[index])
      refInputs[Math.min(index + 1, maxLength.length - 1)].current.focus();
    if (index === maxLength.length - 1 && generateValue().length === maxLength.length) {
      onComplete?.(generateValue());
    }
    setValue(generateValue());
  };

  const handleOnKeyDown = (event, index) => {
    if (event.key === "Backspace" && event.currentTarget.value.length > 0) return;
    else if (event.key === "Backspace") refInputs[Math.max(index - 1, 0)].current.focus();
    onKeyDown?.(event);
  };

  const validateOTP = () => {
    if (required && value.length === 0) {
      return {
        validate: false,
        error: "------",
      };
    } else if ((value.length > 0 || required) && value.length < maxLength.length) {
      return {
        validate: false,
        error: "=--------=",
      };
    } else return { validate: true, error: "" };
  };

  useImperativeHandle(ref, () => ({
    getValue() {
      return value;
    },
    validate() {
      const { error, validate } = validateOTP();

      if (!validate) {
        refInputs[0].current.select();
        setError(error);
      }
      return validate;
    },
    setError(error) {
      refInputs[0].current.select();
      setError(error);
    },
  }));

  return (
    <div className="flex flex-col gap-2">
      <InputLabel label={label} required={required} />

      <div className="flex gap-4" dir="ltr">
        {maxLength.map((_, i) => (
          <motion.input
            animate={{
              x: error.length > 0 ? [20, -20, 15, -15, 10, -10, 0] : [],
            }}
            transition={{ duration: 0.5 }}
            autoFocus={i === 0 && autoFocus}
            onFocus={(e) => e.target.select()}
            name={`input_${i}`}
            key={'input' + 'otp' + i}
            className={twMerge(
              "border-b p-2 text-center",
              error.length > 0 ? "border-b-red focus:border-b-red" : "border-b-purple-4 focus:border-purple-1"
            )}
            maxLength={maxLength[i]}
            inputMode={inputMode}
            onChange={(event) => handleOnChange(event, i)}
            ref={refInputs[i]}
            onKeyDown={(event) => handleOnKeyDown(event, i)}
          />
        ))}
      </div>
      <RenderIf cond={error.length > 0}>
        <motion.div animate={{ y: 0, opacity: 1 }} initial={{ y: -20, opacity: 0 }}>
          <Typo as="p" size="xs" type="danger">
            {error}
          </Typo>
        </motion.div>
      </RenderIf>
    </div>
  );
};

export default forwardRef(OTPInput);
