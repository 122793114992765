import React, {Fragment, useEffect, useRef, useState} from 'react';
import {fullName} from "../../../../core/helper/fullName";
import FileViewExplore from "../../../../core/components/file-view-explore";
import {Heart, MessageCircle,Eye} from "lucide-react";
import CommentEXP from "../commentEXP";
import SendCommentEXP from "../sendComment";
import {useAuthContext} from "../../../Auth/provider/AuthProvider";
import {usePostAction} from "./api";
import {BottomSheet} from "../bottomSheet";
import {getCurrentTimestamp, getJalaliDate} from "../../../../core/utils/date";
import {useNavigate} from "react-router-dom";
import {Link} from "react-router-dom"
import {useListComments} from "../../../../core/components/comment/api/comment";
import LoadMore from "../../../../core/components/loadmore";
import ErrorReportModal from "../../../../core/components/modal/error-report";
import {CommonIcon} from "../../../../core/components/icon";
import DropDownBox from "../../../../core/components/drop-down-box";
import RenderIf from "../../../../core/components/render-if";
import CommentNotFond from "../../../../core/components/not-found/comment";

function PostCardEXP({post}) {
    const {user} = useAuthContext()
    const [openErrorReport, setOpenErrorReport] = useState(false)
    const refDropBox = useRef(null);
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false)
    const [liked, setLiked] = useState(false)
    const [likesCount, setLikesCount] = useState(0)
    const [comments, setComments] = useState([])
    const postRef = useRef(null);
    const timeoutRef = useRef(null);
    const {mutate, isPending , isError , isSuccess} = usePostAction()
    const [viewed, setViewed] = useState("")

    const handleDropBoxItemClick = (_item) => {
        if (_item.id === "report") setOpenErrorReport(true);
    };

    const {
        data: ListComments,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
        refetch
    } = useListComments(
        {mission_runner_report_id: post?.id},
        {enabled: isOpen}
    );

    useEffect(() => {
        if (isOpen === false){
            setComments(post?.meta?.comments)
        }
    }, [isOpen]);

    useEffect(() => {
        setLikesCount(post?.totalLike ?? 0)
        setComments(post?.meta?.comments)
        if (post?.likes?.length > 0){
            setLiked(true)
        }
    }, [post]);


    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];

                if (entry.isIntersecting) {
                    timeoutRef.current = setTimeout(() => {
                        if (viewed !== post?.id){
                            setViewed(post?.id)
                            mutate(
                                {
                                    missionRunnerReportId: post?.id,
                                    type: "view"
                                },
                                {
                                    onSuccess: () => {

                                    },
                                    onError: (error) => {
                                        // handleError(error)
                                    }
                                }
                            );
                        }

                    }, 1000); // 1 second timer
                } else {
                    return clearTimeout(timeoutRef.current); // Cancel if user scrolls away
                }
            },
            { threshold: 1.0 } // Post must be fully visible
        );

        if (postRef.current) {
            observer.observe(postRef.current);
        }

        return () => {
            if (postRef.current) {
                observer.unobserve(postRef.current);
            }
            clearTimeout(timeoutRef.current);
        };
    }, [post?.id]);


    const like = function (){
        setLiked(!liked)
        if (liked === false){
            setLikesCount(likesCount + 1)
        } if (liked === true) {
            setLikesCount(likesCount - 1)
        }
        mutate(
            {
                missionRunnerReportId: post?.id,
                type: "like"
            },
            {
                onSuccess: () => {
                    // setLiked(!liked);
                },
                onError: (error) => {
                    // handleError(error)
                }
            }
        );
    }
    return (
        <div
            className="relative bg-white mb-4 sm:rounded-lg overflow-hidden"
        >
            {/* Post Header */}
            <div className="p-4 border-b">
                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <div className="w-8 h-8 rounded-full bg-gray-200 ml-3">
                            <img className={'rounded-full'}
                                 src={(post?.meta?.runner?.team?.logoDetail?.url ?? post?.meta?.runner?.user?.avatarDetail?.url) ?? '/images/avatarDefault.png'}
                                 alt={post?.meta?.runner?.user?.id}/>
                        </div>
                        <div className={'flex flex-col'}>
                            <span
                                className="font-semibold text-sm"
                                ref={postRef}
                            > {post?.meta?.runner?.team?.name ?? fullName(post?.meta?.runner?.user?.firstName, post?.meta?.runner?.user?.lastName)} </span>
                            <div>
                                <Link to={`/app/explore?name=${post?.meta?.runner?.user?.provinceDetail?.name}`}>
                                    <span className={"text-xs cursor-pointer"}>{post?.meta?.runner?.user?.provinceDetail?.name} </span>
                                </Link>
                                <Link to={`/app/explore?name=${post?.meta?.runner?.user?.cityDetail?.name}`}>
                                    <span className={"text-xs cursor-pointer"} >{post?.meta?.runner?.user?.cityDetail?.name}</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className={'relative'}>
                        <CommonIcon
                            icon="more"
                            className="h-4 w-4 cursor-pointer"
                            stroke="#707489"
                            onClick={() => refDropBox.current.toggleVisibility()}
                        />
                        <DropDownBox
                            ref={refDropBox}
                            initOpen={false}
                            className="left-0 top-0"
                            onChange={handleDropBoxItemClick}
                            items={[
                                {
                                    id: "report",
                                    title: 'گزارش خطا',
                                    icon: <CommonIcon
                                        icon="flag"
                                        className="h-4 w-4 cursor-pointer"
                                        stroke="#707489"
                                    />,
                                },
                            ]}
                        />
                    </div>
                    {/*<MoreHorizontal className="h-5 w-5 text-gray-500"/>*/}
                </div>
            </div>

            {/* Post Image */}
            <div className="relative w-full">
                <FileViewExplore detail={post?.fileDetail}
                                 controls
                                 className={'w-full aspect-square object-cover'}
                />
            </div>

            {/* Post Actions and Comments */}
            <div className="p-4">
                <div className="flex items-center justify-between mb-4">
                    <div></div>
                    <div className="flex items-center gap-2">
                        <MessageCircle
                            onClick={()=> setIsOpen(true)}
                            className="h-6 w-6 hover:text-blue-500 cursor-pointer"/>
                        <Heart onClick={()=> like()}
                               fill={!!liked ? 'red' : 'white'}
                               strokeWidth={!!liked ? 0 : 2}
                               className="h-6 w-6 hover:text-red-500 cursor-pointer"/>
                        {/*<Share2 className="h-6 w-6 hover:text-blue-500 cursor-pointer"/>*/}
                    </div>
                    {/*<Bookmark className="h-6 w-6 hover:text-yellow-500 cursor-pointer"/>*/}
                </div>
                <p className="flex justify-end font-semibold mb-2"> پسند {likesCount}</p>

                <div className="space-y-4">
                    <div className="flex items-start">
                        {/*<div className="w-8 h-8 rounded-full bg-gray-200"></div>*/}
                        <div>
                            <p className={'text-md '}>
                                {post?.caption}
                            </p>
                            <p className="text-gray-500 text-sm mt-1">{getJalaliDate(post?.createdAt)}</p>
                        </div>
                    </div>
                    <p className={'flex gap-2 justify-end text-gray-7 mb-2'}>{post?.totalView}<Eye strokeWidth={1}/></p>
                    {/*{post?.comments?.map((comment, index) => (*/}
                    {/*    <CommentEXP comment={comment} key={`${comment?.id}-${index}` + '-' + comment?.user?.id}/>*/}
                    {/*))}*/}
                </div>
                {/* Add Comment */}
                {/*<SendCommentEXP missionRunnerReportId={post?.id}/>*/}
            </div>
            <BottomSheet isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <RenderIf cond={ListComments?.pages?.at(0)?.data?.length === 0 && comments?.length === 0}>
                    <CommentNotFond />
                </RenderIf>
                {comments?.map((comment, index) => (
                    <CommentEXP comment={comment} key={'post-comment-list' + comment?.id + '-'+ index + '-' + comment?.user?.id}/>
                ))}
                {ListComments?.pages?.map((data,i)=>(
                    <Fragment key={'missionRunnerComment' + i}>
                        {data?.data?.map((comment,index)=> {
                            if ((comments?.map(x=>x.id).includes(comment.id))) {
                                return null
                            } else {
                                return <CommentEXP comment={comment}
                                                   key={'post-comment-list' + comment?.id + '-'+ index + '-' + comment?.user?.id}
                                />
                            }
                        })}
                    </Fragment>
                ))}
                <LoadMore
                    auto
                    fetchNextPage={fetchNextPage}
                    hasNextPage={hasNextPage === true}
                    isFetchingNextPage={isFetchingNextPage}
                />
                <SendCommentEXP missionRunnerReportId={post?.id}
                                setComment={(comment)=> setComments([{comment: comment,createdAt: getCurrentTimestamp(),myStatus: 'pending' ,user:user},...comments])}/>
            </BottomSheet>
            <ErrorReportModal
                isOpen={openErrorReport}
                onClose={() => setOpenErrorReport(false)}
                related={"mission_runner_report"}
                relatedId={post?.id}
            />
        </div>
    );
}

export default PostCardEXP;