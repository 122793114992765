import useQueryString from "../../../../core/hooks/useQueryString";
// import RenderSwitch from "../../../../core/components/render-switch";
// import RenderIf from "../../../../core/components/render-if";
// import Typo from "../../../../core/components/typo";
// import {ScoreBadge} from "../../../../core/components/badge";
// import {Fragment} from "react";
// import ExperienceGameDoneNotificationCard from "../experience-game-done";
// import ExperienceExamDoneNotificationCard from "../experience-exam-done";
// import ExperienceSolutionExtraNotificationCard from "../experience-solution-extra";
// import ExperienceProfileCompletedNotificationCard from "../experience-profile-completed";
// import ExperienceNotificationCard from "../experience";
// import ScoreGameDoneNotificationCard from "../score-game-done";
// import ScoreNotificationCard from "../score";
// import ScoreSolutionExtraNotificationCard from "../score-solution-extra";
// import Spinner from "../../../../core/components/spinner";
// import LoadMore from "../../../../core/components/loadmore";


const ScoreList = () => {
  // const { uuid, team } = useUser();
  const { queryStringFN } = useQueryString();

  // const {
  //   data: scoresData,
  //   fetchNextPage,
  //   hasNextPage,
  //   isFetchingNextPage,
  //   isFetching,
  // } = useScoreGetAllInfinite({
  //   props: {
  //     teamUUID: queryStringFN.get("name") === "score" ? team?.uuid : undefined,
  //     userUUID: queryStringFN.get("name") === "experience" ? uuid : undefined,
  //   },
  //   enabled: (queryStringFN.get("name") === "score" && !!team) || queryStringFN.get("name") === "experience",
  // });
  //
  // const { data: userData } = useUserGet({ props: {} });
    if (1===1) return null
  // return (
  //   <>
  //     <RenderSwitch
  //       conds={[
  //         !isFetching &&
  //           (scoresData?.pages.at(0)?.data.length ?? 0) === 0 &&
  //           queryStringFN.get("name", "experience") === "experience",
  //         !team && queryStringFN.get("name") === "score",
  //         !isFetching &&
  //           (scoresData?.pages.at(0)?.data.length ?? 0) === 0 &&
  //           queryStringFN.get("name") === "score" &&
  //           !!team,
  //       ]}
  //     >
  //       {[
  //         <Typo key={0}>هنوز تجربه‌ای برات ثبت نشده!</Typo>,
  //         <Typo key={1}>هنوز عضو تیمی نشدی!</Typo>,
  //         <Typo key={2}>تیمتون هنوز امتیازی دریافت نکرده!</Typo>,
  //       ]}
  //     </RenderSwitch>
  //
  //     <RenderIf cond={queryStringFN.get("name") === "experience"}>
  //       <div className="flex items-center justify-center gap-1">
  //         <Typo size="sm">جمع کل تجربه‌هات</Typo>
  //         <ScoreBadge size="sm" withBack type="xp" amount={userData?.data.experience ?? 0} />
  //         <Typo size="sm">هست که جزئیات کاملش به شرح زیره :</Typo>
  //       </div>
  //     </RenderIf>
  //
  //     <RenderIf cond={queryStringFN.get("name") === "score"}>
  //       <div className="flex items-center justify-center gap-1">
  //         <Typo size="sm">جمع کل امتیازهای تیمت</Typo>
  //         <ScoreBadge size="sm" withBack type="score" amount={userData?.data.team?.score ?? 0} />
  //         <Typo size="sm">هست که جزئیات کاملش به شرح زیره :</Typo>
  //       </div>
  //     </RenderIf>
  //
  //     {scoresData?.pages.map((group, iG) => (
  //       <div key={iG}>
  //         {group.data.map((score, i) => (
  //           <Fragment key={i}>
  //             <RenderIf cond={queryStringFN.get("name") === "experience" && score.event === "game.done"}>
  //               <ExperienceGameDoneNotificationCard score={score} />
  //             </RenderIf>
  //             <RenderIf cond={queryStringFN.get("name") === "experience" && score.event === "exam.done"}>
  //               <ExperienceExamDoneNotificationCard score={score} />
  //             </RenderIf>
  //             <RenderIf cond={queryStringFN.get("name") === "experience" && score.event === "solution.extra"}>
  //               <ExperienceSolutionExtraNotificationCard score={score} />
  //             </RenderIf>
  //             <RenderIf cond={queryStringFN.get("name") === "experience" && score.event === "profile.completed"}>
  //               <ExperienceProfileCompletedNotificationCard score={score} />
  //             </RenderIf>
  //             <RenderIf cond={queryStringFN.get("name") === "experience" && score.event === "optional"}>
  //               <ExperienceNotificationCard score={score} />
  //             </RenderIf>
  //             <RenderIf cond={queryStringFN.get("name") === "score" && score.event === "game.done"}>
  //               <ScoreGameDoneNotificationCard score={score} />
  //             </RenderIf>
  //             <RenderIf cond={queryStringFN.get("name") === "score" && score.event === "optional"}>
  //               <ScoreNotificationCard score={score} />
  //             </RenderIf>
  //             <RenderIf cond={queryStringFN.get("name") === "score" && score.event === "solution.extra"}>
  //               <ScoreSolutionExtraNotificationCard score={score} />
  //             </RenderIf>
  //           </Fragment>
  //         ))}
  //       </div>
  //     ))}
  //
  //     <RenderIf cond={isFetching}>
  //       <Spinner type="accent" size="xs" />
  //     </RenderIf>
  //
  //     <div className="pt-8">
  //       <LoadMore
  //         fetchNextPage={fetchNextPage}
  //         hasNextPage={hasNextPage === true}
  //         isFetchingNextPage={isFetchingNextPage}
  //       />
  //     </div>
  //   </>
  // );
};

export default ScoreList;
