import Section01 from "./components/section01";
import {Outlet} from "react-router-dom";
import LandingHeader from "./components/header";
import Section02 from "./components/section02";
import Section03 from "./components/section03";
import Section04 from "./components/section04";
import Section05 from "./components/section05";
import Section06 from "./components/section06";
import Section07 from "./components/section07";
import Section08 from "./components/section08";


export const LandingScreen = () => {
    return (
        <div className="lg:px flex flex-col gap-10 pt-16 overflow-x-hidden">
            <LandingHeader/>
            <Section01/>
            <Section02/>
            <Section03/>
            <Section04/>
            <Section05/>
            <Section06/>
            <Section07/>
            <Section08/>
        </div>
    )
}

