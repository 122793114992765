import {forwardRef, useImperativeHandle, useRef} from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import {twMerge} from "tailwind-merge";

const ScrollView = ({children, className}, ref) => {
    const innerRef = useRef(null);

    useImperativeHandle(ref, () => ({
        scrollTo: (top, left, behavior) => {
            innerRef.current?.scrollTo({top, left, behavior});
        },
    }));

    return (
        <ScrollContainer innerRef={innerRef} className={twMerge("flex", className)} horizontal vertical={false}>
            {children}
        </ScrollContainer>
    );
};

export default forwardRef(ScrollView);
