import {useInfiniteQuery, useQuery} from "@tanstack/react-query";
import {apiGetMissions} from "../../../data/api/mission";


export const useGetMissions = (args, config) => {
    return useInfiniteQuery({
        queryKey: ['missions', args, config],
        queryFn: ({pageParam = 1}) => apiGetMissions({...args, page: pageParam}),
        ...config,
        getNextPageParam: (lastPage,allPages) =>
            lastPage.meta.currentPage < lastPage.meta.lastPage ? lastPage.meta.currentPage + 1 : undefined,

    })
}