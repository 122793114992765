import {useMutation, useQuery} from '@tanstack/react-query'
import {
    apiGetCurrentUser,
    apiGetDashboard, apiGetOtpDetail,
    apiPostAuthenticate, apiPostAuthenticateRecovery,
    apiPostGenerateOtp,
    apiPostLoginByOtp,
    apiUpdateProfile,
} from "../../../data/api/auth";
import {getMyOrganisationList} from "../../../data/api/organisation";
import {getMyTeams, leftMyTeams} from "../../../data/api/team";

export const usePostAuthenticate = () => {
    return useMutation({
        mutationFn: ({mobile, password}) =>
            apiPostAuthenticate({
                mobile,
                password,
                role: 'student'
                // otp: opt.otp ? opt.otp : 'string',
                // refresh_token: opt.refresh_token ? opt.refresh_token : 'string',
            }),
    })
}


export const usePostAuthenticateRecovery = () => {
    return useMutation({
        mutationFn: ({mobile, password, password_confirmation,  otp, otp_token}) =>
            apiPostAuthenticateRecovery({
                mobile: mobile,
                password: password,
                password_confirmation: password_confirmation,
                otp: otp,
                otp_token: otp_token,
                role: 'student'
            }),
    })
}

export const useSendOtp = () => {
    return useMutation({
        mutationFn: ({mobile}) =>
            apiPostGenerateOtp({
                mobile: mobile,
                role: 'student'
            }),
    })
}

export const useGetOtp = () => {
    return useMutation({
        mutationFn: ({mobile, otpToken}) =>
          apiGetOtpDetail(mobile, otpToken),
    })
}

export const useLoginOtp = () => {
    return useMutation({
        mutationFn: ({mobile, otp, otpToken}) =>
            apiPostLoginByOtp({
                mobile: mobile,
                otp: otp,
                otp_token: otpToken,
                role: 'student'
            }),
    })
}


export const useGetCurrentUser = (config) => {
    return useQuery({
        queryKey: ['auth', 'currentUser'],
        queryFn: () => apiGetCurrentUser(),
        ...config,
    })
}
export const useGetDashboard = (config) => {
    return useQuery({
        queryKey: ['auth', 'dashboard'],
        queryFn: () => apiGetDashboard(),
        ...config,
    })
}
export const useGetOrganisations = (config) => {
    return useQuery({
        queryKey: ['auth', 'organisations'],
        queryFn: () => getMyOrganisationList(),
        ...config,
    })
}
export const useGetTeams = (config) => {
    return useQuery({
        queryKey: ['auth', 'teams'],
        queryFn: () => getMyTeams(),
        ...config,
    })
}

export const useLeftTeam = () => {
    return useMutation({
        mutationFn: ({team_user_id}) => leftMyTeams(team_user_id),
    })
}


export const useUpdateGender = () => {
    return useMutation({
        mutationFn: ({gender}) => apiUpdateProfile({gender}),
    })
}
export const useUpdateProfile = () => {
    return useMutation({
        mutationFn: (data) => apiUpdateProfile(data),
    })
}