import Typo from "../../../../../core/components/typo";
import {useState} from "react";
import {useAuthContext} from "../../../../Auth/provider/AuthProvider";

const GameReportInfoCard = ({
  description,
  description2,
  title,
  type,
  gender,
  gameType,
}) => {
  const [myGender, setMyGender] = useState()
  const {user} = useAuthContext()
  const getImage = () => {
    //for game that have null gender set my gender
    const tempGender = user?.gender ?? "male";

    // if (type === "rank" && tempGender === "male")
    //   if (gameType === "team") return "assets.Game.ReportCard.BoysRankImage";
    //   else return "assets.Game.ReportCard.BoyRankImage";
    // else if (type === "rank" && tempGender === "female")
    //   if (gameType === "team") return "assets.Game.ReportCard.GirlsRankImage";
    //   else return "assets.Game.ReportCard.GirlRankImage";
    // else
    if (type === "score" && tempGender === "male")
      if (gameType === "team") return "/images/report-boys-score.png";
      else return "/images/report-boy-score.png";
    else {
      if (gameType === "team") return "/images/report-girls-score.png";
      else return "/images/report-girl-score.png";
    }
  };

  return (
    <div className="flex w-full items-center gap-3 rounded-3xl border-2 border-white bg-purple-5 p-4 shadow">
      <div className="w-20 md:w-28 xl:w-36">
        <img src={getImage()} alt={title} />
      </div>
      <div className="flex flex-col gap-1">
        <Typo as="h1" weight="bold" size="xl">
          {title}
        </Typo>
        <Typo as="p" size="xs" weight="bold">
          {description}
        </Typo>
        {description2}
      </div>
    </div>
  );
};

export default GameReportInfoCard;
