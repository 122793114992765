import { twMerge } from "tailwind-merge";
import {GameIcon} from "../../../../core/components/icon";
import Typo from "../../../../core/components/typo";
import __ from "../../../../core/utils/translate/translate";

const ProfileHeaderBox = ({ count, type, isMe = false }) => {

  const getBackgroundColor = () => {
    if (type === "mission") return "bg-linear-purple-7";
    else if (type === "club") return "bg-linear-orange-45";
    else return "bg-linear-rose-80";
  };

  const getIcon = () => {
    if (type === "mission") return "mission-white";
    else if (type === "club") return "club-white";
    else return "award-white";
  };

  const getTitle = ()=> {
    if (type === "mission") return isMe ? __("You completed the mission") : __("Mission Accomplished");
    else if (type === "club") return isMe ? 'دوره پشت سر گذاشتی' : __("Program Accomplished");
    else return isMe ? __("You completed the award") : __("Award Accomplished");
  };

  return (
    <div className="w-28 rounded-3xl bg-opacity-50 bg-gradient-to-b from-white to-transparent to-70% p-0.5 md:w-36 shrink-0">
      <div
        className={twMerge(
          "relative flex aspect-square flex-col items-center justify-center overflow-hidden rounded-3xl p-4",
          getBackgroundColor()
        )}
      >
        <div className="absolute -bottom-4 -left-4 h-24 w-24 rounded-full bg-white/5" />
        <div className="absolute -bottom-4 -left-4 h-16 w-16 rounded-full bg-white/10" />

        <GameIcon icon={getIcon()} className="absolute -bottom-2 -left-2 h-12 w-12 rotate-[-13deg] opacity-50" />

        <Typo as="h2" weight="bold" type="on-accent" size="3xl">
          {count}
        </Typo>
        <Typo as="p" type="on-accent" size="xs" className="text-center">
          {getTitle()}
        </Typo>
      </div>
    </div>
  );
};

export default ProfileHeaderBox;
