import React from 'react';
import RootLayout from "../../layout/root";
import OrganizationsFilter from "./components/filter";
import OrganizationList from "./components/list";

export default function OrganizationsPage(props) {
    return (
        <RootLayout title={"کانون‌ها"} navigateTo={'/app/profile'}>
            <div className={'flex flex-col gap-8 px-8 lg:px-4  py-20'}>
                <OrganizationsFilter title={"کانون‌ها"} tooltip={'کانون‌ یعنی هر مدرسه‌، هیئت، پایگاه و یا هر مجموعه‌فرهنگی ورزشی که از زیرساخت نوجهان استفاده میکنه. وقتی به یک کانون می‌پیوندی، می‌تونی توی دوره‌ها و ماموریت‌هایش شرکت کنی، با بقیه اعضای کانون تیم بشی و وارد رقابت‌های هیجان‌انگیزش بشی. آماده‌ای عضو یک کانون بشی و کلی تجربه جدید کسب کنی؟ '}/>
                <OrganizationList/>
            </div>
        </RootLayout>
    );
}