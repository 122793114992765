import { twMerge } from "tailwind-merge";
import {CommonIcon} from "../../icon";
import Typo from "../../typo";

const AllCard = ({ children, className, onClick, hiddenIcon = false  }) => {
  return (
    <div
      onClick={() => onClick()}
      className={twMerge("flex w-52 shrink-0 items-center justify-center gap-1 rounded-3xl bg-white/20 hover:cursor-pointer", className)}
    >
      <Typo as="h2" weight="bold" type="on-accent" className="drop-shadow">
        {children}
      </Typo>
      {!hiddenIcon && <CommonIcon icon="arrow" stroke="#FFF" className={twMerge("drop-shadow")} />}

    </div>
  );
};

export default AllCard;
