import React from 'react';
import RenderIf from "../../../../core/components/render-if";
import Typo from "../../../../core/components/typo";
import FileView from "../../../../core/components/file-view";
import Button from "../../../../core/components/button";
import JoinOrganizationWithUsername from "../JoinModal";

export default function HeaderCard({organizationData,image, onClick, name, slogan, description, province, city,owner}) {
    return (
        <>
            <div className="flex flex-col sm:flex-row md:flex-row gap-2 rounded-3xl bg-white p-2 shadow">
                <RenderIf cond={!!image}>
                    <div
                        className="relative aspect-square h-full max-h-[80vw] sm:max-h-[40vh] shrink-0 overflow-hidden rounded-3xl">
                        <div className="absolute right-2 top-2 flex hidden flex-wrap items-center gap-2">
                            <Typo className="rounded-full bg-black/75 px-1" size="2xs" type="on-accent">
                                {province}
                            </Typo>
                        </div>
                        <FileView {...image} thumbnail={undefined} controls className="h-full w-full bg-cover"/>
                    </div>
                </RenderIf>
                <div className="flex flex-1 flex-col items-start gap-2">
                    <div>
                        <Typo size="lg" weight="bold" className="ui-ui-clamp-2 pt-2">
                            {name}
                        </Typo>
                        <RenderIf cond={!!slogan}>
                            <Typo className={'mt-4'} size="sm" type="secondary">
                                {slogan}
                            </Typo>
                        </RenderIf>
                        <RenderIf cond={!!description}>
                            <Typo className={'mt-4'} size="xs" type="secondary">
                                {description}
                            </Typo>
                        </RenderIf>
                        <RenderIf cond={!!owner}>
                            <Typo size="sm" type="secondary" className={'pt-2'}>
                                <i className={'icon-person-circle-check'}> </i>
                                {owner}
                            </Typo>
                        </RenderIf>
                    </div>
                    <div className={'flex gap-2'}>
                        <RenderIf cond={!!province}>
                            <Typo className="rounded-full text-linear-rose-80/20 bg-purple-4 px-2 py-1" size="2xs"
                                  type="on-accent">
                                <i className={'icon-location-dot'}> </i>
                                {province}
                            </Typo>
                        </RenderIf>
                        <RenderIf cond={!!city}>
                            <Typo className="rounded-full text-linear-rose-80/20 bg-purple-4 px-2 py-1" size="2xs"
                                  type="on-accent">
                                <i className={'icon-map-location'}> </i>
                                {city}
                            </Typo>
                        </RenderIf>
                    </div>
                </div>
            </div>
            <JoinOrganizationWithUsername onClick={onClick} name={name}/>
        </>
    );
}