import __, {currentLanguage} from "../utils/translate/translate";

export const AccessTokenKey = 'accessToken'
export const LockOnOrganizationPageId = 'lock-on-organization-page:organizationId'
export const LockOnOrganizationPageDomainAdmin = 'x-organization-domain'
export const RefreshTokenKey = 'refreshToken'
export const FirebaseTokenKey = 'fcmToken'
export const FirebaseBlockTokenKey = 'fcmIsBlock'
export const FirebaseTokenSendBackend = 'fcmSendBack'
export const AllowedFileSize = (100 * 1024 * 1024); // 100 MB

export const TooltipColor = function (){
    return '#9173ff'
}



export const LoginByGoogleUrl = () => {
    return process.env.REACT_APP_BACKEND_URL + 'auth/google/student-' + getDomainName(window.location.href) + '/redirect';
}


export function getDomainName(url) {
    try {
        // Use URL constructor to parse the URL
        const parsedUrl = new URL(url);

        // Get the hostname (e.g., "www.example.com")
        const hostname = parsedUrl.hostname;

        // Split the hostname into parts (e.g., ["www", "example", "com"])
        const parts = hostname.split('.');

        // Check if the hostname contains subdomains
        if (parts.length > 2) {
            // Return the second-to-last part for domains with subdomains
            return parts[parts.length - 2];
        } else if (parts.length === 2) {
            // Return the first part for standard domains
            return parts[0];
        } else {
            // Return the full hostname if no TLD is found
            return hostname;
        }
    } catch (error) {

        return null;
    }
}


export function GetApiUrl()
{
    if(currentLanguage() === 'ar-sa')
    {
        return  process.env.REACT_APP_API_URL_EN
    }

    return process.env.REACT_APP_API_URL
}



export const EDUCATIONS = {
    "elementary_1": __("Elementary_1"),
    "elementary_2": __("Elementary_2"),
    "elementary_3": __("Elementary_3"),
    "elementary_4": __("Elementary_4"),
    "elementary_5": __("Elementary_5"),
    "elementary_6": __("Elementary_6"),
    "secondary_7": __("Secondary_7"),
    "secondary_8": __("Secondary_8"),
    "secondary_9": __("Secondary_9"),
    "secondary_10": __("Secondary_10"),
    "secondary_11": __("Secondary_11"),
    "secondary_12": __("Secondary_12"),
    // "diploma": __("Diploma"),
    // "associate": __("Associate"),
    // "bachelor": __("Bachelor"),
    // "master": __("Master"),
    // "phd": __("Phd"),
    // "postdoc": __("Postdoc"),
}

export const getGameType = (type) => {
    if (['self', 'team'].includes(type)) return __("Mission");
    else if (type === 'course') return __("Course");
    else if (type === 'camp') return __("Campaign");
    else if (type === 'book') return __("Book");
    else if (type === 'match') return __("Competition");
    else return __("Competition");
}
