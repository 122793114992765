import Typo from "..";

export const Number = ({ children }) => {
    return (
        <Typo weight='bold' size='3xl' type='accent' className='w-4 h-4 rounded bg-purple-4 flex items-center justify-center'>
            {children}
        </Typo>
    );
}

