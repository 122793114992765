const HomeBottomSection = () => {
  return (
    <div className=" relative mt-8">
      <img src="/images/home-top-curve.svg" alt="top-curve" loading="lazy" />

      <div className="relative -mt-0.5 flex h-full flex-col bg-linear-purple-0 pb-20 pt-8 overflow-hidden">
        <img
          src="/images/cp-box-papers.svg"
          alt="paper"
          className="absolute left-1/2 top-2 mx-auto w-full max-w-[512px] -translate-x-1/2 opacity-25"
          loading="lazy"
        />
        <div className="z-[1] flex w-screen flex-col gap-6">
         
          {/* <BottomSectionBestTeams /> */}
          {/* <BottomSectionStatistics /> */}
          {/* <BottomSectionNewest /> */}
        </div>
      </div>
    </div>
  );
};

export default HomeBottomSection;
