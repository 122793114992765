import React, {useEffect, useState} from "react";
import RenderIf from "../render-if";

const calculate = (second, withDays = false) => {
    const days = Math.floor(second / (60 * 60 * 24));
    const hours = Math.floor((second / (60 * 60)) % 24);
    const minutes = Math.floor((second / 60) % 60);
    const seconds = Math.floor(second % 60);

    return [withDays ? days : 0, withDays ? hours : days * 24 + hours, minutes, seconds];
};

const formatString = (input) => {
    return input.toString().padStart(2, "0");
};

const Timer = ({second = 0, withDays = false, renderFN, onComplete}) => {
    const [counter, setCounter] = useState(0);
    const [timer, setTimer] = useState(calculate(0, withDays));
    const [isStart, setIsStart] = useState(false)

    useEffect(() => {
        if (!isStart) return;
        const timerInterval =
            counter > 0
                ? setInterval(() => {
                    const newCounter = counter - 1;
                    setCounter(newCounter);
                    setTimer(calculate(newCounter, withDays));
                }, 1000)
                : onComplete?.();
        return () => {
            if (timerInterval) clearInterval(timerInterval);
        };
    }, [counter, isStart]);

    useEffect(() => {
        if (second > 0) {
            setIsStart(true)
            setCounter(second)
        }
    }, [second])

    if (renderFN)
        return renderFN({
            days: formatString(timer[0]),
            hours: formatString(timer[1]),
            minutes: formatString(timer[2]),
            seconds: formatString(timer[3]),
        });
    return (
        <div>
            <RenderIf cond={withDays}>
                <span>{formatString(timer[0])}</span>
                <span>:</span>
            </RenderIf>
            <span>{formatString(timer[1])}</span>
            <span>:</span>
            <span>{formatString(timer[2])}</span>
            <span>:</span>
            <span>{formatString(timer[3])}</span>
        </div>
    );
};

export default Timer;
