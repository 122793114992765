import React, {useState} from 'react';
import Button from "../../../../core/components/button";
import __ from "../../../../core/utils/translate/translate";
import Modal from "../../../../core/components/modal";
import Typo from "../../../../core/components/typo";

export default function JoinOrganizationWithUsername({onClick, name}) {
    const [modal, setModal] = useState("idle");

    return (
        <>
            <Button size={''} containerClassName="mt-auto max-w-64" onClick={() => setModal("join")}>
                درخواست عضویت
            </Button>
            <Modal
                isOpen={modal === "join"}
                onClose={() => setModal("idle")}
                title={'عضویت در کانون'}
                className="flex flex-col gap-2"
            >
                <Typo>{`مطمئنی میخوای عضو کانون ${name} بشی؟`}</Typo>
                <div className="flex justify-center gap-4">
                    <Button
                        type="danger"
                        onClick={onClick}
                    >
                        {__("Yes")}
                    </Button>
                    <Button onClick={() => setModal("idle")} type="danger-light">
                        {__("No")}
                    </Button>
                </div>
            </Modal>
        </>
    );
}