import React, {Fragment} from "react";
import {useNavigate} from "react-router-dom";
import Typo from "../../../../../../core/components/typo";
import RenderIf from "../../../../../../core/components/render-if";
import ActivityNotFound from "../../../../../../core/components/not-found/activity";
import {GameCard, GameCardLoading} from "../../../../../../core/components/card";
import {useGetMissionRunners} from "../../../../api/missionRunner";

const ActivityList = ({
                          statuses,
                          title,
                          mode,
                          showEmptyIcon = false,
                          types = ['course', 'camp'],
                      }) => {
    // const { uuid: userUUID } = useUser();
    const navigate = useNavigate();
    // const [gameUsersData, setGameUsersData] = useState();

    //   missionType
    // startType
    // status
    const {
        data: gameUsersData,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useGetMissionRunners({
        missionType: "course",
        startType: null,
        status: null,
        mode: mode
    });

    if (!showEmptyIcon && !!gameUsersData && (gameUsersData?.meta?.total ?? 0) === 0) return <></>;

    return (
        <>
            <Typo as="h1" weight="semi-bold" className="ui-clamp-1 mb-4 mt-4 flex-1 text-black">
                {title}
            </Typo>

            <RenderIf cond={showEmptyIcon && !!gameUsersData && (gameUsersData?.meta?.total ?? 0) === 0}>
                <ActivityNotFound/>
            </RenderIf>
            <div className="grid gap-x-4 gap-y-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4">
                <RenderIf cond={!gameUsersData}>
                    {Array.from({length: 4}).map((_, i) => (
                        <GameCardLoading key={'gameCardLoadingMyActivity' + i}/>
                    ))}
                </RenderIf>
                {gameUsersData?.data?.map((missionRunner, i) => (
                    <GameCard
                        key={'gameCardMissionRunner' + missionRunner?.id + '-' + i}
                        id={missionRunner?.mission?.id}
                        cover={missionRunner.mission?.coverDetail}
                        type={missionRunner.mission?.type}
                        score={missionRunner.mission?.score}
                        minScore={missionRunner.mission?.minScore}
                        XP={missionRunner.mission?.experience}
                        minXP={missionRunner.mission?.minExperience}
                        name={missionRunner.mission?.name}
                        capacity={missionRunner.mission?.capacity}
                        players={missionRunner.mission?.players}
                        progress={missionRunner.progress}
                        rate={missionRunner.mission?.rate}
                        isEnd={(missionRunner.mission?.secondsToExpire ?? 0) > 0}
                        gameUserStatus={missionRunner.status}
                        // onClick={() => navigate(ROUTES.GAME.INDEX(gameUser.game?.uuid!))}
                        secondToExpire={missionRunner.mission?.secondsToExpire ?? 0}
                        secondToStart={missionRunner.mission?.secondsToStart ?? 0}
                        // secondaryButtonProps={
                        //   statuses.includes(EnumGameUserStatus.REPORT_CARD)
                        //     ? {
                        //         secondButtonType: "danger",
                        //         onClick: () => navigate(ROUTES.GAME.REPORT_CARD(gameUser.game?.uuid!)),
                        //       }
                        //     : undefined
                        // }
                        // buttonType={statuses.includes(EnumGameUserStatus.REPORT_CARD) ? "danger-light" : "danger"}
                    />
                ))}
            </div>
            {/*<LoadMore*/}
            {/*  fetchNextPage={fetchNextPage}*/}
            {/*  hasNextPage={hasNextPage === true}*/}
            {/*  isFetchingNextPage={isFetchingNextPage}*/}
            {/*/>*/}
        </>
    );
};

export default ActivityList;
