import useQueryString from "../../../../core/hooks/useQueryString";
import Typo from "../../../../core/components/typo";
import Search from "../../../../core/components/search";
import ScrollView from "../../../../core/components/scroll-view";
import {Tooltip} from "antd";
import {CommonIcon} from "../../../../core/components/icon";
import RenderIf from "../../../../core/components/render-if";
import {TooltipColor} from "../../../../core/constants/constants";


const OrganizationsFilter = ({title,tooltip}) => {
    const {queryStringFN} = useQueryString();

    const handleOnClick = (type = undefined) => {
        queryStringFN.remove("type");
        if (!!type) queryStringFN.set("type", type);
    };

    const getActiveIndex = (type) => {
        if(!type && !queryStringFN.get("type"))
        {
            return true;
        }
        return queryStringFN.get("type") === type;

    };

    return (
        <div className="flex flex-col gap-4">
            <Typo as="h1" weight="semi-bold" className="ui-clamp-1 flex-1 text-black">
                <div className={'flex'}>
                    {title}
                    <RenderIf cond={!!tooltip}>
                        <Tooltip color={TooltipColor()} title={tooltip} className={'my-auto mx-1'}>
                            <i><CommonIcon icon={'info-badge'}/> </i>
                        </Tooltip>
                    </RenderIf>
                </div>
            </Typo>
            <Search placeholder="کانونی که دنبالش می‌گردی رو جست و جو کن"/>
            <ScrollView className="-mx-4 gap-4 px-4">
                {/*<SimpleBadge isActive={getActiveIndex()} onClick={() => handleOnClick()}*/}
                {/*             className="whitespace-nowrap">*/}
                {/*    همه*/}
                {/*</SimpleBadge>*/}
                {/*<SimpleBadge*/}
                {/*    isActive={getActiveIndex('mission')}*/}
                {/*    onClick={() => handleOnClick('mission')}*/}
                {/*    className="whitespace-nowrap"*/}
                {/*>*/}
                {/*    ماموریت*/}
                {/*</SimpleBadge>*/}
                {/*<SimpleBadge*/}
                {/*    isActive={getActiveIndex('learn')}*/}
                {/*    onClick={() => handleOnClick('learn')}*/}
                {/*    className="whitespace-nowrap"*/}
                {/*>*/}
                {/*    دوره*/}
                {/*</SimpleBadge>*/}

            </ScrollView>
        </div>
    );
};

export default OrganizationsFilter;
