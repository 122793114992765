import { twMerge } from "tailwind-merge";
import RenderIf from "../../render-if";
import Typo from "../../typo";
import {CommonIcon} from "../../icon";

const ScoreBadge= ({
  amount,
  type,
  withBack,
  color = "primary",
  size = "base",
  hiddenOnZero = false,
}) => {
  const getBackgroundColor = () => {
    if (type === "star") return "rounded-full bg-[#FFFCD4] px-2";
    else if (type === "score" && withBack) return "bg-linear-rose-80/20 rounded-full p-1 px-2";
    else if (type === "xp" && withBack) return "bg-purple-4 rounded-full p-1 px-2";
    else return "";
  };

  const getTextColor = () => {
    if (type === "star") return "text-sm";
    else if (color === "white") return "text-white";
    else if (type === "score") return "text-red";
    else if (type === "xp") return "text-purple-3";
    else return "text-purple-1";
  };

  const getIconSize = () => {
    if (size === "xs") return "w-4 h-4";
    else if (size === "sm") return "w-5 h-5";
    else return "w-6 h-6";
  };

  if (hiddenOnZero) return <></>;

  return (
    <div className={twMerge("flex items-center gap-0.5", getBackgroundColor())}>
      <Typo weight="medium" className={twMerge("flex items-center justify-center", getTextColor())} size={size}>
        {amount}
      </Typo>
      <RenderIf cond={type === "xp" && color === "primary"}>
        <CommonIcon icon="xp" className={getIconSize()} />
      </RenderIf>
      <RenderIf cond={type === "xp" && color === "white"}>
        <CommonIcon icon="xp-white" className={getIconSize()} />
      </RenderIf>
      <RenderIf cond={type === "score" && color === "primary"}>
        <CommonIcon icon="score" className={getIconSize()} />
      </RenderIf>
      <RenderIf cond={type === "score" && color === "white"}>
        <CommonIcon icon="score-white" className={getIconSize()} />
      </RenderIf>
      <RenderIf cond={type === "star"}>
        <CommonIcon icon="star" />
      </RenderIf>
    </div>
  );
};

export default ScoreBadge;
