import {Suspense, useMemo, useState} from "react";
import useQueryString from "../../core/hooks/useQueryString";
import {MiniLoading} from "../../core/components/loading";
import TabView from "../../core/components/tab-view";
import RenderIf from "../../core/components/render-if";
import MissionsHeader from "./components/header";
import MissionsMissions from "./components/missions";
import MissionsMyMissionsList from "./components/my-missions";
import MainLayout from "../../layout/mainLayout/mainLayout";
import ProfileNotCompleteModal from "../../core/components/modal/profile-not-complete";
import {useAuthContext} from "../Auth/provider/AuthProvider";
import __ from "../../core/utils/translate/translate";


const MissionsPage = () => {
    const {queryStringFN} = useQueryString();
    const {user} = useAuthContext()
    const [modal, setModal] = useState(!user?.firstName ? "profile-not-complete" : "idle");
    // const { complete } = useUser();

    const getActiveIndex = () => {
        return queryStringFN.get("view");
    };

    const memoTabs = useMemo(() => {
        const temp = [
            {id: "missions", title: __("Missions")},
            {id: "my-missions", title: __("My missions")},
        ];
        return temp;
    }, []);

    const handleChangeTab = (tab) => {
        queryStringFN.set("view", tab.id);
    };

    // if (complete === "failed") return <Navigate to={ROUTES.ERROR(403)} replace/>;

    return (
        <>
            <MainLayout>
                <ProfileNotCompleteModal isOpen={modal === "profile-not-complete"} onClose={() => setModal("idle")}/>
                <Suspense fallback={<MiniLoading/>}>
                    <MissionsHeader/>
                </Suspense>

                <TabView tabs={memoTabs} activeTab={getActiveIndex()} onClick={handleChangeTab} className="pb-40">
                    <Suspense fallback={<MiniLoading/>}>
                        <RenderIf cond={queryStringFN.get("view", "missions") === "missions"}>
                            <MissionsMissions/>
                        </RenderIf>
                        <RenderIf cond={queryStringFN.get("view") === "my-missions"}>
                            <MissionsMyMissionsList/>
                        </RenderIf>
                    </Suspense>
                </TabView>
            </MainLayout>
        </>
    );
};

export default MissionsPage;
