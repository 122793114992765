import {Fragment, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Typo from "../../../../../../core/components/typo";
import RenderIf from "../../../../../../core/components/render-if";
import MissionNotFound from "../../../../../../core/components/not-found/mission";
import {GameCard, GameCardLoading} from "../../../../../../core/components/card";
import {useGetMissions} from "../../../../api/mission";
import useQueryString from "../../../../../../core/hooks/useQueryString";
import LoadMore from "../../../../../../core/components/loadmore";


const MissionList = ({
                         statuses,
                         title,
                         mode,
                         showEmptyIcon = false,
                         types = ['self', 'team'],
                     }) => {

    const {queryStringFN} = useQueryString();

    const navigate = useNavigate();
    const {
        data: missionsData,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isFetching,
    } = useGetMissions({
        mode: mode,
        only_my_mission: 1,
        type: 'mission'

    });

    return (
        <>
            <Typo as="h1" weight="semi-bold" className="ui-clamp-1 mb-4 mt-4 flex-1 text-black">
                {title}
            </Typo>

            <RenderIf cond={(missionsData?.pages.at(0)?.meta?.total ?? 0) === 0}>
                <MissionNotFound/>
            </RenderIf>

            <div className="grid gap-x-4 gap-y-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4">
                <RenderIf cond={!missionsData}>
                    {Array.from({length: 4}).map((_, i) => (
                        <GameCardLoading key={'GameCardLoading' + 'missionList' + 'myMission' + i}/>
                    ))}
                </RenderIf>
                {missionsData?.pages.map((data,i)=>(
                    <Fragment key={'Fragment' + 'missionsList' + i}>
                        {data?.data?.map((missionDetail, iG) => (
                            <Fragment key={'Fragment' + missionDetail?.id + '-' + iG}>

                                <GameCard

                                    id={missionDetail.id}
                                    cover={missionDetail?.coverDetail}
                                    type={missionDetail?.type}
                                    score={missionDetail?.score}
                                    name={missionDetail?.title}
                                    progress={missionDetail.progress}
                                    rate={missionDetail?.rate}
                                    isEnd={(missionDetail?.secondsToExpire ?? 0) > 0}
                                    gameUserStatus={missionDetail.status}
                                    secondToExpire={missionDetail?.secondsToExpire ?? 0}
                                    secondToStart={missionDetail?.secondsToStart ?? 0}
                                    secondaryButtonProps={mode === "done"}
                                />

                            </Fragment>
                        ))}
                    </Fragment>
                ))}

            </div>
            <LoadMore
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage === true}
              isFetchingNextPage={isFetchingNextPage}
            />
        </>
    );
};

export default MissionList;
