import {useEffect, useRef} from "react";

export const useDebounce = ({value, delay = 500, fn}) => {
    const timerRef = useRef();

    useEffect(() => {
        timerRef.current = setTimeout(() => fn(), delay);

        return () => {
            clearTimeout(timerRef.current);
        };
    }, [value, delay]);

    return undefined;
};
