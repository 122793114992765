import React from 'react';
import Button from "../../../../../../core/components/button";
import {useRejectNotification} from "../../../../api";
import handleError from "../../../../../../core/helper/handelError";

export default function RejectBtn({notification,refecth,data}) {
    const {mutate:reject, isPending} = useRejectNotification()
    const handleClick = () =>{
        reject(
            {id: notification?.id},
            {
                onSuccess: ()=>{
                    refecth()
                },
                onError: (error) =>{
                    handleError(error)
                    refecth()
                }
            }
        )
    }

    return (
        <>
            <Button size="sm" scale="fit" onClick={handleClick} isLoading={isPending}>
                {data?.title}
            </Button>
        </>
    );
}