import React, {Fragment} from 'react';
import RenderIf from "../../../../core/components/render-if";
import LinkBtn from "./components/linkBtn";
import SeenBtn from "./components/seenBtn";
import AcceptBtn from "./components/acceptBtn";
import RejectBtn from "./components/rejectBtn";
import MissionVotingBtn from "./components/missionVoting";
import ViewMissionBtn from "./components/viewMission";

export default function ButtonsNotification({notification,buttonData,refecth}) {
    return (
        <>
            {buttonData && buttonData.map((item, index) => (
                <Fragment key={item?.key + item?.title + item?.key?.length + item?.title?.length + index}>

                    <RenderIf cond={item?.key === "link"}>
                        <LinkBtn notification={notification} refecth={refecth} data={item}/>
                    </RenderIf>
                    <RenderIf cond={item?.key === "mission_voting"}>
                        <MissionVotingBtn notification={notification} refecth={refecth} data={item}/>
                    </RenderIf>
                    <RenderIf cond={item?.key === "view_mission"}>
                        <ViewMissionBtn notification={notification} refecth={refecth} data={item}/>
                    </RenderIf>
                    <RenderIf cond={!notification?.readAt}>
                        <RenderIf cond={item?.key === "seen"}>
                            <SeenBtn notification={notification} refecth={refecth} data={item}/>
                        </RenderIf>
                        <RenderIf cond={item?.key === "accept"}>
                            <AcceptBtn notification={notification} refecth={refecth} data={item}/>
                        </RenderIf>
                        <RenderIf cond={item?.key === "reject"}>
                            <RejectBtn notification={notification} refecth={refecth} data={item}/>
                        </RenderIf>
                    </RenderIf>
                </Fragment>
            ))}

        </>
    );
}