import {useContext} from 'react';
import {CreateOrJoinTeamContext} from '../../context';
import Typo from "../../../../../../../../core/components/typo";
import Button from "../../../../../../../../core/components/button";
import __ from "../../../../../../../../core/utils/translate/translate";
import {useNavigate} from "react-router-dom";
import {useAuthContext} from "../../../../../../../Auth/provider/AuthProvider";
import RenderIf from "../../../../../../../../core/components/render-if";
import {Tooltip} from "antd";
import {TooltipColor} from "../../../../../../../../core/constants/constants";
import {CommonIcon} from "../../../../../../../../core/components/icon";

const ProfilePersonIdleTeam = ({onChange}) => {
    const {setStatus} = useContext(CreateOrJoinTeamContext)
    const navigate = useNavigate();
    const {organisation} = useAuthContext();

    return (
        <>
            <Typo as="h2" weight="bold">
                <div className={'flex'}>
                    {__("You dont have a team yet!")}
                    <Tooltip className={'my-auto mx-1'} color={TooltipColor()}
                             title={(
                                 <span>
                                     <span>چرا باید عضو تیم باشی؟</span>
                                     <br/>
                                     <span>برخی مأموریت‌ها و چالش‌های نوجهان فقط برای تیم‌ها فعال می‌شن. برای شرکت در این مأموریت‌ها، باید عضو یک تیم باشی!</span>
                                     <br/>
                                     <span>یک تیم از چند نوجوان و حداقل یک مربی تشکیل شده. </span>
                                 </span>
                             )}>
                        <i><CommonIcon icon={'info-badge'}/> </i>
                    </Tooltip>
                </div>
            </Typo>
            <div className={'flex flex-col gap-2'}>
                <Typo as="h3" type="secondary">
                    <div className={'flex'}>
                        میتونی در لیست تیم‌ها جست‌وجو کنی
                        <Tooltip className={'my-auto mx-1'} color={TooltipColor()}
                                 title={'دنبال یه تیم خوب می‌گردی؟ توی لیست تیم‌های کانون بگرد و به اون‌هایی که در حال یارگیری هستن، درخواست عضویت بفرست!'}>
                            <i><CommonIcon icon={'info-badge'}/> </i>
                        </Tooltip>
                    </div>
                </Typo>
                <Button onClick={()=> navigate('/app/teams')}>
                    جست‌وجو در لیست تیم‌ها
                </Button>
            </div>
            <RenderIf cond={organisation?.organization?.settingsPretty?.allowStudentCreateTeam !== 'no'}>
            <div className="flex flex-col gap-2">
                <Typo as="h3" type="secondary">
                    <div className={'flex'}>
                        یا می‌تونی به راحتی یک تیم بسازی
                        <Tooltip className={'my-auto mx-1'} color={TooltipColor()}
                                 title={'اگر روحیه رهبری داری، می‌تونی تیم خودت رو تشکیل بدی و سرتیم بشی. اما یادت باشه که پیدا کردن هم‌تیمی‌های مناسب و یک مربی چالش خودش رو داره. آماده‌ای این مسیر رو شروع کنی؟ '}>
                            <i><CommonIcon icon={'info-badge'}/></i>
                        </Tooltip>
                    </div>
                </Typo>
                <Button onClick={() => onChange("create-team")}>{__("Make team")}</Button>
            </div>
            </RenderIf>
            <div className="flex flex-col gap-2">
                <Typo as="h3" type="secondary">
                    <div className={'flex'}>
                        {__("Or you can join your friends team!")}
                        <Tooltip className={'my-auto mx-1'} color={TooltipColor()}
                                 title={'اگر از دوستت کد عضویت تیمش رو گرفتی، کافیه اینجا واردش کنی تا سریعتر به تیمش ملحق بشی.'}>
                            <i><CommonIcon icon={'info-badge'}/></i>
                        </Tooltip>
                    </div>
                </Typo>
                <Button type="light" onClick={() => onChange("join-to-team")}>
                    {__("Join team")}
                </Button>
            </div>
        </>
    );
};

export default ProfilePersonIdleTeam;
