import {Link, useNavigate} from "react-router-dom";
import {twMerge} from "tailwind-merge";
import {useScrollDirection} from "../../core/hooks/useScrollDirection";
import {useAuthContext} from "../../feature/Auth/provider/AuthProvider";
import {CommonIcon} from "../../core/components/icon";
import RenderIf from "../../core/components/render-if";
import Typo from "../../core/components/typo";
import Button from "../../core/components/button";
import {ScoreBadge} from "../../core/components/badge";
import HeaderMainNotification from "../../core/components/header/main/components/notification";

import HumberMenu from "../../core/providers/HumberMenu";

const MainHeader = () => {
    const scrollDirection = useScrollDirection();
    const {team, dashboard, user, organisation} = useAuthContext();
    const navigate = useNavigate();


    return (
        <>
            <header
                className={twMerge(
                    "fixed top-0 z-[2] w-full rounded-b-3xl bg-white px-4 py-2.5 shadow-[0px_4px_30px_0px_rgba(3,64,160,0.15)] transition-all",
                    scrollDirection === "down" ? "-top-full" : "top-0"
                )}
            >
                <div className="flex items-center justify-between gap-2">
                    <div className="flex items-center gap-2">
                        <HeaderMainNotification/>

                        <Link to="/app/profile">
                            <CommonIcon icon="profile"/>
                        </Link>


                            <Typo
                                onClick={() => {navigate('/app/profile')}}
                                className="w-24 truncate"
                                size="xs"
                            >
                                {user?.firstName} {user?.lastName} {!user?.firstName && !user?.lastName ? user?.mobile : ''}
                            </Typo>

                    </div>

                    <div className="flex items-center gap-2">


                        <div onClick={() => {
                            navigate(`/app/notification`)
                        }} className="cursor-pointer">
                            <ScoreBadge amount={dashboard?.totalExperience} type="xp"/>
                        </div>

                        <div onClick={() => {
                            navigate(`/app/notification`)
                        }} className="cursor-pointer">
                            <ScoreBadge amount={dashboard?.totalScore} type="score"/>
                        </div>
                    </div>
                    <div>
                        <HumberMenu></HumberMenu>
                    </div>

                    {/*<RenderIf cond={(!team || team?.status !== 'accepted')}>*/}
                    {/*    <span className={`hidden sm:flex`}>*/}
                    {/*        <Button type="light" size="sm" onClick={() => {*/}
                    {/*            navigate('/app/profile');*/}
                    {/*        }} scale="fit">*/}
                    {/*        {__("Join team")}*/}
                    {/*        </Button>*/}
                    {/*    </span>*/}
                    {/*</RenderIf>*/}

                    {/*<RenderIf cond={(team && team?.status === 'accepted')}>*/}
                    {/*    <span className={`hidden sm:flex`}>*/}
                    {/*        <Button type="light" size="sm" onClick={() => {*/}
                    {/*            navigate('/app/profile?view=team');*/}
                    {/*        }} scale="fit">*/}
                    {/*            {team?.team?.name}*/}
                    {/*        </Button>*/}
                    {/*    </span>*/}
                    {/*</RenderIf>*/}


            </div>
            </header>

        </>
    );
};

export default MainHeader;
