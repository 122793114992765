import {useNavigate} from "react-router-dom";
import Typo from "../../../core/components/typo";
import __ from "../../../core/utils/translate/translate";
import {LoginByGoogleUrl} from "../../../core/constants/constants";
import React from "react";

const AuthenticateFooter = ({loginByGoogle = false}) => {

    const navigate = useNavigate();

    return (
        <>
            <Typo as="p" className="text-center" size="2xs">
                {__("I accept")}
                <Typo
                    as="span"
                    size="xs"
                    weight="semi-bold"
                    type="accent"
                    className={'mx-1'}
                    onClick={() => navigate('/authenticate/privacy')}
                >
                    {__("Privacy")}
                </Typo>
                {__("and")}
                <Typo
                    as="span"
                    size="xs"
                    weight="semi-bold"
                    type="accent"
                    className={'mx-1'}
                    onClick={() => navigate('/authenticate/rules')}
                >
                    {__("Rules")}
                </Typo>
            </Typo>
            {loginByGoogle &&
                <div>
                    <hr className={'mb-4'}/>
                    <a href={LoginByGoogleUrl()}
                         className="flex items-center justify-center dark:bg-gray-800">
                        <button
                            className="px-4 py-2 border flex gap-2 border-slate-200 dark:border-slate-700
                            rounded-lg text-slate-700 dark:text-slate-200 hover:border-slate-400 dark:hover:border-slate-500 hover:text-slate-900 dark:hover:text-slate-300 hover:shadow transition duration-150">
                            <img className="w-6 h-6" src="/images/google-color.svg"
                                 loading="lazy" alt="google logo"/>
                            <span>{__('Login with Google')}</span>
                        </button>
                    </a>


                </div>}
        </>
    );
};

export default AuthenticateFooter;
