import Typo from "../../../../core/components/typo";
import FileView from "../../../../core/components/file-view";
import {SwiperSimpleCarousel} from "../../../../core/components/swiper";
import {SwiperSlide} from "swiper/react";
import __ from "../../../../core/utils/translate/translate";


const Section06 = () => {

  return (
    <section className="relative z-[3] mx-auto -mt-10 flex w-full flex-col gap-6 px-4 pb-12 md:-mt-24 lg:max-w-[768px]">
      <div>
        <Typo as="h1" size="xl" weight="bold" className="text-center">
            {__("Teaser introducing Nojahan")}
        </Typo>
        <Typo className="text-center">
            {__("By watching this video, you will understand what is going on and what exciting things are going to happen in this world that you live in!")}
        </Typo>
      </div>
      <div className="w-full">
        <SwiperSimpleCarousel loop pagination navigation>
          <SwiperSlide className="aspect-video">
            <FileView
              controls
              url="https://nojahan.arvanvod.ir/xBeX0OrRaD/jRaPADMyQ9/origin_config.json"
              contents="https://nojahan.arvanvod.ir/xBeX0OrRaD/jRaPADMyQ9/origin_config.json"
              mime="text/uri-list+arvancloud"
              className="rounded-xl"
            />
          </SwiperSlide>
        </SwiperSimpleCarousel>
      </div>
    </section>
  );
};

export default Section06;
