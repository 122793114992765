import React, {Fragment} from "react";
import {useNavigate} from "react-router-dom";
import useQueryString from "../../../../../../core/hooks/useQueryString";
import {useAuthContext} from "../../../../../Auth/provider/AuthProvider";
import {GameCard, GameCardLoading} from "../../../../../../core/components/card";
import RenderIf from "../../../../../../core/components/render-if";
import {useGetMissions} from "../../../../api/mission";
import LoadMore from "../../../../../../core/components/loadmore";
import MissionNotFound from "../../../../../../core/components/not-found/mission";

const MissionsGridList = ({commonGame = false}) => {
    const {queryStringFN} = useQueryString();
    const {
        data: missionsData,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isFetching,
    } = useGetMissions({
        type: queryStringFN.get("type") ?? 'mission',
        search: queryStringFN.get("name") ?? '',
    });

    return (
        <div className="flex flex-col gap-8">
            <div className="grid gap-x-4 gap-y-4 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
                {missionsData?.pages.map((data,i)=>(
                    <Fragment key={'missionsDataList' + i}>
                        {data?.data?.map((mission, i) => (
                            <GameCard
                                key={'missionCard' + mission?.id + '_' + i}
                                id={mission.id}
                                cover={mission.coverDetail}
                                type={mission.type}
                                score={mission.score}
                                minScore={mission.minScore}
                                XP={mission.experience}
                                minXP={mission.minExperience}
                                name={mission.title}
                                capacity={mission.capacity}
                                players={mission.players}
                                progress={mission.progress}
                                rate={mission.rate}
                                isEnd={(mission.secondsToExpire ?? 0) > 0}
                                gameUserStatus={mission.gameUser?.status ?? 0}
                                secondToStart={mission.secondsToStart}
                                secondToExpire={mission.secondsToExpire}
                                special={mission.special}
                            />
                        ))}
                    </Fragment>
                ))}
                <RenderIf cond={false}>
                    {Array.from({length: 3}).map((_, i) => (
                        <GameCardLoading key={'gameCardLoadingMissionList' + i}/>
                    ))}
                </RenderIf>
            </div>
            <RenderIf cond={(missionsData?.pages.at(0)?.meta?.total ?? 0) === 0}>
                <MissionNotFound/>
            </RenderIf>
            <LoadMore
                auto
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage === true}
                isFetchingNextPage={isFetchingNextPage}
            />
        </div>
    );
};

export default MissionsGridList;
