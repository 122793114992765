import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAuthContext} from "../../../../../Auth/provider/AuthProvider";
import Typo from "../../../../../../core/components/typo";
import Button from "../../../../../../core/components/button";
import Modal from "../../../../../../core/components/modal";
import {useGetTeams, useLeftTeam} from "../../../../../Auth/api/auth";
import {toast} from "react-toastify";
import __ from "../../../../../../core/utils/translate/translate";
import handleError from "../../../../../../core/helper/handelError";

const ProfileTeamLeft = ({}) => {
    const {team, playerType, teamStatus, setTeam} = useAuthContext();
    const navigate = useNavigate();

    const [modal, setModal] = useState("idle");

    const {
        mutate: leftMyTeam,
        isSuccess

    } = useLeftTeam();


    const handleLeft = () => {

        leftMyTeam({team_user_id: team.id},
            {
                onSuccess: () => {

                    navigate("/app/profile");
                    navigate(0);


                },
                onError: (e) => {
                    handleError(e)
                }
            });
    };

    if (team?.status !== "accepted") return <></>;

    return (
        <>
            <div className="flex w-full flex-col gap-4 rounded-3xl bg-white p-4 shadow  ">
                <div className="flex-1">
                    <Typo>{__("By leaving the team, all scores will be zero!")}</Typo>

                </div>
                <Button
                    type="danger"
                    containerClassName="self-end"
                    scale="fit"

                    onClick={() => setModal("left")}
                >
                    {__("Leave team")}
                </Button>
            </div>
            <Modal
                isOpen={modal === "left"}
                onClose={() => setModal("idle")}
                title={__("Leave team")}
                className="flex flex-col gap-2"
            >
                <Typo>{__("Are you really leaving the team?")}</Typo>
                <div className="flex justify-center gap-4">
                    <Button onClick={handleLeft} isLoading={false}>
                        {__("Yes")}
                    </Button>
                    <Button onClick={() => setModal("idle")} type="light">
                        {__("No")}
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default ProfileTeamLeft;
