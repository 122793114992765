import {twMerge} from "tailwind-merge";
import {motion} from "framer-motion";
import Typo from "../../../core/components/typo";
import __ from "../../../core/utils/translate/translate";

const AuthGenderItem = ({gender, active = false, onClick}) => {

    const textVariants = {
        hidden: {x: -100, opacity: 0},
        visible: {x: 0, opacity: 1},
    };
    const ChildVariants = {
        hidden: {y: -10, opacity: 0},
        visible: {y: 0, opacity: 1, transition: {when: "beforeChildren"}},
    };



    return (
        <motion.button
            variants={ChildVariants}
            onClick={onClick}
            className={twMerge(
                "flex items-center gap-2 rounded-lg  border px-3 py-1",
                active ? "border-purple-1" : "border-purple-5"
            )}
        >
            <img
                src={gender === "female" ? "/images/icon-girl.png" : "/images/icon-boy.png"}
                alt="girlIcon"
                loading="lazy"
            />
            <motion.div variants={textVariants}>
                <Typo as="p" type={active ? "primary" : "secondary"}>
                    {gender === "female" ? __("I am a girl") : __("I am a boy")}
                </Typo>
            </motion.div>
        </motion.button>
    );
};

export default AuthGenderItem;
