import Modal from "..";
import SelectInput from "../../input/select";
import TextArea from "../../input/text-area";
import Typo from "../../typo";
import RenderIf from "../../render-if";
import Button from "../../button";
import __ from "../../../utils/translate/translate";
import {ENUM_ERROR_USER_REPORT_TYPE} from "../../../enum/userReport";
import {useState} from "react";
import {usePostUserReport} from "./api";
import {toast} from "react-toastify";
import handleError from "../../../helper/handelError";

const ErrorReportModal = ({ related, relatedId, ...rest }) => {
    const [content, setContent] = useState('')
    const [type, setType] = useState({})
    const {mutate, isPending , isError , isSuccess} = usePostUserReport()

    const postUserReport = function (){
        if (type?.type === 'other' && content?.length < 3){
            toast.warn('توضیحات نباید کمتر از ۲ حرف باشد.')
            return null
        } else {
            mutate(
                {
                    related: related,
                    related_id: relatedId,
                    type: type?.type,
                    title: type?.title,
                    content: content
                },
                {
                    onSuccess: (data) => {
                        setType({})
                        toast.success(data?.message)
                        {rest?.onClose()}
                    },
                    onError: (error) => {
                        handleError(error)
                    }
                }
            );
        }
    }

  return (
    <Modal {...rest} className="flex flex-col items-start gap-4 z-[100]" title="گزارش تخلف">
      <Typo className="rounded-xl bg-purple-5 p-4" size="sm">
          {__("Your report will only be referred to the Nojohan team, and the user will be notified if a violation or unusual data is observed. can be modified or deleted if needed.")}
      </Typo>
      <Typo>{__("Why do I want to report?")}</Typo>

      <SelectInput
        label={__("Subject")}
        data={ENUM_ERROR_USER_REPORT_TYPE}
        name={__("Subject")}
        required
        onChange={(item) => {setType(item)}}

      />
        <RenderIf cond={!type?.type}>
            <Typo className={'px-1 text-red'} size="2xs">
                انتخاب موضوع ضروری است
            </Typo>
        </RenderIf>
      <TextArea name={__("Description")}
                label={__("Description")}
                onChange={(e)=> setContent(e.target.value)}
                required />
        <RenderIf cond={type?.type === 'other' && content?.length < 3}>
            <Typo className={'px-1 text-red'} size="2xs">
                توضیحات ضروری است
            </Typo>
        </RenderIf>
      <RenderIf
        cond={false}
        falsyElement={
          <Button scale="fit" containerClassName="self-end shrink-0" onClick={()=>{
              postUserReport()
          }} isLoading={false}>
              {__("Create report")}
          </Button>
        }
      >
        <Typo type="success" size="sm" className="self-center">
            {__("Your report has been successfully submitted.")}
        </Typo>
      </RenderIf>
    </Modal>
  );
};

export default ErrorReportModal;
