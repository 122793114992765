import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Typo from "../../../core/components/typo";
import FileView from "../../../core/components/file-view";
import {ScoreBadge} from "../../../core/components/badge";
import RenderIf from "../../../core/components/render-if";
import TextToHTML from "../../../core/components/text-to-html";
import Button from "../../../core/components/button";
import MainLayout from "../../../layout/mainLayout/mainLayout";
import {useGetAwardDetail} from "../api/award";
import __ from "../../../core/utils/translate/translate";

const AwardDetailPage = () => {
    const navigate = useNavigate();

    const {id} = useParams();

    const [awardData, setAwardData] = useState();

    const {data: award, isLoading, isSuccess} = useGetAwardDetail(id);

    useEffect(() => {
        setAwardData(award);
    }, [award]);

    return (
        <MainLayout>
            <div className="flex flex-col gap-8 pb-8 xl:px-52">
                <div className="grid gap-x-4 gap-y-4 md:grid-cols-2 2xl:grid-cols-2">
                    <div className="flex flex-col gap-4 px-4 py-20">
                        <RenderIf cond={!awardData}>
                            <Typo>{__("Award not found!")}</Typo>
                        </RenderIf>

                        {isSuccess &&

                            <div>
                                <div className="relative w-fit">
                                    <FileView
                                        {...awardData?.data?.fileDetail}
                                        thumbnail={undefined}
                                        controls
                                        className="max-w-lg rounded-xl"
                                        imageClassName="!aspect-square"
                                    />
                                </div>
                                <div>
                                    <div className="flex gap-2 rounded-full mb-2">
                                        <ScoreBadge type="score" amount={awardData?.data?.score} size="sm" withBack/>
                                        <ScoreBadge type="xp" amount={awardData?.data?.experience} size="sm" withBack/>
                                    </div>
                                    <Typo as="h1" weight="bold">
                                        {awardData?.data?.title}
                                    </Typo>
                                    <Typo as="p" weight="light" type="secondary" size="xs" className="hidden">
                                        {awardData?.data?.type}
                                    </Typo>
                                </div>

                                <TextToHTML>{awardData?.data?.summary}</TextToHTML>
                                <hr/>
                                <TextToHTML>{awardData?.data?.description}</TextToHTML>
                                <br/>
                                <div className={`max-w-xs`}>
                                <Button onClick={() => navigate('/app/awards')}>
                                        {__("Back")}
                                    </Button>
                                </div>
                            </div>

                        }
                    </div>
                </div>
            </div>

        </MainLayout>
    );
};

export default AwardDetailPage;
