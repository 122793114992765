import RenderSwitch from "../../render-switch";
import Typo from "../../typo";
import RenderIf from "../../render-if";
import {Link} from "react-router-dom";
import __ from "../../../utils/translate/translate";


const ActivityNotFound = ({isPrivate = false, userUUID, defaultText = 'هیچ دوره‌ای یافت نشد!'}) => {
    return (
        <div className="flex flex-col items-center gap-1">
            <img src="/images/cp-box-new-activities-2.svg" className="grayscale"/>
            <RenderSwitch
                conds={[isPrivate && false === userUUID, isPrivate && false !== userUUID]}
                _default={<Typo>{defaultText}</Typo>}
            >
                {[
                    <div key={0} className="flex flex-col items-center">
                        <Typo>{__("You did not participate in a program!")}</Typo>
                        <RenderIf cond={"" === "success"}>
                            <Link to="" className="text-sm text-gray-2 hover:underline">
                                {__("Go to club")}
                            </Link>
                        </RenderIf>
                    </div>,
                    <Typo key={1}>{__("Has not participated in any program yet!")}</Typo>,
                ]}
            </RenderSwitch>
        </div>
    );
};

export default ActivityNotFound;
