import {useMutation, useQuery} from "@tanstack/react-query";
import {apiChangeHead, apiCreateTeam, apiJoinTeam, apiTeamDetail, apiUpdateTeam} from "../../../data/api/team";


export const useJoinTeam = () => {
    return useMutation({
        mutationFn: ({code}) =>
            apiJoinTeam({
                invite_code: `T-${code}`
            }),
    })
}

export const useGetTeamDetail = (teamId, enabled = true) => {
    return useQuery({
        queryKey: ['team', teamId],
        queryFn: () => apiTeamDetail(teamId),
        enabled: enabled
    })
}
export const useCreateTeam = () => {
    return useMutation({
        mutationFn: (data) =>
            apiCreateTeam(data),
    })
}
export const useUpdateTeam = () => {
    return useMutation({
        mutationFn: ({teamId, data}) =>
            apiUpdateTeam(teamId,data)
    })
}
export const useChangeHead = () => {
    return useMutation({
        mutationFn: ({teamId,newHeadUserId}) =>
            apiChangeHead(teamId,newHeadUserId)
    })
}
