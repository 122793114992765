import React, {useEffect} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import {saveTokens} from "./services/tokenService";
import {motion} from "framer-motion";
import Typo from "../../core/components/typo";
import __ from "../../core/utils/translate/translate";
import Button from "../../core/components/button";

export default function Language() {

    const navigate = useNavigate();
    function changeLanguage(lang) {
        localStorage.setItem("lang", lang)
        navigate('/');
    }
    return (
        <div className="flex h-screen w-screen flex-col items-center justify-center gap-2 bg-purple-5">
            <div className="w-20">
                <img src={`/images/logo.svg`} alt="logo" className="duration-[5000]"/>
            </div>
            <div className={'flex max-w-md gap-2'}>
                <Button onClick={() => changeLanguage('fa-ir')} type={'primary'}>فارسی</Button>
                <Button onClick={() => changeLanguage('ar-sa')} type={'primary'}>العربیه</Button>
                <Button onClick={() => changeLanguage('en-us')} type={'primary'}>English</Button>
            </div>

        </div>
    );
}