import {useEffect} from "react";
import RenderIf from "../render-if";
import {createPortal} from "react-dom";
import Typo from "../typo";
import IconButton from "../icon-button";
import {CommonIcon} from "../icon";
import {twMerge} from "tailwind-merge";

const Modal = ({children, isOpen, onClose, title = "", className}) => {
    useEffect(() => {
        if (isOpen) document.body.style.overflowY = "hidden";
        else document.body.style.overflowY = "auto";
        return () => {
            document.body.style.overflowY = "auto";
        };
    }, [isOpen]);

    return (
        <RenderIf cond={isOpen}>
            {createPortal(
                <div
                    className="fixed inset-0 z-[100] flex items-center justify-center bg-black/10 p-4 backdrop-blur"
                    onClick={() => onClose?.()}
                >
                    <div
                        className="flex max-h-[100vh] w-full flex-col gap-4 rounded-3xl bg-white p-4 md:max-h-[100vh]  md:max-w-xl"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex items-center justify-between">
                            <Typo className="ui-clamp-1 flex-1" size="sm" weight="semi-bold">
                                {title}
                            </Typo>
                            <RenderIf cond={!!onClose}>
                                <IconButton onClick={() => onClose?.()} className="bg-purple-6">
                                    <CommonIcon icon="add" className="rotate-45"/>
                                </IconButton>
                            </RenderIf>
                        </div>
                        <div className={twMerge("overflow-y-auto", className)}>{children}</div>
                    </div>
                </div>,
                document.getElementById("modal")
            )}
        </RenderIf>
    );
};

export default Modal;
