import Modal from "..";
import {useNavigate} from "react-router-dom";
import Typo from "../../typo";
import Button from "../../button";
import __ from "../../../utils/translate/translate";

const ProfileNotCompleteModal = ({isOpen, onClose}) => {

    const navigate = useNavigate();

    const handleOnClick = () => {
        navigate(`/app/profile/edit`)
        // onClose();
    };
    return (
        <Modal isOpen={isOpen} title={__("Your profile is notComplete.")} className="flex flex-col gap-2">
            <Typo className="text-center">{__("Your profile is notComplete.")}</Typo>
            <Button onClick={handleOnClick}>{__("Complete profile")}</Button>
        </Modal>
    );
};

export default ProfileNotCompleteModal;
