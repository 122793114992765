import React from "react";
import Typo from "../../../../../core/components/typo";
import FileView from "../../../../../core/components/file-view";
import RenderIf from "../../../../../core/components/render-if";
import {ScoreBadge} from "../../../../../core/components/badge";

const AwardMainCard = ({XP, image, onClick, score, title, summary, description, type, plan}) => {
    return (
        <div className="flex gap-2 rounded-3xl bg-white p-2 shadow">
            <div className="relative aspect-square h-full max-h-36 shrink-0 overflow-hidden rounded-3xl">
                <div className="absolute right-2 top-2 flex hidden flex-wrap items-center gap-2">
                    <Typo className="rounded-full bg-black/75 px-1" size="2xs" type="on-accent">
                        {type}
                    </Typo>
                </div>
                <FileView {...image} thumbnail={undefined} controls className="h-full w-full bg-cover"/>
            </div>
            <div className="flex flex-1 flex-col items-start gap-2">
                <div>
                    <Typo size="sm" weight="bold" className="ui-ui-clamp-2 pt-2">
                        {title}

                    </Typo>
                    <RenderIf cond={!!summary}>
                        <Typo size="xs" type="secondary">
                            {summary}
                        </Typo>
                    </RenderIf>
                    <RenderIf cond={!!description}>
                        <Typo size="xs" type="secondary">
                            {description}
                        </Typo>
                    </RenderIf>
                </div>
                <div className="mt-auto flex mr-auto ml-1 mb-1 align-left gap-2">
                    <ScoreBadge size="xs" withBack type="xp" amount={XP}/>
                    <ScoreBadge size="xs" withBack type="score" amount={score}/>
                </div>
                {/*<Button size="xs" onClick={() => onClick()} containerClassName="mt-auto">*/}
                {/*    دیدن*/}
                {/*</Button>*/}
            </div>
        </div>
    );
};

export default AwardMainCard;
