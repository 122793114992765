import RootLayout from "../../layout/root";
import ProfileHeader from "./components/header";
import {MiniLoading} from "../../core/components/loading";
import {Suspense} from "react";
import ProfileHeaderPerson from "./person/header";
import ProfilePersonMain from "./person/main";
import MainLayout from "../../layout/mainLayout/mainLayout";
import __ from "../../core/utils/translate/translate";

export const ProfileScreen = () => {
    return (
        <MainLayout title={__("Profile")}>
            <ProfileHeader>
                <Suspense fallback={<MiniLoading/>}>
                    <ProfileHeaderPerson/>
                </Suspense>
            </ProfileHeader>
            <Suspense fallback={<MiniLoading/>}>
                <ProfilePersonMain/>
            </Suspense>
        </MainLayout>
    )
}
