import {useMutation} from "@tanstack/react-query";
import {apiLeftOrganisation} from "../../../data/api/organisationUser";
import {apiUploadFile} from "../../../data/api/file";

export const useUploadFile = () => {
    return useMutation({
        mutationFn: async (file) =>{

            return apiUploadFile(file)
        }

    })
}