import React, {Suspense} from "react";
import {Outlet} from "react-router-dom";
import {FullLoading} from "../../core/components/loading";
import RootHeader from "./header";
import MainFooter from "../mainLayout/mainFooter";

const RootLayout = ({title,children,navigateTo}) => {
    return (
        <>
            <RootHeader title={title} navigateTo={navigateTo}/>
            <div className="bg-purple-6">
                <Suspense fallback={<FullLoading/>}>
                    <div className="mx-auto flex min-h-[100svh] flex-col lg:px-52">
                        {children}
                    </div>
                </Suspense>
            </div>
        </>
    );
};

export default RootLayout;
