import ClubViewLeftClubModal from "./components/left-organisation-modal";
import {useAuthContext} from "../../../Auth/provider/AuthProvider";
import VerticalBox from "../../../../core/components/box/vertical";
import RenderIf from "../../../../core/components/render-if";
import Avatar from "../../../../core/components/avatar";
import Typo from "../../../../core/components/typo";
import {useEffect, useState} from "react";
import __ from "../../../../core/utils/translate/translate";

const ProfileOrganisationView = ({uuid}) => {
    const {organisation} = useAuthContext();

    return (
        <>
            <RenderIf cond={!!organisation}>
                <VerticalBox className="w-full shrink-0 p-4" icon="quote">
                    <div className="flex w-full items-center gap-4">
                        <Avatar
                            image={organisation?.organization?.logoDetail?.url}
                            name={organisation?.organization?.name ?? ""}
                            className="rounded-3xl"
                            size="xl"
                            avatarClassName="rounded-3xl"
                        />

                        <div className="flex flex-col space-y-3">
                            <Typo as="h2" weight="bold" className="ui-clamp-1 w-full flex-1" type="primary">
                                {organisation?.organization?.name}
                            </Typo>
                            <Typo as="h4"  className="ui-clamp-1 w-full flex-1" type="primary">
                                {organisation?.organization?.settingsPretty?.description}
                            </Typo>

                        </div>
                    </div>

                    <Typo as="p" className="ui-clamp-3 m-1 text-black">
                        {organisation?.organization?.settingsPretty?.description}
                    </Typo>
                </VerticalBox>
            </RenderIf>

            {/*<RenderIf cond={!!uuid && groupList.length > 0}>*/}
            {/*    <VerticalBox className="w-full shrink-0 p-4" icon="two-user">*/}
            {/*        <div className="flex w-full flex-col space-y-2.5">*/}
            {/*            {groupList.map((item) => (*/}
            {/*                <article className="flex items-center">*/}
            {/*                    <Avatar name={item.name} image={item.image?.url}/>*/}
            {/*                    <Typo as="h2" weight="semi-bold" className="ui-clamp-1 mr-2 flex-1 text-gray-1">*/}
            {/*                        {item.nameWithType}*/}
            {/*                    </Typo>*/}
            {/*                </article>*/}
            {/*            ))}*/}
            {/*            <Typo size="xs">*/}
            {/*                {(groupData?.meta?.total ?? 0) - 8 > 0*/}
            {/*                    ? `و ${(groupData?.meta?.total ?? 0) - 8} ${groupData?.data?.at(0)?.club?.branches.at(1)} دیگر `*/}
            {/*                    : ""}*/}
            {/*            </Typo>*/}
            {/*        </div>*/}
            {/*    </VerticalBox>*/}
            {/*</RenderIf>*/}
            <RenderIf cond={!!organisation}>
                <VerticalBox
                    withBackground
                    className="rounded-b-3xl shadow-[0px_4px_20px_0px_rgba(0,0,0,0.2)]"
                    containerClassName="p-4 px-8"
                >
                    <div className="relative z-[1] flex w-full flex-col p-2">
                        <Typo weight="semi-bold" className="ui-clamp-3  justify-end">
                            {__("Are you no longer in this organization?")}
                        </Typo>
                        <Typo as="p" className="mb-4" type="secondary">
                            {__("If you want to change your organization, you must leave this organization first. Be aware that when you leave the organization, you will also leave your team and the points you got in the team will be zero.")}
                        </Typo>
                        <ClubViewLeftClubModal/>
                    </div>
                </VerticalBox>
            </RenderIf>
        </>
    );
};

export default ProfileOrganisationView;
