import Typo from "../../../../core/components/typo";
import __ from "../../../../core/utils/translate/translate";

const Section05 = () => {
  return (
    <section className="relative">
      <div className="relative z-[1] h-24 w-full  rounded-[50%] bg-white md:h-48" />
      <div className="-my-12 w-full bg-linear-purple-7 md:-my-24">
        <div className="bg-intro-purple relative flex w-full flex-col bg-cover bg-no-repeat px-10 py-16 md:py-24 lg:px-48 lg:py-32">
          <div className="flex flex-col items-center md:flex-row lg:gap-24">
            <div className="flex flex-col gap-2 text-justify lg:gap-4">
              <Typo as="h1" size="xl" type="on-accent" weight="bold">
                {__("Why Nojahan?")}
              </Typo>
              <Typo type="on-accent">
                {__("In a world where most adults believe more in their own generation and their abilities, Nojohan has come to prove to everyone that young people can handle the biggest missions. Nojahan is a world where everyone trusts you :)")}
              </Typo>
            </div>
            <div className="max-h-[256px] lg:max-h-[512px]">
              <img loading="lazy" src="/images/intro-mountain-peak.png" alt="mountainPeak" className="max-h-[inherit]" />
            </div>
          </div>
          <div className="mt-10 flex flex-col items-center md:flex-row lg:gap-24">
            <div className="flex flex-col gap-2 text-justify md:order-2 lg:gap-4">
              <Typo as="h1" size="xl" type="on-accent" weight="bold">
                {__("Why Nojahan?")}
              </Typo>
              <Typo type="on-accent">
                {__("It is forbidden to use negative verbs about teenagers in Nojohan! Here, no one tells you you dont know, you cant, you havent grown up yet, you dont understand and... in Najahan, everyone looks at you and says in unison: You can!")}
              </Typo>
            </div>
            <div className="max-h-[256px] md:order-1 lg:max-h-[512px]">
              <img loading="lazy" src="/images/intro-success.png" alt="success" className="max-h-[inherit]" />
            </div>
          </div>
        </div>
      </div>
      <div className="relative z-[3] flex h-24 w-full flex-col items-center gap-4 rounded-[50%] bg-white px-4 pt-12 md:h-48 md:px-52" />
    </section>
  );
};

export default Section05;
