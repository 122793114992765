import { useState, useRef } from "react";
import {CommonIcon} from "../../../../icon";
import Modal from "../../../../modal";
import TextArea from "../../../../input/text-area";
import Button from "../../../../button";
import {useUpdateComment} from "../../../../comment/api/comment";
import __ from "../../../../../utils/translate/translate";

const UpdateComment= ({ id, comment, refetch}) => {
  const [modal, setModal] = useState("idle");


  const refInputs = {
    comment: useRef(null),
  };

  const { mutate : updateComment, isLoading } = useUpdateComment();


  const handleUpdate = () => {
    // if (!Input.validateForm(refInputs)) return;
      updateComment(
          {
              id: id,
            data: {
                comment: refInputs.comment.current.getValue()
            }
          },
          {
              onSuccess: () => {
                  setModal("idle")
                      refetch();
              }
          }
      );
  };

  return (
    <>
      <CommonIcon
        icon="edit2"
        className="h-4 w-4 cursor-pointer"
        stroke="#707489"
        onClick={() => setModal("update")}
      />
      <Modal
        isOpen={modal === "update"}
        title={__("Edit comment")}
        onClose={() => setModal("idle")}
        className="flex flex-col items-end gap-4"
      >
        <TextArea
          label={__("Comment")}
          name="comment"
          value={comment}
          required
          rules={[{ rule: "minLength", cond: 2 }]}
          ref={refInputs.comment}
        />
        <Button onClick={handleUpdate} isLoading={isLoading} scale="fit">
            {__("Edit comment")}
        </Button>
      </Modal>
    </>
  );
};

export default UpdateComment;
