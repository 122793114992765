import { useState } from "react";
import {CommonIcon} from "../../../../icon";
import Modal from "../../../../modal";
import Typo from "../../../../typo";
import Button from "../../../../button";
import {useDeleteComment} from "../../../../comment/api/comment";
import handleError from "../../../../../helper/handelError";
import __ from "../../../../../utils/translate/translate";

const DeleteComment = ({ id,refetch }) => {
  const [modal, setModal] = useState("idle");

  const { mutate, isLoading } = useDeleteComment();

    
    const deleteComment = () => {
        mutate({ id },
        {
            onSuccess: () => {
                setModal("idle")
                refetch()
            },
                onError: (error) => {
            handleError(error)
        }})
    }

  return (
    <>
      <CommonIcon
        icon="add"
        className="rotate-45 cursor-pointer"
        stroke="#707489"
        onClick={() => setModal("update")}
      />
      <Modal
        isOpen={modal === "update"}
        title={__("Delete comment")}
        onClose={() => setModal("idle")}
        className="flex flex-col gap-4"
      >
        <Typo>{__("Are you sure to delete your comment?")}</Typo>
        <div className="flex justify-end gap-4">
          <Button type="light" onClick={() => setModal("idle")} scale="fit">
              {__("No")}
          </Button>
          <Button onClick={() => deleteComment()} isLoading={isLoading} scale="fit">
              {__("Yes")}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default DeleteComment;
