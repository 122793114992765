
const translateJson =
    {
        'fa-ir': require('./fa-ir.json'),
        'ar-sa': require('./ar-sa.json'),
        'en-us': {},
    }


export function currentLanguage()
{
    if(window.location.hostname.indexOf('konante.com') !== -1 || window.location.hostname.indexOf('konanta.com') !== -1)
    {
        return 'ar-sa';
    }

    const lang = localStorage.getItem('lang');
    if(lang === 'fa-ir' || lang === 'en-us' || lang === 'ar-sa')
    {
        return lang
    }

    return 'fa-ir'
}

export default function __(key, replace = {})  {
    try {
        const t = translateJson[currentLanguage()][key];
        let rt = t && t !== '' ? t : key

        if(replace)
        {
            const matches = rt.match(/:\w+/g);

            if(!!(!matches))
            {
                return rt;
            }

            rt = rt.replace(/:\w+/g, (match, key) => {
                const my_key = match.replace(':', '');
                return replace[my_key] ?? my_key
            });
        }
        return rt
    }
    catch (e) {
        return key
    }

}