import {
  AccessTokenKey,
  LockOnOrganizationPageDomainAdmin,
  LockOnOrganizationPageId,
  RefreshTokenKey
} from '../../../core/constants/constants'
import {LogoutFcm} from "../../../utils/helper/utils-db-firebase";

export function clearTokens() {
  localStorage.removeItem(AccessTokenKey)
  localStorage.removeItem(RefreshTokenKey)


  sessionStorage.removeItem(AccessTokenKey)
  sessionStorage.removeItem(RefreshTokenKey)
  sessionStorage.removeItem(RefreshTokenKey)
}


export function unLockOnOrganizationPage() {
  /**
   * Clear domain detail
   */
  localStorage.removeItem(LockOnOrganizationPageDomainAdmin)
  localStorage.removeItem(LockOnOrganizationPageId)

}

export function saveTokens({ accessToken, refreshToken, rememberMe = false }) {
  clearTokens()

  if (accessToken) {
    if (rememberMe) {
      localStorage.setItem(AccessTokenKey, accessToken)
    } else {
      sessionStorage.setItem(AccessTokenKey, accessToken)
    }
  }
  if (refreshToken) {
    if (rememberMe) {
      localStorage.setItem(RefreshTokenKey, refreshToken)
    } else {
      sessionStorage.setItem(RefreshTokenKey, refreshToken)
    }
  }
}

/**
 * @returns {string|null}
 */
export function getAccessToken() {
  return localStorage.getItem(AccessTokenKey) || sessionStorage.getItem(AccessTokenKey)
}

export function getLockOnPageOrganizationId() {
  return localStorage.getItem(LockOnOrganizationPageId)
}
export function getLockOnPageDomainId() {
  return localStorage.getItem(LockOnOrganizationPageDomainAdmin)
}

/**
 * @returns {string|null}
 */
export function getRefreshToken() {
  return localStorage.getItem(RefreshTokenKey) || sessionStorage.getItem(RefreshTokenKey)
}

/**
 */
export function redirectToHome() {
  window.location.href = '/'
}

export function saveTokensAndRedirect({ accessToken, refreshToken, rememberMe = false }) {
  saveTokens({ accessToken, refreshToken, rememberMe })
  redirectToHome()
}

/**
 */
export function clearTokensAndRedirect() {
  clearTokens()
  LogoutFcm()
  unLockOnOrganizationPage()
  redirectToHome()
}
