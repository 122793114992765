import React, {useEffect} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import {saveTokens} from "./services/tokenService";
import {motion} from "framer-motion";
import Typo from "../../core/components/typo";
import __ from "../../core/utils/translate/translate";

export default function LoginAs() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    useEffect(() => {
        saveTokens({
            accessToken : searchParams.get('accessToken')
        })
        navigate('/app');
    }, [searchParams]);
    return (
        <div className="flex h-screen w-screen flex-col items-center justify-center gap-2 bg-purple-5">
            <div className="w-20">
                <img src={`/images/mini-logo.svg`} alt="logo" className="animate-spin duration-[5000]"/>
            </div>
            <Typo weight="bold">{__("Connecting to user profile")}</Typo>
            <motion.div animate={{opacity: [1, 0.5, 1]}} transition={{repeat: Infinity}}>
                <Typo size="xs">{__("Please wait a moment")}</Typo>
            </motion.div>
        </div>
    );
}