import {twMerge} from "tailwind-merge";

const Spinner = ({type = "primary", size = "base", className}) => {
    const getType = () => {
        if (type === "primary") return "border-white";
        else return "border-purple-1";
    };

    const getSize = () => {
        if (size === "base") return "w-8 h-8";
        else if (size === "sm") return "w-6 h-6";
        else return "w-4 h-4";
    };

    return (
        <div
            className={twMerge(
                "inline-block animate-spin rounded-full border-[2px] !border-b-transparent",
                getType(),
                getSize(),
                className
            )}
        />
    );
};

export default Spinner;
