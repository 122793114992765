import React, {useState} from 'react';
import Button from "../../../../core/components/button";
import Modal from "../../../../core/components/modal";
import Typo from "../../../../core/components/typo";
import RenderIf from "../../render-if";
import {MiniLoading} from "../../loading";

export default function FileSizeModal({onClick, name}) {
    const [modal, setModal] = useState("idle");
    const [loading, setLoading] = useState(true)
    return (
        <>
            <Button type={'secondary'} size={'xs'}
                    containerClassName="mt-auto max-w-64"
                    onClick={() => setModal("join")}>آموزش کم کردن حجم</Button>
            <Modal
                isOpen={modal === "join"}
                onClose={() => setModal("idle")}
                title={'آموزش کم کردن حجم فایل'}
                className="flex flex-col gap-2"
            >
                <Typo className="flex flex-col gap-2">
                    <span>حجم ویدئوت یه خورده زیاده، ولی جای نگرانی نیست! 😎</span>
                    <span>می‌تونی برنامه Video Compressor رو دانلود کنی و با روشی که توی کلیپ زیر آموزش دادیم، حجم ویدیوت رو کم کنی، اونم بدون افت کیفیت! 🚀</span>
                </Typo>
                <RenderIf cond={loading}>
                    <div className={'mx-auto'}>
                        <MiniLoading />
                    </div>
                </RenderIf>
                <iframe
                    onLoad={() => setLoading(false)}
                    className={'w-full h-64 rounded'}
                    src="https://player.arvancloud.ir/index.html?config=https://nojahan.arvanvod.ir/jXBxPXxPGn/vdyLBGN9bJ/origin_config.json"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>

            </Modal>
        </>
    );
}