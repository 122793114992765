import {useInfiniteQuery, useQuery} from "@tanstack/react-query";
import {getPostById, getPosts} from "../../../data/api/post";

export const useGetPostById = (id) => {
    return useQuery({
        queryKey: ['home', 'news', id],
        queryFn: () => getPostById(id),
    })
}
export const useGetPosts = (type) => {
    return useInfiniteQuery({
        queryKey: ['posts','postsList'],
        queryFn: ({pageParam = 1}) => getPosts({page: pageParam,type: type,per_page: 8}),
        getNextPageParam: lastPage =>
            lastPage.meta.currentPage < lastPage.meta.lastPage ? lastPage.meta.currentPage + 1 : undefined,
    })
}