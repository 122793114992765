import {motion} from "framer-motion";
import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {twMerge} from "tailwind-merge";
import {InputLabel} from "..";
import {CommonIcon} from "../../icon";
import RenderIf from "../../render-if";
import Modal from "../../modal";
import Typo from "../../typo";
import useOutsideClick from "../../../hooks/useOutSideClick";
import useScreenSize from "../../../hooks/useScreenSize";
import Spinner from "../../spinner";
import __ from "../../../utils/translate/translate";

const SelectInput = (
    {
        scale = "full",
        label = "",
        inputMode = "text",
        dir = "rtl",
        item,
        itemID,
        data = [],
        autoFocus = false,
        maxLength,
        required = false,
        name,
        liveValidate = false,
        onChange,
        onKeyDown,
        placeHolder,
        inputClassName,
        className,
        disabled = false,
        loading = false,
    },
    ref
) => {
    const setInitItem = () => {
        return itemID ? data.find((record) => record.id.toString() === itemID.toString()) : item;
    };

    const [selected, setSelected] = useState(setInitItem());
    const [error, setError] = useState("");
    const [isFocus, setIsFocus] = useState(false);
    const [filterItems, setFilterItems] = useState([]);

    const refInput = useRef(null);
    const refWrapper = useRef(null);

    const {screenSize} = useScreenSize();

    useOutsideClick(refWrapper, () => {
        if (!["xs", "sm"].includes(screenSize)) setIsFocus(false);
    });

    const handleChange = (_selected) => {
        setIsFocus(false);
        onChange?.(_selected);
        setSelected(_selected);
    };

    useImperativeHandle(ref, () => ({
        getItem() {
            return selected;
        },
        setItem(value) {
            if (value === null || value === undefined) return;
            setSelected(value);
        },
        setItemByID(value) {
            if (value === null || value === undefined) return;
            const temp = data.find((record) => record.id.toString() === value.toString());
            setSelected(temp);
        },
        reset() {
            setSelected(null);
            setError("");
        },
        focus() {
            refInput.current.focus();
        },
        validate() {
            const {isValidate, error} = validate(name, selected, required);
            if (!isValidate) {
                refInput.current.focus();
                setError(error);
            }
            return isValidate;
        },
        setError(error) {
            refInput.current.focus();
            setError(error);
        },
    }));

    useEffect(() => {
        // setItems(required ? data : [{ id: null, title: "هیچکدام" }, ...data]);
        setFilterItems(required ? data : [{id: null, title: __("Nothing")}, ...data]);
    }, [data, required]);

    useEffect(() => {
        const temp = setInitItem();
        if (temp) {
            setSelected(temp);
            onChange?.(temp);
        }
    }, [data]);

    return (
        <>
            <div className={twMerge("flex flex-col gap-2", className, scale === "fit" ? "w-fit" : "w-full")}
                 ref={refWrapper}>
                <InputLabel label={label} required={required}/>

                <div className="relative">
                    <motion.input
                        animate={{
                            x: error.length > 0 ? [20, -20, 15, -15, 10, -10, 0] : [],
                        }}
                        transition={{duration: 0.5}}
                        className={twMerge(
                            "h-10 rounded-xl border px-2",
                            scale === "fit" ? "w-fit" : "w-full",
                            inputClassName,
                            error.length > 0 ? "border-red" : "border-purple-4 focus:border-purple-1"
                        )}
                        dir={dir}
                        inputMode={inputMode}
                        value={selected?.title ?? ""}
                        ref={refInput}
                        autoFocus={autoFocus}
                        maxLength={maxLength}
                        placeholder={placeHolder}
                        onKeyDown={onKeyDown}
                        onChange={() => null}
                        onFocus={() => setIsFocus(true)}
                        disabled={disabled}
                        readOnly
                    />
                    <CommonIcon
                        icon="chevron"
                        className={twMerge(
                            "absolute left-2 top-1/2 h-4 w-4 -translate-y-1/2 transition-all",
                            isFocus ? "rotate-90" : "-rotate-90"
                        )}
                    />
                    <ul
                        className={twMerge(
                            "absolute top-[calc(100%_+_0.5rem)] z-[2] max-h-[200px] w-full overflow-y-auto rounded-xl border border-purple-4 bg-white shadow",
                            isFocus ? "hidden md:block" : "hidden"
                        )}
                    >
                        <RenderIf cond={loading}>
                            <div className="p-2">
                                <Spinner type="accent" size="xs"/>
                            </div>
                        </RenderIf>
                        {filterItems.map((dataItem, i) => (
                            <li key={'selectCategoryInput' + i} className="cursor-pointer p-2 hover:bg-purple-4"
                                onClick={() => handleChange(dataItem)}>
                                {dataItem.title}
                            </li>
                        ))}
                    </ul>
                </div>
                <RenderIf cond={error.length > 0}>
                    <motion.div animate={{y: 0, opacity: 1}} initial={{y: -20, opacity: 0}}>
                        <Typo as="p" size="xs" type="danger">
                            {error}
                        </Typo>
                    </motion.div>
                </RenderIf>
            </div>
            <Modal isOpen={isFocus && ["xs", "sm"].includes(screenSize)} onClose={() => setIsFocus(false)} title={name}>
                {filterItems.map((dataItem, i) => (
                    <div key={'filterItems' + dataItem?.id + '-' + i} className="cursor-pointer p-2 hover:bg-purple-4"
                         onClick={() => handleChange(dataItem)}>
                        {dataItem.title}
                    </div>
                ))}
            </Modal>
        </>
    );
};

export default forwardRef(SelectInput);
