import RenderIf from "../../../../core/components/render-if";
import {useAuthContext} from "../../../Auth/provider/AuthProvider";
import MissionsFilter from "./components/filter";
import MissionsGridList from "./components/grid-list";
import MissionsHorizontalList from "./components/horizontal-list";
import __ from "../../../../core/utils/translate/translate";

const MissionsMissions = () => {
    const {organisation} = useAuthContext();

    return (
        <div className="flex flex-col gap-8">
            {/*<RenderIf cond={!!organisation}>*/}
            {/*    <div className="-mx-4">*/}
            {/*        <MissionsHorizontalList/>*/}
            {/*    </div>*/}
            {/*</RenderIf>*/}

            <div className="xl:px-52">
                <MissionsFilter title={!!organisation ? 'ماموریت‌های کانون' : __("Nojahan missions")}/>
            </div>

            <div className="flex flex-col gap-4 xl:px-52">
                <MissionsGridList commonGame={!organisation}/>
            </div>
        </div>
    );
};

export default MissionsMissions;
