import {NOJAHAN_LEVEL_DATA} from "./data";
import Typo from "../../../../core/components/typo";
import __ from "../../../../core/utils/translate/translate";

const Section04 = () => {
    return (
        <section className="flex flex-col items-center gap-6 px-4 pt-32 lg:px-52 lg:pt-0">
            <div className="text-primary-hover text-center  text-xl font-extrabold ">
                <Typo as="h1" size="xl" weight="bold">
                    {__("Here is Nojahan")}،
                </Typo>
                <Typo as="h2" size="xl" weight="bold">
                    {__("A place for all young people!")}
                </Typo>
            </div>
            <div className="grid w-full grid-cols-2 gap-x-4 gap-y-4 lg:grid-cols-4 lg:gap-x-16">
                {NOJAHAN_LEVEL_DATA.map((level, i) => (
                    <div className="flex flex-col items-center gap-2" key={'NOJAHAN_LEVEL_DATA' + i}>
                        <img src={level.image} alt={level.title} loading="lazy" className="h-24"/>
                        <Typo as="h2" type="accent" weight="bold">
                            {`${level.position}.${level.title}`}
                        </Typo>
                        <Typo as="p" type="secondary" size="sm" className="text-center">
                            {level.description}
                        </Typo>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Section04;
