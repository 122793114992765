import {Fragment} from "react";
import GameCardTimer from "./timer";
import Timer from "./timer";
import {twMerge} from "tailwind-merge";
import {GameIcon} from "../../icon";
import Typo from "../../typo";
import RenderIf from "../../render-if";
import FileView from "../../file-view";
import {ScoreBadge} from "../../badge";
import RenderSwitch from "../../render-switch";
import GameProgressbar from "../../progressbar/game";
import Button from "../../button";
import {getGameType} from "../../../constants/constants";
import {Link, useNavigate} from "react-router-dom";
import __ from "../../../utils/translate/translate";

const GameCard = ({
                      id,
                      cover,
                      type,
                      score,
                      minScore,
                      XP,
                      minXP,
                      name,
                      capacity,
                      players,
                      progress,
                      rate,
                      isEnd = false,
                      gameUserStatus,
                      className,
                      secondToStart,
                      secondToExpire,
                      categories,
                      status,
                      onClick,
                      special = 0,
                      buttonType = undefined,
                      secondaryButtonProps,
                  }) => {
    const navigate = useNavigate()

    const getIcon = () => {
        if (['self', 'team'].includes(type)) return "mission";
        else if (type === 'course') return "club";
        else if (type === 'camp') return "camp";
        else if (type === 'book') return "book";
        else if (type === 'match') return "match";
        else return "match";
    };

    return (
        <div className={twMerge("flex w-full shrink-0 flex-col gap-3 rounded-3xl bg-white p-3", className)}>
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-1">
                    <GameIcon icon={getIcon()}/>
                    <Typo as="h3" size="xs" weight="bold">
                        {getGameType(type)}
                    </Typo>
                    <RenderIf cond={(categories?.length ?? 0) > 0}>
                        <div className="h-full w-[1px] bg-purple-5"/>
                        {categories?.map((category, i) => (
                            <Typo as="h3" size="xs" key={'gameCard' + 'categories' + i}>
                                {category}
                            </Typo>
                        ))}
                    </RenderIf>
                </div>
                <RenderIf cond={status !== 'approved' && !!status}>
                    <Typo size="xs" className="rounded-full bg-purple-5 px-2">
                        Game.GetStatus(status!)
                    </Typo>
                </RenderIf>
                <ScoreBadge type="score" amount={score} withBack size="sm" hiddenOnZero={!score}/>
                <ScoreBadge type="xp" amount={XP} withBack size="sm" hiddenOnZero={!XP}/>
            </div>
            <div className="relative overflow-hidden rounded-xl">
                <RenderIf cond={!!cover} falsyElement={<img
                    src="https://dl.nojahan.ir/minio/shared_images/game-cover.png" alt={name}/>}>
                    <FileView {...cover} thumbnail={undefined} controls/>
                </RenderIf>
                <RenderIf cond={!!secondToStart && secondToStart < 0}>
                    <div
                        className="absolute bottom-0 left-0 right-0 flex items-center justify-between gap-2 bg-black/50 p-2 backdrop-blur">
                        <Typo as="p" size="xs" type="on-accent" weight="bold">
                            {__("Time left until start")}
                        </Typo>
                        <Timer second={Math.abs(secondToStart)} withDays renderFN={GameCardTimer}/>
                    </div>
                </RenderIf>
                <RenderIf
                    cond={!!secondToExpire && ((!!secondToStart && secondToStart >= 0) || !secondToStart) && secondToExpire < 0}
                >
                    <div
                        className="absolute bottom-0 left-0 right-0 flex items-center justify-between gap-2 bg-black/50 p-2 backdrop-blur">
                        <Typo as="p" size="xs" type="on-accent" weight="bold">
                            {__("Time left until end")}
                        </Typo>
                        <Timer second={Math.abs(secondToExpire)} withDays renderFN={GameCardTimer}/>
                    </div>
                </RenderIf>
            </div>
            <div className="flex items-center justify-between gap-1">
                <Typo as="h1" weight="bold" className="ui-clamp-1 flex-1">
                    {name}
                </Typo>
                {/*<ScoreBadge type="star" amount={rate}/>*/}
            </div>
            <div className="flex items-center justify-between gap-1">
                <RenderIf cond={!!players}>
                <Typo
                    as="p"
                    size="xs"
                    className={twMerge(['self', 'team'].includes(type) ? "invisible" : "visible")}
                >
                    <RenderSwitch
                        conds={[players === 0, players !== 0 && type === 'team']}
                        _default={<Fragment> {players} {__("people participated")}</Fragment>}
                    >
                        {[
                            <Fragment key={0}> {__("No participants")}</Fragment>,
                            <Fragment key={1}>{players}{__("team participated")}</Fragment>,
                        ]}
                    </RenderSwitch>
                </Typo>
                </RenderIf>
                <RenderIf cond={!!capacity}>
                    <Typo as="p" size="xs" type="secondary">
                        {__("Capacity")} :
                        <Typo as="span" size="xs">
                            <RenderIf cond={type === 'team'}
                                      falsyElement={`${capacity} ${__("person")} `}>
                                {capacity}{__("team")}
                            </RenderIf>
                        </Typo>
                    </Typo>
                </RenderIf>
            </div>
            <GameProgressbar progress={Math.min(progress, 100)} gameUserStatus={gameUserStatus}/>
            <RenderIf cond={!!minScore || !!minXP}>
                <div className="flex items-center gap-2">
                    <ScoreBadge withBack amount={minScore} type="score" size="xs" hiddenOnZero/>
                    <ScoreBadge withBack amount={minXP} type="xp" size="xs" hiddenOnZero/>
                    <Typo as="p" size="xs">
                        {__("Required")}
                    </Typo>
                </div>
            </RenderIf>
            <RenderIf cond={isEnd}>
                <div className="flex items-center justify-center rounded-full bg-red/10 px-3 py-2">
                    <Typo as="p" size="xs" type="danger">
                        {__("This :type is finished!", {type: type})}
                    </Typo>
                </div>
            </RenderIf>

            <div className=" mt-auto flex gap-2">
                <RenderIf cond={!secondaryButtonProps}>
                    <Link to={`/app/missions/${id}`} className="w-full">
                        <Button containerClassName="flex-1" type={buttonType}>
                            {__("See")}
                        </Button>
                    </Link>
                </RenderIf>
                <RenderIf cond={!!secondaryButtonProps}>
                    <Button
                        containerClassName="flex-1"
                        onClick={() => navigate(`/app/missions/${id}/report-card`)}
                        type={secondaryButtonProps?.secondButtonType}
                    >
                        {__("View report card")}
                    </Button>
                    <Button onClick={() => navigate(`/app/missions/${id}`)} type={`light`}>
                        {__("Program overview")}
                    </Button>
                </RenderIf>
            </div>
        </div>
    );
};

export default GameCard;
