import {useState} from "react";
import {useNavigate} from "react-router-dom";
import Button from "../../../../../../core/components/button";
import Modal from "../../../../../../core/components/modal";
import Typo from "../../../../../../core/components/typo";
import {useLeftOrganisation} from "../../../../api/organisation";
import {useAuthContext} from "../../../../../Auth/provider/AuthProvider";
import handleError from "../../../../../../core/helper/handelError";
import {setActiveOrganisation} from "../../../../../Auth/services/organisationService";
import __ from "../../../../../../core/utils/translate/translate";

const ClubViewLeftClubModal = () => {
    const navigate = useNavigate();
    const {organisation, setOrganisation} = useAuthContext();
    const [modal, setModal] = useState("idle");

    const {mutate: leftOrganisation, isLoading, isError, error, isSuccess, data} = useLeftOrganisation();

    const leftOrgan = () => {
        leftOrganisation({organisationUserId: organisation?.id}, {
            onSuccess: (data) => {
                setActiveOrganisation(null)
                window.location.href = "/app/profile"
            },
            onError: (error) => {
                handleError(error)
            }
        })
    }

    return (
        <>
            <Button type="danger-light" containerClassName="self-end" scale="fit" onClick={() => setModal("left")}>
                {__("Leave organization")}
            </Button>
            <Modal
                isOpen={modal === "left"}
                onClose={() => setModal("idle")}
                title={__("LEave organization")}
                className="flex flex-col gap-2"
            >
                <Typo>{__("Do you really go out of organization?")}</Typo>
                <Typo as="p" size="sm" className="mb-4 rounded bg-yellow/20 p-1" type="warning">
                    {__("When you leave the organization, you will leave your team and the points you got in the team will be zero!")}
                </Typo>
                <div className="flex justify-center gap-4">
                    <Button
                        type="danger"
                        onClick={() => {
                            leftOrgan()

                        }}
                        isLoading={isLoading}
                    >
                        {__("Yes")}
                    </Button>
                    <Button onClick={() => setModal("idle")} type="danger-light">
                        {__("No")}
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default ClubViewLeftClubModal;
