import {useInfiniteQuery} from "@tanstack/react-query";
import {apiListOrganization} from "../../../data/api/organisation";
import {apiListExplore} from "../../../data/api/explore";

export const useListExplore = (args, config) => {
    return useInfiniteQuery({
        queryKey: ['explore', args, config],
        queryFn: ({pageParam = 1}) => apiListExplore({...args, page: pageParam}),
        ...config,
        getNextPageParam: lastPage =>
            lastPage.meta.currentPage < lastPage.meta.lastPage ? lastPage.meta.currentPage + 1 : undefined,
    })
}