import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import {useAuthContext} from "../../../feature/Auth/provider/AuthProvider";
import Skeleton from "../skeleton";
import VerticalBox from "../box/vertical";

const JoinRequests = () => {
  const navigate = useNavigate();
  const { team, playerType } = useAuthContext();



  if (playerType !== "head") return <></>;
  if (!false)
    return (
      <div className="flex w-full items-center gap-2 rounded-xl bg-white p-2">
        <Skeleton className="h-12 w-12 rounded-full" />
        <Skeleton className="h-3 w-1/2" />
      </div>
    );
  return (
    <VerticalBox icon="two-user">
      <div className="flex w-full flex-col gap-2">
        {/*{communityUsersData?.data*/}
        {/*  .filter((cUser) => !cUser.confirm)*/}
        {/*  .map((communityUser, i) => (*/}
        {/*    <div className="grid grid-cols-3 items-center gap-x-4 gap-y-4 p-4 md:grid-cols-4" key={i}>*/}
        {/*      <div className="col-span-3 flex items-center gap-2">*/}
        {/*        <Avatar*/}
        {/*          onClick={() => navigate(ROUTES.PROFILE.INDEX(communityUser.user?.uuid!))}*/}
        {/*          name={communityUser.user?.fullName!}*/}
        {/*          image={communityUser.user?.avatar?.thumbnail}*/}
        {/*          avatarClassName={twMerge(*/}
        {/*            "border-4 border-white shadow-lg",*/}
        {/*            communityUser.type === EnumTeamUserType.HEAD && "border-red",*/}
        {/*            communityUser.type === EnumTeamUserType.MENTOR && "border-orange-1"*/}
        {/*          )}*/}
        {/*        />*/}
        {/*        <div className="flex flex-1 flex-col gap-1">*/}
        {/*          <Typo*/}
        {/*            as="h2"*/}
        {/*            weight="bold"*/}
        {/*            className="ui-clamp-1"*/}
        {/*            onClick={() => navigate(ROUTES.PROFILE.INDEX(communityUser.user?.uuid!))}*/}
        {/*          >*/}
        {/*            {communityUser.user?.fullName}*/}
        {/*          </Typo>*/}
        {/*          <Typo as="p" size="sm" type="secondary">*/}
        {/*            {communityUser.typeStr}*/}
        {/*          </Typo>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className="col-span-3 md:col-span-1">*/}
        {/*        <div className="flex justify-end gap-2 ">*/}
        {/*          <Button*/}
        {/*            scale="fit"*/}
        {/*            onClick={() => confirmMutate({ id: communityUser.id, confirm: true })}*/}
        {/*            disabled={rejectLoading}*/}
        {/*            isLoading={confirmLoading}*/}
        {/*          >*/}
        {/*            می‌پذیرم*/}
        {/*          </Button>*/}
        {/*          <Button*/}
        {/*            scale="fit"*/}
        {/*            type="light"*/}
        {/*            onClick={() => rejectMutate({ id: communityUser.id })}*/}
        {/*            disabled={confirmLoading}*/}
        {/*            isLoading={rejectLoading}*/}
        {/*          >*/}
        {/*            نمی‌پذیرم*/}
        {/*          </Button>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  ))}*/}
      </div>
    </VerticalBox>
  );
};

export default JoinRequests;
