import {useEffect, useState} from "react";
import useQueryString from "../../hooks/useQueryString";
import {TextInput} from "../input";
import {useDebounce} from "../../hooks/useDebounce";

const Search = ({ placeholder }) => {
  const { queryStringFN } = useQueryString();
  const [search, setSearch] = useState("");

  useEffect(() => {
    setSearch(queryStringFN.get("name"))
  }, [queryStringFN.get("name")]);

  useDebounce({
    value: search,
    fn: () => {
      if (search?.length === 0) queryStringFN.remove("name");
      else if (search?.length >= 2) queryStringFN.set("name", search);
    },
  });

  return (
    <TextInput
      name="search"
      placeholder={placeholder}
      className="w-full font-light "
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      inputMode="search"
    />
  );
};

export default Search;
