import useQueryString from "../../../../../../core/hooks/useQueryString";
import Typo from "../../../../../../core/components/typo";
import ScrollView from "../../../../../../core/components/scroll-view";
import Badge from "../../../../../../core/components/badge/counter";
import RenderIf from "../../../../../../core/components/render-if";
import Search from "../../../../../../core/components/search";
import {useState} from "react";
import {SimpleBadge} from "../../../../../../core/components/badge";
import __ from "../../../../../../core/utils/translate/translate";

const MissionsFilter = ({title}) => {
    const {queryStringFN} = useQueryString();
    const [data, setData] = useState();
    const handleOnClick = (type, special) => {
        queryStringFN.remove("type");
        queryStringFN.remove("special");
        if (!!type) queryStringFN.set("type", type);
        if (!!special) queryStringFN.set("special", special);
    };

    const getActiveIndex = () => {
        if (!queryStringFN.get("type") && !queryStringFN.get("special")) return 1;
        else if (queryStringFN.get("type") === 'self' && queryStringFN.get("special") === "-1")
            return 3;
        else if (queryStringFN.get("type") === 'self' && queryStringFN.get("special") === "1")
            return 4;
        else return 2;
    };

    return (
        <div className="flex flex-col gap-4">
            <Typo as="h1" weight="semi-bold" className="ui-clamp-1 flex-1 text-black">
                {title}
            </Typo>
            <Search placeholder={__("Search for the mission you want")}/>
            <ScrollView className="-mx-4 gap-4 px-4">
                <SimpleBadge isActive={getActiveIndex() === 1} onClick={() => handleOnClick()}
                       className="whitespace-nowrap">
                    {__("All")}
                </SimpleBadge>
                <SimpleBadge
                    isActive={getActiveIndex() === 2}
                    onClick={() => handleOnClick('team')}
                    className="whitespace-nowrap"
                >
                    {__("Team mission")}
                </SimpleBadge>
                <SimpleBadge
                    isActive={getActiveIndex() === 3}
                    onClick={() => handleOnClick('self', -1)}
                    className="whitespace-nowrap"
                >
                    {__("Self mission")}
                </SimpleBadge>
                <RenderIf cond={data?.data?.plan?.uuid === ''}>
                    <SimpleBadge
                        isActive={getActiveIndex() === 4}
                        onClick={() => handleOnClick('self', 1)}
                        className="whitespace-nowrap"
                    >
                        {__("Special mission of the head of the council")}
                    </SimpleBadge>
                </RenderIf>
            </ScrollView>
        </div>
    );
};

export default MissionsFilter;
