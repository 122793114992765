import RenderIf from "../../../../core/components/render-if";
import {CommonIcon} from "../../../../core/components/icon";
import Typo from "../../../../core/components/typo";

const GameAttribute = ({icon, label, value, description, cond}) => {
    if (!cond) return <></>;
    return (
        <div className="flex items-start gap-1">
            <RenderIf cond={!!icon}>
                <CommonIcon icon={icon} fill="#FF9052" stroke="transparent"/>
            </RenderIf>
            <RenderIf cond={!!label}>
                <Typo size="sm" type="secondary">
                    {label}:
                </Typo>
            </RenderIf>
            <div>
                <Typo size="sm" type="accent">
                    {value}
                </Typo>
                <RenderIf cond={!!description}>
                    <Typo size="xs" type="secondary">
                        {description}
                    </Typo>
                </RenderIf>
            </div>
        </div>
    );
};

export default GameAttribute;
