import React from 'react';
export function BottomSheet({ isOpen, onClose, children }) {
    return (
        <>
            {/* Overlay */}
            {isOpen && (
                <div
                    className="fixed inset-0 bg-black/50 z-40 transition-opacity"
                    onClick={onClose}
                />
            )}

            {/* Bottom Sheet */}
            <div
                className={`max-w-3xl fixed bottom-0 left-0 right-0 bg-white rounded-t-2xl z-50 transition-transform duration-300 ease-out max-h-[85vh] flex flex-col m-auto ${
                    isOpen ? 'translate-y-0' : 'translate-y-full'
                }`}
            >
                <div className="p-4 border-b border-gray-200">
                    <div className="w-12 h-1.5 bg-gray-300 rounded-full mx-auto mb-4" />
                </div>
                <div className="relative max-w-3xl overflow-y-auto flex-1 pb-20">
                    {children}
                </div>
            </div>
        </>
    );
}