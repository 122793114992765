const MissionsHeader = () => {
    return (
        <div className="relative min-h-[15rem] overflow-hidden bg-linear-purple-0 p-4 py-20">
            <img
                alt="background"
                src="/images/profile-header-bg.svg"
                className="absolute -top-12 w-full bg-cover opacity-50 md:w-1/4"
            />
        </div>
    );
};

export default MissionsHeader;
