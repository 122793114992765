import Skeleton from "../../../../../../core/components/skeleton";

const HomeNewsBoxLoading = () => {
  return (
    <div className="relative aspect-4/3 h-[calc(256px-28px-0.5rem)] shrink-0 rounded-xl bg-white">
      <Skeleton className="absolute bottom-2 right-2 h-3 w-1/2 rounded" />
    </div>
  );
};

export default HomeNewsBoxLoading;
