import React from 'react';
import RootLayout from "../../layout/root";
import TeamsFilter from "./components/filter";
import TeamList from "./components/list";

export default function TeamsPage(props) {
    return (
        <RootLayout title={"تیم‌ها"} navigateTo={'/app/profile'}>
            <div className={'flex flex-col gap-8 px-8 lg:px-4  py-20'}>
                <TeamsFilter title={"تیم‌ها"}/>
                <TeamList/>
            </div>
        </RootLayout>
    );
}