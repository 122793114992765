import useQueryString from "../../../../../../core/hooks/useQueryString";
import Typo from "../../../../../../core/components/typo";
import Search from "../../../../../../core/components/search";
import ScrollView from "../../../../../../core/components/scroll-view";
import {SimpleBadge} from "../../../../../../core/components/badge";


const AwardsFilter = ({ title }) => {
  const { queryStringFN } = useQueryString();

  const handleOnClick = (index = undefined) => {
    if (index === 0) queryStringFN.set("view", "awards-list");
    else if (index === 1) queryStringFN.set("view", "my-awards-list");
    else if (index === 2) queryStringFN.set("view", "team-awards-list");
    else if (index === 3) queryStringFN.set("view", "group-awards-list");
    else queryStringFN.set("view", "organization-awards-list");
  };

  const getActiveIndex = () => {
    if (queryStringFN.get("view", "awards-list") === "awards-list") return 0;
    else if (queryStringFN.get("view") === "my-awards-list") return 1;
    else if (queryStringFN.get("view") === "team-awards-list") return 2;
    else if (queryStringFN.get("view") === "group-awards-list") return 3;
    else return 4;
  };

  return (
    <div className="flex flex-col gap-4">
      <Typo as="h1" weight="semi-bold" className="ui-clamp-1 flex-1 text-black">
        {title}
      </Typo>
      {/*<Search placeholder="دنبال چه جایزه‌ای می‌گردی؟" />*/}
      {/*<ScrollView className="-mx-4 gap-4 px-4">*/}
      {/*  <SimpleBadge isActive={getActiveIndex() === 0} onClick={() => handleOnClick(0)} className="whitespace-nowrap">*/}
      {/*    همه*/}
      {/*  </SimpleBadge>*/}
      {/*  <SimpleBadge isActive={getActiveIndex() === 1} onClick={() => handleOnClick(1)} className="whitespace-nowrap">*/}
      {/*    فردی*/}
      {/*  </SimpleBadge>*/}
      {/*  <SimpleBadge isActive={getActiveIndex() === 2} onClick={() => handleOnClick(2)} className="whitespace-nowrap">*/}
      {/*    تیمی*/}
      {/*  </SimpleBadge>*/}

      {/*</ScrollView>*/}
    </div>
  );
};

export default AwardsFilter;
