import { useNavigate } from "react-router-dom";
import Typo from "../../core/components/typo";
import Button from "../../core/components/button";
import __ from "../../core/utils/translate/translate";

const RulesScreen = () => {
  const navigate = useNavigate();

 const rules =[
     {
         title: "",
         description: __("Nojahan account holders must read the following rules carefully and keep them in mind when using Nojahan services.")
     },
     {
         title: "",
         description: __("The owner of a user account is responsible for all the activities he performs using that user account on Nojahan. Therefore, in case of any problem or complaint about a user account, its owner must be responsible.")
     },
     {
         title: "",
         description: __("Note, if the mobile number you used to create your account changes; Change or delete it in Nojahan immediately by contacting the support.")

     },
     {
         title: "",
         description: __("The owner of a user account in Nojahan must be diligent in maintaining and maintaining the login information of his user account, such as mobile number and SMS password. If this information is stolen, it is necessary to immediately discuss the matter with Nojahan. Otherwise, you will be responsible for any activity that is done using your user account in Nojahan.")

     },
     {
         title: "",
         description: __("The user must enter his personal information correctly when creating a user account. (name and surname, national code, date of birth and other personal information) otherwise, Nojahan is not responsible for any mistakes recorded in the user account.")

     },
     {
         title: "",
         description: __("Each person can have only one user account in Nojahan.")

     },
     {
         title: "",
         description: __("Users are required to enter real information in their account. In special circumstances, Nojahan can request authentication from a user. In such a situation, if the user fails to authenticate, Nojahan can block that person")

     },
     {
         title: __("Nojahan can suspend, deactivate or delete the user account at any time at its discretion."),

         description: __("")
     },
     {
         title: __("Content production"),

         description: __("The meaning of content production is all the things that are created and published by the user in Nojahan. Any text, photo, sound and video published by the user is actually the content produced by him. Therefore, the user agrees not to publish content that violates the rules of Nojahan. Otherwise, Nojahan has the authority to deal with the wrong user according to the rules. The following are the contents that are prohibited to be published by the user in Nojahan and are against the rules of this application.")

     },
     {
         title: __("Information about any other person (name, national code, contact number, etc.)"),

         description: __("")
     },
     {
         title: __("Unreal information, violence, insulting, profanity"),

         description: __("")
     },
     {
         title: __("Content that violates customary and religious laws"),

         description: __("")
     },
     {
         title: __("Content that violates the laws of the Islamic Republic of Iran"),

         description: __("")
     },
     {
         title: __("Insulting saints, persons or ethnicities"),

         description: __("")
     },
     {
         title: __("Content that violates any intellectual and material property rights, privacy and any individual or entity rights"),

         description: __("")
     },
     {
         title: __("Judgable and objectionable content that prevents others from using this service or limits the possibility of their use or is harmful to others in any way."),

         description: __("")
     },
     {
         title: __("Conducting any kind of advertising, whether promoting the site, channel, etc., or encouraging others to follow, like, and other activities within the program, any use of the service that causes destruction, negative impact, or restrictions on other people's use, or any use that cause destruction, damage or create a limitation in the process of service activity."),

         description: __("")
     },
     {
         title: __("Collecting personal information of other users"),

         description: __("")
     },
     {
         title: __("Send spam or commercial messages through the Service"),

         description: __("")
     },
     {
         title: __("Using the service for any illegal or unauthorized purposes or activities, or participating in and promoting illegal activities, respecting the privacy of users"),

         description: __("")
     }
  ];

    return (
    <>
      {rules.map((rule, i) => (
        <div key={'rulesScreen' + 'rule' + rule?.id + '-' + i}>
          <Typo as="h1" weight="bold">
            {rule.title}
          </Typo>
          <Typo as="p">{rule.description}</Typo>
        </div>
      ))}

      <Button onClick={() => navigate(-1)} containerClassName="shrink-0">
          {__("Back")}
      </Button>
    </>
  );
};

export default RulesScreen;
