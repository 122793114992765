import React from 'react';
import {useMarkAsRead} from "../../../../api";
import handleError from "../../../../../../core/helper/handelError";
import Button from "../../../../../../core/components/button";
import {CommonIcon} from "../../../../../../core/components/icon";

export default function SeenBtn({notification,data,refecth}) {
    const {mutate: seen, isPending: seenPending} = useMarkAsRead()


    const handleSeen = () =>{
        seen(
            {id: notification?.id},
            {
                onSuccess: ()=>{
                    refecth()
                },
                onError: (error) =>{
                    handleError(error)
                }
            }
        )
    }
    return (
        <>
            <Button size="sm" scale="fit" onClick={handleSeen} isLoading={seenPending}>
                {data?.title}
            </Button>
        </>
    );
}