import React, {useState} from 'react';
import {SwiperSimpleCarousel} from "../../../../core/components/swiper";
import {SwiperSlide} from "swiper/react";
import FileView from "../../../../core/components/file-view";
import ScrollView from "../../../../core/components/scroll-view";
import {twMerge} from "tailwind-merge";
import Button from "../../../../core/components/button";
import Typo from "../../../../core/components/typo";

function HomeCampaign ({data}) {
    const [slide, setSlide] = useState([])
    const [i, setI] = useState('')
    const handleNavigate = (url) => {
        if (url) window.open(url, "_self");
    };
    return (
        <div className="px-4">
            <div className="sm:hidden">
                <SwiperSimpleCarousel loop pagination navigation className="w-full">
                    <div
                        className="relative h-[calc(256px-28px-0.5rem)] shrink-0 cursor-pointer overflow-auto rounded-xl w-96"
                        onClick={() => handleNavigate('/app/missions')}
                    >
                        <FileView url={"/images/jet.jpg"}
                                  mime={"image/jpeg"} controls imageClassName="!aspect-3/2"/>
                    </div>
                </SwiperSimpleCarousel>
            </div>
            <div className="hidden sm:block">
                <div className="hidden sm:block">
                    <ScrollView className="flex justify-center gap-4 px-4">
                        <div
                            className="relative h-[calc(256px-28px-0.5rem)] shrink-0 cursor-pointer overflow-auto rounded-xl w-96"
                            onClick={() => handleNavigate('/app/missions')}
                        >
                            <FileView url={"/images/jet.jpg"}
                                      mime={"image/jpeg"} controls imageClassName="!aspect-3/2"/>
                        </div>
                    </ScrollView>
                </div>
            </div>
        </div>
    );
}

export default HomeCampaign;