import axios from "axios";
import {getActiveOrganisation} from "../../feature/Auth/services/organisationService";
import {getAccessToken} from "../../feature/Auth/services/tokenService";
import {GetApiUrl} from "../../core/constants/constants";

export async function apiUploadFile(file) {
    const formData = new FormData();

    formData.append('file', file, "test");
    return axios.post(`${GetApiUrl()}file/upload`, formData, {
        headers: {
            'Authorization': `Bearer ${getAccessToken()}`,
            'organization': getActiveOrganisation()?.organizationId,
            'Content-Type': 'multipart/form-data'
        }
    });

    // return generalRequest.post('file/upload', data)
}
