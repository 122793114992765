import {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {OTPInput} from "../../core/components/input";
import Typo from "../../core/components/typo";
import Button from "../../core/components/button";
import AuthOTPTimer from "./components/otpTimer";
import {useLoginOtp} from "./api/auth";
import handleError from "../../core/helper/handelError";
import {saveTokens} from "./services/tokenService";
import { toast } from "react-toastify";
import __ from "../../core/utils/translate/translate";

const OtpScreen = () => {
    const navigate = useNavigate();
    const {mobile, otpToken} = useParams();

    const refInputs = {
        otp: useRef(null),
    };
    const {mutate: loginByOtp, isPending,
        isError, error, isSuccess, data} = useLoginOtp()


    const handleAuth = () => {
        // if (!Input.validateForm(refInputs)) return;
        if(!refInputs.otp.current.getValue())
        {
            toast.error(__("Please enter the code sent by SMS."))
            return;
        }

        loginByOtp({mobile, otp: refInputs.otp.current.getValue(), otpToken}, {
            onSuccess: (data) => {
                saveTokens({
                    accessToken: data.data?.accessToken, refreshToken: data.data?.refreshToken, rememberMe: true
                });
                navigate('/app', {replace: true})
            }, onError: (error) => {
                handleError(error);
            }
        })
    }


    useEffect(() => {
        if (mobile.length < 11) navigate('/authenticate/login', {replace: true});
    }, [mobile]);

    return (<>
        <OTPInput
            name={__("Verification code")}
            label={__("Please enter the code sent by SMS.")}
            autoFocus
            ref={refInputs.otp}
            required
            onKeyDown={(event) => {
                if (event.key === "Enter") handleAuth();
            }}
        />

        <Typo as="p" size="xs" type="accent" onClick={() => navigate(-1)}>
            {__("Not my number")}
            {' '}
            {mobile}
        </Typo>
        <div className="flex-1"/>

        <div className="flex flex-col items-center gap-2">
            <AuthOTPTimer mobile={mobile} otpToken={otpToken}/>
            <Button onClick={handleAuth} isLoading={isPending}>
                {__("Continue")}
            </Button>
        </div>
    </>);
};

export default OtpScreen;
