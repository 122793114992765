import React from "react";
import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";
import Typo from "../../../typo";

const QuizViewOption = ({ id, active, onClick, option }) => {
  return (
    <div className="flex items-center" onClick={() => onClick(id)}>
      <div className="w-fit rounded-full bg-purple-1 p-0.5">
        <div className="rounded-full bg-purple-5 p-1">
          <motion.div
            transition={{ duration: 0.3 }}
            animate={{ scale: active ? [0, 1.5, 1] : [1, 0] }}
            className={"h-3 w-3 rounded-full bg-purple-1"}
          />
        </div>
      </div>
      <div className="w-3">
        <motion.div animate={{ width: active ? "0.75rem" : "0rem" }} className="h-1 bg-purple-1" />
      </div>

      <Typo
        className={twMerge(
          "w-full  rounded-xl border p-1.5 px-4 transition-all md:p-3",
          active ? "border-purple-1" : "border-purple-4"
        )}
        size="sm"
        type={active ? "primary" : "secondary"}
      >
        {option}
      </Typo>
    </div>
  );
};

export default QuizViewOption;
