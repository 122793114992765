import {generalRequest} from "../services";

export async function apiGetMissionRunners(config = {}) {
    return generalRequest.get(`student/mission_runners`, config)
}

export async function apiGetMissionRunnerDetail(id, config = {}) {
    return generalRequest.get(`student/mission_runner/${id}`, config)
}

export async function apiGetMissionRunner(id, config = {}) {
    return generalRequest.get(`student/mission_runner/mission/${id}`, config)
}

export async function apiStartMissionRunner(id, config = {}) {
    return generalRequest.post(`student/mission/${id}/start`, config)
}

export async function apiRepeatMissionRunner(missionRunnerId, config = {}) {
    return generalRequest.put(`student/mission_runner/${missionRunnerId}`, config)
}

export async function apiPostMissionRunnerReport(runnerId, stepId, data) {
    return generalRequest.post(`student/mission_runner/${runnerId}/runner_step/${stepId}/report`, data)
}
export async function apiDeleteMissionRunnerReport(reportId) {
    return generalRequest.delete(`student/report/${reportId}`)
}
export async function apiPostVote(runnerId, vote){
    return generalRequest.post(`student/mission_voting/${runnerId}`, {vote: vote})
}