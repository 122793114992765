import { twMerge } from "tailwind-merge";
import {useState} from "react";
import Typo from "../../../../../core/components/typo";
import {CommonIcon} from "../../../../../core/components/icon";
import {toMBString} from "../../../../../utils/helper";

const StepGuide= ({ gameStepFileData, className, iconWrapperClassName ,content}) => {

  if (!gameStepFileData) return <></>;

  return (
    <div className={twMerge("mb-7 mt-7 flex items-center gap-2.5 rounded-xl p-3 bg-white w-full lg:w-1/3", className)}>
      <a
        href={gameStepFileData?.url}
        target={"_blank"}
        download={1}
        className={twMerge("flex cursor-pointer items-center justify-center rounded-xl p-2", iconWrapperClassName)}
      >
        <CommonIcon icon="document-download" />
      </a>

      <div className="flex flex-col items-start gap-[5px]">
        <Typo weight="bold" size={'sm'}>{content?.title}</Typo>

        <Typo type="secondary" size="sm">
          {toMBString(gameStepFileData?.size ?? 0)}
        </Typo>
      </div>
    </div>
  );
};

export default StepGuide;
