import {generalRequest} from '../services'

export async function getPosts(params) {
    const query = new URLSearchParams(params);
    const str = query.toString();
    return generalRequest.get('student/posts?'+ str, params)
}

export async function getPostById(id) {
    return generalRequest.get(`student/post/${id}`)
}