import { useParams } from "react-router-dom";
import GameStepRequirementInput from "./components/requirement-input";
import {useState} from "react";
import Skeleton from "../../../../../core/components/skeleton";
import {useGetMissionRunnerDetail} from "../../../api/missionRunner";

const GameStepRequirements = ({content,missionType}) => {

  if (!content)
    return (
      <div className="flex w-full flex-col gap-2 rounded-3xl bg-white p-4 sm:max-w-xs">
        <div className="flex items-center gap-2">
          <Skeleton className="h-2 flex-1" />
          <Skeleton className="h-6 w-12 rounded-full" />
        </div>
        <Skeleton className="aspect-4/3 min-h-[200px] w-full rounded-3xl" />
      </div>
    );

  return (
    <div className="grid gap-x-4 gap-y-4 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 items-start">
        <GameStepRequirementInput requirement={content} missionType={missionType}/>
    </div>
  );
};

export default GameStepRequirements;
