import {useNavigate} from "react-router-dom";
import {MiniLoading} from "../../../../../../core/components/loading";
import MissionNotFound from "../../../../../../core/components/not-found/mission";
import ActivityNotFound from "../../../../../../core/components/not-found/activity";
import {SimpleGameCard} from "../../../../../../core/components/card";
import HorizontalBox from "../../../../../../core/components/box/horizontal";
import {useGetMissionRunners} from "../../../../api/missionRunners";

const ProfilePersonGames = ({uuid, title, type, image}) => {
        const navigate = useNavigate();
        const {
            data,
            error,
            isLoading,
            isSuccess,
            isError,
        } = useGetMissionRunners( type);


        if (isLoading) return <MiniLoading/>;
        if (type ==="mission" && data?.data?.length === 0)
            return <MissionNotFound isPrivate userUUID={uuid}/>;
        if (type ==="learn" && data?.data?.length === 0)
            return <ActivityNotFound isPrivate userUUID={uuid}/>;

        return (
            <HorizontalBox background="purple-4" title={title} image={image} className="-mx-4">

                {data?.data
                    // .filter((gameUser) => !!gameUser.game)
                    .map((gameUser, i) => (
                        <SimpleGameCard
                            key={'SimpleGameCard' + gameUser?.id + '-' + i}
                            id={gameUser.mission?.id}
                            cover={gameUser.mission?.coverDetail}
                            XP={gameUser.mission?.experience ?? 0}
                            score={gameUser.mission?.score ?? 0}
                            title={gameUser.mission?.title}
                            type={gameUser.mission?.type}
                            onClick={() => navigate(ROUTES.GAME.INDEX(gameUser.game?.uuid))}
                        />
                    ))}
            </HorizontalBox>
        );
    }
;

export default ProfilePersonGames;
