import ActivityList from "./components/list";
import __ from "../../../../core/utils/translate/translate";

const ClubMyActivitiesList = () => {
    return (
        <div className="sm:px-36 md:px-20 lg:px-1  xl:px-52">
            <ActivityList
                title={'دوره‌های در جریان'}
                statuses={[
                    'signup', 'doing', 'ready'
                ]}
                mode="doing"
                showEmptyIcon
            />

            <ActivityList
                title={'دوره‌های پایان یافته'}
                mode="done"
                statuses={[
                    'finish',
                    'success',
                    'report_card',
                    'evaluation',
                    'failed',
                    'left'
                ]}
            />
        </div>
    );
};

export default ClubMyActivitiesList;
