import Skeleton from "../../../../../core/components/skeleton";

const NewsCardLoading = () => {
    return (
        <div className="bg-white rounded-3xl shadow-[0px_4px_4px_0px_rgba(0,0,0,0.10)] overflow-hidden">
            <Skeleton className="w-full aspect-3/2"/>
            <div className="p-4 flex flex-col gap-4">
                <Skeleton className='w-1/2 h-4'/>
                <div className="flex flex-col gap-1">
                    <Skeleton className='w-full h-2'/>
                    <Skeleton className='w-1/2 h-2'/>
                </div>
                <div className="flex justify-between">
                    <Skeleton className='w-32 h-2'/>
                    <Skeleton className='w-10 h-2'/>
                </div>
            </div>
        </div>
    );
}

export default NewsCardLoading;