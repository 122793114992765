import Typo from "../../typo";
import __ from "../../../utils/translate/translate";


const GameCardTimer= ({ days, hours, minutes, seconds }) => {

    return (<div className="flex items-start gap-1">
        <div className="flex flex-col items-center">
            <Typo weight="bold" type="on-accent" size="sm">
                {seconds}
            </Typo>
            <Typo type="on-accent" size="3xs">
                {__("Second")}
            </Typo>
        </div>
        <Typo weight="bold" type="on-accent" size="sm">
            :
        </Typo>
        <div className="flex flex-col items-center">
            <Typo weight="bold" type="on-accent" size="sm">
                {minutes}
            </Typo>
            <Typo type="on-accent" size="3xs">
                {__("Minute")}
            </Typo>
        </div>
        <Typo weight="bold" type="on-accent" size="sm">
            :
        </Typo>
        <div className="flex flex-col items-center">
            <Typo weight="bold" type="on-accent" size="sm">
                {hours}
            </Typo>
            <Typo type="on-accent" size="3xs">
                {__("Hours")}
            </Typo>
        </div>
        <Typo weight="bold" type="on-accent" size="sm">
            :
        </Typo>
        <div className="flex flex-col items-center">
            <Typo weight="bold" type="on-accent" size="sm">
                {days}
            </Typo>
            <Typo type="on-accent" size="3xs">
                {__("Day")}
            </Typo>
        </div>
    </div>);
}

export default GameCardTimer;