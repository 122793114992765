import RenderIf from "../../render-if";
import Typo from "../../typo";
import __ from "../../../utils/translate/translate";

const InputLabel = ({ label, required = false, withoutColon = false }) => {

  return (
    <RenderIf cond={!!label && label.length > 0}>
      <div className="flex items-start justify-between gap-1">
        <label className="block text-xs text-gray-1">
          {label}
          {!withoutColon ? ":" : ""}
        </label>
        <RenderIf cond={!required}>
          <Typo as="span" size="xs" type="accent" className="rounded-full bg-purple-5 px-2">
            {__("Optional")}
          </Typo>
        </RenderIf>
      </div>
    </RenderIf>
  );
};

export default InputLabel;
