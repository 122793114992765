import {twMerge} from "tailwind-merge";
import {GameIcon} from "../../icon";
import Typo from "../../typo";
import {ScoreBadge} from "../../badge";
import RenderIf from "../../render-if";
import FileView from "../../file-view";
import {getGameType} from "../../../constants/constants";
import {Link} from "react-router-dom";

const SimpleGameCard = ({id,type, score, XP, cover, title, onClick, className}) => {
    const getIcon = () => {
        if (['self', 'team'].includes(type)) return "mission";
        else if (type === 'course') return "club";
        else if (type === 'camp') return "camp";
        else if (type === 'book') return "book";
        else if (type === 'match') return "match";
        else return "match";
    };


    return (
        <Link to={`/app/missions/${id}`} // Path: src/feature/game/index.jsx
            className={twMerge(
                "flex w-full shrink-0 cursor-pointer flex-col gap-1 rounded-3xl bg-white p-2 xs:w-52",
                className
            )}
        >
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-1">
                    <div className="flex items-center gap-1">
                        <GameIcon icon={getIcon()}/>

                        <Typo as="h2" size="xs" weight="bold">
                            {getGameType(type)}
                        </Typo>
                    </div>
                </div>
                <ScoreBadge amount={XP} type={"xp"} withBack size="sm" hiddenOnZero/>
                <ScoreBadge amount={score} type={"score"} withBack size="sm" hiddenOnZero/>
            </div>
            <div className="w-full overflow-hidden rounded-xl">
                <RenderIf cond={!!cover} falsyElement={<img src="/images/game-cover.png" alt={title}/>}>
                    <FileView {...cover} thumbnail={undefined} controls/>
                </RenderIf>
            </div>
            <Typo className="ui-clamp-1" size="sm" weight="bold">
                {title}
            </Typo>
        </Link>
    );
};

export default SimpleGameCard;
