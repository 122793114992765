import axios from 'axios'
import {camelize, snakize} from '../../core/utils/convert-to-camel-snake'
import {
    clearTokensAndRedirect,
    getAccessToken,
    getLockOnPageDomainId,
    getLockOnPageOrganizationId
} from '../../feature/Auth/services/tokenService'
import {getActiveOrganisation} from "../../feature/Auth/services/organisationService";
import {currentLanguage} from "../../core/utils/translate/translate";

const getAuthToken = () => {
    const token = getAccessToken()
    return `Bearer ${token}`
}

const addHeaderInterceptor = (instance) => {
    instance.interceptors.request.use(
        (config) => {
            config.headers.Authorization = getAuthToken()
            config.headers.organization = getActiveOrganisation()?.organizationId
            config.headers[`Accept-language`] = currentLanguage()

            if(getLockOnPageDomainId())
            {
                config.headers[`x-organization-domain`] = getLockOnPageDomainId()
            }

            return config
        },
        (e) => {
            return Promise.reject(e)
        },
    )
}

const addRequestInterceptor = (instance) => {
    instance.interceptors.request.use(
        (config) => {
            config.data = snakize(config?.data)
            return config
        },
        (e) => {
            return Promise.reject(e)
        },
    )
}

const addResponseInterceptor = (instance) => {
    instance.interceptors.response.use(
        (res) => {
            if (res && res.data) return camelize(res.data)
            return res
        },
        (e) => {

            if (e?.response?.status === 401 && !e?.request?.responseURL.endsWith('auth/authenticate')) {
                // try {
                //   await refreshToken();
                //   // درخواست دوباره با توکن جدید
                //   const originalRequest = error.config;
                //   const response = await api.request(originalRequest);
                //   return response;
                // } catch (refreshError) {
                // خروج از سیستم و هدایت به صفحه ورود
                clearTokensAndRedirect()
                // }
            }
            return Promise.reject(e)
        },
    )
}
const createPureInstance = (baseUrl, {headers, ...configs} = {}) => {
    return axios.create({
        timeout: 15000,
        baseURL: baseUrl,
        headers: {
            ...(headers || {}),
        },
        maxRedirects: 0,
        ...configs,
    })
}

const createInstance = (baseUrl, configs = {}) => {
    const instance = createPureInstance(baseUrl, configs)

    addHeaderInterceptor(instance)
    addResponseInterceptor(instance)
    addRequestInterceptor(instance)

    return instance
}
// const refreshToken = async (instance) => {
//   try {
//     const response = await instance.post('/api/refresh-token', {
//       refreshToken: localStorage.getItem(RefreshTokenKey),
//     })
//     localStorage.setItem('token', response.data?.token)
//     localStorage.setItem('refreshToken', response.data?.refreshToken)
//   } catch (error) {
//     throw new Error('Unable to refresh token')
//   }
// }

export {createPureInstance, createInstance}
