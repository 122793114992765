const CommonIcon = ({icon, stroke = "#820F99", fill = "none", ...rest}) => {
    switch (icon) {
        case "add":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={fill}
                    stroke={stroke}
                    {...rest}
                >
                    <path d="M6 12H18" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12 18V6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            );
        case 'trash':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                     stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
                     className="lucide lucide-trash-2">
                    <path d="M3 6h18"/>
                    <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/>
                    <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/>
                    <line x1="10" x2="10" y1="11" y2="17"/>
                    <line x1="14" x2="14" y1="11" y2="17"/>
                </svg>
            );
        case "more":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                     stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
                     className="lucide lucide-ellipsis-vertical"
                     {...rest}>
                    <circle cx="12" cy="12" r="1"/>
                    <circle cx="12" cy="5" r="1"/>
                    <circle cx="12" cy="19" r="1"/>
                </svg>
            );
        case "add-circle":
            return (
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={rest.className}
                >
                    <path
                        d="M10.0003 18.3334C14.5837 18.3334 18.3337 14.5834 18.3337 10.0001C18.3337 5.41675 14.5837 1.66675 10.0003 1.66675C5.41699 1.66675 1.66699 5.41675 1.66699 10.0001C1.66699 14.5834 5.41699 18.3334 10.0003 18.3334Z"
                        fill="url(#paint0_linear_3481_74985)"
                    />
                    <path d="M6.66699 10H13.3337" stroke="white" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M10 13.3334V6.66675" stroke="white" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <defs>
                        <linearGradient
                            id="paint0_linear_3481_74985"
                            x1="2.93732"
                            y1="19.0687"
                            x2="16.7839"
                            y2="0.931456"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#E22D79"/>
                            <stop offset="1" stopColor="#FB6779"/>
                        </linearGradient>
                    </defs>
                </svg>
            );
        case "add-user":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={fill}
                    stroke={stroke}
                    {...rest}
                >
                    <path
                        d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M3.40991 22C3.40991 18.13 7.25991 15 11.9999 15C12.9599 15 13.8899 15.13 14.7599 15.37"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M22 18C22 18.32 21.96 18.63 21.88 18.93C21.79 19.33 21.63 19.72 21.42 20.06C20.73 21.22 19.46 22 18 22C16.97 22 16.04 21.61 15.34 20.97C15.04 20.71 14.78 20.4 14.58 20.06C14.21 19.46 14 18.75 14 18C14 16.92 14.43 15.93 15.13 15.21C15.86 14.46 16.88 14 18 14C19.18 14 20.25 14.51 20.97 15.33C21.61 16.04 22 16.98 22 18Z"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M19.49 17.98H16.51"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M18 16.52V19.51"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );

        case "arrow":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={fill}
                    stroke={stroke}
                    {...rest}
                >
                    <path
                        d="M9.57 5.93018L3.5 12.0002L9.57 18.0702"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M20.5 12H3.67004"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case "attachment":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={fill}
                    stroke={stroke}
                    {...rest}
                >
                    <path
                        d="M12.33 12.1499L9.85999 14.6199C8.48999 15.9899 8.48999 18.1999 9.85999 19.5699C11.23 20.9399 13.44 20.9399 14.81 19.5699L18.7 15.6799C21.43 12.9499 21.43 8.50992 18.7 5.77992C15.97 3.04992 11.53 3.04992 8.79999 5.77992L4.55999 10.0199C2.21999 12.3599 2.21999 16.1599 4.55999 18.5099"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );

        case "award":
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12 16.75C11.6 16.75 11.2 16.72 10.82 16.65C8.7 16.34 6.77 15.12 5.55 13.31C4.7 12.03 4.25 10.54 4.25 9C4.25 4.73 7.73 1.25 12 1.25C16.27 1.25 19.75 4.73 19.75 9C19.75 10.54 19.3 12.03 18.45 13.31C17.22 15.13 15.29 16.34 13.15 16.66C12.8 16.72 12.4 16.75 12 16.75ZM12 2.75C8.55 2.75 5.75 5.55 5.75 9C5.75 10.25 6.11 11.45 6.79 12.47C7.78 13.93 9.33 14.91 11.05 15.16C11.69 15.27 12.32 15.27 12.91 15.16C14.66 14.91 16.21 13.92 17.2 12.46C17.88 11.44 18.24 10.24 18.24 8.98999C18.25 5.54999 15.45 2.75 12 2.75Z"
                        fill="#707489"
                    />
                    <path
                        d="M6.46933 22.5905C6.32933 22.5905 6.19933 22.5705 6.05933 22.5405C5.40933 22.3905 4.90933 21.8904 4.75933 21.2404L4.40933 19.7704C4.38933 19.6804 4.31933 19.6104 4.21933 19.5804L2.56933 19.1905C1.94933 19.0405 1.45933 18.5805 1.28933 17.9705C1.11933 17.3605 1.28933 16.7005 1.73933 16.2505L5.63933 12.3505C5.79933 12.1905 6.01933 12.1105 6.23933 12.1305C6.45933 12.1505 6.65933 12.2704 6.78933 12.4604C7.77933 13.9204 9.32933 14.9105 11.0593 15.1605C11.6993 15.2705 12.3293 15.2705 12.9193 15.1605C14.6693 14.9105 16.2193 13.9204 17.2093 12.4604C17.3293 12.2704 17.5393 12.1505 17.7593 12.1305C17.9793 12.1105 18.1993 12.1905 18.3593 12.3505L22.2593 16.2505C22.7093 16.7005 22.8793 17.3605 22.7093 17.9705C22.5393 18.5805 22.0393 19.0505 21.4293 19.1905L19.7793 19.5804C19.6893 19.6004 19.6193 19.6704 19.5893 19.7704L19.2393 21.2404C19.0893 21.8904 18.5893 22.3905 17.9393 22.5405C17.2893 22.7005 16.6193 22.4704 16.1993 21.9604L11.9993 17.1305L7.79933 21.9705C7.45933 22.3705 6.97933 22.5905 6.46933 22.5905ZM6.08933 14.0305L2.79933 17.3205C2.70933 17.4105 2.71933 17.5105 2.73933 17.5705C2.74933 17.6205 2.79933 17.7204 2.91933 17.7404L4.56933 18.1305C5.21933 18.2805 5.71933 18.7805 5.86933 19.4305L6.21933 20.9005C6.24933 21.0305 6.34933 21.0705 6.40933 21.0905C6.46933 21.1005 6.56933 21.1105 6.65933 21.0105L10.4893 16.6005C8.78933 16.2705 7.22933 15.3605 6.08933 14.0305ZM13.5093 16.5905L17.3393 20.9904C17.4293 21.1004 17.5393 21.1004 17.5993 21.0804C17.6593 21.0704 17.7493 21.0205 17.7893 20.8905L18.1393 19.4205C18.2893 18.7705 18.7893 18.2705 19.4393 18.1205L21.0893 17.7305C21.2093 17.7005 21.2593 17.6105 21.2693 17.5605C21.2893 17.5105 21.2993 17.4005 21.2093 17.3105L17.9193 14.0204C16.7693 15.3504 15.2193 16.2605 13.5093 16.5905Z"
                        fill="#707489"
                    />
                    <path
                        d="M13.8901 12.8903C13.6301 12.8903 13.3201 12.8203 12.9501 12.6003L12.0001 12.0302L11.0501 12.5902C10.1801 13.1102 9.61014 12.8102 9.40014 12.6602C9.19014 12.5102 8.74014 12.0603 8.97014 11.0703L9.21014 10.0403L8.41014 9.30023C7.97014 8.86023 7.81014 8.33025 7.96014 7.85025C8.11014 7.37025 8.55014 7.03024 9.17014 6.93024L10.2401 6.75024L10.7501 5.63025C11.0401 5.06025 11.4901 4.74023 12.0001 4.74023C12.5101 4.74023 12.9701 5.07026 13.2501 5.64026L13.8401 6.82025L14.8301 6.94025C15.4401 7.04025 15.8801 7.38023 16.0401 7.86023C16.1901 8.34023 16.0301 8.87024 15.5901 9.31024L14.7601 10.1403L15.0201 11.0703C15.2501 12.0603 14.8001 12.5102 14.5901 12.6602C14.4801 12.7502 14.2401 12.8903 13.8901 12.8903ZM9.61014 8.39026L10.3001 9.08023C10.6201 9.40023 10.7801 9.94025 10.6801 10.3802L10.4901 11.1802L11.2901 10.7102C11.7201 10.4602 12.3001 10.4602 12.7201 10.7102L13.5201 11.1802L13.3401 10.3802C13.2401 9.93025 13.3901 9.40023 13.7101 9.08023L14.4001 8.39026L13.5301 8.24023C13.1101 8.17023 12.6901 7.86026 12.5001 7.48026L12.0001 6.50024L11.5001 7.50024C11.3201 7.87024 10.9001 8.19025 10.4801 8.26025L9.61014 8.39026Z"
                        fill="#8252E1"
                    />
                </svg>
            );

        case "award-hover":
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12 16.75C11.6 16.75 11.2 16.72 10.82 16.65C8.7 16.34 6.77 15.12 5.55 13.31C4.7 12.03 4.25 10.54 4.25 9C4.25 4.73 7.73 1.25 12 1.25C16.27 1.25 19.75 4.73 19.75 9C19.75 10.54 19.3 12.03 18.45 13.31C17.22 15.13 15.29 16.34 13.15 16.66C12.8 16.72 12.4 16.75 12 16.75ZM12 2.75C8.55 2.75 5.75 5.55 5.75 9C5.75 10.25 6.11 11.45 6.79 12.47C7.78 13.93 9.33 14.91 11.05 15.16C11.69 15.27 12.32 15.27 12.91 15.16C14.66 14.91 16.21 13.92 17.2 12.46C17.88 11.44 18.24 10.24 18.24 8.98999C18.25 5.54999 15.45 2.75 12 2.75Z"
                        fill="#FFFFFF"
                    />
                    <path
                        d="M6.46933 22.5905C6.32933 22.5905 6.19933 22.5705 6.05933 22.5405C5.40933 22.3905 4.90933 21.8904 4.75933 21.2404L4.40933 19.7704C4.38933 19.6804 4.31933 19.6104 4.21933 19.5804L2.56933 19.1905C1.94933 19.0405 1.45933 18.5805 1.28933 17.9705C1.11933 17.3605 1.28933 16.7005 1.73933 16.2505L5.63933 12.3505C5.79933 12.1905 6.01933 12.1105 6.23933 12.1305C6.45933 12.1505 6.65933 12.2704 6.78933 12.4604C7.77933 13.9204 9.32933 14.9105 11.0593 15.1605C11.6993 15.2705 12.3293 15.2705 12.9193 15.1605C14.6693 14.9105 16.2193 13.9204 17.2093 12.4604C17.3293 12.2704 17.5393 12.1505 17.7593 12.1305C17.9793 12.1105 18.1993 12.1905 18.3593 12.3505L22.2593 16.2505C22.7093 16.7005 22.8793 17.3605 22.7093 17.9705C22.5393 18.5805 22.0393 19.0505 21.4293 19.1905L19.7793 19.5804C19.6893 19.6004 19.6193 19.6704 19.5893 19.7704L19.2393 21.2404C19.0893 21.8904 18.5893 22.3905 17.9393 22.5405C17.2893 22.7005 16.6193 22.4704 16.1993 21.9604L11.9993 17.1305L7.79933 21.9705C7.45933 22.3705 6.97933 22.5905 6.46933 22.5905ZM6.08933 14.0305L2.79933 17.3205C2.70933 17.4105 2.71933 17.5105 2.73933 17.5705C2.74933 17.6205 2.79933 17.7204 2.91933 17.7404L4.56933 18.1305C5.21933 18.2805 5.71933 18.7805 5.86933 19.4305L6.21933 20.9005C6.24933 21.0305 6.34933 21.0705 6.40933 21.0905C6.46933 21.1005 6.56933 21.1105 6.65933 21.0105L10.4893 16.6005C8.78933 16.2705 7.22933 15.3605 6.08933 14.0305ZM13.5093 16.5905L17.3393 20.9904C17.4293 21.1004 17.5393 21.1004 17.5993 21.0804C17.6593 21.0704 17.7493 21.0205 17.7893 20.8905L18.1393 19.4205C18.2893 18.7705 18.7893 18.2705 19.4393 18.1205L21.0893 17.7305C21.2093 17.7005 21.2593 17.6105 21.2693 17.5605C21.2893 17.5105 21.2993 17.4005 21.2093 17.3105L17.9193 14.0204C16.7693 15.3504 15.2193 16.2605 13.5093 16.5905Z"
                        fill="#FFFFFF"
                    />
                    <path
                        d="M13.8901 12.8903C13.6301 12.8903 13.3201 12.8203 12.9501 12.6003L12.0001 12.0302L11.0501 12.5902C10.1801 13.1102 9.61014 12.8102 9.40014 12.6602C9.19014 12.5102 8.74014 12.0603 8.97014 11.0703L9.21014 10.0403L8.41014 9.30023C7.97014 8.86023 7.81014 8.33025 7.96014 7.85025C8.11014 7.37025 8.55014 7.03024 9.17014 6.93024L10.2401 6.75024L10.7501 5.63025C11.0401 5.06025 11.4901 4.74023 12.0001 4.74023C12.5101 4.74023 12.9701 5.07026 13.2501 5.64026L13.8401 6.82025L14.8301 6.94025C15.4401 7.04025 15.8801 7.38023 16.0401 7.86023C16.1901 8.34023 16.0301 8.87024 15.5901 9.31024L14.7601 10.1403L15.0201 11.0703C15.2501 12.0603 14.8001 12.5102 14.5901 12.6602C14.4801 12.7502 14.2401 12.8903 13.8901 12.8903ZM9.61014 8.39026L10.3001 9.08023C10.6201 9.40023 10.7801 9.94025 10.6801 10.3802L10.4901 11.1802L11.2901 10.7102C11.7201 10.4602 12.3001 10.4602 12.7201 10.7102L13.5201 11.1802L13.3401 10.3802C13.2401 9.93025 13.3901 9.40023 13.7101 9.08023L14.4001 8.39026L13.5301 8.24023C13.1101 8.17023 12.6901 7.86026 12.5001 7.48026L12.0001 6.50024L11.5001 7.50024C11.3201 7.87024 10.9001 8.19025 10.4801 8.26025L9.61014 8.39026Z"
                        fill="#FFFFFF"
                        fillOpacity={0.6}
                    />
                </svg>
            );
        case "award2":
            return (
                <svg width="33" height="33" viewBox="0 0 33 33" fill="none"
                     xmlns="http://www.w3.org/2000/svg" {...rest}>
                    <g>
                        <path
                            opacity="0.4"
                            d="M27.4173 14.0156L27.3399 24.682C27.3109 28.6819 25.9679 30.0152 21.9679 30.0152H11.3013C7.30126 30.0152 5.9776 28.6819 6.00662 24.682L6.08398 14.0156H27.4173Z"
                            fill="white"
                        />
                        <path
                            d="M29.4864 10.0157L29.4767 11.349C29.466 12.8156 28.7507 14.0156 26.7907 14.0156H6.79067C4.75067 14.0156 4.13271 12.8156 4.14335 11.349L4.15302 10.0157C4.16366 8.54909 4.79903 7.34912 6.83903 7.34912H26.839C28.799 7.34912 29.497 8.54909 29.4864 10.0157Z"
                            fill="white"
                        />
                        <path
                            opacity="0.4"
                            d="M16.3591 7.3491H8.99905C8.5493 6.85578 8.56814 6.0958 9.05162 5.61581L10.9587 3.72253C11.4556 3.22921 12.2689 3.22921 12.7587 3.72253L16.3591 7.3491Z"
                            fill="white"
                        />
                        <path
                            opacity="0.4"
                            d="M24.6657 7.3491H17.3057L20.9587 3.72253C21.4556 3.22921 22.2689 3.22921 22.7587 3.72253L24.6383 5.61581C25.1148 6.0958 25.1226 6.85578 24.6657 7.3491Z"
                            fill="white"
                        />
                        <path
                            opacity="0.6"
                            d="M12.7107 14.0156L12.661 20.8688C12.6532 21.9354 13.822 22.5621 14.7195 21.9887L15.9789 21.1621C16.4343 20.8688 17.0077 20.8688 17.4455 21.1621L18.6264 21.9621C19.5021 22.5487 20.6933 21.9221 20.7011 20.8554L20.7507 14.0156H12.7107Z"
                            fill="white"
                        />
                    </g>
                </svg>
            );
        case "calendar":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke={stroke}
                    fill={fill}
                    xmlns="http://www.w3.org/2000/svg"
                    {...rest}
                >
                    <path
                        d="M16.75 3.56V2C16.75 1.59 16.41 1.25 16 1.25C15.59 1.25 15.25 1.59 15.25 2V3.5H8.74998V2C8.74998 1.59 8.40998 1.25 7.99998 1.25C7.58998 1.25 7.24998 1.59 7.24998 2V3.56C4.54998 3.81 3.23999 5.42 3.03999 7.81C3.01999 8.1 3.25999 8.34 3.53999 8.34H20.46C20.75 8.34 20.99 8.09 20.96 7.81C20.76 5.42 19.45 3.81 16.75 3.56Z"/>
                    <path
                        opacity="0.4"
                        d="M20 9.84009C20.55 9.84009 21 10.2901 21 10.8401V17.0001C21 20.0001 19.5 22.0001 16 22.0001H8C4.5 22.0001 3 20.0001 3 17.0001V10.8401C3 10.2901 3.45 9.84009 4 9.84009H20Z"
                    />
                    <path
                        d="M8.5 14.9999C8.37 14.9999 8.24 14.9699 8.12 14.9199C8 14.8699 7.89001 14.7999 7.79001 14.7099C7.70001 14.6099 7.62999 14.4999 7.57999 14.3799C7.52999 14.2599 7.5 14.1299 7.5 13.9999C7.5 13.8699 7.52999 13.7399 7.57999 13.6199C7.62999 13.4999 7.70001 13.3899 7.79001 13.2899C7.89001 13.1999 8 13.1299 8.12 13.0799C8.36 12.9799 8.64 12.9799 8.88 13.0799C9 13.1299 9.10999 13.1999 9.20999 13.2899C9.29999 13.3899 9.37001 13.4999 9.42001 13.6199C9.47001 13.7399 9.5 13.8699 9.5 13.9999C9.5 14.1299 9.47001 14.2599 9.42001 14.3799C9.37001 14.4999 9.29999 14.6099 9.20999 14.7099C9.10999 14.7999 9 14.8699 8.88 14.9199C8.76 14.9699 8.63 14.9999 8.5 14.9999Z"/>
                    <path
                        d="M12 15.0001C11.87 15.0001 11.74 14.9701 11.62 14.9201C11.5 14.8701 11.39 14.8001 11.29 14.7101C11.11 14.5201 11 14.2601 11 14.0001C11 13.7401 11.11 13.4801 11.29 13.2901C11.39 13.2001 11.5 13.1301 11.62 13.0801C11.86 12.9701 12.14 12.9701 12.38 13.0801C12.5 13.1301 12.61 13.2001 12.71 13.2901C12.89 13.4801 13 13.7401 13 14.0001C13 14.2601 12.89 14.5201 12.71 14.7101C12.61 14.8001 12.5 14.8701 12.38 14.9201C12.26 14.9701 12.13 15.0001 12 15.0001Z"/>
                    <path
                        d="M8.5 18.4999C8.37 18.4999 8.24 18.4699 8.12 18.4199C8 18.3699 7.89001 18.2999 7.79001 18.2099C7.61001 18.0199 7.5 17.7599 7.5 17.4999C7.5 17.2399 7.61001 16.9799 7.79001 16.7899C7.89001 16.6999 8 16.6299 8.12 16.5799C8.36 16.4799 8.64 16.4799 8.88 16.5799C9 16.6299 9.10999 16.6999 9.20999 16.7899C9.38999 16.9799 9.5 17.2399 9.5 17.4999C9.5 17.7599 9.38999 18.0199 9.20999 18.2099C9.10999 18.2999 9 18.3699 8.88 18.4199C8.76 18.4699 8.63 18.4999 8.5 18.4999Z"/>
                </svg>
            );
        case "chart":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill={fill}
                    stroke={stroke}
                    xmlns="http://www.w3.org/2000/svg"
                    {...rest}
                >
                    <path
                        opacity="0.4"
                        d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2Z"
                    />
                    <path
                        d="M16.42 7.8099V16.1899C16.42 16.8299 15.9 17.3499 15.26 17.3499C14.61 17.3499 14.09 16.8299 14.09 16.1899V7.8099C14.09 7.1699 14.61 6.6499 15.26 6.6499C15.9 6.6499 16.42 7.1699 16.42 7.8099Z"/>
                    <path
                        d="M9.90996 12.93V16.19C9.90996 16.83 9.38996 17.35 8.73996 17.35C8.09996 17.35 7.57996 16.83 7.57996 16.19V12.93C7.57996 12.29 8.09996 11.77 8.73996 11.77C9.38996 11.77 9.90996 12.29 9.90996 12.93Z"/>
                </svg>
            );

        case "chevron":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={fill}
                    stroke={stroke}
                    {...rest}
                >
                    <path
                        d="M15.0001 19.9201L8.48009 13.4001C7.71009 12.6301 7.71009 11.3701 8.48009 10.6001L15.0001 4.08008"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );

        case "club":
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M5.63012 13.0796L5.62012 17.7696C5.62012 19.0396 6.60012 20.3996 7.80012 20.7996L10.9901 21.8596C11.5401 22.0396 12.4501 22.0396 13.0101 21.8596L16.2001 20.7996C17.4001 20.3996 18.3801 19.0396 18.3801 17.7696V13.1296"
                        stroke="#8252E1"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M10.0495 2.53028L4.02953 6.46028C2.09953 7.72028 2.09953 10.5403 4.02953 11.8003L10.0495 15.7303C11.1295 16.4403 12.9095 16.4403 13.9895 15.7303L19.9795 11.8003C21.8995 10.5403 21.8995 7.73028 19.9795 6.47028L13.9895 2.54028C12.9095 1.82028 11.1295 1.82028 10.0495 2.53028Z"
                        stroke="#707489"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path d="M21.4004 15V9" stroke="#707489" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                </svg>
            );
        case "club-hover":
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M5.63012 13.0796L5.62012 17.7696C5.62012 19.0396 6.60012 20.3996 7.80012 20.7996L10.9901 21.8596C11.5401 22.0396 12.4501 22.0396 13.0101 21.8596L16.2001 20.7996C17.4001 20.3996 18.3801 19.0396 18.3801 17.7696V13.1296"
                        stroke="#FFF"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M10.0495 2.53028L4.02953 6.46028C2.09953 7.72028 2.09953 10.5403 4.02953 11.8003L10.0495 15.7303C11.1295 16.4403 12.9095 16.4403 13.9895 15.7303L19.9795 11.8003C21.8995 10.5403 21.8995 7.73028 19.9795 6.47028L13.9895 2.54028C12.9095 1.82028 11.1295 1.82028 10.0495 2.53028Z"
                        stroke="#FFF"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M21.4004 15V9"
                        stroke="#FFF"
                        fillOpacity={0.6}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case "danger":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={fill}
                    stroke={stroke}
                    {...rest}
                >
                    <path
                        opacity="0.4"
                        d="M21.76 15.92L15.36 4.4C14.5 2.85 13.31 2 12 2C10.69 2 9.49998 2.85 8.63998 4.4L2.23998 15.92C1.42998 17.39 1.33998 18.8 1.98998 19.91C2.63998 21.02 3.91998 21.63 5.59998 21.63H18.4C20.08 21.63 21.36 21.02 22.01 19.91C22.66 18.8 22.57 17.38 21.76 15.92Z"
                    />
                    <path
                        d="M12 14.75C11.59 14.75 11.25 14.41 11.25 14V9C11.25 8.59 11.59 8.25 12 8.25C12.41 8.25 12.75 8.59 12.75 9V14C12.75 14.41 12.41 14.75 12 14.75Z"/>
                    <path
                        d="M12 18.0001C11.94 18.0001 11.87 17.9901 11.8 17.9801C11.74 17.9701 11.68 17.9501 11.62 17.9201C11.56 17.9001 11.5 17.8701 11.44 17.8301C11.39 17.7901 11.34 17.7501 11.29 17.7101C11.11 17.5201 11 17.2601 11 17.0001C11 16.7401 11.11 16.4801 11.29 16.2901C11.34 16.2501 11.39 16.2101 11.44 16.1701C11.5 16.1301 11.56 16.1001 11.62 16.0801C11.68 16.0501 11.74 16.0301 11.8 16.0201C11.93 15.9901 12.07 15.9901 12.19 16.0201C12.26 16.0301 12.32 16.0501 12.38 16.0801C12.44 16.1001 12.5 16.1301 12.56 16.1701C12.61 16.2101 12.66 16.2501 12.71 16.2901C12.89 16.4801 13 16.7401 13 17.0001C13 17.2601 12.89 17.5201 12.71 17.7101C12.66 17.7501 12.61 17.7901 12.56 17.8301C12.5 17.8701 12.44 17.9001 12.38 17.9201C12.32 17.9501 12.26 17.9701 12.19 17.9801C12.13 17.9901 12.06 18.0001 12 18.0001Z"/>
                </svg>
            );

        case "document":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={fill}
                    stroke={stroke}
                    {...rest}
                >
                    <path
                        d="M21 7V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V7C3 4 4.5 2 8 2H16C19.5 2 21 4 21 7Z"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M14.5 4.5V6.5C14.5 7.6 15.4 8.5 16.5 8.5H18.5"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path d="M8 13H12" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M8 17H16" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                          strokeLinejoin="round"/>
                </svg>
            );

        case "document-copy":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={fill}
                    stroke={stroke}
                    {...rest}
                >
                    <path
                        d="M17 13.4V16.4C17 20.4 15.4 22 11.4 22H7.6C3.6 22 2 20.4 2 16.4V12.6C2 8.6 3.6 7 7.6 7H10.6"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M17.0001 13.4H13.8001C11.4001 13.4 10.6001 12.6 10.6001 10.2V7L17.0001 13.4Z"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path d="M11.6001 2H15.6001" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M7 5C7 3.34 8.34 2 10 2H12.62" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path
                        d="M21.9999 8V14.19C21.9999 15.74 20.7399 17 19.1899 17"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M22 8H19C16.75 8 16 7.25 16 5V2L22 8Z"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case "document-download":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={fill}
                    stroke={stroke}
                    {...rest}
                >
                    <path d="M12 2V8L14 6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12 8L10 6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path
                        d="M7 12C3 12 3 13.79 3 16V17C3 19.76 3 22 8 22H16C20 22 21 19.76 21 17V16C21 13.79 21 12 17 12C16 12 15.72 12.21 15.2 12.6L14.18 13.68C13 14.94 11 14.94 9.81 13.68L8.8 12.6C8.28 12.21 8 12 7 12Z"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M5 12V8.00004C5 5.99004 5 4.33004 8 4.04004"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M19 12V8.00004C19 5.99004 19 4.33004 16 4.04004"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );

        case "document-upload":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={fill}
                    stroke={stroke}
                    {...rest}
                >
                    <path d="M12 8V2L10 4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12 2L14 4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path
                        d="M7 12C3 12 3 13.79 3 16V17C3 19.76 3 22 8 22H16C20 22 21 19.76 21 17V16C21 13.79 21 12 17 12C16 12 15.72 12.21 15.2 12.6L14.18 13.68C13 14.94 11 14.94 9.81 13.68L8.8 12.6C8.28 12.21 8 12 7 12Z"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M5 12V10C5 7.99004 5 6.33004 8 6.04004"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M19 12V10C19 7.99004 19 6.33004 16 6.04004"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );

        case "edit":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={fill}
                    stroke={stroke}
                    {...rest}
                >
                    <path
                        d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M16.0399 3.01976L8.15988 10.8998C7.85988 11.1998 7.55988 11.7898 7.49988 12.2198L7.06988 15.2298C6.90988 16.3198 7.67988 17.0798 8.76988 16.9298L11.7799 16.4998C12.1999 16.4398 12.7899 16.1398 13.0999 15.8398L20.9799 7.95976C22.3399 6.59976 22.9799 5.01976 20.9799 3.01976C18.9799 1.01976 17.3999 1.65976 16.0399 3.01976Z"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M14.9102 4.1499C15.5802 6.5399 17.4502 8.4099 19.8502 9.0899"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case "edit2":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={fill}
                    stroke={stroke}
                    {...rest}
                >
                    <path
                        d="M13.2599 3.60022L5.04985 12.2902C4.73985 12.6202 4.43985 13.2702 4.37985 13.7202L4.00985 16.9602C3.87985 18.1302 4.71985 18.9302 5.87985 18.7302L9.09985 18.1802C9.54985 18.1002 10.1799 17.7702 10.4899 17.4302L18.6999 8.74022C20.1199 7.24022 20.7599 5.53022 18.5499 3.44022C16.3499 1.37022 14.6799 2.10022 13.2599 3.60022Z"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M11.8901 5.0498C12.3201 7.8098 14.5601 9.9198 17.3401 10.1998"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path d="M3 22H21" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                          strokeLinejoin="round"/>
                </svg>
            );

        case "eye":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={fill}
                    stroke={stroke}
                    {...rest}
                >
                    <path
                        d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M12.0001 20.27C15.5301 20.27 18.8201 18.19 21.1101 14.59C22.0101 13.18 22.0101 10.81 21.1101 9.39997C18.8201 5.79997 15.5301 3.71997 12.0001 3.71997C8.47009 3.71997 5.18009 5.79997 2.89009 9.39997C1.99009 10.81 1.99009 13.18 2.89009 14.59C5.18009 18.19 8.47009 20.27 12.0001 20.27Z"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );

        case "flag":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={fill}
                    stroke={stroke}
                    {...rest}
                >
                    <path d="M5.1499 2V22" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path
                        d="M5.1499 4H16.3499C19.0499 4 19.6499 5.5 17.7499 7.4L16.5499 8.6C15.7499 9.4 15.7499 10.7 16.5499 11.4L17.7499 12.6C19.6499 14.5 18.9499 16 16.3499 16H5.1499"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );

        case "home":
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12 18.0005V15.0005"
                        stroke="#707489"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M10.0703 2.82034L3.14027 8.37034C2.36027 8.99034 1.86027 10.3003 2.03027 11.2803L3.36027 19.2403C3.60027 20.6603 4.96027 21.8103 6.40027 21.8103H17.6003C19.0303 21.8103 20.4003 20.6503 20.6403 19.2403L21.9703 11.2803C22.1303 10.3003 21.6303 8.99034 20.8603 8.37034L13.9303 2.83034C12.8603 1.97034 11.1303 1.97034 10.0703 2.82034Z"
                        stroke="#8252E1"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case "home-hover":
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 18.0005V15.0005" stroke="#FFF" strokeWidth="1.5" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path
                        d="M10.0703 2.82034L3.14027 8.37034C2.36027 8.99034 1.86027 10.3003 2.03027 11.2803L3.36027 19.2403C3.60027 20.6603 4.96027 21.8103 6.40027 21.8103H17.6003C19.0303 21.8103 20.4003 20.6503 20.6403 19.2403L21.9703 11.2803C22.1303 10.3003 21.6303 8.99034 20.8603 8.37034L13.9303 2.83034C12.8603 1.97034 11.1303 1.97034 10.0703 2.82034Z"
                        stroke="#FFF"
                        fillOpacity={0.6}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );

        case "lamp":
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.5">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M16.1833 1.73876C16.4066 1.8173 16.5239 2.06198 16.4454 2.28526L15.8137 4.08093C15.7352 4.30421 15.4905 4.42154 15.2672 4.343C15.044 4.26445 14.9266 4.01978 15.0052 3.79649L15.6368 2.00083C15.7154 1.77755 15.9601 1.66021 16.1833 1.73876ZM19.3129 8.92743C19.2309 8.70537 19.3445 8.45894 19.5666 8.377L21.6769 7.59838C21.899 7.51645 22.1454 7.63004 22.2273 7.8521C22.3093 8.07416 22.1957 8.3206 21.9736 8.40253L19.8633 9.18115C19.6412 9.26309 19.3948 9.14949 19.3129 8.92743ZM18.7752 15.3415C18.8953 15.1375 19.158 15.0696 19.362 15.1897L21.1583 16.2474C21.3623 16.3675 21.4302 16.6302 21.3101 16.8341C21.19 17.0381 20.9273 17.1061 20.7234 16.986L18.9271 15.9283C18.7231 15.8082 18.6551 15.5455 18.7752 15.3415Z"
                            fill="white"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.59882 16.6208C2.47085 16.4217 2.52853 16.1565 2.72765 16.0286L4.32893 14.9995C4.52805 14.8716 4.7932 14.9292 4.92117 15.1284C5.04913 15.3275 4.99145 15.5926 4.79233 15.7206L3.19106 16.7497C2.99194 16.8776 2.72678 16.8199 2.59882 16.6208ZM4.2664 9.31496C4.19469 9.54053 3.9537 9.66526 3.72813 9.59355L1.58449 8.91208C1.35892 8.84037 1.23419 8.59937 1.3059 8.37381C1.37761 8.14824 1.6186 8.02351 1.84417 8.09522L3.98781 8.77669C4.21338 8.8484 4.33811 9.08939 4.2664 9.31496ZM8.53219 4.32854C8.31681 4.4267 8.06264 4.33166 7.96448 4.11628L7.18862 2.41379C7.09046 2.1984 7.1855 1.94423 7.40088 1.84608C7.61626 1.74792 7.87043 1.84296 7.96859 2.05834L8.74445 3.76083C8.8426 3.97621 8.74757 4.23039 8.53219 4.32854Z"
                            fill="white"
                        />
                    </g>
                    <path
                        d="M16.8882 8.57451C16.1363 7.05613 14.683 5.93543 12.9983 5.56668C11.2341 5.17624 9.42648 5.5956 8.04548 6.70908C6.65725 7.81532 5.86914 9.47108 5.86914 11.2425C5.86914 12.9312 6.78043 14.6904 8.20008 15.8521C8.49035 16.0897 8.68175 16.4348 8.68175 16.8099C8.67452 17.0124 8.66729 17.3233 8.91313 17.5763C9.16619 17.8366 9.54217 17.8655 9.83861 17.8655H13.5478C13.9382 17.8655 14.2347 17.7571 14.4371 17.5546C14.7119 17.2726 14.7047 16.9111 14.6974 16.7159C14.6974 16.3995 14.858 16.1077 15.1047 15.9095C17.1028 14.3038 18.2581 11.3278 16.8882 8.57451Z"
                        fill="white"
                    />
                    <path
                        opacity="0.5"
                        d="M9.3325 18.5457C9.37588 18.5457 9.42649 18.5529 9.46987 18.5674C10.9232 18.9795 12.4488 18.9795 13.9021 18.5674C14.1696 18.4951 14.4516 18.6469 14.5239 18.9144C14.6034 19.182 14.4444 19.4639 14.1768 19.5362C12.5428 19.999 10.822 19.999 9.18789 19.5362C8.92037 19.4567 8.7613 19.182 8.84083 18.9144C8.91314 18.6903 9.11559 18.5457 9.3325 18.5457Z"
                        fill="white"
                    />
                    <path
                        opacity="0.5"
                        d="M9.3325 20.1241C9.37588 20.1241 9.42649 20.1313 9.46987 20.1457C10.9232 20.5579 12.4488 20.5579 13.9021 20.1457C14.1696 20.0734 14.4516 20.2253 14.5239 20.4928C14.6034 20.7603 14.4444 21.0423 14.1768 21.1146C12.5428 21.5774 10.822 21.5774 9.18789 21.1146C8.92037 21.0351 8.7613 20.7603 8.84083 20.4928C8.91314 20.2687 9.11559 20.1241 9.3325 20.1241Z"
                        fill="white"
                    />
                </svg>
            );

        case "lock":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={fill}
                    stroke={stroke}
                    {...rest}
                >
                    <path
                        d="M6 10V8C6 4.69 7 2 12 2C17 2 18 4.69 18 8V10"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M12 18.5C13.3807 18.5 14.5 17.3807 14.5 16C14.5 14.6193 13.3807 13.5 12 13.5C10.6193 13.5 9.5 14.6193 9.5 16C9.5 17.3807 10.6193 18.5 12 18.5Z"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M17 22H7C3 22 2 21 2 17V15C2 11 3 10 7 10H17C21 10 22 11 22 15V17C22 21 21 22 17 22Z"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case "lock_lucide":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill={fill}
                     stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
                     className="lucide lucide-lock-keyhole">
                    <circle cx="12" cy="16" r="1"/>
                    <rect x="3" y="10" width="18" height="12" rx="2"/>
                    <path d="M7 10V7a5 5 0 0 1 10 0v3"/>
                </svg>
            )
        case 'unlock_lucide':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill={fill}
                     stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
                     {...rest}
                     className="lucide lucide-lock-keyhole-open">
                <circle cx="12" cy="16" r="1"/>
                    <rect width="18" height="12" x="3" y="10" rx="2"/>
                    <path d="M7 10V7a5 5 0 0 1 9.33-2.5"/>
                </svg>
            );
        case "logout":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={fill}
                    stroke={stroke}
                    {...rest}
                >
                    <path
                        d="M8.8999 7.56023C9.2099 3.96023 11.0599 2.49023 15.1099 2.49023H15.2399C19.7099 2.49023 21.4999 4.28023 21.4999 8.75023V15.2702C21.4999 19.7402 19.7099 21.5302 15.2399 21.5302H15.1099C11.0899 21.5302 9.2399 20.0802 8.9099 16.5402"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path d="M15.0001 12H3.62012" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path
                        d="M5.85 8.6499L2.5 11.9999L5.85 15.3499"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );

        case "message":
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M16.9 19.0103C16.59 19.0103 16.28 18.9202 16.01 18.7402L15.05 18.1102C14.78 17.9302 14.65 17.5903 14.74 17.2803C14.81 17.0503 14.84 16.7802 14.84 16.4802V12.4102C14.84 10.7802 13.82 9.76025 12.19 9.76025H5.39999C5.27999 9.76025 5.17 9.77027 5.06 9.78027C4.85 9.79027 4.65001 9.72026 4.49001 9.58026C4.33001 9.44026 4.25 9.24027 4.25 9.03027V6.26025C4.25 3.32025 6.31 1.26025 9.25 1.26025H17.75C20.69 1.26025 22.75 3.32025 22.75 6.26025V11.3602C22.75 12.8102 22.26 14.0902 21.36 14.9702C20.64 15.7002 19.64 16.1702 18.5 16.3102V17.4202C18.5 18.0202 18.17 18.5603 17.65 18.8403C17.41 18.9503 17.15 19.0103 16.9 19.0103ZM16.3 17.1303L16.95 17.5002C17.01 17.4702 17.01 17.4202 17.01 17.4102V15.6002C17.01 15.1902 17.35 14.8502 17.76 14.8502C18.81 14.8502 19.7 14.5203 20.31 13.9003C20.94 13.2803 21.26 12.4002 21.26 11.3502V6.25024C21.26 4.12024 19.89 2.75024 17.76 2.75024H9.25999C7.12999 2.75024 5.75999 4.12024 5.75999 6.25024V8.25025H12.2C14.64 8.25025 16.35 9.96027 16.35 12.4003V16.4702C16.34 16.7002 16.33 16.9203 16.3 17.1303Z"
                        fill="#707489"
                    />
                    <path
                        d="M6.07001 22.75C5.85001 22.75 5.62 22.7 5.41 22.59C4.94 22.34 4.64999 21.86 4.64999 21.32V20.56C3.76999 20.42 2.99 20.05 2.41 19.47C1.65 18.71 1.25 17.67 1.25 16.47V12.4C1.25 10.14 2.72999 8.48002 4.92999 8.27002C5.08999 8.26002 5.23999 8.25 5.39999 8.25H12.19C14.63 8.25 16.34 9.96002 16.34 12.4V16.47C16.34 16.91 16.29 17.32 16.18 17.69C15.73 19.49 14.2 20.62 12.19 20.62H9.7L6.87 22.5C6.63 22.67 6.35001 22.75 6.07001 22.75ZM5.39999 9.75C5.27999 9.75 5.17 9.76002 5.06 9.77002C3.62 9.90002 2.75 10.89 2.75 12.4V16.47C2.75 17.27 3 17.94 3.47 18.41C3.93 18.87 4.59999 19.12 5.39999 19.12C5.80999 19.12 6.14999 19.46 6.14999 19.87V21.18L9.05 19.25C9.17 19.17 9.32 19.12 9.47 19.12H12.19C13.51 19.12 14.44 18.46 14.73 17.3C14.8 17.05 14.84 16.77 14.84 16.47V12.4C14.84 10.77 13.82 9.75 12.19 9.75H5.39999Z"
                        fill="#8252E1"
                    />
                </svg>
            );
        case "message-hover":
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M16.9 19.0103C16.59 19.0103 16.28 18.9202 16.01 18.7402L15.05 18.1102C14.78 17.9302 14.65 17.5903 14.74 17.2803C14.81 17.0503 14.84 16.7802 14.84 16.4802V12.4102C14.84 10.7802 13.82 9.76025 12.19 9.76025H5.39999C5.27999 9.76025 5.17 9.77027 5.06 9.78027C4.85 9.79027 4.65001 9.72026 4.49001 9.58026C4.33001 9.44026 4.25 9.24027 4.25 9.03027V6.26025C4.25 3.32025 6.31 1.26025 9.25 1.26025H17.75C20.69 1.26025 22.75 3.32025 22.75 6.26025V11.3602C22.75 12.8102 22.26 14.0902 21.36 14.9702C20.64 15.7002 19.64 16.1702 18.5 16.3102V17.4202C18.5 18.0202 18.17 18.5603 17.65 18.8403C17.41 18.9503 17.15 19.0103 16.9 19.0103ZM16.3 17.1303L16.95 17.5002C17.01 17.4702 17.01 17.4202 17.01 17.4102V15.6002C17.01 15.1902 17.35 14.8502 17.76 14.8502C18.81 14.8502 19.7 14.5203 20.31 13.9003C20.94 13.2803 21.26 12.4002 21.26 11.3502V6.25024C21.26 4.12024 19.89 2.75024 17.76 2.75024H9.25999C7.12999 2.75024 5.75999 4.12024 5.75999 6.25024V8.25025H12.2C14.64 8.25025 16.35 9.96027 16.35 12.4003V16.4702C16.34 16.7002 16.33 16.9203 16.3 17.1303Z"
                        fill="#FFFFFF"
                    />
                    <path
                        d="M6.07001 22.75C5.85001 22.75 5.62 22.7 5.41 22.59C4.94 22.34 4.64999 21.86 4.64999 21.32V20.56C3.76999 20.42 2.99 20.05 2.41 19.47C1.65 18.71 1.25 17.67 1.25 16.47V12.4C1.25 10.14 2.72999 8.48002 4.92999 8.27002C5.08999 8.26002 5.23999 8.25 5.39999 8.25H12.19C14.63 8.25 16.34 9.96002 16.34 12.4V16.47C16.34 16.91 16.29 17.32 16.18 17.69C15.73 19.49 14.2 20.62 12.19 20.62H9.7L6.87 22.5C6.63 22.67 6.35001 22.75 6.07001 22.75ZM5.39999 9.75C5.27999 9.75 5.17 9.76002 5.06 9.77002C3.62 9.90002 2.75 10.89 2.75 12.4V16.47C2.75 17.27 3 17.94 3.47 18.41C3.93 18.87 4.59999 19.12 5.39999 19.12C5.80999 19.12 6.14999 19.46 6.14999 19.87V21.18L9.05 19.25C9.17 19.17 9.32 19.12 9.47 19.12H12.19C13.51 19.12 14.44 18.46 14.73 17.3C14.8 17.05 14.84 16.77 14.84 16.47V12.4C14.84 10.77 13.82 9.75 12.19 9.75H5.39999Z"
                        fill="#FFFFFF"
                        fillOpacity={0.6}
                    />
                </svg>
            );

        case "mission":
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg" {...rest}>
                    <path
                        d="M16.0002 22.7503H8.00023C3.38023 22.7503 2.52023 20.6003 2.30023 18.5103L1.55023 10.5003C1.44023 9.45025 1.41023 7.90025 2.45023 6.74025C3.35023 5.74025 4.84023 5.26025 7.00023 5.26025H17.0002C19.1702 5.26025 20.6602 5.75025 21.5502 6.74025C22.5902 7.90025 22.5602 9.45025 22.4502 10.5103L21.7002 18.5003C21.4802 20.6003 20.6202 22.7503 16.0002 22.7503ZM7.00023 6.75025C5.31023 6.75025 4.15023 7.08025 3.56023 7.74025C3.07023 8.28025 2.91023 9.11025 3.04023 10.3503L3.79023 18.3603C3.96023 19.9403 4.39023 21.2503 8.00023 21.2503H16.0002C19.6002 21.2503 20.0402 19.9403 20.2102 18.3503L20.9602 10.3603C21.0902 9.11025 20.9302 8.28025 20.4402 7.74025C19.8502 7.08025 18.6902 6.75025 17.0002 6.75025H7.00023Z"
                        fill="#707489"
                    />
                    <path
                        d="M16 6.75C15.59 6.75 15.25 6.41 15.25 6V5.2C15.25 3.42 15.25 2.75 12.8 2.75H11.2C8.75 2.75 8.75 3.42 8.75 5.2V6C8.75 6.41 8.41 6.75 8 6.75C7.59 6.75 7.25 6.41 7.25 6V5.2C7.25 3.44 7.25 1.25 11.2 1.25H12.8C16.75 1.25 16.75 3.44 16.75 5.2V6C16.75 6.41 16.41 6.75 16 6.75Z"
                        fill="#707489"
                    />
                    <path
                        d="M12 16.75C9.25 16.75 9.25 15.05 9.25 14.03V13C9.25 11.59 9.59 11.25 11 11.25H13C14.41 11.25 14.75 11.59 14.75 13V14C14.75 15.04 14.75 16.75 12 16.75ZM10.75 12.75C10.75 12.83 10.75 12.92 10.75 13V14.03C10.75 15.06 10.75 15.25 12 15.25C13.25 15.25 13.25 15.09 13.25 14.02V13C13.25 12.92 13.25 12.83 13.25 12.75C13.17 12.75 13.08 12.75 13 12.75H11C10.92 12.75 10.83 12.75 10.75 12.75Z"
                        fill="#8252E1"
                    />
                    <path
                        d="M13.9995 14.7698C13.6295 14.7698 13.2995 14.4898 13.2595 14.1098C13.2095 13.6998 13.4995 13.3198 13.9095 13.2698C16.5495 12.9398 19.0795 11.9398 21.2095 10.3898C21.5395 10.1398 22.0095 10.2198 22.2595 10.5598C22.4995 10.8898 22.4295 11.3598 22.0895 11.6098C19.7495 13.3098 16.9895 14.3998 14.0895 14.7698C14.0595 14.7698 14.0295 14.7698 13.9995 14.7698Z"
                        fill="#8252E1"
                    />
                    <path
                        d="M9.99975 14.7804C9.96975 14.7804 9.93974 14.7804 9.90975 14.7804C7.16975 14.4704 4.49975 13.4704 2.18975 11.8904C1.84975 11.6604 1.75975 11.1904 1.98975 10.8504C2.21975 10.5104 2.68975 10.4204 3.02975 10.6504C5.13975 12.0904 7.56975 13.0004 10.0697 13.2904C10.4797 13.3404 10.7797 13.7104 10.7297 14.1204C10.6997 14.5004 10.3797 14.7804 9.99975 14.7804Z"
                        fill="#8252E1"
                    />
                </svg>
            );
        case "mission-hover":
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M16.0002 22.7503H8.00023C3.38023 22.7503 2.52023 20.6003 2.30023 18.5103L1.55023 10.5003C1.44023 9.45025 1.41023 7.90025 2.45023 6.74025C3.35023 5.74025 4.84023 5.26025 7.00023 5.26025H17.0002C19.1702 5.26025 20.6602 5.75025 21.5502 6.74025C22.5902 7.90025 22.5602 9.45025 22.4502 10.5103L21.7002 18.5003C21.4802 20.6003 20.6202 22.7503 16.0002 22.7503ZM7.00023 6.75025C5.31023 6.75025 4.15023 7.08025 3.56023 7.74025C3.07023 8.28025 2.91023 9.11025 3.04023 10.3503L3.79023 18.3603C3.96023 19.9403 4.39023 21.2503 8.00023 21.2503H16.0002C19.6002 21.2503 20.0402 19.9403 20.2102 18.3503L20.9602 10.3603C21.0902 9.11025 20.9302 8.28025 20.4402 7.74025C19.8502 7.08025 18.6902 6.75025 17.0002 6.75025H7.00023Z"
                        fill="#FFF"
                    />
                    <path
                        d="M16 6.75C15.59 6.75 15.25 6.41 15.25 6V5.2C15.25 3.42 15.25 2.75 12.8 2.75H11.2C8.75 2.75 8.75 3.42 8.75 5.2V6C8.75 6.41 8.41 6.75 8 6.75C7.59 6.75 7.25 6.41 7.25 6V5.2C7.25 3.44 7.25 1.25 11.2 1.25H12.8C16.75 1.25 16.75 3.44 16.75 5.2V6C16.75 6.41 16.41 6.75 16 6.75Z"
                        fill="#FFF"
                    />
                    <path
                        d="M12 16.75C9.25 16.75 9.25 15.05 9.25 14.03V13C9.25 11.59 9.59 11.25 11 11.25H13C14.41 11.25 14.75 11.59 14.75 13V14C14.75 15.04 14.75 16.75 12 16.75ZM10.75 12.75C10.75 12.83 10.75 12.92 10.75 13V14.03C10.75 15.06 10.75 15.25 12 15.25C13.25 15.25 13.25 15.09 13.25 14.02V13C13.25 12.92 13.25 12.83 13.25 12.75C13.17 12.75 13.08 12.75 13 12.75H11C10.92 12.75 10.83 12.75 10.75 12.75Z"
                        fill="#FFF"
                        fillOpacity={0.6}
                    />
                    <path
                        d="M13.9995 14.7698C13.6295 14.7698 13.2995 14.4898 13.2595 14.1098C13.2095 13.6998 13.4995 13.3198 13.9095 13.2698C16.5495 12.9398 19.0795 11.9398 21.2095 10.3898C21.5395 10.1398 22.0095 10.2198 22.2595 10.5598C22.4995 10.8898 22.4295 11.3598 22.0895 11.6098C19.7495 13.3098 16.9895 14.3998 14.0895 14.7698C14.0595 14.7698 14.0295 14.7698 13.9995 14.7698Z"
                        fill="#FFF"
                        fillOpacity={0.6}
                    />
                    <path
                        d="M9.99975 14.7804C9.96975 14.7804 9.93974 14.7804 9.90975 14.7804C7.16975 14.4704 4.49975 13.4704 2.18975 11.8904C1.84975 11.6604 1.75975 11.1904 1.98975 10.8504C2.21975 10.5104 2.68975 10.4204 3.02975 10.6504C5.13975 12.0904 7.56975 13.0004 10.0697 13.2904C10.4797 13.3404 10.7797 13.7104 10.7297 14.1204C10.6997 14.5004 10.3797 14.7804 9.99975 14.7804Z"
                        fill="#FFF"
                        fillOpacity={0.6}
                    />
                </svg>
            );

        case "notification":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={fill}
                    stroke={stroke}
                    {...rest}
                >
                    <path
                        d="M12.0201 2.90991C8.71009 2.90991 6.02009 5.59991 6.02009 8.90991V11.7999C6.02009 12.4099 5.76009 13.3399 5.45009 13.8599L4.30009 15.7699C3.59009 16.9499 4.08009 18.2599 5.38009 18.6999C9.69009 20.1399 14.3401 20.1399 18.6501 18.6999C19.8601 18.2999 20.3901 16.8699 19.7301 15.7699L18.5801 13.8599C18.2801 13.3399 18.0201 12.4099 18.0201 11.7999V8.90991C18.0201 5.60991 15.3201 2.90991 12.0201 2.90991Z"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                    />
                    <path
                        d="M13.8699 3.19994C13.5599 3.10994 13.2399 3.03994 12.9099 2.99994C11.9499 2.87994 11.0299 2.94994 10.1699 3.19994C10.4599 2.45994 11.1799 1.93994 12.0199 1.93994C12.8599 1.93994 13.5799 2.45994 13.8699 3.19994Z"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M15.02 19.0601C15.02 20.7101 13.67 22.0601 12.02 22.0601C11.2 22.0601 10.44 21.7201 9.90002 21.1801C9.36002 20.6401 9.02002 19.8801 9.02002 19.0601"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                    />
                </svg>
            );

        case "pause":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={fill}
                    stroke={stroke}
                    {...rest}
                >
                    <path
                        d="M10.65 19.11V4.89C10.65 3.54 10.08 3 8.64 3H5.01C3.57 3 3 3.54 3 4.89V19.11C3 20.46 3.57 21 5.01 21H8.64C10.08 21 10.65 20.46 10.65 19.11Z"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M21 19.11V4.89C21 3.54 20.43 3 18.99 3H15.36C13.93 3 13.35 3.54 13.35 4.89V19.11C13.35 20.46 13.92 21 15.36 21H18.99C20.43 21 21 20.46 21 19.11Z"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );

        case "people":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={fill}
                    stroke={stroke}
                    {...rest}
                >
                    <path
                        opacity="0.4"
                        d="M17.53 7.77C17.46 7.76 17.39 7.76 17.32 7.77C15.77 7.72 14.54 6.45 14.54 4.89C14.54 3.3 15.83 2 17.43 2C19.02 2 20.32 3.29 20.32 4.89C20.31 6.45 19.08 7.72 17.53 7.77Z"
                    />
                    <path
                        opacity="0.4"
                        d="M20.7901 14.6999C19.6701 15.4499 18.1001 15.7299 16.6501 15.5399C17.0301 14.7199 17.2301 13.8099 17.2401 12.8499C17.2401 11.8499 17.0201 10.8999 16.6001 10.0699C18.0801 9.86992 19.6501 10.1499 20.7801 10.8999C22.3601 11.9399 22.3601 13.6499 20.7901 14.6999Z"
                    />
                    <path
                        opacity="0.4"
                        d="M6.4399 7.77C6.5099 7.76 6.5799 7.76 6.6499 7.77C8.1999 7.72 9.4299 6.45 9.4299 4.89C9.4299 3.3 8.1399 2 6.5399 2C4.9499 2 3.6499 3.29 3.6499 4.89C3.6599 6.45 4.8899 7.72 6.4399 7.77Z"
                    />
                    <path
                        opacity="0.4"
                        d="M6.55012 12.8501C6.55012 13.8201 6.76012 14.7401 7.14012 15.5701C5.73012 15.7201 4.26012 15.4201 3.18012 14.7101C1.60012 13.6601 1.60012 11.9501 3.18012 10.9001C4.25012 10.1801 5.76012 9.8901 7.18012 10.0501C6.77012 10.8901 6.55012 11.8401 6.55012 12.8501Z"
                    />
                    <path
                        d="M12.12 15.87C12.04 15.86 11.9501 15.86 11.8601 15.87C10.0201 15.81 8.55005 14.3 8.55005 12.44C8.55005 10.54 10.0801 9 11.9901 9C13.8901 9 15.43 10.54 15.43 12.44C15.43 14.3 13.97 15.81 12.12 15.87Z"/>
                    <path
                        d="M8.87005 17.9399C7.36005 18.9499 7.36005 20.6099 8.87005 21.6099C10.59 22.7599 13.4101 22.7599 15.1301 21.6099C16.6401 20.5999 16.6401 18.9399 15.1301 17.9399C13.4201 16.7899 10.6 16.7899 8.87005 17.9399Z"/>
                </svg>
            );
        case "play":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={fill}
                    stroke={stroke}
                    {...rest}
                >
                    <path
                        d="M18.7 8.97989L4.14 17.7099C4.05 17.3799 4 17.0299 4 16.6699V7.32989C4 4.24989 7.33 2.32989 10 3.86989L14.04 6.19989L18.09 8.53989C18.31 8.66989 18.52 8.80989 18.7 8.97989Z"/>
                    <path
                        opacity="0.4"
                        d="M18.09 15.4598L14.04 17.7998L9.99997 20.1298C8.08997 21.2298 5.83997 20.5698 4.71997 18.9598L5.13997 18.7098L19.58 10.0498C20.58 11.8498 20.09 14.3098 18.09 15.4598Z"
                    />
                </svg>
            );

        case "play-circle":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={fill}
                    stroke={stroke}
                    {...rest}
                >
                    <path
                        d="M11.97 22C17.4928 22 21.97 17.5228 21.97 12C21.97 6.47715 17.4928 2 11.97 2C6.44715 2 1.97 6.47715 1.97 12C1.97 17.5228 6.44715 22 11.97 22Z"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M8.73999 12.2301V10.5601C8.73999 8.48012 10.21 7.63012 12.01 8.67012L13.46 9.51012L14.91 10.3501C16.71 11.3901 16.71 13.0901 14.91 14.1301L13.46 14.9701L12.01 15.8101C10.21 16.8501 8.73999 16.0001 8.73999 13.9201V12.2301Z"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );

        case "profile":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={fill}
                    stroke={stroke}
                    {...rest}
                >
                    <path
                        d="M12.1601 10.87C12.0601 10.86 11.9401 10.86 11.8301 10.87C9.45006 10.79 7.56006 8.84 7.56006 6.44C7.56006 3.99 9.54006 2 12.0001 2C14.4501 2 16.4401 3.99 16.4401 6.44C16.4301 8.84 14.5401 10.79 12.1601 10.87Z"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M7.15997 14.56C4.73997 16.18 4.73997 18.82 7.15997 20.43C9.90997 22.27 14.42 22.27 17.17 20.43C19.59 18.81 19.59 16.17 17.17 14.56C14.43 12.73 9.91997 12.73 7.15997 14.56Z"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case "profile-hover":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={'white'}
                    stroke={'white'}
                    {...rest}
                >
                    <path
                        d="M12.1601 10.87C12.0601 10.86 11.9401 10.86 11.8301 10.87C9.45006 10.79 7.56006 8.84 7.56006 6.44C7.56006 3.99 9.54006 2 12.0001 2C14.4501 2 16.4401 3.99 16.4401 6.44C16.4301 8.84 14.5401 10.79 12.1601 10.87Z"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M7.15997 14.56C4.73997 16.18 4.73997 18.82 7.15997 20.43C9.90997 22.27 14.42 22.27 17.17 20.43C19.59 18.81 19.59 16.17 17.17 14.56C14.43 12.73 9.91997 12.73 7.15997 14.56Z"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );

        case "help":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
                     className="lucide lucide-circle-help">
                    <circle cx="12" cy="12" r="10"/>
                    <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"/>
                    <path d="M12 17h.01"/>
                </svg>
            );
        case "ravi":
            return (
                <svg xmlns="http://www.w3.org/2000/svg"
                     width="24"
                     height="24"
                     viewBox="0 0 24 24"
                     stroke="#707489"
                     strokeWidth="1.5"
                     fill="none"
                     className="lucide lucide-tv-minimal-play"
                     {...rest}
                >
                    <path
                        d="M10 7.75a.75.75 0 0 1 1.142-.638l3.664 2.249a.75.75 0 0 1 0 1.278l-3.664 2.25a.75.75 0 0 1-1.142-.64z"
                        stroke="#8251e2"
                        strokeWidth="1.5"  // افزایش ضخامت فقط برای مثلث پلی
                    />
                    <path d="M7 21h10"/>
                    <rect width="20" height="14" x="2" y="3" rx="2"/>
                </svg>

            );
        case "ravi-hover":
            return (
                <svg xmlns="http://www.w3.org/2000/svg"
                     width="24"
                     height="24"
                     viewBox="0 0 24 24"
                     fill="none"
                     stroke="white"
                     strokeWidth="1.5"
                     {...rest}
                     className="lucide lucide-tv-minimal-play">
                    <path
                        d="M10 7.75a.75.75 0 0 1 1.142-.638l3.664 2.249a.75.75 0 0 1 0 1.278l-3.664 2.25a.75.75 0 0 1-1.142-.64z"
                        strokeWidth="1.5"/>
                    v
                    <path d="M7 21h10" fill={"red"}/>
                    <rect width="20" height="14" x="2" y="3" rx="2"/>
                </svg>
            )
        case "quote":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={fill}
                    stroke={stroke}
                    {...rest}
                >
                    <path
                        d="M8.09 11.63H3.4C3.48 6.95997 4.4 6.18997 7.27 4.48997C7.6 4.28997 7.71 3.86997 7.51 3.52997C7.32 3.19997 6.89 3.08997 6.56 3.28997C3.18 5.28997 2 6.50997 2 12.32V17.71C2 19.42 3.39 20.8 5.09 20.8H8.09C9.85 20.8 11.18 19.47 11.18 17.71V14.71C11.18 12.96 9.85 11.63 8.09 11.63Z"/>
                    <path
                        d="M18.91 11.63H14.22C14.3 6.95997 15.22 6.18997 18.09 4.48997C18.42 4.28997 18.53 3.86997 18.33 3.52997C18.13 3.19997 17.71 3.08997 17.37 3.28997C13.99 5.28997 12.81 6.50997 12.81 12.33V17.72C12.81 19.43 14.2 20.81 15.9 20.81H18.9C20.66 20.81 21.99 19.48 21.99 17.72V14.72C22 12.96 20.67 11.63 18.91 11.63Z"/>
                </svg>
            );
        case "refresh":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={fill}
                    stroke={stroke}
                    {...rest}
                >
                    <path
                        d="M14.55 22.42C14.22 22.42 13.91 22.2 13.82 21.86C13.72 21.46 13.95 21.05 14.35 20.94C18.41 19.87 21.24 16.19 21.24 11.99C21.24 6.89 17.09 2.74 11.99 2.74C7.66 2.74 4.82 5.27 3.49 6.8H6.43C6.84 6.8 7.18 7.14 7.18 7.55C7.18 7.96 6.86 8.31 6.44 8.31H2.01C1.94 8.31 1.87 8.3 1.8 8.28C1.71 8.25 1.63 8.21 1.56 8.16C1.47 8.1 1.4 8.02 1.35 7.93C1.3 7.83 1.26 7.73 1.25 7.62C1.25 7.59 1.25 7.57 1.25 7.54V3C1.25 2.59 1.59 2.25 2 2.25C2.41 2.25 2.75 2.59 2.75 3V5.39C4.38 3.64 7.45 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 16.88 19.46 21.16 14.74 22.4C14.68 22.41 14.61 22.42 14.55 22.42Z"/>
                    <path
                        opacity="0.4"
                        d="M11.29 22.73C11.27 22.73 11.24 22.73 11.22 22.72C10.15 22.65 9.1 22.41 8.1 22.02C7.81 21.91 7.62 21.62 7.62 21.31C7.62 21.22 7.64 21.13 7.67 21.05C7.82 20.67 8.26 20.47 8.64 20.62C9.5 20.96 10.41 21.16 11.33 21.23H11.34C11.74 21.25 12.04 21.59 12.04 21.99C12.04 22 12.04 22.02 12.04 22.03C12.01 22.42 11.68 22.73 11.29 22.73ZM5.78 20.58C5.61 20.58 5.45 20.53 5.31 20.42C4.47 19.75 3.74 18.96 3.13 18.07C3.04 17.94 2.99 17.8 2.99 17.65C2.99 17.4 3.11 17.17 3.32 17.03C3.65 16.8 4.14 16.89 4.36 17.22C4.89 17.99 5.52 18.67 6.25 19.24C6.42 19.38 6.53 19.59 6.53 19.82C6.53 19.99 6.48 20.16 6.37 20.3C6.23 20.48 6.01 20.58 5.78 20.58ZM2.44 15.7C2.11 15.7 1.82 15.49 1.73 15.18C1.41 14.15 1.25 13.08 1.25 12C1.25 11.59 1.59 11.25 2 11.25C2.41 11.25 2.75 11.59 2.75 12C2.75 12.93 2.89 13.85 3.16 14.73C3.18 14.8 3.19 14.88 3.19 14.96C3.19 15.29 2.98 15.57 2.67 15.67C2.59 15.69 2.52 15.7 2.44 15.7Z"
                    />
                    <path
                        opacity="0.4"
                        d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
                    />
                </svg>
            );

        case "search":
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill={fill} stroke={stroke} {...rest}>
                    <path
                        d="M11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20Z"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M18.9299 20.6898C19.4599 22.2898 20.6699 22.4498 21.5999 21.0498C22.4499 19.7698 21.8899 18.7198 20.3499 18.7198C19.2099 18.7098 18.5699 19.5998 18.9299 20.6898Z"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case "send":
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill={fill} stroke={stroke} {...rest}>
                    <path
                        d="M9.51002 4.23013L18.07 8.51013C21.91 10.4301 21.91 13.5701 18.07 15.4901L9.51002 19.7701C3.75002 22.6501 1.40002 20.2901 4.28002 14.5401L5.15002 12.8101C5.37002 12.3701 5.37002 11.6401 5.15002 11.2001L4.28002 9.46013C1.40002 3.71013 3.76002 1.35013 9.51002 4.23013Z"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path d="M5.43994 12H10.8399" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            );

        case "score":
            return (
                <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={rest.className}
                >
                    <path
                        opacity="0.5"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.99976 2.49994C7.25194 2.38742 7.54758 2.50065 7.66009 2.75283L8.52327 4.56991C8.63578 4.82209 8.52255 5.11774 8.27037 5.23025C8.01819 5.34276 7.72255 5.22953 7.61003 4.97735L6.74686 3.16027C6.63435 2.90809 6.74757 2.61245 6.99976 2.49994ZM18.2658 2.33273C18.5147 2.45238 18.6194 2.75113 18.4998 3L17.5173 4.99028C17.3976 5.23915 17.0989 5.34391 16.85 5.22426C16.6011 5.10461 16.4964 4.80586 16.616 4.55699L17.5985 2.56671C17.7182 2.31783 18.0169 2.21308 18.2658 2.33273ZM20.3814 12.0081C20.3814 11.7319 20.6052 11.5081 20.8814 11.5081H22.0667C22.3428 11.5081 22.5667 11.7319 22.5667 12.0081C22.5667 12.2842 22.3428 12.5081 22.0667 12.5081H20.8814C20.6052 12.5081 20.3814 12.2842 20.3814 12.0081ZM2.56665 12.1556C2.56665 11.8795 2.79051 11.6556 3.06665 11.6556H3.96493C4.24107 11.6556 4.46493 11.8795 4.46493 12.1556C4.46493 12.4318 4.24107 12.6556 3.96493 12.6556H3.06665C2.79051 12.6556 2.56665 12.4318 2.56665 12.1556ZM7.34664 19.3855C7.59336 19.5095 7.69282 19.8101 7.56878 20.0568L6.67106 21.7778C6.54703 22.0245 6.24647 22.124 5.99976 21.9999C5.75304 21.8759 5.65358 21.5753 5.77761 21.3286L6.67533 19.6076C6.79937 19.3609 7.09992 19.2614 7.34664 19.3855ZM17.6673 19.3926C17.9101 19.261 18.2136 19.3512 18.3451 19.594L19.379 21.3015C19.5106 21.5443 19.4204 21.8477 19.1776 21.9793C18.9348 22.1108 18.6313 22.0206 18.4998 21.7778L17.4658 20.0704C17.3343 19.8276 17.4245 19.5241 17.6673 19.3926Z"
                        fill="#F96478"
                    />
                    <path
                        d="M18.8837 11.4265L18.3637 10.2388L17.2197 7.62596C16.9143 6.93975 16.4333 6.37891 15.3283 6.37891H9.73857C8.63362 6.37891 8.15264 6.93975 7.84715 7.62596L6.7032 10.2388L6.18323 11.4265C5.88424 12.1193 6.04673 13.1354 6.54721 13.6963L10.9995 18.6713C11.8445 19.6148 13.2224 19.6148 14.0674 18.6713L18.5197 13.6963C19.0202 13.1354 19.1827 12.1193 18.8837 11.4265Z"
                        fill="url(#paint0_linear_3088_20726)"
                    />
                    <mask id="mask0_3088_20726" maskUnits="userSpaceOnUse" x="5" y="6" width="15" height="14">
                        <path
                            opacity="0.2"
                            d="M18.9807 11.4276L18.4528 10.2398L17.2914 7.62659C16.9812 6.9403 16.4929 6.37939 15.3711 6.37939H9.69601C8.57419 6.37939 8.08587 6.9403 7.77572 7.62659L6.61431 10.2398L6.08639 11.4276C5.78284 12.1205 5.94781 13.1367 6.45593 13.6976L10.9762 18.6732C11.8341 19.6169 13.233 19.6169 14.0909 18.6732L18.6112 13.6976C19.1193 13.1367 19.2843 12.1205 18.9807 11.4276Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask0_3088_20726)">
                        <path
                            opacity="0.5"
                            d="M20.0334 12.8794L16.2834 5.37939H8.78345L5.03345 12.8794L9.53345 12.1294H15.5334L20.0334 12.8794Z"
                            fill="white"
                        />
                        <path opacity="0.6" d="M15.5334 12.1294L12.5334 21.1294L9.53345 12.1294H12.5334H15.5334Z"
                              fill="white"/>
                        <path d="M15.5334 12.1294L14.0334 6.12939H11.0334L9.53345 12.1294H12.5334H15.5334Z"
                              fill="white"/>
                    </g>
                    <defs>
                        <linearGradient
                            id="paint0_linear_3088_20726"
                            x1="7.0243"
                            y1="19.9524"
                            x2="17.8246"
                            y2="5.80538"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#E22D79"/>
                            <stop offset="1" stopColor="#FB6779"/>
                        </linearGradient>
                    </defs>
                </svg>
            );

        case "score-white":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={rest.className}
                >
                    <path
                        opacity="0.5"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.19489 1.69903C6.44707 1.58651 6.74271 1.69974 6.85522 1.95192L8.02327 4.56998C8.13578 4.82216 8.02255 5.1178 7.77037 5.23031C7.51819 5.34282 7.22255 5.2296 7.11003 4.97742L5.94199 2.35936C5.82948 2.10718 5.9427 1.81154 6.19489 1.69903ZM18.042 1.70502C18.2908 1.82467 18.3956 2.12341 18.276 2.37229L17.0173 4.99034C16.8976 5.23922 16.5989 5.34397 16.35 5.22432C16.1011 5.10467 15.9964 4.80592 16.116 4.55705L17.3747 1.93899C17.4944 1.69012 17.7931 1.58536 18.042 1.70502ZM19.8814 12.0081C19.8814 11.732 20.1052 11.5081 20.3814 11.5081H22.5667C22.8428 11.5081 23.0667 11.732 23.0667 12.0081C23.0667 12.2843 22.8428 12.5081 22.5667 12.5081H20.3814C20.1052 12.5081 19.8814 12.2843 19.8814 12.0081ZM1.06665 12.1557C1.06665 11.8796 1.29051 11.6557 1.56665 11.6557H3.46493C3.74108 11.6557 3.96493 11.8796 3.96493 12.1557C3.96493 12.4318 3.74108 12.6557 3.46493 12.6557H1.56665C1.29051 12.6557 1.06665 12.4318 1.06665 12.1557ZM6.84664 19.3855C7.09336 19.5096 7.19282 19.8101 7.06878 20.0568L5.90074 22.3802C5.77671 22.6269 5.47615 22.7264 5.22944 22.6024C4.98272 22.4783 4.88326 22.1778 5.00729 21.9311L6.17533 19.6077C6.29937 19.361 6.59992 19.2615 6.84664 19.3855ZM17.1673 19.3926C17.4101 19.2611 17.7136 19.3513 17.8451 19.5941L19.1038 21.9175C19.2353 22.1603 19.1451 22.4637 18.9023 22.5953C18.6595 22.7268 18.3561 22.6366 18.2245 22.3938L16.9658 20.0704C16.8343 19.8276 16.9245 19.5242 17.1673 19.3926Z"
                        fill="white"
                    />
                    <path
                        opacity="0.6"
                        d="M18.3837 11.4267L17.8637 10.2391L16.7197 7.6262C16.4143 6.93999 15.9333 6.37915 14.8283 6.37915H9.23857C8.13362 6.37915 7.65264 6.93999 7.34715 7.6262L6.2032 10.2391L5.68323 11.4267C5.38424 12.1195 5.54673 13.1357 6.04721 13.6965L10.4995 18.6715C11.3445 19.615 12.7224 19.615 13.5674 18.6715L18.0197 13.6965C18.5202 13.1357 18.6827 12.1195 18.3837 11.4267Z"
                        fill="white"
                    />
                    <mask id="mask0_3409_24240" maskUnits="userSpaceOnUse" x="5" y="6" width="14" height="14">
                        <path
                            d="M18.4807 11.4276L17.9528 10.2398L16.7914 7.62659C16.4812 6.9403 15.9929 6.37939 14.8711 6.37939H9.19601C8.07419 6.37939 7.58587 6.9403 7.27572 7.62659L6.11431 10.2398L5.58639 11.4276C5.28284 12.1205 5.44781 13.1367 5.95593 13.6976L10.4762 18.6732C11.3341 19.6169 12.733 19.6169 13.5909 18.6732L18.1112 13.6976C18.6193 13.1367 18.7843 12.1205 18.4807 11.4276Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask0_3409_24240)">
                        <path
                            opacity="0.5"
                            d="M19.5334 12.8794L15.7834 5.37939H8.28345L4.53345 12.8794L9.03345 12.1294H15.0334L19.5334 12.8794Z"
                            fill="white"
                        />
                        <path opacity="0.6" d="M15.0334 12.1294L12.0334 21.1294L9.03345 12.1294H12.0334H15.0334Z"
                              fill="white"/>
                        <path d="M15.0334 12.1294L13.5334 6.12939H10.5334L9.03345 12.1294H12.0334H15.0334Z"
                              fill="white"/>
                    </g>
                </svg>
            );
        case "star":
            return (
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill={fill}
                    xmlns="http://www.w3.org/2000/svg"
                    className={rest.className}
                >
                    <path
                        d="M9.15327 2.34001L10.3266 4.68668C10.4866 5.01334 10.9133 5.32668 11.2733 5.38668L13.3999 5.74001C14.7599 5.96668 15.0799 6.95334 14.0999 7.92668L12.4466 9.58001C12.1666 9.86001 12.0133 10.4 12.0999 10.7867L12.5733 12.8333C12.9466 14.4533 12.0866 15.08 10.6533 14.2333L8.65994 13.0533C8.29994 12.84 7.70661 12.84 7.33994 13.0533L5.34661 14.2333C3.91994 15.08 3.05327 14.4467 3.42661 12.8333L3.89994 10.7867C3.98661 10.4 3.83327 9.86001 3.55327 9.58001L1.89994 7.92668C0.926606 6.95334 1.23994 5.96668 2.59994 5.74001L4.72661 5.38668C5.07994 5.32668 5.50661 5.01334 5.66661 4.68668L6.83994 2.34001C7.47994 1.06668 8.51994 1.06668 9.15327 2.34001Z"
                        fill="url(#paint0_linear_3409_20591)"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_3409_20591"
                            x1="8.00139"
                            y1="1.38501"
                            x2="8.00139"
                            y2="14.6105"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#F9EF00"/>
                            <stop offset="1" stopColor="#F9C200"/>
                        </linearGradient>
                    </defs>
                </svg>
            );
        case "tick-circle":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={fill}
                    stroke={stroke}
                    {...rest}
                >
                    <path
                        opacity="0.4"
                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    />
                    <path
                        d="M10.5799 15.5801C10.3799 15.5801 10.1899 15.5001 10.0499 15.3601L7.21994 12.5301C6.92994 12.2401 6.92994 11.7601 7.21994 11.4701C7.50994 11.1801 7.98994 11.1801 8.27994 11.4701L10.5799 13.7701L15.7199 8.6301C16.0099 8.3401 16.4899 8.3401 16.7799 8.6301C17.0699 8.9201 17.0699 9.4001 16.7799 9.6901L11.1099 15.3601C10.9699 15.5001 10.7799 15.5801 10.5799 15.5801Z"/>
                </svg>
            );

        case "two-user":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill={fill}
                    stroke={stroke}
                    {...rest}
                >
                    <path
                        d="M9.16006 10.87C9.06006 10.86 8.94006 10.86 8.83006 10.87C6.45006 10.79 4.56006 8.84 4.56006 6.44C4.56006 3.99 6.54006 2 9.00006 2C11.4501 2 13.4401 3.99 13.4401 6.44C13.4301 8.84 11.5401 10.79 9.16006 10.87Z"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M16.41 4C18.35 4 19.91 5.57 19.91 7.5C19.91 9.39 18.41 10.93 16.54 11C16.46 10.99 16.37 10.99 16.28 11"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M4.15997 14.56C1.73997 16.18 1.73997 18.82 4.15997 20.43C6.90997 22.27 11.42 22.27 14.17 20.43C16.59 18.81 16.59 16.17 14.17 14.56C11.43 12.73 6.91997 12.73 4.15997 14.56Z"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M18.3401 20C19.0601 19.85 19.7401 19.56 20.3001 19.13C21.8601 17.96 21.8601 16.03 20.3001 14.86C19.7501 14.44 19.0801 14.16 18.3701 14"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case "info-badge":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill={fill}
                     stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
                     className="lucide lucide-badge-info">
                    <path
                        d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z"/>
                    <line x1="12" x2="12" y1="16" y2="12"/>
                    <line x1="12" x2="12.01" y1="8" y2="8"/>
                </svg>
            );
        case "alert_clock":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill={fill}
                     stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
                     className="lucide lucide-clock-alert">
                    <path d="M12 6v6l4 2"/>
                    <path d="M16 21.16a10 10 0 1 1 5-13.516"/>
                    <path d="M20 11.5v6"/>
                    <path d="M20 21.5h.01"/>
                </svg>
            );
        case "xp":
            return (
                <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={rest.className}
                >
                <circle opacity="0.5" cx="3.40127" cy="11.5683" r="1.92178" fill="#8252E1"/>
                    <circle opacity="0.5" cx="17.2672" cy="20.0375" r="1.92178" fill="#8252E1"/>
                    <circle opacity="0.5" cx="7.01772" cy="20.0375" r="1.92178" fill="#8252E1"/>
                    <circle opacity="0.5" cx="17.2672" cy="3.88076" r="1.92178" fill="#8252E1"/>
                    <circle opacity="0.5" cx="7.01772" cy="3.88076" r="1.92178" fill="#8252E1"/>
                    <circle opacity="0.5" cx="21.3378" cy="11.5683" r="1.92178" fill="#8252E1"/>
                    <path
                        opacity="0.8"
                        d="M12.3697 6.22949C10.971 6.22949 9.83398 7.36654 9.83398 8.76517C9.83398 10.1371 10.907 11.2475 12.3056 11.2955C12.3483 11.2902 12.391 11.2902 12.423 11.2955C12.4337 11.2955 12.4391 11.2955 12.4497 11.2955C12.4551 11.2955 12.4551 11.2955 12.4604 11.2955C13.827 11.2475 14.9 10.1371 14.9053 8.76517C14.9053 7.36654 13.7683 6.22949 12.3697 6.22949Z"
                        fill="url(#paint0_linear_3088_20741)"
                    />
                    <path
                        d="M15.0813 12.7154C13.5919 11.7225 11.163 11.7225 9.66297 12.7154C8.98501 13.1691 8.61133 13.783 8.61133 14.4397C8.61133 15.0963 8.98501 15.7048 9.65763 16.1532C10.405 16.655 11.3872 16.9059 12.3695 16.9059C13.3517 16.9059 14.3339 16.655 15.0813 16.1532C15.7539 15.6995 16.1276 15.0909 16.1276 14.429C16.1223 13.7724 15.7539 13.1638 15.0813 12.7154Z"
                        fill="url(#paint1_linear_3088_20741)"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_3088_20741"
                            x1="9.834"
                            y1="11.2955"
                            x2="10.5086"
                            y2="5.69946"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_3088_20741"
                            x1="8.61135"
                            y1="16.9059"
                            x2="9.04687"
                            y2="11.4096"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                    </defs>
                </svg>
            );

        case "xp-white":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={rest.className}
                >
                    <circle opacity="0.4" cx="2.90127" cy="11.5687" r="1.92178" fill="white"/>
                    <circle opacity="0.4" cx="16.7672" cy="20.0377" r="1.92178" fill="white"/>
                    <circle opacity="0.4" cx="6.51772" cy="20.0377" r="1.92178" fill="white"/>
                    <circle opacity="0.4" cx="16.7672" cy="3.88125" r="1.92178" fill="white"/>
                    <circle opacity="0.4" cx="6.51772" cy="3.88125" r="1.92178" fill="white"/>
                    <circle opacity="0.4" cx="20.8378" cy="11.5687" r="1.92178" fill="white"/>
                    <path
                        opacity="0.7"
                        d="M11.8697 6.22974C10.471 6.22974 9.33398 7.36679 9.33398 8.76541C9.33398 10.1373 10.407 11.2477 11.8056 11.2957C11.8483 11.2904 11.891 11.2904 11.923 11.2957C11.9337 11.2957 11.9391 11.2957 11.9497 11.2957C11.9551 11.2957 11.9551 11.2957 11.9604 11.2957C13.327 11.2477 14.4 10.1373 14.4053 8.76541C14.4053 7.36679 13.2683 6.22974 11.8697 6.22974Z"
                        fill="white"
                    />
                    <path
                        d="M14.5813 12.7156C13.0919 11.7227 10.663 11.7227 9.16297 12.7156C8.48501 13.1694 8.11133 13.7833 8.11133 14.4399C8.11133 15.0965 8.48501 15.7051 9.15763 16.1535C9.90499 16.6553 10.8872 16.9062 11.8695 16.9062C12.8517 16.9062 13.8339 16.6553 14.5813 16.1535C15.2539 15.6997 15.6276 15.0912 15.6276 14.4292C15.6223 13.7726 15.2539 13.164 14.5813 12.7156Z"
                        fill="white"
                    />
                </svg>
            );

        default:
            return <></>;
    }
};

export default CommonIcon;
