
import {getToken, onMessage} from "firebase/messaging";
import {messaging} from "../../firebase";


export async function firebaseFire()
{
    try {
        const requestPermission = async () => {
            const permission = await Notification.requestPermission();
            if (permission === "granted") {
                const token = await getToken(messaging, {
                    vapidKey: "BGIYvEpVnIP6HbnVJKjFvE1fnoK6U8s-VAWK0WDXMGUNC7s2e3qZCkqk2VLd-zcq6FH0hAdwe9qtcsjcqm1T8-c",
                });

                localStorage.setItem('fcmToken', token);
                localStorage.setItem('fcmIsBlock', 'no');
            }
            else
            {
                localStorage.setItem('fcmIsBlock', 'yes')
            }
        };

        requestPermission().then(r => {});

        // Listen for foreground messages
        onMessage(messaging, (payload) => {
            // Message received
            new Notification(payload.notification.title, {
                body: payload.notification.body,
                icon: "/firebase-logo.png",
            });
        });
    }
    catch(err)
    {
        console.log(err)
    }

}
