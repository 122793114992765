import { useNavigate } from "react-router-dom";
import FileView from "../../../../../../core/components/file-view";
import Typo from "../../../../../../core/components/typo";
const NewsItem= ({ title, cover, id, url }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    // if (url.length > 0) window.location.href = url;
    // else navigate(ROUTES.NEWS.INDEX(uuid));
    navigate(`/app/news/${id}`)
  };


  return (
    <div
      className="relative h-[calc(256px-28px-0.5rem)] shrink-0 cursor-pointer overflow-auto rounded-xl w-96"
      onClick={handleNavigate}
    >
      <FileView {...cover} thumbnail={cover?.url} controls imageClassName="!aspect-3/2" />
      <div className="absolute inset-0 flex items-end bg-gradient-to-b from-transparent from-50% to-black to-100% p-2">
        <Typo as="h1" className="ui-clamp-2 text-white">
          {title}
        </Typo>
      </div>
    </div>
  );
};

export default NewsItem;
