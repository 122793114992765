import {useNavigate, useParams} from "react-router-dom";
import NewsPageLoading from "./loading";
import RenderIf from "../../core/components/render-if";
import FileView from "../../core/components/file-view";
import Typo from "../../core/components/typo";
import TextToHTML from "../../core/components/text-to-html";
import {useGetPostById} from "./api/post";
import RootLayout from "../../layout/root";
import {getJalaliDate, getJalaliDateString} from "../../core/utils/date";
import SendComment from "../../core/components/comment/send";
import CommentList from "../../core/components/comment/list";
import __ from "../../core/utils/translate/translate";


const NewsPage = () => {
    const {id} = useParams();

    const {data} = useGetPostById(id);

    if (!data) return <NewsPageLoading/>;

    if(data?.data?.url)
    {
        window.location.href = data?.data?.url;
        return null;
    }

    return (
        <RootLayout title={data?.data?.title}>
            <div className="mx-auto flex w-full flex-col gap-12 p-4 py-20 xl:px-52">
                <div className="flex flex-col gap-4">
                    <RenderIf cond={data.data?.coverDetail}>
                        <FileView
                            {...data?.data.coverDetail}
                            thumbnail={undefined}
                            controls
                            className="max-w-lg rounded-3xl object-contain"
                            imageClassName="!aspect-3/2"
                        />
                    </RenderIf>

                    <Typo as="p" type="secondary" size="xs">
                        {__("Published in")} {getJalaliDate(data.data?.createdAt)}
                    </Typo>
                    <Typo as="h1" weight="bold">
                        {data.data?.title}
                    </Typo>
                    <TextToHTML>{data.data?.content}</TextToHTML>
                </div>
                <div className="flex flex-col gap-4">
                    <SendComment id={data?.data?.id} type={"news"} />
                    <CommentList primaryKey={id} type={"news"} />
                </div>
            </div>
        </RootLayout>
    );
};

export default NewsPage;
