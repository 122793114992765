import {apiDeleteWebappToken, apiSendWebappToken} from "../../data/api/auth";
import {
    AccessTokenKey,
    FirebaseBlockTokenKey,
    FirebaseTokenKey,
    FirebaseTokenSendBackend
} from "../../core/constants/constants";


export function firebaseFireDatabase() {
    if (!FcmTokenDB() && FcmToken() && isLogin()) {
        apiSendWebappToken({webapp_token: FcmToken(), project_id: "nojahan-student"}).then(r => {
            FcmTokenDB(Date.now())
        })
    }
}

function FcmToken(token = null, reset = false) {

    if (reset) {
        localStorage.removeItem(FirebaseTokenKey);
    }

    if (token) {
        localStorage.setItem(FirebaseTokenKey, token);
    }

    return localStorage.getItem(FirebaseTokenKey);
}

function isLogin() {
    return !!localStorage.getItem(AccessTokenKey);
}


function FcmTokenDB(token = null, reset = false) {

    if (reset) {
        localStorage.removeItem(FirebaseTokenSendBackend);
    }

    if (token) {
        localStorage.setItem(FirebaseTokenSendBackend, token);
    }

    return localStorage.getItem(FirebaseTokenSendBackend);
}

export function LogoutFcm() {

    FcmTokenDB(null, true)
    FcmToken(null, true)
}

function isBlock(isBlock = null, reset = false) {
    if (reset) {
        localStorage.removeItem(FirebaseBlockTokenKey);
    }

    if (isBlock) {
        localStorage.setItem(FirebaseBlockTokenKey, isBlock);
    }

    return !!localStorage.getItem(FirebaseBlockTokenKey);
}


