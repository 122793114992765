import useQueryString from "../../../../../../core/hooks/useQueryString";
import Typo from "../../../../../../core/components/typo";
import Search from "../../../../../../core/components/search";
import ScrollView from "../../../../../../core/components/scroll-view";
import {SimpleBadge} from "../../../../../../core/components/badge";

const ActivitiesFilter = ({title}) => {
    const {queryStringFN} = useQueryString();

    const handleOnClick = (type = undefined) => {
        queryStringFN.remove("type");
        if (!!type) queryStringFN.set("type", type);
    };

    const getActiveIndex = (type) => {
        if(!type && !queryStringFN.get("type"))
        {
            return true;
        }
        return queryStringFN.get("type") === type;

    };

    return (
        <div className="flex flex-col gap-4">
            <Typo as="h1" weight="semi-bold" className="ui-clamp-1 flex-1 text-black">
                {title}
            </Typo>
            <Search placeholder="دوره‌ای که میخوای رو جست و جو کن"/>
            <ScrollView className="-mx-4 gap-4 px-4">
                {/*<SimpleBadge isActive={getActiveIndex()} onClick={() => handleOnClick()}*/}
                {/*             className="whitespace-nowrap">*/}
                {/*    همه*/}
                {/*</SimpleBadge>*/}
                {/*<SimpleBadge*/}
                {/*    isActive={getActiveIndex('mission')}*/}
                {/*    onClick={() => handleOnClick('mission')}*/}
                {/*    className="whitespace-nowrap"*/}
                {/*>*/}
                {/*    ماموریت*/}
                {/*</SimpleBadge>*/}
                {/*<SimpleBadge*/}
                {/*    isActive={getActiveIndex('learn')}*/}
                {/*    onClick={() => handleOnClick('learn')}*/}
                {/*    className="whitespace-nowrap"*/}
                {/*>*/}
                {/*    دوره*/}
                {/*</SimpleBadge>*/}

            </ScrollView>
        </div>
    );
};

export default ActivitiesFilter;
