import {useState} from "react";
import Typo from "../../../../../../../../core/components/typo";
import Modal from "../../../../../../../../core/components/modal";
import Button from "../../../../../../../../core/components/button";
import {useLeftOrganisation} from "../../../../../../api/organisation";
import {setActiveOrganisation} from "../../../../../../../Auth/services/organisationService";
import handleError from "../../../../../../../../core/helper/handelError";
import {useAuthContext} from "../../../../../../../Auth/provider/AuthProvider";
import __ from "../../../../../../../../core/utils/translate/translate";

const JoinToClubCanceRequest = () => {
    const [modal, setModal] = useState(false);
    const {organisation} = useAuthContext();
    const {mutate: leftOrganisation, isPending, isError, error, isSuccess, data} = useLeftOrganisation();

    const leftOrgan = () => {
        leftOrganisation({organisationUserId: organisation?.id}, {
            onSuccess: (data) => {
                setActiveOrganisation(null)
                window.location.href = "/app/profile"
            },
            onError: (error) => {
                handleError(error)
            }
        })
    }
    return (
        <>
            <Typo size="xs" type="danger" className="hover:underline" onClick={() => setModal(true)}>
                {__("Cancel the request")}
            </Typo>
            <Modal title={__("Remove the request to join the organiozation")} isOpen={modal} onClose={() => setModal(false)}>
                <Typo>{__("Do you really want to cancel the membership request?")}</Typo>
                <div className="flex justify-end gap-2">
                    <Button
                        scale="fit"
                        onClick={leftOrgan}
                        isLoading={isPending}
                    >
                        {__("Yes")}
                    </Button>
                    <Button scale="fit" type="light" onClick={() => setModal(false)}>
                        {__("No")}
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default JoinToClubCanceRequest;
