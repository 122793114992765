import {motion} from "framer-motion";
import Typo from "../../typo";
import __ from "../../../utils/translate/translate";

const FullLoading = () => {
    return (
        <div className="flex h-screen w-screen flex-col items-center justify-center gap-2 bg-purple-5">
            <div className="w-20">
                <img src="/images/mini-logo.svg" alt="logo" className="animate-spin duration-[5000]"/>
            </div>
            <Typo weight="bold">{__("Loading information")}</Typo>
            <motion.div animate={{opacity: [1, 0.5, 1]}} transition={{repeat: Infinity}}>
                <Typo size="xs">{__("Please wait")}</Typo>
            </motion.div>
        </div>
    );
};

export default FullLoading;
