import RenderIf from "../render-if";
import Typo from "../typo";
import Spinner from "../spinner";
import {useEffect, useRef} from "react";
import {useEnterSection} from "../../hooks/useEnterSection";
import __ from "../../utils/translate/translate";

const LoadMore = ({
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        spinnerType = "accent",
        title,
        auto = false,
    }) => {

    const refTrigger = useRef(null);
    const isVisible = useEnterSection(refTrigger, "0px");

    useEffect(() => {
        if (isVisible && auto && hasNextPage) fetchNextPage();
    }, [isVisible]);

    return (
        <div className="flex items-center justify-center" ref={refTrigger}>
            <RenderIf cond={hasNextPage}>
                <Typo as="p" type="secondary" size="xs" onClick={fetchNextPage}>
                    {title ?? __("Load more")}
                </Typo>
            </RenderIf>
            <RenderIf cond={isFetchingNextPage}>
                <Spinner type={spinnerType} size="xs" />
            </RenderIf>
        </div>
    );
};

export default LoadMore;
