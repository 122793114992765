const GameIcon = ({icon, className}) => {
    switch (icon) {
        case "award-white":
            return (
                <svg
                    width="33"
                    height="32"
                    viewBox="0 0 33 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={className}
                >
                    <path
                        opacity="0.4"
                        d="M26.762 13.333L26.6847 23.9994C26.6557 27.9993 25.3127 29.3326 21.3127 29.3326H10.646C6.64599 29.3326 5.32233 27.9993 5.35134 23.9994L5.42871 13.333H26.762Z"
                        fill="white"
                    />
                    <path
                        d="M28.8311 9.3331L28.8214 10.6664C28.8108 12.133 28.0954 13.333 26.1354 13.333H6.1354C4.0954 13.333 3.47744 12.133 3.48808 10.6664L3.49775 9.3331C3.50839 7.86647 4.14376 6.6665 6.18376 6.6665H26.1838C28.1438 6.6665 28.8417 7.86647 28.8311 9.3331Z"
                        fill="white"
                    />
                    <path
                        opacity="0.4"
                        d="M15.7038 6.66648H8.34378C7.89402 6.17316 7.91287 5.41318 8.39635 4.9332L10.3034 3.03991C10.8003 2.54659 11.6137 2.54659 12.1034 3.03991L15.7038 6.66648Z"
                        fill="white"
                    />
                    <path
                        opacity="0.4"
                        d="M24.0105 6.66648H16.6505L20.3034 3.03991C20.8003 2.54659 21.6137 2.54659 22.1034 3.03991L23.983 4.9332C24.4595 5.41318 24.4674 6.17316 24.0105 6.66648Z"
                        fill="white"
                    />
                    <path
                        opacity="0.6"
                        d="M12.0554 13.333L12.0057 20.1862C11.998 21.2528 13.1668 21.8794 14.0643 21.3061L15.3236 20.4795C15.779 20.1862 16.3524 20.1862 16.7902 20.4795L17.9711 21.2795C18.8469 21.8661 20.0381 21.2395 20.0458 20.1728L20.0954 13.333H12.0554Z"
                        fill="white"
                    />
                </svg>
            );

        case "book":
            return (
                <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={className}
                >
                    <rect width="32" height="32" rx="16" fill="black" fillOpacity="0.1"/>
                    <path
                        d="M21.7229 5.46447L26.6709 14.0346L11.5125 22.7863C9.83059 23.7573 9.25105 25.9202 10.2221 27.6021L3.51142 15.9789C1.03744 11.6938 1.49021 10.0041 5.77527 7.53009L13.2741 3.20062C17.5592 0.726644 19.2489 1.17941 21.7229 5.46447Z"
                        fill="url(#paint0_linear_2665_50926)"
                    />
                    <path
                        opacity="0.4"
                        d="M26.6705 14.0344L28.8352 17.7838C30.0289 19.8514 29.3181 22.5043 27.2505 23.698L16.5379 29.8829C14.4703 31.0766 11.8174 30.3658 10.6237 28.2983L10.2217 27.6019C9.25066 25.92 9.8302 23.7572 11.5121 22.7861L26.6705 14.0344Z"
                        fill="url(#paint1_linear_2665_50926)"
                    />
                    <path
                        d="M17.3658 9.05103L8.79564 13.999C8.35642 14.2526 7.78191 14.0986 7.52832 13.6594C7.27474 13.2202 7.42868 12.6457 7.8679 12.3921L16.438 7.44413C16.8772 7.19055 17.4517 7.34449 17.7053 7.78371C17.9589 8.22292 17.805 8.79744 17.3658 9.05103Z"
                        fill="white"
                    />
                    <path
                        d="M16.317 14.656L10.9607 17.7485C10.5215 18.0021 9.94695 17.8481 9.69336 17.4089C9.43978 16.9697 9.59372 16.3952 10.0329 16.1416L15.3893 13.0491C15.8285 12.7955 16.403 12.9495 16.6566 13.3887C16.9102 13.8279 16.7562 14.4024 16.317 14.656Z"
                        fill="white"
                    />
                    <path
                        d="M20.0271 22.5158L16.2825 24.8842C15.8228 25.175 15.6798 25.7799 15.9607 26.2458L18.5581 30.5526C18.9227 31.1571 19.7819 31.2037 20.2098 30.6423L20.8864 29.7544C21.0358 29.5585 21.2522 29.4245 21.4942 29.3783L22.4797 29.1901C23.1635 29.0596 23.508 28.2876 23.1484 27.6914L20.0271 22.5158Z"
                        fill="white"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_2665_50926"
                            x1="9.52469"
                            y1="5.36535"
                            x2="19.3278"
                            y2="22.3449"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#FF6767"/>
                            <stop offset="1" stopColor="#FF8A7E"/>
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_2665_50926"
                            x1="17.5647"
                            y1="19.2916"
                            x2="21.8942"
                            y2="26.7905"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#FF6767"/>
                            <stop offset="1" stopColor="#FF8A7E"/>
                        </linearGradient>
                    </defs>
                </svg>
            );
        case "book-hover":
            return (
                <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={className}
                >
                    <rect width="32" height="32" rx="16" fill="black" fillOpacity="0.1"/>
                    <path
                        d="M21.705 5.49603L26.6381 14.0405L11.5251 22.766C9.8483 23.7341 9.27049 25.8905 10.2386 27.5673L3.54805 15.9789C1.08149 11.7067 1.5329 10.022 5.80512 7.55546L13.2815 3.23897C17.5537 0.772398 19.2384 1.22381 21.705 5.49603Z"
                        fill="url(#paint0_linear_2665_50932)"
                    />
                    <path
                        opacity="0.4"
                        d="M26.6374 14.041L28.7957 17.7792C29.9858 19.8406 29.2771 22.4855 27.2157 23.6756L16.5352 29.8421C14.4738 31.0322 11.8288 30.3235 10.6387 28.2621L10.2379 27.5679C9.26977 25.891 9.84758 23.7346 11.5244 22.7665L26.6374 14.041Z"
                        fill="url(#paint1_linear_2665_50932)"
                    />
                    <path
                        d="M17.3613 9.07219L8.81682 14.0053C8.37892 14.2581 7.80613 14.1047 7.5533 13.6668C7.30048 13.2289 7.45396 12.6561 7.89186 12.4032L16.4363 7.4701C16.8742 7.21728 17.447 7.37076 17.6998 7.80866C17.9527 8.24657 17.7992 8.81936 17.3613 9.07219Z"
                        fill="url(#paint2_linear_2665_50932)"
                    />
                    <path
                        d="M16.3153 14.6599L10.975 17.7431C10.5371 17.9959 9.96433 17.8425 9.7115 17.4046C9.45868 16.9667 9.61216 16.3939 10.0501 16.141L15.3903 13.0578C15.8282 12.805 16.401 12.9585 16.6539 13.3964C16.9067 13.8343 16.7532 14.4071 16.3153 14.6599Z"
                        fill="url(#paint3_linear_2665_50932)"
                    />
                    <path
                        d="M20.1616 22.647L16.4308 25.0067C15.971 25.2975 15.828 25.9024 16.109 26.3683L18.6948 30.6559C19.0594 31.2604 19.9186 31.3071 20.3465 30.7456L21.0177 29.8649C21.167 29.6689 21.3835 29.535 21.6255 29.4887L22.6027 29.3021C23.2865 29.1716 23.631 28.3996 23.2714 27.8035L20.1616 22.647Z"
                        fill="url(#paint4_linear_2665_50932)"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_2665_50932"
                            x1="9.54332"
                            y1="5.39721"
                            x2="19.3171"
                            y2="22.3259"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="white" stopOpacity="0.8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_2665_50932"
                            x1="17.5589"
                            y1="19.2825"
                            x2="21.8754"
                            y2="26.7589"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="white" stopOpacity="0.8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint2_linear_2665_50932"
                            x1="8.01581"
                            y1="14.4678"
                            x2="7.0138"
                            y2="12.6533"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                        <linearGradient
                            id="paint3_linear_2665_50932"
                            x1="10.174"
                            y1="18.2056"
                            x2="9.18818"
                            y2="16.3827"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                        <linearGradient
                            id="paint4_linear_2665_50932"
                            x1="15.7558"
                            y1="32.0029"
                            x2="16.8812"
                            y2="21.7908"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                    </defs>
                </svg>
            );
        case "camp":
            return (
                <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={className}
                >
                    <rect width="32" height="32" rx="16" fill="black" fillOpacity="0.1"/>
                    <path
                        opacity="0.4"
                        d="M25.8055 11.3567L23.5834 10.7613C23.0819 10.666 22.6389 10.3521 22.3541 9.88534C22.031 9.37906 21.9388 8.73944 22.0999 8.13839L22.6855 5.95275C23.1931 4.05853 22.9767 2.64383 22.069 1.95161C21.1795 1.26428 19.7497 1.42776 18.0638 2.40109L5.13117 9.86776C4.89535 10.0193 4.81238 10.3289 4.95238 10.5714L12.9524 24.4278C13.0924 24.6703 13.402 24.7533 13.6445 24.6133L26.5772 17.1466C28.2399 16.1866 29.0666 15.0319 28.921 13.8997C28.7687 12.7559 27.67 11.8661 25.8055 11.3567Z"
                        fill="url(#paint0_linear_2665_50924)"
                    />
                    <path
                        d="M19.9542 11.5057L19.7088 12.7539C19.6616 12.9409 19.7158 13.2353 19.8189 13.4009L20.4859 14.4856C20.8981 15.1477 20.5954 15.765 19.8203 15.8552L18.5592 15.999C18.3685 16.0272 18.0938 16.1738 17.9642 16.3165L17.1428 17.2841C16.6363 17.8778 15.955 17.7857 15.6344 17.0747L15.1046 15.9167C15.0245 15.739 14.81 15.53 14.6284 15.4652L13.4549 14.9742C12.7278 14.686 12.6109 13.9986 13.1829 13.4728L14.1234 12.6181C14.2706 12.4807 14.3911 12.2106 14.4032 12.013L14.5089 10.7511C14.5751 9.96601 15.1701 9.64848 15.8591 10.0305L16.9663 10.6452C17.1372 10.7451 17.4287 10.7953 17.6247 10.7495L18.8583 10.444C19.6136 10.2615 20.1195 10.7412 19.9542 11.5057Z"
                        fill="white"
                    />
                    <path
                        d="M17.3908 31.1028C16.9173 31.3761 16.2981 31.2102 16.0247 30.7368L3.69141 9.37481C3.41807 8.90139 3.584 8.28212 4.05743 8.00879C4.53086 7.73546 5.15012 7.90139 5.42346 8.37481L17.7568 29.7368C18.0301 30.2102 17.8642 30.8295 17.3908 31.1028Z"
                        fill="url(#paint1_linear_2665_50924)"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_2665_50924"
                            x1="13.0433"
                            y1="5.29966"
                            x2="21.5567"
                            y2="20.0452"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#E61C6C"/>
                            <stop offset="1" stopColor="#FF3886"/>
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_2665_50924"
                            x1="4.05743"
                            y1="8.00879"
                            x2="17.3908"
                            y2="31.1028"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#E61C6C"/>
                            <stop offset="1" stopColor="#FF3886"/>
                        </linearGradient>
                    </defs>
                </svg>
            );
        case "camp-hover":
            return (
                <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={className}
                >
                    <rect width="32" height="32" rx="16" fill="black" fillOpacity="0.1"/>
                    <path
                        opacity="0.4"
                        d="M25.8055 11.3567L23.5834 10.7613C23.0819 10.666 22.6389 10.3521 22.3541 9.88534C22.031 9.37906 21.9388 8.73944 22.0999 8.13839L22.6855 5.95275C23.1931 4.05853 22.9767 2.64383 22.069 1.95161C21.1795 1.26428 19.7497 1.42776 18.0638 2.40109L5.13117 9.86776C4.89535 10.0193 4.81238 10.3289 4.95238 10.5714L12.9524 24.4278C13.0924 24.6703 13.402 24.7533 13.6445 24.6133L26.5771 17.1466C28.2399 16.1866 29.0666 15.0319 28.921 13.8997C28.7687 12.7559 27.67 11.8661 25.8055 11.3567Z"
                        fill="url(#paint0_linear_2665_50930)"
                    />
                    <path
                        d="M19.9542 11.5057L19.7088 12.7539C19.6616 12.9409 19.7158 13.2353 19.8189 13.4009L20.4859 14.4856C20.8981 15.1477 20.5954 15.765 19.8203 15.8552L18.5592 15.999C18.3685 16.0272 18.0938 16.1738 17.9642 16.3165L17.1428 17.2841C16.6363 17.8778 15.955 17.7857 15.6344 17.0747L15.1046 15.9167C15.0245 15.739 14.81 15.53 14.6284 15.4652L13.4549 14.9742C12.7278 14.686 12.6109 13.9986 13.1829 13.4728L14.1234 12.6181C14.2706 12.4807 14.3911 12.2106 14.4032 12.013L14.5089 10.7511C14.5751 9.96601 15.1701 9.64848 15.8591 10.0305L16.9663 10.6452C17.1372 10.7451 17.4287 10.7953 17.6247 10.7495L18.8583 10.444C19.6136 10.2615 20.1195 10.7412 19.9542 11.5057Z"
                        fill="url(#paint1_linear_2665_50930)"
                    />
                    <path
                        d="M17.3908 31.1028C16.9173 31.3761 16.2981 31.2102 16.0247 30.7368L3.69141 9.37481C3.41807 8.90139 3.584 8.28212 4.05743 8.00879C4.53086 7.73546 5.15012 7.90139 5.42346 8.37481L17.7568 29.7368C18.0301 30.2102 17.8642 30.8295 17.3908 31.1028Z"
                        fill="url(#paint2_linear_2665_50930)"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_2665_50930"
                            x1="13.0433"
                            y1="5.29966"
                            x2="21.5567"
                            y2="20.0452"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="white" stopOpacity="0.8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_2665_50930"
                            x1="15.0343"
                            y1="18.5134"
                            x2="11.9391"
                            y2="10.6493"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                        <linearGradient
                            id="paint2_linear_2665_50930"
                            x1="4.05743"
                            y1="8.00879"
                            x2="17.3908"
                            y2="31.1028"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="white" stopOpacity="0.8"/>
                        </linearGradient>
                    </defs>
                </svg>
            );

        case "club":
            return (
                <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={className}
                >
                    <rect width="32" height="32" rx="16" fill="black" fillOpacity="0.1"/>
                    <path
                        opacity="0.4"
                        d="M24.5065 17.1201V23.6935C24.5065 25.3868 23.1865 27.2001 21.5998 27.7334L17.3465 29.1468C16.5998 29.4001 15.3865 29.4001 14.6532 29.1468L10.3998 27.7334C8.79983 27.2001 7.49316 25.3868 7.49316 23.6935L7.5065 17.1201L13.3998 20.9601C14.8398 21.9068 17.2132 21.9068 18.6532 20.9601L24.5065 17.1201Z"
                        fill="url(#paint0_linear_2665_50922)"
                    />
                    <path
                        d="M26.64 8.61357L18.6534 3.37357C17.2134 2.42691 14.84 2.42691 13.4 3.37357L5.37336 8.61357C2.80003 10.2802 2.80003 14.0536 5.37336 15.7336L7.50669 17.1202L13.4 20.9602C14.84 21.9069 17.2134 21.9069 18.6534 20.9602L24.5067 17.1202L26.3334 15.9202V20.0002C26.3334 20.5469 26.7867 21.0002 27.3334 21.0002C27.88 21.0002 28.3334 20.5469 28.3334 20.0002V13.4402C28.8667 11.7202 28.32 9.72024 26.64 8.61357Z"
                        fill="url(#paint1_linear_2665_50922)"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_2665_50922"
                            x1="15.9998"
                            y1="17.1201"
                            x2="15.9998"
                            y2="29.3368"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#8835B2"/>
                            <stop offset="1" stopColor="#711F9A"/>
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_2665_50922"
                            x1="15.9867"
                            y1="2.66357"
                            x2="15.9867"
                            y2="21.6702"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#8835B2"/>
                            <stop offset="1" stopColor="#711F9A"/>
                        </linearGradient>
                    </defs>
                </svg>
            );

        case "club-hover":
            return (
                <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={className}
                >
                    <rect width="32" height="32" rx="16" fill="black" fillOpacity="0.1"/>
                    <path
                        opacity="0.4"
                        d="M24.5065 17.1201V23.6935C24.5065 25.3868 23.1865 27.2001 21.5998 27.7334L17.3465 29.1468C16.5998 29.4001 15.3865 29.4001 14.6532 29.1468L10.3998 27.7334C8.79983 27.2001 7.49316 25.3868 7.49316 23.6935L7.5065 17.1201L13.3998 20.9601C14.8398 21.9068 17.2132 21.9068 18.6532 20.9601L24.5065 17.1201Z"
                        fill="url(#paint0_linear_2665_50928)"
                    />
                    <path
                        d="M26.64 8.61357L18.6534 3.37357C17.2134 2.42691 14.84 2.42691 13.4 3.37357L5.37336 8.61357C2.80003 10.2802 2.80003 14.0536 5.37336 15.7336L7.50669 17.1202L13.4 20.9602C14.84 21.9069 17.2134 21.9069 18.6534 20.9602L24.5067 17.1202L26.3334 15.9202V20.0002C26.3334 20.5469 26.7867 21.0002 27.3334 21.0002C27.88 21.0002 28.3334 20.5469 28.3334 20.0002V13.4402C28.8667 11.7202 28.32 9.72024 26.64 8.61357Z"
                        fill="url(#paint1_linear_2665_50928)"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_2665_50928"
                            x1="15.9998"
                            y1="17.1201"
                            x2="15.9998"
                            y2="29.3368"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="white" stopOpacity="0.8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_2665_50928"
                            x1="15.9867"
                            y1="2.66357"
                            x2="15.9867"
                            y2="21.6702"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="white" stopOpacity="0.8"/>
                        </linearGradient>
                    </defs>
                </svg>
            );
        case "club-white":
            return (
                <svg
                    width="33"
                    height="33"
                    viewBox="0 0 33 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={className}
                >
                    <path
                        opacity="0.4"
                        d="M25.3112 17.9458L25.2635 24.519C25.2512 26.2123 23.9181 28.0255 22.3275 28.5589L18.064 29.9722C17.3154 30.2255 16.1021 30.2255 15.3706 29.9722L11.1275 28.5589C9.5314 28.0255 8.23789 26.2123 8.25017 24.519L8.31119 17.9458L14.1767 21.7857C15.6098 22.7323 17.9831 22.7323 19.43 21.7857L25.3112 17.9458Z"
                        fill="white"
                    />
                    <path
                        d="M27.5063 9.4391L19.5576 4.19924C18.1245 3.2526 15.7512 3.2526 14.3043 4.19924L6.23963 9.4391C3.6542 11.1057 3.62683 14.879 6.18798 16.5589L8.31126 17.9455L14.1767 21.7854C15.6099 22.7321 17.9832 22.7321 19.4301 21.7854L25.3113 17.9455L27.1466 16.7456L27.117 20.8255C27.1131 21.3721 27.5631 21.8254 28.1098 21.8254C28.6564 21.8254 29.1131 21.3721 29.117 20.8255L29.1646 14.2656C29.7104 12.5457 29.1783 10.5457 27.5063 9.4391Z"
                        fill="white"
                    />
                </svg>
            );

        case "match":
            return (
                <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={className}
                >
                    <rect width="32" height="32" rx="16" fill="black" fillOpacity="0.1"/>
                    <path
                        opacity="0.4"
                        d="M24 27.3331H22.6667V26.9998C22.6667 25.5331 21.4667 24.3331 20 24.3331H17V21.2798C16.6667 21.3198 16.3333 21.3331 16 21.3331C15.6667 21.3331 15.3333 21.3198 15 21.2798V24.3331H12C10.5333 24.3331 9.33333 25.5331 9.33333 26.9998V27.3331H8C7.45333 27.3331 7 27.7865 7 28.3331C7 28.8798 7.45333 29.3331 8 29.3331H24C24.5467 29.3331 25 28.8798 25 28.3331C25 27.7865 24.5467 27.3331 24 27.3331Z"
                        fill="url(#paint0_linear_2665_50927)"
                    />
                    <path
                        opacity="0.4"
                        d="M7.36004 15.5198C6.48004 15.1865 5.70671 14.6398 5.09337 14.0265C3.85337 12.6532 3.04004 11.0132 3.04004 9.09317C3.04004 7.17317 4.54671 5.6665 6.46671 5.6665H7.21337C6.86671 6.37317 6.66671 7.15984 6.66671 7.99984V11.9998C6.66671 13.2532 6.90671 14.4398 7.36004 15.5198Z"
                        fill="url(#paint1_linear_2665_50927)"
                    />
                    <path
                        opacity="0.4"
                        d="M28.9596 9.09317C28.9596 11.0132 28.1463 12.6532 26.9063 14.0265C26.293 14.6398 25.5196 15.1865 24.6396 15.5198C25.093 14.4398 25.333 13.2532 25.333 11.9998V7.99984C25.333 7.15984 25.133 6.37317 24.7863 5.6665H25.533C27.453 5.6665 28.9596 7.17317 28.9596 9.09317Z"
                        fill="url(#paint2_linear_2665_50927)"
                    />
                    <path
                        d="M20.0003 2.6665H12.0003C9.05366 2.6665 6.66699 5.05317 6.66699 7.99984V11.9998C6.66699 17.1598 10.8403 21.3332 16.0003 21.3332C21.1603 21.3332 25.3337 17.1598 25.3337 11.9998V7.99984C25.3337 5.05317 22.947 2.6665 20.0003 2.6665ZM19.787 11.2665L18.9603 12.2798C18.827 12.4265 18.7337 12.7198 18.747 12.9198L18.827 14.2265C18.8803 15.0265 18.307 15.4398 17.5603 15.1465L16.347 14.6665C16.1603 14.5998 15.8403 14.5998 15.6537 14.6665L14.4403 15.1465C13.6937 15.4398 13.1203 15.0265 13.1737 14.2265L13.2537 12.9198C13.267 12.7198 13.1737 12.4265 13.0403 12.2798L12.2137 11.2665C11.6937 10.6532 11.9203 9.97317 12.6937 9.77317L13.9603 9.45317C14.1603 9.39984 14.4003 9.21317 14.507 9.03984L15.2137 7.9465C15.6537 7.2665 16.347 7.2665 16.787 7.9465L17.4937 9.03984C17.6003 9.21317 17.8403 9.39984 18.0403 9.45317L19.307 9.77317C20.0803 9.97317 20.307 10.6532 19.787 11.2665Z"
                        fill="url(#paint3_linear_2665_50927)"
                    />
                    <path
                        d="M19.7867 11.2665L18.96 12.2799C18.8267 12.4265 18.7333 12.7199 18.7467 12.9199L18.8267 14.2265C18.88 15.0265 18.3067 15.4399 17.56 15.1465L16.3467 14.6665C16.16 14.5999 15.84 14.5999 15.6533 14.6665L14.44 15.1465C13.6933 15.4399 13.12 15.0265 13.1733 14.2265L13.2533 12.9199C13.2667 12.7199 13.1733 12.4265 13.04 12.2799L12.2133 11.2665C11.6933 10.6532 11.92 9.97319 12.6933 9.77319L13.96 9.45319C14.16 9.39986 14.4 9.21319 14.5067 9.03986L15.2133 7.94652C15.6533 7.26652 16.3467 7.26652 16.7867 7.94652L17.4933 9.03986C17.6 9.21319 17.84 9.39986 18.04 9.45319L19.3067 9.77319C20.08 9.97319 20.3067 10.6532 19.7867 11.2665Z"
                        fill="white"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_2665_50927"
                            x1="16"
                            y1="21.2798"
                            x2="16"
                            y2="29.3331"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#2C0082"/>
                            <stop offset="1" stopColor="#5500B1"/>
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_2665_50927"
                            x1="5.20004"
                            y1="5.6665"
                            x2="5.20004"
                            y2="15.5198"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#2C0082"/>
                            <stop offset="1" stopColor="#5500B1"/>
                        </linearGradient>
                        <linearGradient
                            id="paint2_linear_2665_50927"
                            x1="26.7996"
                            y1="5.6665"
                            x2="26.7996"
                            y2="15.5198"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#2C0082"/>
                            <stop offset="1" stopColor="#5500B1"/>
                        </linearGradient>
                        <linearGradient
                            id="paint3_linear_2665_50927"
                            x1="16.0003"
                            y1="2.6665"
                            x2="16.0003"
                            y2="21.3332"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#2C0082"/>
                            <stop offset="1" stopColor="#5500B1"/>
                        </linearGradient>
                    </defs>
                </svg>
            );
        case "match-hover":
            return (
                <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={className}
                >
                    <rect width="32" height="32" rx="16" fill="black" fillOpacity="0.1"/>
                    <path
                        opacity="0.4"
                        d="M24 27.3331H22.6667V26.9998C22.6667 25.5331 21.4667 24.3331 20 24.3331H17V21.2798C16.6667 21.3198 16.3333 21.3331 16 21.3331C15.6667 21.3331 15.3333 21.3198 15 21.2798V24.3331H12C10.5333 24.3331 9.33333 25.5331 9.33333 26.9998V27.3331H8C7.45333 27.3331 7 27.7865 7 28.3331C7 28.8798 7.45333 29.3331 8 29.3331H24C24.5467 29.3331 25 28.8798 25 28.3331C25 27.7865 24.5467 27.3331 24 27.3331Z"
                        fill="url(#paint0_linear_2665_50933)"
                    />
                    <path
                        opacity="0.4"
                        d="M7.36004 15.5198C6.48004 15.1865 5.70671 14.6398 5.09337 14.0265C3.85337 12.6532 3.04004 11.0132 3.04004 9.09317C3.04004 7.17317 4.54671 5.6665 6.46671 5.6665H7.21337C6.86671 6.37317 6.66671 7.15984 6.66671 7.99984V11.9998C6.66671 13.2532 6.90671 14.4398 7.36004 15.5198Z"
                        fill="url(#paint1_linear_2665_50933)"
                    />
                    <path
                        opacity="0.4"
                        d="M28.9596 9.09317C28.9596 11.0132 28.1463 12.6532 26.9063 14.0265C26.293 14.6398 25.5196 15.1865 24.6396 15.5198C25.093 14.4398 25.333 13.2532 25.333 11.9998V7.99984C25.333 7.15984 25.133 6.37317 24.7863 5.6665H25.533C27.453 5.6665 28.9596 7.17317 28.9596 9.09317Z"
                        fill="url(#paint2_linear_2665_50933)"
                    />
                    <path
                        d="M20.0003 2.6665H12.0003C9.05366 2.6665 6.66699 5.05317 6.66699 7.99984V11.9998C6.66699 17.1598 10.8403 21.3332 16.0003 21.3332C21.1603 21.3332 25.3337 17.1598 25.3337 11.9998V7.99984C25.3337 5.05317 22.947 2.6665 20.0003 2.6665ZM19.787 11.2665L18.9603 12.2798C18.827 12.4265 18.7337 12.7198 18.747 12.9198L18.827 14.2265C18.8803 15.0265 18.307 15.4398 17.5603 15.1465L16.347 14.6665C16.1603 14.5998 15.8403 14.5998 15.6537 14.6665L14.4403 15.1465C13.6937 15.4398 13.1203 15.0265 13.1737 14.2265L13.2537 12.9198C13.267 12.7198 13.1737 12.4265 13.0403 12.2798L12.2137 11.2665C11.6937 10.6532 11.9203 9.97317 12.6937 9.77317L13.9603 9.45317C14.1603 9.39984 14.4003 9.21317 14.507 9.03984L15.2137 7.9465C15.6537 7.2665 16.347 7.2665 16.787 7.9465L17.4937 9.03984C17.6003 9.21317 17.8403 9.39984 18.0403 9.45317L19.307 9.77317C20.0803 9.97317 20.307 10.6532 19.787 11.2665Z"
                        fill="url(#paint3_linear_2665_50933)"
                    />
                    <path
                        d="M19.7867 11.2665L18.96 12.2799C18.8267 12.4265 18.7333 12.7199 18.7467 12.9199L18.8267 14.2265C18.88 15.0265 18.3067 15.4399 17.56 15.1465L16.3467 14.6665C16.16 14.5999 15.84 14.5999 15.6533 14.6665L14.44 15.1465C13.6933 15.4399 13.12 15.0265 13.1733 14.2265L13.2533 12.9199C13.2667 12.7199 13.1733 12.4265 13.04 12.2799L12.2133 11.2665C11.6933 10.6532 11.92 9.97319 12.6933 9.77319L13.96 9.45319C14.16 9.39986 14.4 9.21319 14.5067 9.03986L15.2133 7.94652C15.6533 7.26652 16.3467 7.26652 16.7867 7.94652L17.4933 9.03986C17.6 9.21319 17.84 9.39986 18.04 9.45319L19.3067 9.77319C20.08 9.97319 20.3067 10.6532 19.7867 11.2665Z"
                        fill="url(#paint4_linear_2665_50933)"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_2665_50933"
                            x1="16"
                            y1="21.2798"
                            x2="16"
                            y2="29.3331"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="white" stopOpacity="0.8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_2665_50933"
                            x1="5.20004"
                            y1="5.6665"
                            x2="5.20004"
                            y2="15.5198"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="white" stopOpacity="0.8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint2_linear_2665_50933"
                            x1="26.7996"
                            y1="5.6665"
                            x2="26.7996"
                            y2="15.5198"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="white" stopOpacity="0.8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint3_linear_2665_50933"
                            x1="16.0003"
                            y1="2.6665"
                            x2="16.0003"
                            y2="21.3332"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="white" stopOpacity="0.8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint4_linear_2665_50933"
                            x1="11.9326"
                            y1="15.2409"
                            x2="12.9319"
                            y2="6.61038"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                    </defs>
                </svg>
            );
        case "mission":
            return (
                <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={className}
                >
                    <rect width="32" height="32" rx="16" fill="black" fillOpacity="0.1"/>
                    <path
                        d="M20.0622 5.04875C20.7755 4.73669 21.5352 4.64175 22.2853 4.49773C23.2109 4.32363 24.1497 4.2692 25.0966 4.23561C25.3582 4.22949 25.5966 4.32365 25.8523 4.33908C26.0749 4.54022 26.2975 4.74137 26.5201 4.94251C26.9568 6.42833 26.7647 7.93878 26.65 9.43826C26.6165 9.89301 26.5191 10.3439 26.4574 10.8001C26.3394 10.7069 26.2146 10.6212 26.1033 10.5206C24.0849 8.69689 22.0739 6.87988 20.0622 5.04875Z"
                        fill="url(#paint0_linear_2665_50951)"
                    />
                    <path
                        opacity="0.4"
                        d="M19.9287 5.04872C20.0118 5.0276 20.0981 5.08115 20.1617 5.13861C22.1467 6.9322 24.1248 8.71962 26.1035 10.5207C26.3087 10.7061 26.4232 10.9714 26.3621 11.2412C26.0298 12.7083 25.5568 14.1282 24.9051 15.4869C24.5657 16.1907 24.2041 16.8744 23.7408 17.5066C23.6745 17.5949 23.6304 17.7033 23.5716 17.7983C23.366 18.0706 23.1463 18.3436 22.9407 18.6159C22.1205 19.7474 21.1356 20.7032 20.0772 21.6061C19.6336 21.9886 19.1398 22.3035 18.726 22.7088C18.644 22.7892 18.5366 22.8375 18.4443 22.906C17.5834 23.5158 16.7232 24.1396 15.8697 24.7561C15.0661 25.2503 14.2728 25.744 13.4722 26.2381C13.2328 26.3858 12.9352 26.335 12.7265 26.1463C10.7188 24.3323 8.71669 22.5233 6.70595 20.7155C6.1634 20.2277 5.94302 19.4839 6.27022 18.8318C6.37502 18.623 6.47969 18.4138 6.56621 18.1957C6.69904 17.8616 6.91676 17.5711 7.05155 17.2439C7.12244 17.0718 7.23968 16.9081 7.33493 16.7482C7.85524 15.8442 8.36884 14.9476 8.89656 14.0502C9.00761 13.8676 9.11938 13.6992 9.23043 13.5166C9.91229 12.4487 10.6097 11.4083 11.446 10.4529C12.001 9.81047 12.6366 9.26479 13.2387 8.67893C13.3108 8.60878 13.3875 8.54353 13.4685 8.48379C14.0936 8.02242 14.6968 7.5323 15.3543 7.12685C16.7746 6.23788 18.2977 5.57516 19.8847 5.06386C19.8995 5.05908 19.9136 5.05257 19.9287 5.04872ZM20.9995 15.5282C22.1847 14.3358 22.3454 12.0694 20.744 10.6494C19.2309 9.29576 17.0706 9.55336 15.8859 10.8944C14.5999 12.3326 14.681 14.4806 16.056 15.7633C17.497 17.0923 19.8123 16.9614 20.9995 15.5282Z"
                        fill="url(#paint1_linear_2665_50951)"
                    />
                    <path
                        d="M7.72006 23.1643C8.4924 23.8153 9.26474 24.4663 10.0371 25.1173C10.0997 25.85 9.95264 26.5365 9.5894 27.1847C8.50702 28.7171 6.99977 29.2639 5.17507 29.0855C4.88846 29.0531 4.69729 28.892 4.61684 28.615C4.18202 27.098 4.40212 25.6886 5.33591 24.4101C5.4559 24.2367 5.62943 24.1084 5.78001 23.9608C6.33202 23.4455 7.00391 23.2536 7.72006 23.1643Z"
                        fill="url(#paint2_linear_2665_50951)"
                    />
                    <path
                        d="M16.7595 24.359C17.2025 23.8836 19.5957 22.0194 20.0847 21.6125C21.1431 20.7096 22.128 19.7538 22.9482 18.6223C23.1464 18.3433 23.3661 18.0703 23.5791 17.8047C23.7615 18.468 23.7752 19.154 23.8521 19.8297C24.0938 21.9341 23.6631 23.9026 22.537 25.7009C22.4521 25.8396 22.3522 25.965 22.2524 26.0904C21.3728 26.4132 20.3593 26.7014 19.9763 26.4093C19.0761 25.6901 17.592 25.1382 16.7595 24.359Z"
                        fill="url(#paint3_linear_2665_50951)"
                    />
                    <path
                        d="M18.611 22.8406C19.4436 23.6198 20.2627 24.4138 21.1623 25.1188C21.5453 25.411 21.8888 25.7618 22.2517 26.0762C21.8803 26.8453 21.3255 27.4891 20.718 28.0719C20.3399 28.4308 19.741 28.3477 19.272 27.8971C18.1628 26.8275 16.9925 25.8105 15.8698 24.7557C16.7233 24.1392 17.5909 23.5221 18.4445 22.9056C18.4928 22.8819 18.5486 22.865 18.611 22.8406Z"
                        fill="url(#paint4_linear_2665_50951)"
                    />
                    <path
                        d="M12.7077 9.18154C12.9246 8.97789 13.1416 8.77424 13.3528 8.56537C13.1664 8.49258 12.9756 8.50543 12.7892 8.51797C12.6866 8.52488 12.5854 8.53169 12.487 8.52409C11.1375 8.43641 9.80257 8.49665 8.50646 8.90181C7.27952 9.27517 6.17037 9.87619 5.23094 10.7518C5.23094 10.7518 4.5952 11.1645 5.22563 10.7608C5.22275 10.758 5.21986 10.7553 5.21698 10.7526C4.68563 11.0854 4.24631 11.5341 3.8053 11.9846C3.75662 12.0343 3.70792 12.0841 3.65908 12.1337C3.10213 12.7352 3.12132 13.251 3.72311 13.8082C4.29151 14.3352 4.85973 14.8588 5.42794 15.3823C5.99617 15.9058 6.56439 16.4293 7.13279 16.9563C7.22646 16.9105 7.27206 16.8338 7.32224 16.7495C7.32387 16.7467 7.3255 16.744 7.32713 16.7412C7.58729 16.2892 7.84762 15.8407 8.10795 15.3922C8.16736 15.2899 8.22677 15.1875 8.28618 15.0851C8.29059 15.0889 8.29485 15.0927 8.29897 15.0964C8.3545 15.0051 8.56115 14.6538 8.76779 14.3026C8.97443 13.9514 9.18107 13.6001 9.2366 13.5089C9.91176 12.4483 10.6233 11.4072 11.4521 10.4452C11.8399 9.99613 12.2738 9.58883 12.7077 9.18154Z"
                        fill="url(#paint5_linear_2665_50951)"
                    />
                    <path
                        d="M20.9996 15.5281C19.8124 16.9614 17.4971 17.0923 16.0561 15.7633C14.6744 14.488 14.6 12.3326 15.886 10.8944C17.0849 9.55262 19.2309 9.29573 20.744 10.6494C22.3455 12.0694 22.1848 14.3357 20.9996 15.5281Z"
                        fill="white"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_2665_50951"
                            x1="20.0555"
                            y1="5.05618"
                            x2="23.5892"
                            y2="1.60129"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_2665_50951"
                            x1="4.19325"
                            y1="18.4767"
                            x2="22.1568"
                            y2="4.50396"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                        <linearGradient
                            id="paint2_linear_2665_50951"
                            x1="2.88425"
                            y1="27.3115"
                            x2="8.11526"
                            y2="22.7073"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                        <linearGradient
                            id="paint3_linear_2665_50951"
                            x1="15.0211"
                            y1="23.4748"
                            x2="25.4116"
                            y2="18.9616"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                        <linearGradient
                            id="paint4_linear_2665_50951"
                            x1="15.0211"
                            y1="23.4748"
                            x2="25.4116"
                            y2="18.9616"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                        <linearGradient
                            id="paint5_linear_2665_50951"
                            x1="2.66694"
                            y1="12.3234"
                            x2="13.8609"
                            y2="7.8682"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                    </defs>
                </svg>
            );

        case "mission-hover":
            return (
                <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={className}
                >
                    <rect width="32" height="32" rx="16" fill="black" fillOpacity="0.1"/>
                    <path
                        d="M20.0622 5.04875C20.7755 4.73669 21.5352 4.64175 22.2853 4.49773C23.2109 4.32363 24.1497 4.2692 25.0966 4.23561C25.3582 4.22949 25.5966 4.32365 25.8523 4.33908C26.0749 4.54022 26.2975 4.74137 26.5201 4.94251C26.9568 6.42833 26.7647 7.93878 26.65 9.43826C26.6165 9.89301 26.5191 10.3439 26.4574 10.8001C26.3394 10.7069 26.2146 10.6212 26.1033 10.5206C24.0849 8.69689 22.0739 6.87988 20.0622 5.04875Z"
                        fill="url(#paint0_linear_2665_50942)"
                    />
                    <path
                        opacity="0.4"
                        d="M19.9287 5.04872C20.0118 5.0276 20.0981 5.08115 20.1617 5.13861C22.1467 6.9322 24.1248 8.71962 26.1035 10.5207V10.5207C26.3087 10.7061 26.4232 10.9714 26.3621 11.2412C26.0298 12.7083 25.5568 14.1282 24.9051 15.4869C24.5657 16.1907 24.2041 16.8744 23.7408 17.5066C23.6745 17.5949 23.6304 17.7033 23.5716 17.7983C23.366 18.0706 23.1463 18.3436 22.9407 18.6159C22.1205 19.7474 21.1356 20.7032 20.0772 21.6061C19.6336 21.9886 19.1398 22.3035 18.726 22.7088C18.644 22.7892 18.5366 22.8375 18.4443 22.906V22.906C17.5834 23.5158 16.7232 24.1396 15.8697 24.7561C15.0661 25.2503 14.2728 25.744 13.4722 26.2381C13.2328 26.3858 12.9352 26.335 12.7265 26.1463V26.1463C10.7188 24.3323 8.71669 22.5233 6.70595 20.7155C6.1634 20.2277 5.94302 19.4839 6.27022 18.8318C6.37501 18.623 6.47969 18.4138 6.56621 18.1957C6.69904 17.8616 6.91675 17.5711 7.05155 17.2439C7.12244 17.0718 7.23968 16.9081 7.33493 16.7482V16.7482C7.85523 15.8442 8.36884 14.9476 8.89656 14.0502C9.00761 13.8676 9.11938 13.6992 9.23043 13.5166C9.91229 12.4487 10.6097 11.4083 11.446 10.4529C12.001 9.81047 12.6366 9.26479 13.2387 8.67894C13.3108 8.60878 13.3875 8.54353 13.4685 8.48379C14.0936 8.02242 14.6968 7.5323 15.3543 7.12686C16.7746 6.23788 18.2977 5.57516 19.8847 5.06386C19.8995 5.05908 19.9136 5.05257 19.9287 5.04872V5.04872ZM20.9995 15.5282C22.1847 14.3358 22.3454 12.0694 20.744 10.6494C19.2309 9.29576 17.0706 9.55336 15.8859 10.8944C14.5999 12.3326 14.681 14.4806 16.056 15.7633C17.497 17.0923 19.8123 16.9614 20.9995 15.5282Z"
                        fill="url(#paint1_linear_2665_50942)"
                    />
                    <path
                        d="M7.72006 23.1643C8.4924 23.8153 9.26474 24.4663 10.0371 25.1173C10.0997 25.85 9.95264 26.5365 9.5894 27.1847C8.50702 28.7171 6.99977 29.2639 5.17507 29.0855C4.88846 29.0531 4.69729 28.892 4.61684 28.615C4.18202 27.098 4.40212 25.6886 5.33591 24.4101C5.4559 24.2367 5.62943 24.1084 5.78001 23.9608C6.33202 23.4455 7.00391 23.2536 7.72006 23.1643Z"
                        fill="url(#paint2_linear_2665_50942)"
                    />
                    <path
                        d="M16.7595 24.359C17.2025 23.8836 19.5957 22.0194 20.0847 21.6125C21.1431 20.7096 22.128 19.7538 22.9482 18.6223C23.1464 18.3433 23.3661 18.0703 23.5791 17.8047C23.7615 18.468 23.7752 19.154 23.8521 19.8297C24.0938 21.9341 23.6631 23.9026 22.537 25.7009C22.4521 25.8396 22.3522 25.965 22.2524 26.0904C21.3728 26.4132 20.3593 26.7014 19.9763 26.4093C19.0761 25.6901 17.592 25.1382 16.7595 24.359Z"
                        fill="url(#paint3_linear_2665_50942)"
                    />
                    <path
                        d="M18.611 22.8406C19.4436 23.6198 20.2627 24.4138 21.1623 25.1188C21.5453 25.411 21.8888 25.7618 22.2517 26.0762C21.8803 26.8453 21.3255 27.4891 20.718 28.0719C20.3399 28.4308 19.741 28.3477 19.272 27.8971C18.1628 26.8275 16.9925 25.8105 15.8698 24.7557C16.7233 24.1392 17.5909 23.5221 18.4445 22.9056C18.4928 22.8819 18.5486 22.865 18.611 22.8406Z"
                        fill="url(#paint4_linear_2665_50942)"
                    />
                    <path
                        d="M12.7077 9.18154C12.9246 8.97789 13.1416 8.77424 13.3528 8.56537C13.1664 8.49258 12.9756 8.50543 12.7892 8.51797C12.6866 8.52488 12.5854 8.53169 12.487 8.52409C11.1375 8.43641 9.80257 8.49665 8.50646 8.90181C7.27952 9.27517 6.17037 9.87619 5.23094 10.7518C5.23094 10.7518 4.5952 11.1645 5.22563 10.7608C5.22275 10.758 5.21986 10.7553 5.21698 10.7526C4.68563 11.0854 4.24631 11.5341 3.8053 11.9846C3.75662 12.0343 3.70792 12.0841 3.65908 12.1337C3.10213 12.7352 3.12132 13.251 3.72311 13.8082C4.29151 14.3352 4.85973 14.8588 5.42794 15.3823C5.99617 15.9058 6.56439 16.4293 7.13279 16.9563C7.22646 16.9105 7.27206 16.8338 7.32224 16.7495C7.32387 16.7467 7.3255 16.744 7.32713 16.7412C7.58729 16.2892 7.84762 15.8407 8.10795 15.3922C8.16736 15.2899 8.22677 15.1875 8.28618 15.0851C8.29059 15.0889 8.29485 15.0927 8.29897 15.0964C8.3545 15.0051 8.56115 14.6538 8.76779 14.3026C8.97443 13.9514 9.18107 13.6001 9.2366 13.5089C9.91176 12.4483 10.6233 11.4072 11.4521 10.4452C11.8399 9.99613 12.2738 9.58883 12.7077 9.18154Z"
                        fill="url(#paint5_linear_2665_50942)"
                    />
                    <path
                        d="M20.9996 15.5281C19.8124 16.9614 17.4971 17.0923 16.0561 15.7633C14.6744 14.488 14.6 12.3326 15.886 10.8944C17.0849 9.55262 19.2309 9.29573 20.744 10.6494C22.3455 12.0694 22.1848 14.3357 20.9996 15.5281Z"
                        fill="url(#paint6_linear_2665_50942)"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_2665_50942"
                            x1="20.1378"
                            y1="4.9651"
                            x2="24.0942"
                            y2="1.49511"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="white" stopOpacity="0.8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_2665_50942"
                            x1="4.57726"
                            y1="18.0516"
                            x2="24.9983"
                            y2="6.49041"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="white" stopOpacity="0.8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint2_linear_2665_50942"
                            x1="2.99681"
                            y1="27.1779"
                            x2="9.00334"
                            y2="23.1049"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="white" stopOpacity="0.8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint3_linear_2665_50942"
                            x1="15.2588"
                            y1="23.3173"
                            x2="26.5347"
                            y2="20.2751"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="white" stopOpacity="0.8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint4_linear_2665_50942"
                            x1="15.2588"
                            y1="23.3173"
                            x2="26.5347"
                            y2="20.2751"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="white" stopOpacity="0.8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint5_linear_2665_50942"
                            x1="2.92127"
                            y1="12.1548"
                            x2="14.9073"
                            y2="9.59851"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="white" stopOpacity="0.8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint6_linear_2665_50942"
                            x1="13.5403"
                            y1="13.4905"
                            x2="19.3666"
                            y2="8.42811"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                    </defs>
                </svg>
            );

        case "mission-white":
            return (
                <svg
                    width="33"
                    height="32"
                    viewBox="0 0 33 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={className}
                >
                    <path
                        d="M28.2846 9.30635C27.1603 8.05305 25.2716 7.4264 22.5249 7.4264H22.2049L22.2053 7.37306C22.2215 5.13312 22.2416 2.35986 17.2283 2.35986H15.2016C10.1883 2.35986 10.1681 5.14646 10.1519 7.37306L10.1515 7.43973H9.83146C7.07146 7.43973 5.18692 8.06638 4.04449 9.31968C2.71385 10.7863 2.73954 12.7596 2.86311 14.1062L2.87576 14.1996L2.99894 15.5995C3.01218 15.6128 3.03865 15.6395 3.06522 15.6528C3.50309 15.9462 3.9543 16.2395 4.43236 16.5062C4.61816 16.6262 4.81739 16.7328 5.01661 16.8395C7.28752 18.0928 9.7881 18.9328 12.3318 19.3461C12.4427 20.5994 12.9787 22.066 15.8987 22.066C18.8187 22.066 19.4026 20.6127 19.5053 19.3194C22.2285 18.8794 24.862 17.9328 27.2454 16.5462C27.3257 16.5062 27.3793 16.4662 27.4463 16.4262C28.0621 16.0795 28.6382 15.7062 29.2012 15.2929C29.2279 15.2795 29.2548 15.2529 29.2683 15.2262L29.3251 14.7462L29.3963 14.1196C29.4103 14.0396 29.4107 13.9729 29.4248 13.8796C29.5412 12.5329 29.5279 10.693 28.2846 9.30635ZM17.5517 18.4394C17.5414 19.8527 17.5399 20.0661 15.8999 20.0661C14.2599 20.0661 14.2617 19.8127 14.2716 18.4528L14.2838 16.7728H17.5638L17.5517 18.4394ZM12.0582 7.4264L12.0586 7.37306C12.0751 5.10646 12.0811 4.26648 15.1878 4.26648H17.2145C20.3211 4.26648 20.315 5.11979 20.2986 7.37306L20.2981 7.43973H12.0581L12.0582 7.4264Z"
                        fill="white"
                    />
                    <path
                        opacity="0.4"
                        d="M27.4466 16.3997C27.3796 16.4397 27.3127 16.4797 27.2457 16.5197C24.8623 17.9063 22.2289 18.8396 19.5056 19.2929C19.3896 20.5729 18.819 22.0395 15.899 22.0395C12.979 22.0395 12.4295 20.5862 12.3321 19.3196C9.78831 18.9196 7.28773 18.0796 5.01692 16.813C4.81769 16.7063 4.61847 16.5997 4.43267 16.4797C3.9546 16.213 3.5034 15.9197 3.06552 15.6264C3.03896 15.613 3.01248 15.5864 2.99925 15.573L3.74962 24.2528C4.01037 26.9061 5.08388 29.6393 10.9505 29.6393H21.1105C26.9772 29.6393 28.0904 26.9061 28.3897 24.2395L29.2953 15.1997C29.2818 15.2264 29.2549 15.253 29.2281 15.2664C28.6518 15.6797 28.0623 16.0664 27.4466 16.3997Z"
                        fill="white"
                    />
                </svg>
            );

        case "online":
            return (
                <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={className}
                >
                    <rect width="32" height="32" rx="16" fill="black" fillOpacity="0.1"/>
                    <path
                        opacity="0.4"
                        d="M29 7.80918V19H3V7.80918C3 4.02274 4.57787 2 7.74161 2H24.2584C27.4221 2 29 4.02274 29 7.80918Z"
                        fill="url(#paint0_linear_2665_50925)"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3.88889 18H28.1111V7.80918C28.1111 6.01843 27.7348 4.80007 27.1238 4.04286C26.5407 3.32013 25.6386 2.88889 24.2584 2.88889H7.74161C6.36143 2.88889 5.45934 3.32013 4.87618 4.04286C4.2652 4.80007 3.88889 6.01843 3.88889 7.80918V18ZM29 18V19V19.2643C29 22.4758 28.4364 24.0925 25.2613 24.0925H18.3033C17.5876 24.0925 17.002 24.6872 17.002 25.4141V26.696C17.002 27.4229 17.5876 28.0176 18.3033 28.0176H21.4525C21.986 28.0176 22.4284 28.467 22.4284 29.0088C22.4284 29.5507 21.986 30 21.4525 30H10.5866C10.04 30 9.61061 29.5507 9.61061 29.0088C9.61061 28.467 10.0531 28.0176 10.5866 28.0176H13.7357C14.4515 28.0176 15.037 27.4229 15.037 26.696V25.4141C15.037 24.6872 14.4515 24.0925 13.7357 24.0925H7.73874C4.57658 24.0925 3 22.489 3 19.2643V19V18V7.80918C3 4.02274 4.57787 2 7.74161 2H24.2584C27.4221 2 29 4.02274 29 7.80918V18Z"
                        fill="url(#paint1_linear_2665_50925)"
                    />
                    <path
                        d="M19.0752 9.74232L16.106 8.27649C15.3688 7.90784 14.4781 7.90784 13.7409 8.27649C13.0037 8.64514 12.5635 9.29467 12.5635 10.032V12.9724C12.5635 13.7009 13.0037 14.3592 13.7409 14.7279C14.1095 14.9122 14.519 15 14.9183 15C15.3279 15 15.7272 14.9122 16.0958 14.7279L19.065 13.2621C19.8021 12.8934 20.2424 12.2439 20.2424 11.5066C20.2629 10.7693 19.8226 10.111 19.0752 9.74232Z"
                        fill="white"
                    />
                    <defs>
                        <linearGradient id="paint0_linear_2665_50925" x1="16" y1="2" x2="16" y2="19"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#DD55A6"/>
                            <stop offset="1" stopColor="#E56EDA"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_2665_50925" x1="16" y1="2" x2="16" y2="30"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#DD55A6"/>
                            <stop offset="1" stopColor="#E56EDA"/>
                        </linearGradient>
                    </defs>
                </svg>
            );
        case "online-hover":
            return (
                <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={className}
                >
                    <rect width="32" height="32" rx="16" fill="black" fillOpacity="0.1"/>
                    <path
                        d="M28.0889 8.00795V17.9553H3.91113C3.91113 17.9553 3.91113 11.3737 3.91113 8.00795C3.91113 4.64223 4.63778 2.84424 8.32043 2.84424H23.6796C27.1934 2.84423 28.0889 4.64223 28.0889 8.00795Z"
                        fill="url(#paint0_linear_2665_50931)"
                        fillOpacity="0.4"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M28.1111 18.1111V7.80918C28.1111 6.01843 27.7348 4.80007 27.1238 4.04286C26.5407 3.32013 25.6386 2.88889 24.2584 2.88889H7.74161C6.36143 2.88889 5.45934 3.32013 4.87618 4.04286C4.2652 4.80007 3.88889 6.01843 3.88889 7.80918V18.1111H28.1111ZM29 7.80918C29 4.02274 27.4221 2 24.2584 2H7.74161C4.57787 2 3 4.02274 3 7.80918V19H29V7.80918Z"
                        fill="white"
                    />
                    <path
                        d="M3 18V19.2643C3 22.489 4.57658 24.0925 7.73874 24.0925H13.7357C14.4515 24.0925 15.037 24.6872 15.037 25.4141V26.696C15.037 27.4229 14.4515 28.0176 13.7357 28.0176H10.5866C10.0531 28.0176 9.61061 28.467 9.61061 29.0088C9.61061 29.5507 10.04 30 10.5866 30H21.4525C21.986 30 22.4284 29.5507 22.4284 29.0088C22.4284 28.467 21.986 28.0176 21.4525 28.0176H18.3033C17.5876 28.0176 17.002 27.4229 17.002 26.696V25.4141C17.002 24.6872 17.5876 24.0925 18.3033 24.0925H25.2613C28.4364 24.0925 29 22.4758 29 19.2643V18H3Z"
                        fill="url(#paint1_linear_2665_50931)"
                    />
                    <path
                        d="M19.0752 9.74232L16.106 8.27649C15.3688 7.90784 14.4781 7.90784 13.7409 8.27649C13.0037 8.64514 12.5635 9.29467 12.5635 10.032V12.9724C12.5635 13.7009 13.0037 14.3592 13.7409 14.7279C14.1095 14.9122 14.519 15 14.9183 15C15.3279 15 15.7272 14.9122 16.0958 14.7279L19.065 13.2621C19.8021 12.8934 20.2424 12.2439 20.2424 11.5066C20.2629 10.7693 19.8226 10.111 19.0752 9.74232Z"
                        fill="url(#paint2_linear_2665_50931)"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_2665_50931"
                            x1="16"
                            y1="2.84424"
                            x2="16"
                            y2="17.9553"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="white" stopOpacity="0.8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_2665_50931"
                            x1="16"
                            y1="18"
                            x2="16"
                            y2="30"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="white" stopOpacity="0.8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint2_linear_2665_50931"
                            x1="12.5635"
                            y1="15"
                            x2="13.4161"
                            y2="7.24903"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                    </defs>
                </svg>
            );

        default:
            return <></>;
    }
};

export default GameIcon;
