import {useNavigate} from "react-router-dom";
import Typo from "../../core/components/typo";
import Button from "../../core/components/button";
import __ from "../../core/utils/translate/translate";

const PrivacyScreen = () => {
    const navigate = useNavigate();

    const rules = [
        {
            title: __("How does Nojahan protect your information?"),

            description: __("Today, with the expansion of the flow of information and the daily use of the Internet and communication devices, the privacy of users has become one of the most serious concerns. The concern of many users is the preservation of personal information, as well as the mobile number, name and surname, and the password that they give us to register and enter the system. It must be said that we also understand the importance of this issue and users privacy is very important to us. Below you will find the privacy and confidentiality policy of users."),

        },
        {
            title: __("What information is received from you?"),

            description: __("To register in Nojahan application and website, it is necessary to provide us with information such as your first and last name, mobile phone number, and national code. Receiving your personal information is only for registering in the application and informing you about events."),

        },
        {
            title: __("How is your information protected?"),

            description: __("Although security is defined in relative terms, in the design of Nojahan application and website, it has been tried to consider the highest levels of hardware and software security. At the time of registration, credit code request, report registration, etc., all requests and services will be stored on our secure servers and protected by strong firewalls. Your personal information will also be transferred to our website encrypted in a secure HTTPS platform and kept for future interactions."),

        },
        {
            title: __("What will your information be used for?"),

            description: __("Both legally and ethically, we are obligated to protect your information and files. Your personal information is also stored in coded form only for future interactions so that you can easily enter the system and use the services provided in future visits."),

        },
        {
            title: __("Who has access to your information?"),

            description: __("Support colleagues have access to some of your information, which is used to provide better support and better service. All your personal information will be kept with us and will not be given to other people or organizations. It is obvious that legal authorities are able to access this information by submitting a court order. In general, we are obliged to protect and protect your information."),

        }
    ]


    return (
        <>
            {rules.map((rule, i) => (
                <div key={'privacyScreen' + 'rule' + rule?.id + '-' + i}>
                    <Typo as="h1" weight="bold">
                        {rule.title}
                    </Typo>
                    <Typo as="p">{rule.description}</Typo>
                </div>
            ))}

            <Button onClick={() => navigate(-1)} containerClassName="shrink-0">
                {__("Back")}
            </Button>
        </>
    );
};

export default PrivacyScreen;
