import { convertDigitsToWords } from "persian-helpers";
import RenderIf from "../render-if";
import Typo from "../typo";
import {ScoreBadge} from "../badge";
import __ from "../../utils/translate/translate";

const Rank = ({
  place = 1,
  title,
  scoreAmount,
  scoreType,
  titleType = "secondary",
  scoreColor = "primary",
}) => {
  const getTitle = ()=> {
    if (title) return title;
    else return `${__("Rank")} ${convertDigitsToWords(place, { ordinal: true })}`;
  };

  const getImageRank = ()=> {
    if (place === 1) return "/images/first-place.svg";
    else if (place === 2) return "/images/second-place.svg";
    else if (place === 3) return "/images/third-place.svg";
    else return "";
  };

  return (
    <div className="flex gap-1">
      <RenderIf cond={place <= 3}>
        <img src={getImageRank()} alt="rank" className="w-8" loading="lazy" />
      </RenderIf>
      <div className="flex flex-col items-center justify-between">
        <Typo type={titleType} size="xs" className="whitespace-nowrap">
          {getTitle()}
        </Typo>
        <ScoreBadge amount={scoreAmount} type={scoreType} color={scoreColor} />
      </div>
    </div>
  );
};

export default Rank;
