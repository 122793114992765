import {useContext, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {CreateOrJoinTeamContext} from "../../context";
import {TextArea, TextInput} from "../../../../../../../../core/components/input";
import Typo from "../../../../../../../../core/components/typo";
import RenderIf from "../../../../../../../../core/components/render-if";
import Button from "../../../../../../../../core/components/button";
import {useCreateTeam} from "../../../../../../api/team";
import {useGetTeams} from "../../../../../../../Auth/api/auth";
import __ from "../../../../../../../../core/utils/translate/translate";
import {useAuthContext} from "../../../../../../../Auth/provider/AuthProvider";

const ProfilePersonCreateTeam = ({onChange}) => {
    const navigate = useNavigate();
    const {setStatus} = useContext(CreateOrJoinTeamContext);
    const [error, setError] = useState(undefined);


    const refInputs = {
        name: useRef(null), description: useRef(null),
    };

    const {
        mutate: createTeam, isPendingCreateTeam, isErrorCreateTeam, errorCreateTeam, isSuccessCreateTeam, dataCreateTeam
    } = useCreateTeam();
    const {refetch} = useGetTeams({manual: true})


    const handleCreate = () => {
        if (refInputs.name.current.getValue().length < 2) {
            setError(__("Team name must be at least 2 letters"));
            return;
        }
        createTeam({
            name: refInputs.name.current.getValue(), description: refInputs.description.current.getValue()
        }, {
            onSuccess: (data) => {
                refetch().then(() => {
                    navigate(`/app/profile?view=team`);
                });

            }, onError: (error) => {
                setError(error);
            }
        })
    };

    return (<>
        <TextInput
            label={__("Team name")}
            name={__("Team name")}
            required
            rules={[{rule: "minLength", cond: 2}]}
            maxLength={32}
            ref={refInputs.name}
        />
        <div className="flex w-full flex-col gap-2">
            <TextArea
                label={__("About team")}
                name={__("About team")}
                maxLength={250}
                rules={[{rule: "minLength", cond: 2}]}
                ref={refInputs.description}
            />
            <Typo as="p" type="secondary" size="sm">
                {__("Write from your team so that the NOjahaniha get to know you better.")}
            </Typo>
        </div>
        <RenderIf cond={!!error}>
            <Typo className="bg-yellow/20" type="warning" size="sm">
                {error}
            </Typo>
        </RenderIf>
        <div className="flex w-full items-center gap-2">
            <Button onClick={handleCreate} isLoading={isPendingCreateTeam}>
                {__("Make team")}
            </Button>
            <Button type="light" onClick={() => onChange("without-team")}>
                {__("Not now!")}
            </Button>
        </div>
    </>);
};

export default ProfilePersonCreateTeam;
