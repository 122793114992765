const RoadMapIcon = ({icon, className}) => {
    switch (icon) {
        case "advanture-begin":
            return (
                <svg
                    width="54"
                    height="60"
                    viewBox="0 0 54 60"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={className}
                >
                    <g filter="url(#filter0_d_3007_57771)">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M41.9964 37.2877C41.9964 38.5313 43.0046 39.5395 44.2482 39.5395C45.4918 39.5395 46.5 38.5313 46.5 37.2877C46.5 36.0441 45.4918 35.0359 44.2482 35.0359C43.0046 35.0359 41.9964 36.0441 41.9964 37.2877ZM25.7834 51.2488H31.1877H35.6913H38.3935H40.195C42.6822 51.2488 44.6986 49.2324 44.6986 46.7452C44.6986 44.2579 42.6822 42.2415 40.195 42.2415C39.5039 42.2415 38.8491 42.3972 38.2639 42.6754C37.625 39.3497 34.6998 36.8372 31.1877 36.8372C27.7031 36.8372 24.7962 39.3107 24.1269 42.5977C23.2511 41.816 22.0957 41.3408 20.8294 41.3408C18.0934 41.3408 15.8754 43.5588 15.8754 46.2948C15.8754 49.0308 18.0934 51.2488 20.8294 51.2488H23.0812H25.7834ZM15.8754 41.3408C14.8805 41.3408 14.074 40.5343 14.074 39.5394C14.074 38.5445 14.8805 37.7379 15.8754 37.7379C16.8703 37.7379 17.6769 38.5445 17.6769 39.5394C17.6769 40.5343 16.8703 41.3408 15.8754 41.3408Z"
                            fill="url(#paint0_linear_3007_57771)"
                        />
                        <path
                            d="M14.5378 9.41005C14.5998 8.40692 14.9387 7.47843 15.2155 6.53201C15.5612 5.36636 16.0521 4.25613 16.5717 3.14883C16.7189 2.84476 16.9676 2.62647 17.136 2.34197C17.4983 2.20487 17.8605 2.06778 18.2228 1.93068C20.1866 2.30505 21.8081 3.41609 23.4625 4.43186C23.965 4.73839 24.4255 5.11604 24.913 5.45584C24.7364 5.53646 24.5644 5.62915 24.3833 5.69769C21.099 6.94069 17.8267 8.17912 14.5378 9.41005Z"
                            fill="url(#paint1_linear_3007_57771)"
                        />
                        <path
                            d="M14.5382 9.41084C17.8225 8.16784 21.0948 6.92941 24.3837 5.69849C24.5649 5.62994 24.7368 5.53725 24.9134 5.45664C26.5809 6.79863 28.0871 8.29822 29.3809 10.0024C29.9891 10.807 30.5611 11.6254 31.0139 12.5302C31.0763 12.6584 31.1748 12.7729 31.2492 12.8965C31.4407 13.2931 31.6247 13.7064 31.8163 14.103C32.6323 15.7121 33.1494 17.4066 33.5623 19.1544C33.7581 19.9634 33.8239 20.7939 34.1042 21.5709C34.0908 21.6449 34.082 21.7311 34.0927 21.796C34.2855 23.1442 34.4949 24.4999 34.6997 25.8435C34.8095 27.2645 34.9269 28.6688 35.0367 30.0898C34.839 30.1508 34.6366 30.1998 34.4434 30.2729C30.7002 31.6896 26.9691 33.1017 23.2305 34.5304C22.6004 33.9962 22.216 33.2724 21.6809 32.6609C21.2913 32.215 21.0645 31.6524 20.6041 31.2747C20.6067 31.1357 20.5415 31.0363 20.4716 30.9247C19.7401 29.7942 19.0131 28.6758 18.2937 27.5407C18.1494 27.3056 18.0219 27.0779 17.8776 26.8427C17.0531 25.4301 16.2694 24.0158 15.6652 22.4922C15.2299 21.4151 14.9729 20.2982 14.6267 19.2012C14.462 18.1459 14.2324 17.1015 14.1493 16.0429C13.9647 13.8775 14.1036 11.7277 14.4556 9.59387C14.4569 9.52438 14.5082 9.47739 14.5382 9.41084ZM27.1258 14.5122C26.455 12.4481 23.947 10.9275 21.3721 11.9296C18.9255 12.8693 17.9478 15.5021 18.7896 17.6533C19.6832 19.9781 22.1979 21.1512 24.4816 20.3283C26.8574 19.4567 28.072 16.7205 27.1258 14.5122Z"
                            fill="url(#paint2_linear_3007_57771)"
                        />
                        <path
                            d="M23.2429 34.5252C26.9861 33.1085 30.7127 31.6844 34.4559 30.2677C34.6491 30.1946 34.8514 30.1456 35.0492 30.0846C35.0706 31.2355 35.0512 32.388 34.9911 33.5422C34.9479 34.3036 34.6291 34.8106 33.8909 35.0486C33.3987 35.2072 32.937 35.4096 32.454 35.5924C31.2344 36.0539 30.0149 36.5155 28.7953 36.977C28.2519 37.1827 27.704 37.3763 27.1727 37.5773C26.6414 37.7784 26.1441 37.7045 25.7365 37.3206C24.8623 36.5063 24.0756 35.6312 23.3235 34.7017C23.2644 34.655 23.2658 34.5855 23.2429 34.5252Z"
                            fill="url(#paint3_linear_3007_57771)"
                        />
                        <path
                            d="M28.7831 36.9817C30.0027 36.5202 31.2222 36.0586 32.4418 35.597C33.3072 35.987 33.9889 36.5845 34.4914 37.4015C35.5387 39.5853 35.2076 41.6285 33.8593 43.5737C33.6426 43.8765 33.3408 43.9907 32.9779 43.9073C30.9941 43.4439 29.5433 42.3096 28.6888 40.453C28.5688 40.2087 28.5332 39.9324 28.4614 39.6698C28.2275 38.7235 28.4335 37.8453 28.7831 36.9817Z"
                            fill="url(#paint4_linear_3007_57771)"
                        />
                        <path
                            d="M34.7689 24.5871C34.4841 23.798 33.754 19.9505 33.5749 19.149C33.1619 17.4013 32.6448 15.7067 31.8288 14.0977C31.6252 13.7056 31.4412 13.2923 31.2617 12.8911C32.1311 13.0726 32.927 13.4613 33.7483 13.7713C36.3077 14.7344 38.3145 16.3894 39.7159 18.7428C39.8252 18.9222 39.9104 19.1107 39.9955 19.2992C39.8478 20.4997 39.5813 21.8336 39.0199 22.1012C37.6633 22.7112 36.1546 24.0902 34.7689 24.5871Z"
                            fill="url(#paint5_linear_3007_57771)"
                        />
                        <path
                            d="M34.1166 21.5655C35.5022 21.0686 36.897 20.596 38.237 19.9784C38.7983 19.7108 39.4037 19.5231 39.9787 19.2917C40.6431 20.1717 41.0555 21.1884 41.3667 22.2296C41.5559 22.8755 41.1075 23.5143 40.3135 23.7872C38.4311 24.4306 36.5732 25.1751 34.7 25.8427C34.4952 24.499 34.2979 23.1388 34.0931 21.7951C34.0944 21.7256 34.1078 21.6516 34.1166 21.5655Z"
                            fill="url(#paint6_linear_3007_57771)"
                        />
                        <path
                            d="M14.9487 20.2931C14.8427 19.9239 14.7367 19.5547 14.6213 19.189C14.4279 19.3601 14.3301 19.5869 14.2346 19.8083C14.1821 19.9302 14.1302 20.0505 14.0635 20.159C13.1673 21.6572 12.4495 23.2258 12.1508 24.9532C11.8563 26.5824 11.8927 28.2105 12.3445 29.8056C12.3445 29.8056 12.4437 30.779 12.3516 29.817C12.3468 29.8187 12.342 29.8204 12.3371 29.8221C12.4061 30.6285 12.6625 31.3976 12.9199 32.1696C12.9483 32.2549 12.9767 32.3401 13.0049 32.4254C13.3674 33.4197 13.9711 33.7017 14.9658 33.339C15.9061 32.997 16.8423 32.653 17.7785 32.309C18.7147 31.965 19.651 31.6211 20.5913 31.279C20.5939 31.1444 20.5327 31.0468 20.4654 30.9394C20.4632 30.936 20.4611 30.9325 20.4589 30.929C20.0931 30.3637 19.7315 29.8004 19.3699 29.237C19.2873 29.1084 19.2048 28.9799 19.1223 28.8513C19.1292 28.8484 19.136 28.8457 19.1427 28.8432C19.0706 28.7256 18.789 28.2812 18.5075 27.8368C18.2259 27.3925 17.9443 26.9481 17.8722 26.8305C17.0523 25.4299 16.276 23.999 15.6597 22.48C15.3726 21.77 15.1606 21.0315 14.9487 20.2931Z"
                            fill="url(#paint7_linear_3007_57771)"
                        />
                        <path
                            d="M27.1263 14.5117C28.0725 16.72 26.858 19.4563 24.4822 20.3278C22.203 21.1628 19.6838 19.9776 18.7901 17.6529C17.9559 15.485 18.926 12.8689 21.3726 11.9291C23.9475 10.927 26.4556 12.4476 27.1263 14.5117Z"
                            fill="url(#paint8_linear_3007_57771)"
                        />
                    </g>
                    <defs>
                        <filter
                            id="filter0_d_3007_57771"
                            x="7.96094"
                            y="1.93066"
                            width="42.5391"
                            height="57.3181"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3007_57771"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3007_57771"
                                     result="shape"/>
                        </filter>
                        <linearGradient
                            id="paint0_linear_3007_57771"
                            x1="30.287"
                            y1="35.0359"
                            x2="30.287"
                            y2="51.2489"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="#EEE6F8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_3007_57771"
                            x1="14.5424"
                            y1="9.42211"
                            x2="12.6577"
                            y2="3.32704"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                        <linearGradient
                            id="paint2_linear_3007_57771"
                            x1="16.7872"
                            y1="22.6993"
                            x2="32.3719"
                            y2="16.8011"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#EEE6F8"/>
                            <stop offset="1" stopColor="white"/>
                        </linearGradient>
                        <linearGradient
                            id="paint3_linear_3007_57771"
                            x1="24.7434"
                            y1="38.5136"
                            x2="23.2335"
                            y2="33.972"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                        <linearGradient
                            id="paint4_linear_3007_57771"
                            x1="30.4189"
                            y1="45.0413"
                            x2="35.5748"
                            y2="36.2586"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#F27349"/>
                            <stop offset="1" stopColor="#FF9673"/>
                        </linearGradient>
                        <linearGradient
                            id="paint5_linear_3007_57771"
                            x1="32.7287"
                            y1="26.0622"
                            x2="33.6707"
                            y2="11.4686"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                        <linearGradient
                            id="paint6_linear_3007_57771"
                            x1="32.7287"
                            y1="26.0622"
                            x2="33.6707"
                            y2="11.4686"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                        <linearGradient
                            id="paint7_linear_3007_57771"
                            x1="12.6379"
                            y1="33.6767"
                            x2="14.1202"
                            y2="18.1944"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                        <linearGradient
                            id="paint8_linear_3007_57771"
                            x1="20.3888"
                            y1="21.8772"
                            x2="25.6103"
                            y2="10.3717"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#F27349"/>
                            <stop offset="1" stopColor="#FF9673"/>
                        </linearGradient>
                    </defs>
                </svg>
            );
        case "advanture-complete-profile":
            return (
                <svg
                    width="56"
                    height="56"
                    viewBox="0 0 56 56"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={className}
                >
                    <g filter="url(#filter0_d_3007_57757)">
                        <path
                            d="M41.7501 14.596L45.1765 32.1187C46.3859 38.3032 44.1123 43.0333 36.9261 44.4499L20.5003 47.6878C13.3141 49.1044 9.42803 45.5885 8.21868 39.404L4.7922 21.8813C3.58285 15.6968 5.85645 10.9667 13.0427 9.55012L29.4684 6.31219C36.6547 4.8956 40.5407 8.41148 41.7501 14.596Z"
                            fill="url(#paint0_linear_3007_57757)"
                        />
                        <path
                            d="M13.6612 13.795C12.8175 13.9606 11.9805 13.3983 11.8149 12.5546L10.6028 6.38124C10.4372 5.53754 10.9995 4.70053 11.8432 4.53488C12.6868 4.36923 13.5239 4.93152 13.6895 5.77522L14.9016 11.9486C15.0672 12.7923 14.5049 13.6293 13.6612 13.795Z"
                            fill="url(#paint1_linear_3007_57757)"
                        />
                        <path
                            d="M30.1231 10.563C29.2794 10.7287 28.4424 10.1664 28.2768 9.32269L27.0647 3.1493C26.8991 2.30561 27.4614 1.46859 28.3051 1.30295C29.1488 1.1373 29.9858 1.69959 30.1514 2.54328L31.3635 8.71667C31.5291 9.56037 30.9668 10.3974 30.1231 10.563Z"
                            fill="url(#paint2_linear_3007_57757)"
                        />
                        <path
                            d="M28.4317 23.718L16.0849 26.1421C15.2412 26.3077 14.4042 25.7454 14.2386 24.9017C14.0729 24.058 14.6352 23.221 15.4789 23.0554L27.8257 20.6313C28.6694 20.4657 29.5064 21.0279 29.672 21.8716C29.8377 22.7153 29.2754 23.5523 28.4317 23.718Z"
                            fill="url(#paint3_linear_3007_57757)"
                        />
                        <path
                            d="M24.2788 35.2191L18.1054 36.4311C17.2617 36.5968 16.4247 36.0345 16.2591 35.1908C16.0934 34.3471 16.6557 33.5101 17.4994 33.3444L23.6728 32.1324C24.5165 31.9668 25.3535 32.529 25.5192 33.3727C25.6848 34.2164 25.1225 35.0535 24.2788 35.2191Z"
                            fill="url(#paint4_linear_3007_57757)"
                        />
                        <path
                            d="M47.3893 26.0883C45.5722 24.6388 43.2652 23.7812 40.7539 23.7812C38.2427 23.7812 35.8947 24.6591 34.0572 26.1495C31.5868 28.0891 30.0352 31.1313 30.0352 34.5C30.0352 36.5008 30.6068 38.42 31.5868 40.0125C32.3423 41.2579 33.3222 42.34 34.486 43.1771C36.2418 44.4633 38.406 45.2188 40.7539 45.2188C43.4693 45.2188 45.9193 44.2183 47.7977 42.5442C48.6348 41.85 49.3493 40.9925 49.921 40.0125C50.901 38.42 51.4727 36.5008 51.4727 34.5C51.4727 31.0904 49.8802 28.0483 47.3893 26.0883Z"
                            fill="url(#paint5_linear_3007_57757)"
                        />
                        <path
                            d="M40.1539 39.3068C39.8631 39.3639 39.5531 39.3237 39.2825 39.1601L36.5767 37.524C36.0535 37.2077 35.9224 36.5399 36.2871 36.0493C36.6518 35.5587 37.3866 35.4144 37.9097 35.7307L39.6595 36.7887L44.0234 30.9182C44.3881 30.4276 45.1229 30.2834 45.646 30.5997C46.1692 30.916 46.3003 31.5838 45.9356 32.0744L40.9051 38.8415C40.7318 39.0922 40.4448 39.2497 40.1539 39.3068Z"
                            fill="white"
                        />
                    </g>
                    <defs>
                        <filter
                            id="filter0_d_3007_57757"
                            x="0.484375"
                            y="1.27399"
                            width="54.9883"
                            height="54.726"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3007_57757"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3007_57757"
                                     result="shape"/>
                        </filter>
                        <linearGradient
                            id="paint0_linear_3007_57757"
                            x1="21.2559"
                            y1="7.93136"
                            x2="28.7715"
                            y2="46.0576"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="#EEE6F8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_3007_57757"
                            x1="12.1179"
                            y1="14.098"
                            x2="13.6382"
                            y2="4.2708"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                        <linearGradient
                            id="paint2_linear_3007_57757"
                            x1="28.5798"
                            y1="10.866"
                            x2="30.1001"
                            y2="1.03887"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                        <linearGradient
                            id="paint3_linear_3007_57757"
                            x1="14.5416"
                            y1="26.4451"
                            x2="14.5633"
                            y2="20.2761"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#F27349"/>
                            <stop offset="1" stopColor="#FF9673"/>
                        </linearGradient>
                        <linearGradient
                            id="paint4_linear_3007_57757"
                            x1="16.5621"
                            y1="36.7342"
                            x2="17.3233"
                            y2="30.8145"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#F27349"/>
                            <stop offset="1" stopColor="#FF9673"/>
                        </linearGradient>
                        <linearGradient
                            id="paint5_linear_3007_57757"
                            x1="30.0352"
                            y1="45.2188"
                            x2="32.8929"
                            y2="21.539"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                    </defs>
                </svg>
            );

        case "advanture-level-1":
            return (
                <svg
                    width="57"
                    height="57"
                    viewBox="0 0 57 57"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={className}
                >
                    <g filter="url(#filter0_d_3007_57745)">
                        <path
                            d="M40.8536 11.0704C38.7055 9.68183 35.6916 9.50732 31.6756 10.5834L31.2077 10.7088L31.1868 10.6308C30.3092 7.3556 29.2227 3.30059 21.8925 5.26472L18.9292 6.05872C11.599 8.02285 12.6908 12.0974 13.5631 15.3531L13.5893 15.4505L13.1214 15.5759C9.08586 16.6572 6.58255 18.31 5.41649 20.5866C4.06107 23.2482 4.89267 26.1179 5.61522 28.0346L5.67128 28.1659L6.41472 30.1606C6.43944 30.1749 6.48888 30.2035 6.53309 30.2125C7.29136 30.469 8.06912 30.7203 8.87542 30.9222C9.19537 31.0245 9.52959 31.1021 9.86381 31.1797C13.6885 32.119 17.6827 32.3651 21.5682 31.9717C22.2347 33.7573 23.6086 35.6876 27.8781 34.5436C32.1476 33.3996 32.4165 31.05 32.0462 29.1224C35.8509 27.4134 39.3205 25.0002 42.2474 22.0428C42.3487 21.953 42.411 21.8736 42.4928 21.789C43.2538 21.0418 43.9458 20.2714 44.6027 19.4476C44.6365 19.4177 44.665 19.3682 44.6741 19.324L44.564 18.6013L44.416 17.6589C44.4041 17.5367 44.378 17.4392 44.3609 17.2975C43.9893 15.2867 43.2294 12.6068 40.8536 11.0704ZM16.351 14.6061C15.4629 11.2919 15.1338 10.0637 19.6762 8.84654L22.6395 8.05254C27.1819 6.83541 27.5162 8.0831 28.399 11.3778L28.4251 11.4753L16.3771 14.7035L16.351 14.6061Z"
                            fill="url(#paint0_linear_3007_57745)"
                        />
                        <path
                            d="M27.0749 31.6241C29.4728 30.9816 29.3892 30.6697 28.8355 28.6032L28.1825 26.1663L23.3867 27.4513L24.0449 29.9077C24.5777 31.8962 24.677 32.2666 27.0749 31.6241Z"
                            fill="url(#paint1_linear_3007_57745)"
                        />
                        <path
                            d="M42.4822 21.7498C42.4004 21.8344 42.3186 21.919 42.2368 22.0036C39.3099 24.961 35.835 27.3547 32.0356 29.0832C32.3811 30.9965 32.1369 33.3604 27.8675 34.5044C23.598 35.6484 22.2098 33.7428 21.5576 31.9325C17.6773 32.3454 13.6831 32.0993 9.85316 31.1405C9.51894 31.0629 9.18472 30.9853 8.86478 30.8829C8.05847 30.6811 7.28071 30.4298 6.52245 30.1733C6.47823 30.1642 6.4288 30.1357 6.40408 30.1214L10.9939 42.4942C12.4429 46.264 15.1123 49.8322 23.6902 47.5338L38.5456 43.5533C47.1235 41.2548 47.6512 36.83 47.0159 32.8212L44.7024 19.2744C44.6934 19.3186 44.6648 19.368 44.6311 19.398C43.9547 20.2269 43.2484 21.0221 42.4822 21.7498Z"
                            fill="url(#paint2_linear_3007_57745)"
                        />
                        <path
                            d="M50.0614 35.1717L44.6485 32.0306C43.3046 31.2406 41.6808 31.2406 40.3369 32.0306C38.993 32.8206 38.1904 34.2124 38.1904 35.7923V42.0933C38.1904 43.6544 38.993 45.0651 40.3369 45.855C41.0088 46.25 41.7554 46.4381 42.4834 46.4381C43.23 46.4381 43.9579 46.25 44.6299 45.855L50.0427 42.714C51.3866 41.924 52.1892 40.5322 52.1892 38.9522C52.2265 37.3723 51.4239 35.9616 50.0614 35.1717Z"
                            fill="url(#paint3_linear_3007_57745)"
                        />
                    </g>
                    <defs>
                        <filter
                            id="filter0_d_3007_57745"
                            x="0.692383"
                            y="4.74945"
                            width="55.498"
                            height="51.5098"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3007_57745"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3007_57745"
                                     result="shape"/>
                        </filter>
                        <linearGradient
                            id="paint0_linear_3007_57745"
                            x1="4.69249"
                            y1="34.8874"
                            x2="7.73968"
                            y2="1.38962"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_3007_57745"
                            x1="24.677"
                            y1="32.2666"
                            x2="28.2072"
                            y2="26.1806"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#F27349"/>
                            <stop offset="1" stopColor="#FF9673"/>
                        </linearGradient>
                        <linearGradient
                            id="paint2_linear_3007_57745"
                            x1="29.9228"
                            y1="45.7953"
                            x2="43.7376"
                            y2="21.1689"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#EEE6F8"/>
                            <stop offset="1" stopColor="white"/>
                        </linearGradient>
                        <linearGradient
                            id="paint3_linear_3007_57745"
                            x1="38.1904"
                            y1="46.4381"
                            x2="53.1548"
                            y2="32.4714"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#F27349"/>
                            <stop offset="1" stopColor="#FF9673"/>
                        </linearGradient>
                    </defs>
                </svg>
            );
        case "advanture-join-to-team":
            return (
                <svg
                    width="61"
                    height="55"
                    viewBox="0 0 61 55"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={className}
                >
                    <g opacity="0.8" filter="url(#filter0_d_3007_57856)">
                        <path
                            d="M23.4582 2.74828C28.681 0.964302 34.3773 3.75992 36.1612 8.98265C37.9112 14.1057 35.3207 19.6206 30.1593 21.584C29.993 21.6185 29.8335 21.673 29.7207 21.7338C29.6808 21.7474 29.6609 21.7542 29.621 21.7678C29.6011 21.7747 29.6011 21.7747 29.5812 21.7815C24.4168 23.3452 18.9937 20.5675 17.2239 15.4513C15.4399 10.2285 18.2355 4.53226 23.4582 2.74828Z"
                            fill="url(#paint0_linear_3007_57856)"
                        />
                        <path
                            d="M22.3193 31.9445C26.6144 26.337 35.6844 23.2389 42.5523 25.0333C45.6627 25.8629 47.8412 27.6787 48.6787 30.1306C49.5162 32.5825 48.897 35.3316 46.9573 37.864C44.8066 40.6911 41.4588 42.8809 37.7909 44.1337C34.123 45.3866 30.1351 45.7026 26.7043 44.782C23.6138 43.9456 21.4422 42.1497 20.5979 39.6779C19.7803 37.2192 20.3795 34.4769 22.3193 31.9445Z"
                            fill="url(#paint1_linear_3007_57856)"
                        />
                        <path
                            d="M5.18726 20.8766C6.18927 24.8527 10.0977 27.3019 14.0617 26.4598C14.0817 26.453 14.0817 26.453 14.1016 26.4462L14.1614 26.4258C14.281 26.3849 14.4006 26.3441 14.5139 26.3499C16.4815 25.7891 18.0446 24.5651 18.9921 22.9727C16.3124 21.8401 14.1967 19.4909 13.4145 16.4191C13.0025 14.7568 13.0568 13.091 13.465 11.5492C12.5782 11.4292 11.6194 11.4896 10.6951 11.7162C6.67227 12.7119 4.17794 16.8139 5.18726 20.8766Z"
                            fill="url(#paint2_linear_3007_57856)"
                        />
                        <path
                            d="M7.49864 40.678C8.31859 42.5571 10.1265 43.8094 12.5877 44.2375C14.9556 44.653 17.6444 44.3133 20.1157 43.4024C18.2379 42.5969 16.8491 41.2682 16.104 39.6084C15.0604 37.2046 15.4262 34.4308 17.1315 31.7335C18.1063 30.2208 19.3751 28.8525 20.8584 27.6558C16.0172 27.8848 10.7684 30.6349 8.29928 34.5501C6.96922 36.6517 6.66556 38.8256 7.49864 40.678Z"
                            fill="url(#paint3_linear_3007_57856)"
                        />
                        <path
                            d="M52.5329 27.2402C50.7158 25.7906 48.4087 24.9331 45.8975 24.9331C43.3862 24.9331 41.0383 25.811 39.2008 27.3014C36.7304 29.241 35.1787 32.2831 35.1787 35.6519C35.1787 37.6527 35.7504 39.5719 36.7304 41.1644C37.4858 42.4098 38.4658 43.4919 39.6295 44.3289C41.3854 45.6152 43.5495 46.3706 45.8975 46.3706C48.6129 46.3706 51.0629 45.3702 52.9412 43.696C53.7783 43.0019 54.4929 42.1444 55.0645 41.1644C56.0445 39.5719 56.6162 37.6527 56.6162 35.6519C56.6162 32.2423 55.0237 29.2002 52.5329 27.2402Z"
                            fill="url(#paint4_linear_3007_57856)"
                        />
                        <path
                            d="M45.2975 40.4587C45.0066 40.5158 44.6967 40.4755 44.4261 40.3119L41.7202 38.6759C41.1971 38.3596 41.066 37.6918 41.4307 37.2012C41.7954 36.7105 42.5302 36.5663 43.0533 36.8826L44.8031 37.9406L49.167 32.0701C49.5317 31.5795 50.2665 31.4352 50.7896 31.7515C51.3127 32.0678 51.4438 32.7356 51.0791 33.2262L46.0487 39.9933C45.8753 40.2441 45.5883 40.4016 45.2975 40.4587Z"
                            fill="white"
                        />
                    </g>
                    <defs>
                        <filter
                            id="filter0_d_3007_57856"
                            x="0.962891"
                            y="2.20996"
                            width="59.6533"
                            height="52.1606"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3007_57856"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3007_57856"
                                     result="shape"/>
                        </filter>
                        <linearGradient
                            id="paint0_linear_3007_57856"
                            x1="23.4581"
                            y1="2.74847"
                            x2="29.9199"
                            y2="21.6659"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="#EEE6F8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_3007_57856"
                            x1="31.4957"
                            y1="25.7047"
                            x2="37.7907"
                            y2="44.1338"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="#EEE6F8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint2_linear_3007_57856"
                            x1="4.96289"
                            y1="26.6253"
                            x2="20.0559"
                            y2="12.6365"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#F27349"/>
                            <stop offset="1" stopColor="#FF9673"/>
                        </linearGradient>
                        <linearGradient
                            id="paint3_linear_3007_57856"
                            x1="7.03516"
                            y1="44.413"
                            x2="23.4867"
                            y2="30.8418"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#F27349"/>
                            <stop offset="1" stopColor="#FF9673"/>
                        </linearGradient>
                        <linearGradient
                            id="paint4_linear_3007_57856"
                            x1="35.1788"
                            y1="46.3706"
                            x2="38.0364"
                            y2="22.6909"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                    </defs>
                </svg>
            );

        case "advanture-level-2":
            return (
                <svg width="57" height="55" viewBox="0 0 57 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.8" filter="url(#filter0_d_3007_57844)">
                        <path
                            d="M23.0892 3.51124C27.7849 1.817 32.9064 4.47199 34.5103 9.43199C36.0837 14.2973 33.7546 19.5348 29.114 21.3994C28.9645 21.4322 28.8212 21.484 28.7198 21.5417C28.6839 21.5546 28.666 21.5611 28.6301 21.574C28.6122 21.5805 28.6122 21.5805 28.5943 21.587C23.951 23.072 19.0753 20.4341 17.484 15.5752C15.88 10.6152 18.3936 5.20547 23.0892 3.51124Z"
                            fill="url(#paint0_linear_3007_57844)"
                        />
                        <path
                            d="M21.9472 31.139C25.9737 25.7443 34.4766 22.7637 40.9151 24.49C43.831 25.2882 45.8732 27.0351 46.6583 29.394C47.4435 31.7528 46.8631 34.3977 45.0446 36.834C43.0284 39.5538 39.8899 41.6605 36.4514 42.8658C33.0129 44.0712 29.2743 44.3752 26.058 43.4895C23.1608 42.6848 21.125 40.9571 20.3335 38.5791C19.567 36.2137 20.1287 33.5754 21.9472 31.139Z"
                            fill="url(#paint1_linear_3007_57844)"
                        />
                        <path
                            d="M6.20791 20.6829C7.13641 24.3605 10.7581 26.6258 14.4314 25.8469C14.4498 25.8407 14.4498 25.8407 14.4683 25.8344L14.5237 25.8155C14.6345 25.7777 14.7454 25.7399 14.8504 25.7453C16.6737 25.2266 18.122 24.0945 19 22.6216C16.5169 21.5741 14.5564 19.4013 13.8316 16.5601C13.4498 15.0226 13.5001 13.4819 13.8784 12.0559C13.0566 11.9449 12.1682 12.0007 11.3117 12.2104C7.58398 13.1313 5.27263 16.9253 6.20791 20.6829Z"
                            fill="url(#paint2_linear_3007_57844)"
                        />
                        <path
                            d="M7.46941 39.4338C8.29984 41.228 10.1308 42.4237 12.6236 42.8325C15.0217 43.2291 17.7448 42.9048 20.2478 42.0351C18.3459 41.266 16.9394 39.9974 16.1848 38.4125C15.1278 36.1174 15.4983 33.4688 17.2255 30.8935C18.2126 29.4492 19.4977 28.1427 21 27C16.0969 27.2187 10.7809 29.8445 8.28028 33.5828C6.93322 35.5894 6.62568 37.6651 7.46941 39.4338Z"
                            fill="url(#paint3_linear_3007_57844)"
                        />
                        <path
                            d="M48.8659 27.3071C47.0488 25.8575 44.7417 25 42.2305 25C39.7192 25 37.3713 25.8779 35.5338 27.3683C33.0634 29.3079 31.5117 32.35 31.5117 35.7188C31.5117 37.7196 32.0834 39.6388 33.0634 41.2313C33.8188 42.4767 34.7988 43.5588 35.9625 44.3958C37.7184 45.6821 39.8826 46.4375 42.2305 46.4375C44.9459 46.4375 47.3959 45.4371 49.2742 43.7629C50.1113 43.0688 50.8259 42.2113 51.3975 41.2313C52.3775 39.6388 52.9492 37.7196 52.9492 35.7187C52.9492 32.3092 51.3567 29.2671 48.8659 27.3071Z"
                            fill="url(#paint4_linear_3007_57844)"
                        />
                        <path
                            d="M38.5918 39.3383C37.8169 39.2995 37.049 39.0027 36.4344 38.4372C35.0387 37.1533 34.9427 34.9574 36.2309 33.5572L38.3566 31.2464C39.6405 29.8508 41.841 29.759 43.2367 31.043C44.6323 32.3269 44.7283 34.5227 43.4401 35.923L42.3773 37.0784"
                            stroke="white"
                            strokeWidth="1.8"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M46.0148 31.2693C46.7897 31.3081 47.5576 31.605 48.1722 32.1704C49.5679 33.4543 49.6639 35.6502 48.3757 37.0505L46.25 39.3612C44.966 40.7568 42.7656 40.8486 41.3699 39.5647C39.9742 38.2807 39.8782 36.0849 41.1664 34.6846L42.2293 33.5292"
                            stroke="white"
                            strokeWidth="1.8"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </g>
                    <defs>
                        <filter
                            id="filter0_d_3007_57844"
                            x="2"
                            y="3"
                            width="54.9512"
                            height="51.4375"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3007_57844"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3007_57844"
                                     result="shape"/>
                        </filter>
                        <linearGradient
                            id="paint0_linear_3007_57844"
                            x1="23.0891"
                            y1="3.51142"
                            x2="29.4938"
                            y2="21.2625"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="#EEE6F8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_3007_57844"
                            x1="30.5498"
                            y1="25.136"
                            x2="36.7305"
                            y2="42.7679"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="#EEE6F8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint2_linear_3007_57844"
                            x1="6"
                            y1="26"
                            x2="19.9616"
                            y2="13.0356"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#F27349"/>
                            <stop offset="1" stopColor="#FF9673"/>
                        </linearGradient>
                        <linearGradient
                            id="paint3_linear_3007_57844"
                            x1="7"
                            y1="43"
                            x2="22.8584"
                            y2="29.1239"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#F27349"/>
                            <stop offset="1" stopColor="#FF9673"/>
                        </linearGradient>
                        <linearGradient
                            id="paint4_linear_3007_57844"
                            x1="31.5118"
                            y1="46.4375"
                            x2="34.3694"
                            y2="22.7578"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                    </defs>
                </svg>
            );
        case "advanture-level-3":
            return (
                <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.8">
                        <path
                            d="M8.66526 24.4037C4.22092 40.1656 14.6948 51.0554 23.7441 49.8149C27.1319 49.8053 27.9043 49.2446 31.6805 48.7269C34.3777 48.3571 43.5143 43.2751 41.1979 29.9556C38.758 15.9256 24.5499 17.7331 23.5759 4.98579C23.5058 4.06887 22.3386 3.55427 21.6751 4.19095C16.2932 9.35497 11.234 14.0705 13.7623 27.5223C13.9251 28.3885 13.054 28.9679 12.5067 28.2772C11.7824 27.3632 11.1499 26.1376 10.656 24.522C10.3569 23.5438 8.94287 23.4191 8.66526 24.4037Z"
                            fill="url(#paint0_linear_3785_37797)"
                        />
                        <path
                            d="M32.5456 30.3169C36.7208 38.2432 33.9463 44.3636 30.9679 47.676C30.8623 47.7935 30.7307 47.8811 30.5806 47.9304C28.9299 48.4723 26.244 48.7668 24.43 49.1305C23.0732 49.4025 17.7838 48.2106 16.4799 40.4509C15.1864 32.7526 20.5049 29.9013 19.4843 23.4541C19.3372 22.525 20.3573 21.6824 21.1378 22.2074C24.08 24.1863 26.7892 27.0207 28.0139 33.3275C28.1869 34.2188 29.1051 34.3714 29.3755 33.5046C29.6121 32.7462 29.8374 31.8762 30.0081 30.9836C30.2076 29.9403 32.0506 29.377 32.5456 30.3169Z"
                            fill="url(#paint1_linear_3785_37797)"
                        />
                        <path
                            d="M42.167 47C47.966 47 52.667 42.299 52.667 36.5C52.667 30.701 47.966 26 42.167 26C36.368 26 31.667 30.701 31.667 36.5C31.667 42.299 36.368 47 42.167 47Z"
                            fill="url(#paint2_linear_3785_37797)"
                        />
                        <path
                            d="M45.8728 35.4185L42.7228 32.2685C42.4183 31.964 41.9143 31.964 41.6098 32.2685L38.4598 35.4185C38.1553 35.723 38.1553 36.227 38.4598 36.5315C38.7643 36.836 39.2683 36.836 39.5728 36.5315L41.3788 34.7255V40.175C41.3788 40.6055 41.7358 40.9625 42.1663 40.9625C42.5968 40.9625 42.9538 40.6055 42.9538 40.175V34.7255L44.7598 36.5315C44.9173 36.689 45.1168 36.7625 45.3163 36.7625C45.5158 36.7625 45.7153 36.689 45.8728 36.5315C46.1773 36.227 46.1773 35.723 45.8728 35.4185Z"
                            fill="white"
                        />
                    </g>
                    <defs>
                        <linearGradient
                            id="paint0_linear_3785_37797"
                            x1="10.1573"
                            y1="51.8796"
                            x2="50.1009"
                            y2="14.1812"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#F27349"/>
                            <stop offset="1" stopColor="#FF9673"/>
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_3785_37797"
                            x1="24.9572"
                            y1="20.2976"
                            x2="17.127"
                            y2="46.1979"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="#EEE6F8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint2_linear_3785_37797"
                            x1="31.667"
                            y1="47"
                            x2="34.4664"
                            y2="23.8036"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                    </defs>
                </svg>
            );
        case "advanture-level-4":
            return (
                <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.8" filter="url(#filter0_d_3007_57733)">
                        <path
                            d="M18.5907 33.4288L27.2078 46.4446C28.2604 48.0307 29.7179 48.1804 30.4523 46.7537L32.9774 41.8538C33.178 41.4414 33.7316 41.0739 34.1895 41.0493L39.7004 40.6157C41.2738 40.4854 41.7286 39.0919 40.6759 37.5058L31.8334 24.1827"
                            fill="url(#paint0_linear_3007_57733)"
                        />
                        <path
                            d="M10.5704 27.5487L6.28573 42.5589C5.76531 44.39 6.59931 45.5945 8.16082 45.2247L13.5252 43.9568C13.9689 43.841 14.6082 44.0227 14.9246 44.3545L18.8376 48.2592C19.9597 49.3697 21.3141 48.8091 21.8345 46.978L26.206 31.5966"
                            fill="url(#paint1_linear_3007_57733)"
                        />
                        <path
                            d="M20.4835 38.4807C21.76 39.4413 23.7447 39.3174 24.9094 38.2044L27.5353 35.6426C28.0337 35.153 28.9942 34.7227 29.6967 34.6789L32.6825 34.4925C34.5442 34.3763 35.9768 32.7529 35.8606 30.8912L35.6742 27.9055C35.6315 27.2205 35.9378 26.1964 36.3616 25.641L38.577 22.7168C39.5376 21.4402 39.4137 19.4556 38.3007 18.2908L35.7388 15.665C35.2493 15.1666 34.819 14.2061 34.7752 13.5035L34.5888 10.5177C34.4726 8.65603 32.8492 7.22342 30.9875 7.33964L28.0017 7.52603C27.3167 7.56879 26.2926 7.2624 25.7372 6.83865L22.813 4.6233C21.5365 3.66271 19.5518 3.78661 18.3871 4.89959L15.7612 7.46142C15.2628 7.95096 14.3023 8.38124 13.5998 8.4251L10.5613 8.61478C8.69958 8.731 7.26697 10.3544 7.38319 12.2161L7.57068 15.2195C7.61344 15.9044 7.30603 16.911 6.89984 17.4653L4.7031 20.4058C3.76009 21.6813 3.88288 23.6484 4.97721 24.7967L7.52261 27.4413C7.99458 27.9408 8.4248 28.9013 8.46756 29.5863L8.65504 32.5895C8.77126 34.4512 10.3946 35.8838 12.2564 35.7676L15.2948 35.5779C15.9798 35.5352 17.004 35.8416 17.5594 36.2653L20.4835 38.4807Z"
                            fill="url(#paint2_linear_3007_57733)"
                        />
                        <path
                            d="M22.5064 33.4921C29.0404 33.0842 34.0067 27.4566 33.5988 20.9226C33.1909 14.3885 27.5633 9.42225 21.0292 9.83015C14.4952 10.2381 9.52891 15.8656 9.93681 22.3997C10.3447 28.9338 15.9723 33.9 22.5064 33.4921Z"
                            fill="white"
                        />
                        <path
                            d="M22.5986 14.0628L24.0903 16.6519C24.2942 17.0125 24.7982 17.3392 25.209 17.3821L27.6355 17.6345C29.1873 17.7967 29.6188 18.8974 28.5814 20.0747L26.832 22.0736C26.5357 22.4121 26.4009 23.0377 26.5263 23.4719L27.2062 25.7687C27.7429 27.5868 26.8164 28.3609 25.1377 27.498L22.8029 26.295C22.3813 26.0775 21.7113 26.1193 21.3124 26.3881L19.1454 27.8721C17.5946 28.9366 16.5709 28.2766 16.8778 26.4135L17.2669 24.0499C17.3372 23.6036 17.1257 22.9996 16.7896 22.7005L14.8052 20.9347C13.6369 19.8951 13.9206 18.7497 15.4402 18.3958L17.8165 17.8436C18.2112 17.7504 18.6708 17.3636 18.8282 16.9804L19.9863 14.2259C20.6185 12.7311 21.7929 12.6577 22.5986 14.0628Z"
                            fill="url(#paint3_linear_3007_57733)"
                        />
                        <path
                            d="M48.3542 27.3071C46.5371 25.8575 44.23 25 41.7188 25C39.2075 25 36.8596 25.8779 35.0221 27.3683C32.5517 29.3079 31 32.35 31 35.7188C31 37.7196 31.5717 39.6388 32.5517 41.2313C33.3071 42.4767 34.2871 43.5588 35.4508 44.3958C37.2067 45.6821 39.3708 46.4375 41.7188 46.4375C44.4342 46.4375 46.8842 45.4371 48.7625 43.7629C49.5996 43.0688 50.3141 42.2113 50.8858 41.2313C51.8658 39.6388 52.4375 37.7196 52.4375 35.7187C52.4375 32.3092 50.845 29.2671 48.3542 27.3071Z"
                            fill="url(#paint4_linear_3007_57733)"
                        />
                        <path
                            d="M42.4502 30.7943L43.4181 32.7461C43.5501 33.0178 43.902 33.2784 44.199 33.3283L45.9533 33.6222C47.0752 33.8107 47.3392 34.6313 46.5307 35.4409L45.1669 36.816C44.9359 37.0489 44.8094 37.498 44.8809 37.8196L45.2714 39.5219C45.5794 40.8693 44.8699 41.3905 43.6876 40.6863L42.0432 39.7049C41.7463 39.5274 41.2568 39.5274 40.9544 39.7049L39.31 40.6863C38.1332 41.3905 37.4183 40.8637 37.7262 39.5219L38.1167 37.8196C38.1882 37.498 38.0617 37.0489 37.8307 36.816L36.4669 35.4409C35.6639 34.6313 35.9224 33.8107 37.0443 33.6222L38.7986 33.3283C39.0901 33.2784 39.442 33.0178 39.574 32.7461L40.5419 30.7943C41.0699 29.7352 41.9278 29.7352 42.4502 30.7943Z"
                            stroke="url(#paint5_linear_3007_57733)"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </g>
                    <defs>
                        <filter
                            id="filter0_d_3007_57733"
                            x="0.0693359"
                            y="3.97662"
                            width="56.3682"
                            height="52.8517"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3007_57733"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3007_57733"
                                     result="shape"/>
                        </filter>
                        <linearGradient
                            id="paint0_linear_3007_57733"
                            x1="23.194"
                            y1="30.5085"
                            x2="37.9239"
                            y2="40.9193"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="#EEE6F8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_3007_57733"
                            x1="15.7823"
                            y1="29.1468"
                            x2="17.4517"
                            y2="47.107"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="#EEE6F8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint2_linear_3007_57733"
                            x1="2.97945"
                            y1="5.08124"
                            x2="40.3086"
                            y2="38.0277"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#F27349"/>
                            <stop offset="1" stopColor="#FF9673"/>
                        </linearGradient>
                        <linearGradient
                            id="paint3_linear_3007_57733"
                            x1="14.6412"
                            y1="28.5843"
                            x2="28.7588"
                            y2="12.5867"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#F27349"/>
                            <stop offset="1" stopColor="#FF9673"/>
                        </linearGradient>
                        <linearGradient
                            id="paint4_linear_3007_57733"
                            x1="31.0001"
                            y1="46.4375"
                            x2="33.8577"
                            y2="22.7578"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                        <linearGradient
                            id="paint5_linear_3007_57733"
                            x1="39.7211"
                            y1="30.2961"
                            x2="43.2759"
                            y2="40.6958"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="#EEE6F8"/>
                        </linearGradient>
                    </defs>
                </svg>
            );

        case "create-mission-step01":
            return (
                <svg width="60" height="57" viewBox="0 0 60 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_3234_102605)">
                        <path
                            d="M41.5001 9.4999L46.6919 32.1187C47.9012 38.3032 45.6277 43.0333 38.4414 44.4499L22.0157 47.6878C14.8294 49.1044 10.9434 45.5885 9.73404 39.404L4.5422 16.7852C3.33285 10.6008 5.60645 5.87065 12.7927 4.45405L29.2184 1.21612C36.4047 -0.200474 40.2907 3.31541 41.5001 9.4999Z"
                            fill="url(#paint0_linear_3234_102605)"
                        />
                        <path
                            d="M51.3542 29.3071C49.5371 27.8575 47.23 27 44.7188 27C42.2075 27 39.8596 27.8779 38.0221 29.3683C35.5517 31.3079 34 34.35 34 37.7188C34 39.7196 34.5717 41.6388 35.5517 43.2313C36.3071 44.4767 37.2871 45.5588 38.4508 46.3958C40.2067 47.6821 42.3708 48.4375 44.7188 48.4375C47.4342 48.4375 49.8842 47.4371 51.7625 45.7629C52.5996 45.0688 53.3141 44.2113 53.8858 43.2313C54.8658 41.6388 55.4375 39.7196 55.4375 37.7187C55.4375 34.3092 53.845 31.2671 51.3542 29.3071Z"
                            fill="url(#paint1_linear_3234_102605)"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M45 32C45.7456 32 46.35 32.6044 46.35 33.35V36.65H49.65C50.3956 36.65 51 37.2544 51 38C51 38.7456 50.3956 39.35 49.65 39.35H46.35V42.65C46.35 43.3956 45.7456 44 45 44C44.2544 44 43.65 43.3956 43.65 42.65V39.35H40.35C39.6044 39.35 39 38.7456 39 38C39 37.2544 39.6044 36.65 40.35 36.65H43.65V33.35C43.65 32.6044 44.2544 32 45 32Z"
                            fill="white"
                        />
                        <path
                            d="M7.89538 18.8272C7.40088 16.6741 8.74542 14.5278 10.8985 14.0333L33.2695 8.89531C35.4225 8.4008 37.5688 9.74535 38.0633 11.8984L40.5616 22.7758C41.0561 24.9289 39.7116 27.0752 37.5585 27.5697L15.1875 32.7077C13.0344 33.2022 10.8881 31.8577 10.3936 29.7046L7.89538 18.8272Z"
                            fill="url(#paint2_linear_3234_102605)"
                        />
                        <path
                            d="M26.5004 36.4078L14.2044 39.0775C13.3642 39.2599 12.5161 38.7144 12.3337 37.8742C12.1513 37.0339 12.6968 36.1859 13.537 36.0035L25.833 33.3338C26.6733 33.1514 27.5213 33.6969 27.7037 34.5371C27.8862 35.3773 27.3407 36.2254 26.5004 36.4078Z"
                            fill="url(#paint3_linear_3234_102605)"
                        />
                        <path
                            d="M22.4462 42.3903L16.2982 43.7251C15.4579 43.9075 14.6099 43.362 14.4275 42.5218C14.245 41.6816 14.7905 40.8335 15.6308 40.6511L21.7788 39.3163C22.619 39.1338 23.467 39.6793 23.6495 40.5196C23.8319 41.3598 23.2864 42.2078 22.4462 42.3903Z"
                            fill="url(#paint4_linear_3234_102605)"
                        />
                        <path
                            d="M33.3524 7.40767L27.2044 8.7425C26.3642 8.92492 25.5161 8.37943 25.3337 7.5392C25.1513 6.69897 25.6968 5.85092 26.537 5.66849L32.685 4.33366C33.5252 4.15124 34.3733 4.69673 34.5557 5.53696C34.7381 6.37719 34.1927 7.22524 33.3524 7.40767Z"
                            fill="url(#paint5_linear_3234_102605)"
                        />
                    </g>
                    <defs>
                        <filter
                            id="filter0_d_3234_102605"
                            x="0.234375"
                            y="0.903931"
                            width="59.2031"
                            height="55.5336"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3234_102605"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3234_102605"
                                     result="shape"/>
                        </filter>
                        <linearGradient
                            id="paint0_linear_3234_102605"
                            x1="22.7715"
                            y1="7.93123"
                            x2="30.2872"
                            y2="46.0575"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="#EEE6F8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_3234_102605"
                            x1="34.0001"
                            y1="48.4375"
                            x2="36.8577"
                            y2="24.7578"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                        <linearGradient
                            id="paint2_linear_3234_102605"
                            x1="11.2891"
                            y1="33.6031"
                            x2="8.06385"
                            y2="12.4341"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                        <linearGradient
                            id="paint3_linear_3234_102605"
                            x1="12.6674"
                            y1="39.4112"
                            x2="12.5662"
                            y2="33.2429"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#F27349"/>
                            <stop offset="1" stopColor="#FF9673"/>
                        </linearGradient>
                        <linearGradient
                            id="paint4_linear_3234_102605"
                            x1="14.7612"
                            y1="44.0588"
                            x2="15.4042"
                            y2="38.1252"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#F27349"/>
                            <stop offset="1" stopColor="#FF9673"/>
                        </linearGradient>
                        <linearGradient
                            id="paint5_linear_3234_102605"
                            x1="25.6674"
                            y1="9.0762"
                            x2="26.3105"
                            y2="3.14255"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#F27349"/>
                            <stop offset="1" stopColor="#FF9673"/>
                        </linearGradient>
                    </defs>
                </svg>
            );

        case "create-mission-step02":
            return (
                <svg width="56" height="55" viewBox="0 0 56 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_3234_102676)">
                        <path
                            d="M47.3347 13.8372L35.2704 8.86823C35.0918 8.78302 34.8969 8.81163 34.731 8.91134C34.5801 9.01104 34.4883 9.16759 34.486 9.35252L34.2589 27.8455C34.2567 28.0304 34.3594 28.2011 34.5228 28.3005C34.6119 28.3573 34.7164 28.3857 34.8212 28.3856C34.911 28.3855 34.986 28.3712 35.0763 28.3285L47.5728 22.486C47.5728 22.486 47.5728 22.486 47.5877 22.486C50.0877 21.2606 51.4394 19.6805 51.385 18.0162C51.3158 16.3376 49.8819 14.8594 47.3347 13.8372Z"
                            fill="url(#paint0_linear_3234_102676)"
                        />
                        <path
                            d="M34.6755 41.1896C33.9905 41.1858 33.4255 40.6145 33.4294 39.9295L33.6014 9.01926C33.6052 8.33422 34.1765 7.76931 34.8615 7.77312C35.5466 7.77693 36.1115 8.34817 36.1077 9.03321L35.9356 39.9434C35.9318 40.6285 35.3605 41.1934 34.6755 41.1896Z"
                            fill="url(#paint1_linear_3234_102676)"
                        />
                        <path
                            d="M25.6413 13.186L13.518 7.92315C13.3384 7.83298 13.1437 7.86261 12.9787 7.96717C12.8288 8.07177 12.7386 8.23637 12.7383 8.43109L12.7053 27.9025C12.705 28.0972 12.8095 28.2771 12.9741 28.3823C13.0639 28.4423 13.1687 28.4725 13.2735 28.4726C13.3634 28.4728 13.4383 28.4579 13.5282 28.4132L25.9704 22.2932C25.9704 22.2932 25.9704 22.2932 25.9854 22.2933C28.4739 21.0094 29.8098 19.3491 29.7378 17.5965C29.651 15.829 28.2007 14.2688 25.6413 13.186Z"
                            fill="url(#paint2_linear_3234_102676)"
                        />
                        <path
                            d="M12.4944 41.0968C11.7769 41.0956 11.1829 40.4996 11.1841 39.7821L11.239 7.40716C11.2402 6.68966 11.8362 6.09567 12.5537 6.09689C13.2712 6.09811 13.8652 6.69411 13.864 7.41161L13.8091 39.7866C13.8079 40.5041 13.2119 41.0981 12.4944 41.0968Z"
                            fill="url(#paint3_linear_3234_102676)"
                        />
                        <path
                            d="M10 47C8.89543 47 7.97831 46.0888 8.27691 45.0254C9.87404 39.3375 18.0452 35 24 35H27C32.9548 35 41.126 39.3375 42.7231 45.0254C43.0217 46.0888 42.1046 47 41 47H10Z"
                            fill="url(#paint4_linear_3234_102676)"
                        />
                    </g>
                    <defs>
                        <filter
                            id="filter0_d_3234_102676"
                            x="4.21973"
                            y="6.09689"
                            width="51.167"
                            height="48.9031"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3234_102676"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3234_102676"
                                     result="shape"/>
                        </filter>
                        <linearGradient
                            id="paint0_linear_3234_102676"
                            x1="42.9965"
                            y1="8.81145"
                            x2="43.0124"
                            y2="28.379"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="#EEE6F8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_3234_102676"
                            x1="33.4224"
                            y1="41.1826"
                            x2="50.2698"
                            y2="30.8418"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                        <linearGradient
                            id="paint2_linear_3234_102676"
                            x1="12.7043"
                            y1="28.4717"
                            x2="32.9649"
                            y2="11.7942"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#F27349"/>
                            <stop offset="1" stopColor="#FF9673"/>
                        </linearGradient>
                        <linearGradient
                            id="paint3_linear_3234_102676"
                            x1="11.1819"
                            y1="41.0946"
                            x2="28.7853"
                            y2="30.1957"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                        <linearGradient
                            id="paint4_linear_3234_102676"
                            x1="25.5"
                            y1="35"
                            x2="25.5"
                            y2="47"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="#EEE6F8"/>
                        </linearGradient>
                    </defs>
                </svg>
            );
        case "create-mission-step03":
            return (
                <svg width="60" height="57" viewBox="0 0 60 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_3234_102683)">
                        <path
                            d="M41.4298 9.4999L46.6216 32.1187C47.8309 38.3032 45.5573 43.0333 38.3711 44.4499L21.9454 47.6878C14.7591 49.1044 10.8731 45.5885 9.66373 39.404L4.47189 16.7852C3.26254 10.6008 5.53613 5.87065 12.7224 4.45405L29.1481 1.21612C36.3344 -0.200474 40.2204 3.31541 41.4298 9.4999Z"
                            fill="url(#paint0_linear_3234_102683)"
                        />
                        <path
                            d="M51.487 29.0883C49.6699 27.6388 47.3628 26.7812 44.8516 26.7812C42.3403 26.7812 39.9924 27.6591 38.1549 29.1495C35.6845 31.0891 34.1328 34.1313 34.1328 37.5C34.1328 39.5008 34.7045 41.42 35.6845 43.0125C36.4399 44.2579 37.4199 45.34 38.5836 46.1771C40.3395 47.4633 42.5036 48.2188 44.8516 48.2188C47.567 48.2188 50.017 47.2183 51.8953 45.5442C52.7324 44.85 53.447 43.9925 54.0186 43.0125C54.9986 41.42 55.5703 39.5008 55.5703 37.5C55.5703 34.0904 53.9778 31.0483 51.487 29.0883Z"
                            fill="url(#paint1_linear_3234_102683)"
                        />
                        <path
                            d="M44.2506 42.3068C43.9598 42.3639 43.6498 42.3237 43.3792 42.1601L40.6733 40.524C40.1502 40.2077 40.0191 39.5399 40.3838 39.0493C40.7485 38.5587 41.4833 38.4144 42.0064 38.7307L43.7562 39.7887L48.1201 33.9182C48.4848 33.4276 49.2196 33.2834 49.7427 33.5997C50.2658 33.916 50.397 34.5838 50.0323 35.0744L45.0018 41.8415C44.8285 42.0922 44.5414 42.2497 44.2506 42.3068Z"
                            fill="white"
                        />
                        <path
                            d="M7.82507 18.8272C7.33056 16.6741 8.67511 14.5278 10.8282 14.0333L33.1991 8.89531C35.3522 8.4008 37.4985 9.74535 37.993 11.8984L40.4913 22.7758C40.9858 24.9289 39.6412 27.0752 37.4882 27.5697L15.1172 32.7077C12.9641 33.2022 10.8178 31.8577 10.3233 29.7046L7.82507 18.8272Z"
                            fill="url(#paint2_linear_3234_102683)"
                        />
                        <path
                            d="M26.4301 36.4078L14.1341 39.0775C13.2939 39.2599 12.4458 38.7144 12.2634 37.8742C12.081 37.0339 12.6265 36.1859 13.4667 36.0035L25.7627 33.3338C26.6029 33.1514 27.451 33.6969 27.6334 34.5371C27.8158 35.3773 27.2704 36.2254 26.4301 36.4078Z"
                            fill="url(#paint3_linear_3234_102683)"
                        />
                        <path
                            d="M22.3759 42.3903L16.2279 43.7251C15.3876 43.9075 14.5396 43.362 14.3571 42.5218C14.1747 41.6816 14.7202 40.8335 15.5604 40.6511L21.7085 39.3163C22.5487 39.1338 23.3967 39.6793 23.5792 40.5196C23.7616 41.3598 23.2161 42.2078 22.3759 42.3903Z"
                            fill="url(#paint4_linear_3234_102683)"
                        />
                        <path
                            d="M33.2821 7.40767L27.1341 8.7425C26.2939 8.92492 25.4458 8.37943 25.2634 7.5392C25.081 6.69897 25.6265 5.85092 26.4667 5.66849L32.6147 4.33366C33.4549 4.15124 34.303 4.69673 34.4854 5.53696C34.6678 6.37719 34.1223 7.22524 33.2821 7.40767Z"
                            fill="url(#paint5_linear_3234_102683)"
                        />
                    </g>
                    <defs>
                        <filter
                            id="filter0_d_3234_102683"
                            x="0.164062"
                            y="0.903931"
                            width="59.4062"
                            height="55.3148"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3234_102683"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3234_102683"
                                     result="shape"/>
                        </filter>
                        <linearGradient
                            id="paint0_linear_3234_102683"
                            x1="22.7012"
                            y1="7.93123"
                            x2="30.2169"
                            y2="46.0575"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="#EEE6F8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_3234_102683"
                            x1="34.1329"
                            y1="48.2188"
                            x2="36.9905"
                            y2="24.539"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                        <linearGradient
                            id="paint2_linear_3234_102683"
                            x1="11.2188"
                            y1="33.6031"
                            x2="7.99354"
                            y2="12.4341"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                        <linearGradient
                            id="paint3_linear_3234_102683"
                            x1="12.5971"
                            y1="39.4112"
                            x2="12.4959"
                            y2="33.2429"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#F27349"/>
                            <stop offset="1" stopColor="#FF9673"/>
                        </linearGradient>
                        <linearGradient
                            id="paint4_linear_3234_102683"
                            x1="14.6909"
                            y1="44.0588"
                            x2="15.3339"
                            y2="38.1252"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#F27349"/>
                            <stop offset="1" stopColor="#FF9673"/>
                        </linearGradient>
                        <linearGradient
                            id="paint5_linear_3234_102683"
                            x1="25.5971"
                            y1="9.0762"
                            x2="26.2402"
                            y2="3.14255"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#F27349"/>
                            <stop offset="1" stopColor="#FF9673"/>
                        </linearGradient>
                    </defs>
                </svg>
            );

        case "team-create":
            return (
                <svg
                    width="56"
                    height="56"
                    viewBox="0 0 56 56"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={className}
                >
                    <g filter="url(#filter0_d_3110_27796)">
                        <path
                            d="M41.9171 14.596L45.3435 32.1187C46.5529 38.3032 44.2793 43.0333 37.093 44.4499L20.6673 47.6878C13.4811 49.1044 9.59502 45.5885 8.38567 39.404L4.95919 21.8813C3.74985 15.6968 6.02344 10.9667 13.2097 9.55012L29.6354 6.31219C36.8217 4.8956 40.7077 8.41148 41.9171 14.596Z"
                            fill="url(#paint0_linear_3110_27796)"
                        />
                        <path
                            d="M13.8282 13.795C12.9845 13.9606 12.1475 13.3983 11.9819 12.5546L10.7698 6.38124C10.6042 5.53754 11.1664 4.70053 12.0101 4.53488C12.8538 4.36923 13.6909 4.93152 13.8565 5.77522L15.0685 11.9486C15.2342 12.7923 14.6719 13.6293 13.8282 13.795Z"
                            fill="url(#paint1_linear_3110_27796)"
                        />
                        <path
                            d="M30.2911 10.563C29.4474 10.7287 28.6104 10.1664 28.4447 9.32269L27.2327 3.1493C27.0671 2.30561 27.6293 1.46859 28.473 1.30295C29.3167 1.1373 30.1537 1.69959 30.3194 2.54328L31.5314 8.71667C31.6971 9.56037 31.1348 10.3974 30.2911 10.563Z"
                            fill="url(#paint2_linear_3110_27796)"
                        />
                        <path
                            d="M28.5987 23.718L16.2519 26.1421C15.4082 26.3077 14.5712 25.7454 14.4056 24.9017C14.2399 24.058 14.8022 23.221 15.6459 23.0554L27.9927 20.6313C28.8364 20.4657 29.6734 21.0279 29.839 21.8716C30.0047 22.7153 29.4424 23.5523 28.5987 23.718Z"
                            fill="url(#paint3_linear_3110_27796)"
                        />
                        <path
                            d="M24.4458 35.2191L18.2724 36.4311C17.4287 36.5968 16.5917 36.0345 16.4261 35.1908C16.2604 34.3471 16.8227 33.5101 17.6664 33.3444L23.8398 32.1324C24.6835 31.9668 25.5205 32.529 25.6861 33.3727C25.8518 34.2164 25.2895 35.0535 24.4458 35.2191Z"
                            fill="url(#paint4_linear_3110_27796)"
                        />
                        <path
                            d="M47.5573 26.0883C45.7402 24.6388 43.4331 23.7812 40.9219 23.7812C38.4106 23.7812 36.0627 24.6591 34.2252 26.1495C31.7548 28.0891 30.2031 31.1313 30.2031 34.5C30.2031 36.5008 30.7748 38.42 31.7548 40.0125C32.5102 41.2579 33.4902 42.34 34.6539 43.1771C36.4098 44.4633 38.574 45.2188 40.9219 45.2188C43.6373 45.2188 46.0873 44.2183 47.9657 42.5442C48.8027 41.85 49.5173 40.9925 50.0889 40.0125C51.0689 38.42 51.6406 36.5008 51.6406 34.5C51.6406 31.0904 50.0481 28.0483 47.5573 26.0883Z"
                            fill="url(#paint5_linear_3110_27796)"
                        />
                        <path
                            d="M40.3209 39.3068C40.0301 39.3639 39.7201 39.3237 39.4495 39.1601L36.7437 37.524C36.2205 37.2077 36.0894 36.5399 36.4541 36.0493C36.8188 35.5587 37.5536 35.4144 38.0767 35.7307L39.8265 36.7887L44.1904 30.9182C44.5551 30.4276 45.2899 30.2834 45.813 30.5997C46.3362 30.916 46.4673 31.5838 46.1026 32.0744L41.0721 38.8415C40.8988 39.0922 40.6118 39.2497 40.3209 39.3068Z"
                            fill="white"
                        />
                    </g>
                    <defs>
                        <filter
                            id="filter0_d_3110_27796"
                            x="0.651367"
                            y="1.27399"
                            width="54.9893"
                            height="54.726"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3110_27796"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3110_27796"
                                     result="shape"/>
                        </filter>
                        <linearGradient
                            id="paint0_linear_3110_27796"
                            x1="21.4229"
                            y1="7.93136"
                            x2="28.9385"
                            y2="46.0576"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="#EEE6F8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_3110_27796"
                            x1="12.2849"
                            y1="14.098"
                            x2="13.8052"
                            y2="4.2708"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                        <linearGradient
                            id="paint2_linear_3110_27796"
                            x1="28.7478"
                            y1="10.866"
                            x2="30.2681"
                            y2="1.03887"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                        <linearGradient
                            id="paint3_linear_3110_27796"
                            x1="14.7086"
                            y1="26.4451"
                            x2="14.7303"
                            y2="20.2761"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#F27349"/>
                            <stop offset="1" stopColor="#FF9673"/>
                        </linearGradient>
                        <linearGradient
                            id="paint4_linear_3110_27796"
                            x1="16.7291"
                            y1="36.7342"
                            x2="17.4902"
                            y2="30.8145"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#F27349"/>
                            <stop offset="1" stopColor="#FF9673"/>
                        </linearGradient>
                        <linearGradient
                            id="paint5_linear_3110_27796"
                            x1="30.2032"
                            y1="45.2188"
                            x2="33.0608"
                            y2="21.539"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                    </defs>
                </svg>
            );
        case "team-not-freeze":
            return (
                <svg
                    width="61"
                    height="55"
                    viewBox="0 0 61 55"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={className}
                >
                    <g opacity="0.8" filter="url(#filter0_d_3110_27784)">
                        <path
                            d="M23.4582 2.74828C28.681 0.964302 34.3773 3.75992 36.1612 8.98265C37.9112 14.1057 35.3207 19.6206 30.1593 21.584C29.993 21.6185 29.8335 21.673 29.7207 21.7338C29.6808 21.7474 29.6609 21.7542 29.621 21.7678C29.6011 21.7747 29.6011 21.7747 29.5812 21.7815C24.4168 23.3452 18.9937 20.5675 17.2239 15.4513C15.4399 10.2285 18.2355 4.53226 23.4582 2.74828Z"
                            fill="url(#paint0_linear_3110_27784)"
                        />
                        <path
                            d="M22.3193 31.9445C26.6144 26.337 35.6844 23.2389 42.5523 25.0333C45.6627 25.8629 47.8412 27.6787 48.6787 30.1306C49.5162 32.5825 48.897 35.3316 46.9573 37.864C44.8066 40.6911 41.4588 42.8809 37.7909 44.1337C34.123 45.3866 30.1351 45.7026 26.7043 44.782C23.6138 43.9456 21.4422 42.1497 20.5979 39.6779C19.7803 37.2192 20.3795 34.4769 22.3193 31.9445Z"
                            fill="url(#paint1_linear_3110_27784)"
                        />
                        <path
                            d="M5.18726 20.8766C6.18927 24.8527 10.0977 27.3019 14.0617 26.4598C14.0817 26.453 14.0817 26.453 14.1016 26.4462L14.1614 26.4258C14.281 26.3849 14.4006 26.3441 14.5139 26.3499C16.4815 25.7891 18.0446 24.5651 18.9921 22.9727C16.3124 21.8401 14.1967 19.4909 13.4145 16.4191C13.0025 14.7568 13.0568 13.091 13.465 11.5492C12.5782 11.4292 11.6194 11.4896 10.6951 11.7162C6.67227 12.7119 4.17794 16.8139 5.18726 20.8766Z"
                            fill="url(#paint2_linear_3110_27784)"
                        />
                        <path
                            d="M7.49864 40.678C8.31859 42.5571 10.1265 43.8094 12.5877 44.2375C14.9556 44.653 17.6444 44.3133 20.1157 43.4024C18.2379 42.5969 16.8491 41.2682 16.104 39.6084C15.0604 37.2046 15.4262 34.4308 17.1315 31.7335C18.1063 30.2208 19.3751 28.8525 20.8584 27.6558C16.0172 27.8848 10.7684 30.6349 8.29928 34.5501C6.96922 36.6517 6.66556 38.8256 7.49864 40.678Z"
                            fill="url(#paint3_linear_3110_27784)"
                        />
                        <path
                            d="M52.5055 27.3071C50.6885 25.8575 48.3814 25 45.8701 25C43.3589 25 41.011 25.8779 39.1735 27.3683C36.703 29.3079 35.1514 32.35 35.1514 35.7188C35.1514 37.7196 35.7231 39.6388 36.7031 41.2313C37.4585 42.4767 38.4384 43.5588 39.6022 44.3958C41.358 45.6821 43.5222 46.4375 45.8701 46.4375C48.5855 46.4375 51.0356 45.4371 52.9139 43.7629C53.751 43.0688 54.4655 42.2113 55.0372 41.2313C56.0172 39.6388 56.5889 37.7196 56.5889 35.7187C56.5889 32.3092 54.9964 29.2671 52.5055 27.3071Z"
                            fill="url(#paint4_linear_3110_27784)"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M46.1514 30C46.897 30 47.5014 30.6044 47.5014 31.35V34.65H50.8014C51.547 34.65 52.1514 35.2544 52.1514 36C52.1514 36.7456 51.547 37.35 50.8014 37.35H47.5014V40.65C47.5014 41.3956 46.897 42 46.1514 42C45.4058 42 44.8014 41.3956 44.8014 40.65V37.35H41.5014C40.7558 37.35 40.1514 36.7456 40.1514 36C40.1514 35.2544 40.7558 34.65 41.5014 34.65H44.8014V31.35C44.8014 30.6044 45.4058 30 46.1514 30Z"
                            fill="white"
                        />
                    </g>
                    <defs>
                        <filter
                            id="filter0_d_3110_27784"
                            x="0.962891"
                            y="2.20996"
                            width="59.626"
                            height="52.2275"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3110_27784"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3110_27784"
                                     result="shape"/>
                        </filter>
                        <linearGradient
                            id="paint0_linear_3110_27784"
                            x1="23.4581"
                            y1="2.74847"
                            x2="29.9199"
                            y2="21.6659"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="#EEE6F8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_3110_27784"
                            x1="31.4957"
                            y1="25.7047"
                            x2="37.7907"
                            y2="44.1338"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="#EEE6F8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint2_linear_3110_27784"
                            x1="4.96289"
                            y1="26.6253"
                            x2="20.0559"
                            y2="12.6365"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#F27349"/>
                            <stop offset="1" stopColor="#FF9673"/>
                        </linearGradient>
                        <linearGradient
                            id="paint3_linear_3110_27784"
                            x1="7.03516"
                            y1="44.413"
                            x2="23.4867"
                            y2="30.8418"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#F27349"/>
                            <stop offset="1" stopColor="#FF9673"/>
                        </linearGradient>
                        <linearGradient
                            id="paint4_linear_3110_27784"
                            x1="35.1514"
                            y1="46.4375"
                            x2="38.0091"
                            y2="22.7578"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                    </defs>
                </svg>
            );

        case "team-freeze":
            return (
                <svg
                    width="61"
                    height="55"
                    viewBox="0 0 61 55"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={className}
                >
                    <g opacity="0.8" filter="url(#filter0_d_3007_57856)">
                        <path
                            d="M23.4582 2.74828C28.681 0.964302 34.3773 3.75992 36.1612 8.98265C37.9112 14.1057 35.3207 19.6206 30.1593 21.584C29.993 21.6185 29.8335 21.673 29.7207 21.7338C29.6808 21.7474 29.6609 21.7542 29.621 21.7678C29.6011 21.7747 29.6011 21.7747 29.5812 21.7815C24.4168 23.3452 18.9937 20.5675 17.2239 15.4513C15.4399 10.2285 18.2355 4.53226 23.4582 2.74828Z"
                            fill="url(#paint0_linear_3007_57856)"
                        />
                        <path
                            d="M22.3193 31.9445C26.6144 26.337 35.6844 23.2389 42.5523 25.0333C45.6627 25.8629 47.8412 27.6787 48.6787 30.1306C49.5162 32.5825 48.897 35.3316 46.9573 37.864C44.8066 40.6911 41.4588 42.8809 37.7909 44.1337C34.123 45.3866 30.1351 45.7026 26.7043 44.782C23.6138 43.9456 21.4422 42.1497 20.5979 39.6779C19.7803 37.2192 20.3795 34.4769 22.3193 31.9445Z"
                            fill="url(#paint1_linear_3007_57856)"
                        />
                        <path
                            d="M5.18726 20.8766C6.18927 24.8527 10.0977 27.3019 14.0617 26.4598C14.0817 26.453 14.0817 26.453 14.1016 26.4462L14.1614 26.4258C14.281 26.3849 14.4006 26.3441 14.5139 26.3499C16.4815 25.7891 18.0446 24.5651 18.9921 22.9727C16.3124 21.8401 14.1967 19.4909 13.4145 16.4191C13.0025 14.7568 13.0568 13.091 13.465 11.5492C12.5782 11.4292 11.6194 11.4896 10.6951 11.7162C6.67227 12.7119 4.17794 16.8139 5.18726 20.8766Z"
                            fill="url(#paint2_linear_3007_57856)"
                        />
                        <path
                            d="M7.49864 40.678C8.31859 42.5571 10.1265 43.8094 12.5877 44.2375C14.9556 44.653 17.6444 44.3133 20.1157 43.4024C18.2379 42.5969 16.8491 41.2682 16.104 39.6084C15.0604 37.2046 15.4262 34.4308 17.1315 31.7335C18.1063 30.2208 19.3751 28.8525 20.8584 27.6558C16.0172 27.8848 10.7684 30.6349 8.29928 34.5501C6.96922 36.6517 6.66556 38.8256 7.49864 40.678Z"
                            fill="url(#paint3_linear_3007_57856)"
                        />
                        <path
                            d="M52.5329 27.2402C50.7158 25.7906 48.4087 24.9331 45.8975 24.9331C43.3862 24.9331 41.0383 25.811 39.2008 27.3014C36.7304 29.241 35.1787 32.2831 35.1787 35.6519C35.1787 37.6527 35.7504 39.5719 36.7304 41.1644C37.4858 42.4098 38.4658 43.4919 39.6295 44.3289C41.3854 45.6152 43.5495 46.3706 45.8975 46.3706C48.6129 46.3706 51.0629 45.3702 52.9412 43.696C53.7783 43.0019 54.4929 42.1444 55.0645 41.1644C56.0445 39.5719 56.6162 37.6527 56.6162 35.6519C56.6162 32.2423 55.0237 29.2002 52.5329 27.2402Z"
                            fill="url(#paint4_linear_3007_57856)"
                        />
                        <path
                            d="M45.2975 40.4587C45.0066 40.5158 44.6967 40.4755 44.4261 40.3119L41.7202 38.6759C41.1971 38.3596 41.066 37.6918 41.4307 37.2012C41.7954 36.7105 42.5302 36.5663 43.0533 36.8826L44.8031 37.9406L49.167 32.0701C49.5317 31.5795 50.2665 31.4352 50.7896 31.7515C51.3127 32.0678 51.4438 32.7356 51.0791 33.2262L46.0487 39.9933C45.8753 40.2441 45.5883 40.4016 45.2975 40.4587Z"
                            fill="white"
                        />
                    </g>
                    <defs>
                        <filter
                            id="filter0_d_3007_57856"
                            x="0.962891"
                            y="2.20996"
                            width="59.6533"
                            height="52.1606"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3007_57856"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3007_57856"
                                     result="shape"/>
                        </filter>
                        <linearGradient
                            id="paint0_linear_3007_57856"
                            x1="23.4581"
                            y1="2.74847"
                            x2="29.9199"
                            y2="21.6659"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="#EEE6F8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_3007_57856"
                            x1="31.4957"
                            y1="25.7047"
                            x2="37.7907"
                            y2="44.1338"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="white"/>
                            <stop offset="1" stopColor="#EEE6F8"/>
                        </linearGradient>
                        <linearGradient
                            id="paint2_linear_3007_57856"
                            x1="4.96289"
                            y1="26.6253"
                            x2="20.0559"
                            y2="12.6365"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#F27349"/>
                            <stop offset="1" stopColor="#FF9673"/>
                        </linearGradient>
                        <linearGradient
                            id="paint3_linear_3007_57856"
                            x1="7.03516"
                            y1="44.413"
                            x2="23.4867"
                            y2="30.8418"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#F27349"/>
                            <stop offset="1" stopColor="#FF9673"/>
                        </linearGradient>
                        <linearGradient
                            id="paint4_linear_3007_57856"
                            x1="35.1788"
                            y1="46.3706"
                            x2="38.0364"
                            y2="22.6909"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#301466"/>
                            <stop offset="1" stopColor="#820F99"/>
                        </linearGradient>
                    </defs>
                </svg>
            );

        default:
            return <></>;
    }
};

export default RoadMapIcon;
