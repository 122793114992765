import {toast} from "react-toastify";

export const addHttps = (v) => {
    return `https://${v}`
}

export function fixQueryInUrl(url) {
    if (url.includes('/?')) {
        return url.replace('/?', '?')
    }
    return url
}

export function buildQueryString(params) {
    return params.map((param) => {
        const [key, value] = param.split('=')
        return `${key}=${value}`
    }).join('&')
}

export function mergeArray(...arrays) {
    return arrays.reduce((acc, val) => acc.concat(val), [])
}

export function mergeObject(...objects) {
    return objects.reduce((acc, val) => Object.assign(acc, val), {})
}


export function ToastErrorApiResult(error) {
    if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message)

    } else {
        if (error?.message) {
            toast.error(error?.message)

        }
    }


}