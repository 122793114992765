import {generalRequest} from "../services";
import {useEffect} from "react";

export async function apiGetMissions(config = {}) {
    const query = new URLSearchParams(config);
    const str = query.toString();
    return generalRequest.get(`student/missions?` + str, config)
}

export async function apiGetMission(id, config = {}) {
    return generalRequest.get(`student/mission/${id}`, config)
}

export async function apiDoneStep(missionId, stepId, subStepId, config = {}) {
    return generalRequest.post(`student/mission_runner_step/${missionId}/${stepId}/${subStepId}`, config)
}