import Typo from "../../../../../../../../core/components/typo";
import __ from "../../../../../../../../core/utils/translate/translate";

export const DescriptionTitleModal = () => {

  return (
    <>
      <Typo>{__("To join the team, there are the following rules:")}</Typo>
      <Typo type="secondary" size="sm" className="pr-6">
          {__("Description")}
      </Typo>
    </>
  );
};
