import {motion} from "framer-motion";
import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {twMerge} from "tailwind-merge";
import {InputLabel} from "..";

const TextInput = (
    {
        scale = "full",
        label = "",
        inputMode = "text",
        dir = "rtl",
        value,
        autoFocus = false,
        maxLength,
        required = false,
        rules,
        name,
        liveValidate = false,
        onChange,
        onKeyDown,
        placeholder,
        error = [],
        inputClassName,
        className,
        type = "text",
        disabled = false,
    },
    ref
) => {
    const [text, setText] = useState( "");

    useEffect(() => {
        if(!!value)
        {
            setText(value)
        }
    }, [value]);

    const refInput = useRef(null);

    const handleChange = (event) => {
        onChange?.(event);
        setText(event.target.value);
    };

    useImperativeHandle(ref, () => ({
        getValue() {
            return text.trim();
        },
        focus() {
            refInput.current.focus();
        },
        setValue(value) {
            setText(value ?? "");
        },
        reset() {
            setText("");
        },
    }));

    return (
        <div className={twMerge("flex flex-col gap-2", scale === "fit" ? "w-fit" : "w-full", className)}>
            <InputLabel label={label} required={required}/>

            <motion.input
                animate={{
                    x: error.length > 0 ? [20, -20, 15, -15, 10, -10, 0] : [],
                }}
                transition={{duration: 0.5}}
                className={twMerge(
                    "h-10 rounded-xl border px-2",
                    scale === "fit" ? "w-fit" : "w-full",
                    inputClassName,
                    error.length > 0 ? "border-red" : "border-purple-4 focus:border-purple-1",
                    disabled ? "bg-gray-100 text-gray-5" : "bg-white text-black"
                )}
                dir={dir}
                inputMode={inputMode}
                value={text}
                ref={refInput}
                autoFocus={autoFocus}
                autoComplete={'off'}
                maxLength={maxLength}
                placeholder={placeholder}
                onChange={handleChange}
                onKeyDown={onKeyDown}
                type={type}
                disabled={disabled}
            />
        </div>
    );
};

export default forwardRef(TextInput);
