import React from "react";
import { TTypoProps } from "./index.type";
import { twMerge } from "tailwind-merge";
import { Number } from './number'

const Typo= ({
  as = "p",
  children,
  weight = "normal",
  size = "base",
  type = "primary",
  className,
  onClick,
  dir = "rtl",
}) => {
  const getFontWeight = () => {
    if (weight === "light") return "font-light";
    else if (weight === "normal") return "font-normal";
    else if (weight === "medium") return "font-medium";
    else if (weight === "semi-bold") return "font-semibold";
    else return "font-bold";
  };

  const getFontSize = () => {
    if (size === "4xl") return "text-4xl";
    else if (size === "3xl") return "text-3xl";
    else if (size === "2xl") return "text-2xl";
    else if (size === "xl") return "text-xl";
    else if (size === "lg") return "text-lg";
    else if (size === "base") return "text-base";
    else if (size === "sm") return "text-sm";
    else if (size === "xs") return "text-xs";
    else if (size === "2xs") return "text-2xs";
    else return "text-3xs";
  };

  const getType = () => {
    if (type === "primary") return "text-purple-1";
    else if (type === "secondary") return "text-gray-2";
    else if (type === "danger") return "text-red";
    else if (type === "success") return "text-green";
    else if (type === "warning") return "text-brown";
    else if (type === "on-accent") return "text-white";
    else return "text-purple-2";
  };

  return React.createElement(
    as,
    {
      className: twMerge(
        getFontWeight(),
        getFontSize(),
        getType(),
        onClick !== undefined && "cursor-pointer",
        className
      ),
      dir,
      onClick,
    },
    children
  );
};
Typo.Number = Number
export default Typo;
