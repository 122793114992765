import ReactGA from 'react-ga';

export default function GoogleAnalyticsInit()
{
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return;
    }

    const TRACKING_ID = "G-EF58PL01HH"; // OUR_TRACKING_ID
    ReactGA.initialize(TRACKING_ID);
}
