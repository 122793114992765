import {useNavigate} from "react-router-dom";
import useQueryString from "../../../../../../core/hooks/useQueryString";
import RenderIf from "../../../../../../core/components/render-if";
import ActivityNotFound from "../../../../../../core/components/not-found/activity";
import {GameCard, GameCardLoading} from "../../../../../../core/components/card";
import {useGetMissions} from "../../../../api/mission";
import LoadMore from "../../../../../../core/components/loadmore";
import React, {Fragment, useEffect} from "react";

const ClubActivitiesList = ({commonGame}) => {
    // const { education } = useUser();
    const navigate = useNavigate();
    const {queryStringFN} = useQueryString();
    // const [activitiesData, setActivitiesData] = useState();

    // const INIT_TYPE_LIST: number[] = [
    //   EnumGameType.COURSE,
    //   EnumGameType.CAMP,
    //   EnumGameType.BOOK,
    //   EnumGameType.MATCH,
    //   EnumGameType.ONLINE,
    // ];
    const {
        data: activitiesData,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useGetMissions({

        page: 1,
        perPage: 8,
        search: queryStringFN.get("name") ?? '',
        type: 'learn',

        // props: {
        //   orderPublishedAt: "desc",
        //   // types: queryStringFN.getAll("type", INIT_TYPE_LIST).map((type) => Number(type)),
        //   perPage: 8,
        //   statuses: [EnumGameStatus.APPROVED, EnumGameStatus.APPROVED_BY_NOJAHAN],
        //   name: queryStringFN.get("name"),
        //   commonGame: commonGame,
        // },
    });
    return (
        <div className="flex flex-col gap-8">
            <RenderIf cond={activitiesData?.pages?.at(0)?.data?.length === 0}>
                <ActivityNotFound/>
            </RenderIf>

            <div className="grid gap-x-4 gap-y-4 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4">
                <RenderIf cond={!activitiesData}>
                    {Array.from({length: 4}).map((_, i) => (
                        <GameCardLoading key={'gameCardLoadingActivities' + i}/>
                    ))}
                </RenderIf>
                {activitiesData?.pages.map((data,i)=>(
                    <Fragment key={'fragmentActivities' + i}>
                        {data?.data?.map((mission, i) => (
                            <GameCard
                                key={'gameCard' + mission?.id + '-' + i}
                                id={mission.id}
                                cover={mission.coverDetail}
                                type={mission.type}
                                score={mission.score}
                                minScore={mission.minScore}
                                XP={mission.experience}
                                minXP={mission.minExperience}
                                name={mission.title}
                                capacity={mission.capacity}
                                players={mission.players}
                                progress={mission.progress}
                                rate={mission.rate}
                                isEnd={(mission.secondsToExpire ?? 0) > 0}
                                gameUserStatus={mission.gameUser?.status ?? 0}
                                // onClick={() => navigate(ROUTES.GAME.INDEX(game.uuid))}
                                secondToStart={mission.secondsToStart}
                                secondToExpire={mission.secondsToExpire}
                                buttonType="danger"
                            />
                        ))}
                    </Fragment>
                ))}
            </div>
            <LoadMore
              auto
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage === true}
              isFetchingNextPage={isFetchingNextPage}
            />
        </div>
    );
};

export default ClubActivitiesList;
