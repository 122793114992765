import {useState} from "react";
import Spinner from "../../../../core/components/spinner";
import RenderSwitch from "../../../../core/components/render-switch";
import ResponsiveFooter from "../../../../core/components/footer";
import Typo from "../../../../core/components/typo";
import Button from "../../../../core/components/button";
import {useAuthContext} from "../../../Auth/provider/AuthProvider";
import __ from "../../../../core/utils/translate/translate";
import RenderIf from "../../../../core/components/render-if";
import {useGetMissionRunnerDetail, useRepeatMissionRunner, useStartMissionRunner} from "../../api/missionRunner";
import {useNavigate} from "react-router-dom";

const GameTeamFooter = ({setShow, runnerData, missionData}) => {

    const navigate = useNavigate();
    const {team} = useAuthContext()
    const [gameData, setGameData] = useState();
    const [status, setStatus] = useState("loading");
    const [gameUserData, setGameUserData] = useState();
    const navigateToGameStep = (gameUUID, stepID) => {}
    const handleStoreGameUser = () => {}


    const {
        mutate: repeatMissionRunner,
        isPending: startMissionRunnerLoading,
        isSuccess: startMissionRunnerSuccess,
        isError: startMissionRunnerIsError,
    } = useRepeatMissionRunner();

    if (!!team && (['signup', 'ready', 'doing'].includes(runnerData?.status) || runnerData?.status === "voting")) {
        setShow(true)
    }

    return (
        <div className="flex flex-col gap-2">
            <ResponsiveFooter>
                <RenderIf
                    cond={runnerData?.status !== 'signup' && runnerData?.status !== 'doing' && runnerData?.status !== 'ready'}>
                    <RenderSwitch
                        conds={[
                            false, //1

                            !runnerData?.data && !team,//2

                            false,//3
                            !!team && (!team?.team?.freeze || team?.team?.freeze === false), //4

                            !!team && team?.teamRole !== "head" && !!runnerData?.data,//5

                            false,//6

                            false,//7

                            false,//8
                            !missionData,//9
                            false,//10
                            false,//11
                            false,//12
                            status === "continue",//13
                            status === "entered",//14
                            status === "evaluation",//15
                            status === "expired",//16
                            status === "failed",//17
                            status === "report-card",//18
                            !!team && runnerData?.status === "pending_mentor",//19
                            !!team && runnerData?.status === "voting_rejected",//20
                            !!team && runnerData?.status === "mentor_rejected",//21
                            status === "wait-for-mentor",//22
                            status === "team-reject",//23
                            status === "mentor-reject",//24
                        ]}
                    >
                        {[

                            <Typo size="sm" className="rounded-full bg-purple-5 p-1 px-4 text-center" type="accent"
                                  key={1}>
                                {__("Failure to meet the prerequisites")}
                            </Typo>,
                            <Typo size="sm" className="rounded-full bg-purple-5 p-1 px-4 text-center" type="accent"
                                  key={2}>
                                {__("You are not a team member yet!")}
                            </Typo>,

                            <Typo size="sm" className="rounded-full bg-purple-5 p-1 px-4 text-center" type="accent"
                                  key={3}>
                                {__("You dont have a mentor yet!")}
                            </Typo>,

                            <Typo size="sm" className="rounded-full bg-purple-5 p-1 px-4 text-center" type="accent"
                                  key={4}>
                                {__("Your team is recruiting")}
                            </Typo>,

                            <Typo size="sm" className="rounded-full bg-purple-5 p-1 px-4 text-center" type="accent"
                                  key={5}>
                                {__("Participate in the event with team head")}
                            </Typo>,

                            <Typo size="sm" className="rounded-full bg-purple-5 p-1 px-4 text-center" type="accent"
                                  key={6}>
                                {/*//   {t("teamFooter.lackOfMember")}*/}
                            </Typo>,

                            <Typo size="sm" className="rounded-full bg-purple-5 p-1 px-4 text-center" type="accent"
                                  key={7}>
                                {/*محدودیت در {gameData?.data?.typeStr} همزمان*/}
                            </Typo>,

                            <Typo size="sm" className="rounded-full bg-purple-5 p-1 px-4 text-center" type="accent"
                                  key={8}>
                                {/*{Math.abs((gameData?.data?.minScore ?? 0) - (team?.score ?? 0))} امتیاز دیگه نیاز داری!*/}
                            </Typo>,

                            <div className="flex items-center justify-center" key={9}>
                                <Spinner type="accent" size="sm"/>
                            </div>,

                            <Button
                                onClick={() => navigateToGameStep(gameData?.data?.uuid, gameUserData?.data?.at(0)?.gameStep?.id)}
                                key={10}
                            >
                                {__("Continue")}
                            </Button>,

                            <Typo size="sm" className="rounded-full bg-purple-5 p-1 px-4 text-center" type="accent"
                                  key={11}>
                                {/*باید تا شروع {gameData?.data?.typeStr} صبر کنی!*/}
                            </Typo>,

                            <Button onClick={() => {
                            }} key={12}>
                                {/*ارزیابی*/}
                            </Button>,

                            <Typo size="sm" className="rounded-full bg-red/10 p-1 px-4 text-center" type="danger"
                                  key={13}>
                                {/*{gameData?.data?.typeStr} پایان یافته است*/}
                            </Typo>,

                            <Typo size="sm" className="rounded-full bg-red/10 p-1 px-4 text-center" type="danger"
                                  key={14}>
                                {/*متاسفانه نتونستی {gameData?.data?.typeStr} رو سر وقت تموم کنی*/}
                            </Typo>,

                            <div className="flex gap-2" key={15}>
                                <Button
                                    // onClick={() => navigateToGameStep(gameData.data?.uuid, gameUserData.data?.at(0)?.gameStep?.id)}
                                    type="light"
                                >
                                    {__("View steps")}
                                </Button>
                                <Button onClick={() => {
                                }}>
                                    {__("Report card")}
                                </Button>
                            </div>,

                            <Button onClick={handleStoreGameUser} key={16}>
                                {__("teamFooter.signup")}
                            </Button>,

                            <Typo size="sm" className="rounded-full bg-red/10 p-1 px-4 text-center" type="danger"
                                  key={17}>
                                {gameData?.data?.type === 'course' ? __("The opportunity to register for the course has ended.") :
                                    __("The opportunity to register for the mission has ended.")}
                            </Typo>,

                            <Button onClick={() => {
                            }} key={18}>
                                {__("Voting")}
                            </Button>,

                            <Typo size="sm" className="rounded-full bg-purple-5 p-1 px-4 text-center" type="accent"
                                  key={19}>
                                {__("The request is being reviewed by the mentor")}
                            </Typo>,

                            <Typo size="sm" className="rounded-full bg-purple-5 p-1 px-4 text-center" type="accent"
                                  key={20}>
                                {__("The opinion of the majority of the team was against participating in the mission.")}
                            </Typo>,

                            <Typo size="sm" className="rounded-full bg-purple-5 p-1 px-4 text-center" type="accent"
                                  key={21}>
                  <span>
                  {__("Your mentor does not agree to participate in this mission.")}
                      <span className={' mx-4 cursor-pointer text-blue-800'} onClick={() => {
                          repeatMissionRunner({missionRunnerId: runnerData?.id}, {
                              onSuccess: (e) => {
                                  navigate(0)
                              },
                              onError: (e) => {

                              }
                          })
                      }}>
                      {__("Repeat request")}
                  </span>
                  </span>

                            </Typo>,
                            <Typo size="xs" type="accent" key={22}
                                  className="rounded-full bg-purple-5 p-1 px-4 text-center">
                                {`این ${gameData?.data?.typeStr}${__('is for another plan!')}`}
                            </Typo>,
                            <Typo size="xs" type="accent" key={23}
                                  className="rounded-full bg-purple-5 p-1 px-4 text-center">
                                {__('You havent completed your profile yet!')}
                            </Typo>,
                            <Typo size="xs" type="accent" key={24}
                                  className="rounded-full bg-purple-5 p-1 px-4 text-center">
                                {__('You must do the handover mission first!')}
                            </Typo>,

                        ]}
                    </RenderSwitch>
                </RenderIf>
            </ResponsiveFooter>
        </div>
    );
};

export default GameTeamFooter;
