import {Suspense, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import ProfilePersonBio from "./components/bio";
import ProfilePersonCreateOrJoinTeam from "./components/create-or-join-team";
import ProfilePersonGames from "./components/games";
import ProfilePersonJoinToClub from "./components/join-to-club";
import useQueryString from "../../../../core/hooks/useQueryString";
import {useAuthContext} from "../../../Auth/provider/AuthProvider";
import RenderIf from "../../../../core/components/render-if";
import {MiniLoading} from "../../../../core/components/loading";
import TabView from "../../../../core/components/tab-view";
import ProfileOrganisationView from "../../components/organisation-view";
import ProfileTeamView from "../../components/team-view";
import ProfileNotCompleteModal from "../../../../core/components/modal/profile-not-complete";
import __ from "../../../../core/utils/translate/translate";


const ProfilePersonMain = () => {
    const {uuid} = useParams();
    const {queryStringFN} = useQueryString();
    const {user, organisation, team} = useAuthContext();
    const [modal, setModal] = useState(!user?.firstName ? "profile-not-complete" : "idle")


    const memoTabs = useMemo(() => {
        const temp = [];
        if (!!user) {
            let displayName = ''
            displayName += user?.firstName ?? '';
            displayName += user?.lastName ? ' ' + user?.lastName : '';
            if(!displayName)
            {
                displayName += user?.mobile
            }
            temp.push({id: "person", title: `${displayName}`});
            if (team?.status === "accepted") temp.push({id: "team", title: team?.team?.name});
            // if (!!userData.data?.team?.group) temp.push({id: "group", title: userData.data?.team.group.nameWithType});
            if (organisation?.status === "accepted") temp.push({
                id: "organisation",
                title: `${__("Organization")} ${organisation?.organization?.name}`
            });
        }
        return temp;
    }, [user, team, organisation]);

    return (
        <TabView
            className="flex min-h-[calc(100svh_-_14rem)] flex-col items-center gap-4"
            tabs={memoTabs}
            activeTab={queryStringFN.get("view", "person")}
            onClick={(tab) => queryStringFN.set("view", tab.id)}
        >
            <Suspense fallback={<MiniLoading/>}>
                <ProfileNotCompleteModal isOpen={modal === "profile-not-complete"} onClose={() => setModal("idle")}/>
                <RenderIf cond={queryStringFN.get("view", "person") === "person"}>
                    <div className="flex w-full flex-col items-center gap-8">
                        <ProfilePersonJoinToClub/>

                        {/*<CreateOrJoinTeamContextProvider>*/}
                        <ProfilePersonCreateOrJoinTeam/>
                        {/*</CreateOrJoinTeamContextProvider>*/}

                        <ProfilePersonBio/>

                        <ProfilePersonGames
                            title={__("My missions")}
                            type={'mission'}
                            uuid={uuid}
                            image="/images/cp-box-new-missions.svg"
                        />
                        <ProfilePersonGames
                            title={__("My courses")}
                            type={'learn'}
                            uuid={uuid}
                            image="/images/cp-box-new-activities-2.svg"
                        />
                    </div>
                </RenderIf>
                <RenderIf cond={queryStringFN.get("view") === "team"}>
                    <ProfileTeamView isLoading={false}/>
                </RenderIf>
                {/*<RenderIf cond={queryStringFN.get("view") === "group"}>*/}
                {/*    <ProfileGroupView groupUUID={userData?.data?.team?.group?.uuid}/>*/}
                {/*</RenderIf>*/}
                <RenderIf cond={queryStringFN.get("view") === "organisation"}>
                    <ProfileOrganisationView/>
                </RenderIf>
            </Suspense>
        </TabView>
    );
};

export default ProfilePersonMain;
