import {generalRequest} from "../services";

export async function apiListNotification(config = {}) {
    const query = new URLSearchParams(config);
    const str = query.toString();
    return generalRequest.get(`notifications?` + str, config)
}

export async function apiMarkAsRead(id) {
    return generalRequest.put(`notification/${id}/read`)
}

export async function apiAcceptNotification(id) {
    return generalRequest.post(`notification/${id}`)
}
export async function apiRejectNotification(id) {
    return generalRequest.delete(`notification/${id}`)
}