import { twMerge } from "tailwind-merge";
import RenderIf from "../../render-if";
import {CommonIcon} from "../../icon";
const VerticalBox= ({
  children,
  className,
  containerClassName,
  withBackground = false,
  icon,
  iconStroke = false,
  iconPlace = "bottom-left",
}) => {
  const getIconPlace = () => {
    if (iconPlace === "bottom-left") return ["-bottom-6 -left-6", "-bottom-4 -left-4"];
    else return ["-top-6 -right-6", "-top-4 -right-4"];
  };
  return (
    <section
      className={twMerge(
        "relative w-full rounded-t-3xl shadow-[0px_-4px_4px_0px_rgba(0,0,0,0.1)]",
        withBackground
          ? "overflow-hidden bg-gradient-to-b from-[#E1E8FE] from-20% to-transparent to-100%"
          : "bg-purple-5",
        !!icon && "overflow-hidden rounded-b-3xl bg-white",
        className
      )}
    >
      <RenderIf cond={withBackground}>
        <img
          src="/images/home-bg.svg"
          alt="bg"
          className="absolute left-0 top-0 -translate-y-1/2 scale-125 md:left-1/2 md:-translate-x-1/2"
        />
      </RenderIf>

      <RenderIf cond={!!icon}>
        <div className={twMerge("absolute h-40 w-40 rounded-full bg-linear-rose-80/5", getIconPlace()[0])} />
        <div className={twMerge("absolute h-20 w-20 rounded-full bg-linear-rose-80/3", getIconPlace()[1])} />
        <CommonIcon
          icon={icon}
          className="absolute bottom-2 left-2 h-8 w-8 opacity-30"
          fill={iconStroke ? "transparent" : "#E22D79"}
          stroke={iconStroke ? "#E22D79" : "transparent"}
        />
      </RenderIf>
      <div
        className={twMerge("relative z-[1] flex w-full flex-col gap-6", !!icon && "items-start", containerClassName)}
      >
        {children}
      </div>
    </section>
  );
};

export default VerticalBox;
