import React, {useEffect, useState} from "react";
import QuizViewOption from "./components/option";
import QuizViewQuestion from "./components/question";
import {useGetMissionRunnerDetail, useSendMissionRunnerReport} from "../../../feature/mission/api/missionRunner";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {ToastErrorApiResult} from "../../helper/funcrions";
import __ from "../../utils/translate/translate";

const QuizView = ({content}) => {
    const {id, stepId, runnerId} = useParams();
    const [answer, setAnswer] = useState();
    const {data: missionRunnerData} = useGetMissionRunnerDetail({runnerId});
    const {mutate: sendAnswer, isLoading, isError, error, isSuccess, data} = useSendMissionRunnerReport();
    const handleClick = (optionID) => {
        setAnswer(optionID);
        sendAnswer({
            runnerId: missionRunnerData.data?.id,
            stepId: stepId,
            contentId: content.id,
            userAnswer: optionID
        },{
            onSuccess : function(result) {
                if(result.responseCode === 'UPDATED')
                {
                    toast.info(__('Your answer has been updated.'))
                }
                else
                {
                    toast.info(__('Your answer has been saved.'))
                }
            },
            onError : function(result) {

                ToastErrorApiResult(result)
            }
        });
    };
    useEffect(() => {
        if (!missionRunnerData?.data) return;
        const contents = missionRunnerData?.data?.reports.filter((report) => report.missionContentId === content.id)
        setAnswer(contents?.at(0)?.json?.userAnswer);
    }, [missionRunnerData?.data]);

    return (
        <div className="flex flex-col gap-8">
            {/*<GameStepTimer/>*/}
            <div>
                <QuizViewQuestion question={content.title}/>
                <div className="flex w-full flex-col gap-2 px-2 py-3 md:w-fit">
                    {(content?.json?.questions ?? []).map((option, iO) => (
                        <QuizViewOption
                            key={iO + '-' + option?.uuid}
                            active={answer === option.uuid}
                            id={option.uuid}
                            onClick={(optionID) => handleClick(option.uuid)}
                            option={option.title}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default QuizView;
