
const ProfileHeader = ({ children }) => {
    return (
        <div className="relative -mb-8 overflow-hidden bg-linear-purple-2-0 ">
            <img alt="background" src="/images/profile-header-bg.svg" className="absolute top-0 w-full lg:-top-40 bg-cover opacity-25" />
            <div className="relative z-[1] px-4 py-20">{children}</div>
        </div>
    );
};

export default ProfileHeader;
