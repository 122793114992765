import RenderSwitch from "../../render-switch";
import Typo from "../../typo";
import RenderIf from "../../render-if";
import {Link} from "react-router-dom";
import __ from "../../../utils/translate/translate";

const MissionNotFound= ({
  isPrivate = false,
  userUUID,
  defaultText = __("Mission not found!"),
}) => {
  return (
    <div className="flex flex-col items-center gap-1">
      <img src="/images/cp-box-new-missions.svg" className="grayscale" />
      <RenderSwitch
        conds={[isPrivate && false === userUUID, isPrivate && false !== userUUID]}
        _default={<Typo>{defaultText}</Typo>}
      >
        {[
          <div key={0} className="flex flex-col items-center">
            <Typo>{__("You havent completed a mission yet!")}</Typo>
            <RenderIf cond={"" === "success"}>
              <Link to="" className="text-sm text-gray-2 hover:underline">
                  {__("Go to missions")}
              </Link>
            </RenderIf>
          </div>,
          <Typo key={1}>{__("He has not done a mission yet!")}</Typo>,
        ]}
      </RenderSwitch>
    </div>
  );
};

export default MissionNotFound;
