import IconButton from "../../../../core/components/icon-button";
import Typo from "../../../../core/components/typo";
import {CommonIcon} from "../../../../core/components/icon";
import __ from "../../../../core/utils/translate/translate";

const Section08 = () => {
    return (
        <div className="grid gap-x-4 rounded-t-3xl bg-linear-purple-7 p-4 lg:grid-cols-2 lg:px-52 lg:pt-12">
            <div className="relative flex flex-col gap-4">
                <IconButton
                    onClick={() => window.scrollTo({behavior: "smooth", top: 0})}
                    className="absolute -top-16 border-2 border-purple-2"
                >
                    <CommonIcon icon="chevron" className="rotate-90"/>
                </IconButton>
                <Typo type="on-accent">{__("Phone")}: 02191007858</Typo>
                <Typo type="on-accent">{__("Email")}: info@nojahan.ir</Typo>
                <Typo type="on-accent">{__("All rights of this website belong to Nojohan team.")}</Typo>
            </div>
            <div className="flex items-end justify-center gap-4 p-8 lg:flex-col">
                <div className="flex aspect-square w-20 items-center justify-center rounded-xl bg-white">
                    <CommonIcon icon="document"/>
                </div>
                <div className="flex aspect-square w-20 items-center justify-center rounded-xl bg-white p-2">
                    <img src="/images/logo.svg" alt="logo" className="w-full"/>
                </div>
            </div>
        </div>
    );
};

export default Section08;
