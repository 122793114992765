import {useQuery} from "@tanstack/react-query";
import {getPosts} from "../../../data/api/post";
import {apiGetMissions} from "../../../data/api/mission";

export const useGetPosts = (config) => {
    return useQuery({
        queryKey: ['home', 'news', config.tag, config.type, config.position],
        queryFn: () => getPosts(config),
        ...config,
    })
}

export const useGetMissions = (config) => {
    return useQuery({
        queryKey: ['home', 'missions', config],
        queryFn: () => apiGetMissions(config),
        ...config,
    })
}