import {motion} from "framer-motion";
import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {twMerge} from "tailwind-merge";
import {InputLabel} from "..";
import RenderIf from "../../render-if";
import Typo from "../../typo";

const TextArea = (
  {
    scale = "full",
    label = "",
    inputMode = "text",
    dir = "rtl",
    value = "",
    autoFocus = false,
    maxLength,
    required = false,
    rules,
    name,
    liveValidate = false,
    onChange,
    onBlur,
    inputClassName,
    className,
    cols,
    rows = 5,
    placeholder,
      disabled,
    onKeyDown,
  },
  ref
) => {
  const [text, setText] = useState("");
  const [error, setError] = useState("");

    useEffect(() => {
        if (!!value){
            setText(value)
        } else {
            setText("")
        }
    }, [value]);

  const refInput = useRef(null);

  const handleChange = (event) => {
    onChange?.(event);
    setText(event.target.value);
  };

  useImperativeHandle(ref, () => ({
    getValue() {
      return text.trim();
    },
    setValue(value) {
      setText(value ?? "");
    },
    focus() {
      refInput.current.focus();
    },
    setError(error) {
      refInput.current.focus();
      setError(error);
    },
    reset() {
      setText("");
    },
  }));

  return (
    <div className={twMerge("flex flex-col gap-2", scale === "fit" ? "w-fit" : "w-full", className)}>
      <InputLabel label={label} required={required} />
      <motion.textarea
        onBlur={onBlur}
        disabled={disabled}
        animate={{
          x: error.length > 0 ? [20, -20, 15, -15, 10, -10, 0] : [],
        }}
        transition={{ duration: 0.5 }}
        className={twMerge(
          "rounded-lg border p-2",
          scale === "fit" ? "w-fit" : "w-full",
          inputClassName,
          error.length > 0 ? "border-red" : "border-purple-4 focus:border-purple-1"
        )}
        dir={dir}
        inputMode={inputMode}
        value={text}
        onChange={handleChange}
        ref={refInput}
        autoFocus={autoFocus}
        maxLength={maxLength}
        rows={rows}
        cols={cols}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
      />
      <RenderIf cond={error.length > 0}>
        <motion.div animate={{ y: 0, opacity: 1 }} initial={{ y: -20, opacity: 0 }}>
          <Typo as="p" size="xs" type="danger">
            {error}
          </Typo>
        </motion.div>
      </RenderIf>
    </div>
  );
};

export default forwardRef(TextArea);
