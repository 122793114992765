import {NOJAHAN_STATISTICS} from "./data";
import Typo from "../../../../core/components/typo";
import RenderIf from "../../../../core/components/render-if";
import __ from "../../../../core/utils/translate/translate";


const IntroRow07 = () => {

    return (
        <section className="flex flex-col gap-6 px-4 ">
            <div>
                <Typo as="h1" size="xl" weight="bold" className="text-center">
                    {__("What about the Nojahan member?")}
                </Typo>
                <Typo className="text-center">
                    {__("Follow the statistics of Nojahan and the activities of Nojahan special agents from this section:")}
                </Typo>
            </div>
            <div className="grid grid-cols-2 gap-4 px-6 md:grid-cols-4">
                {NOJAHAN_STATISTICS.map((statistics, i) => (
                    <div className="col-span-1 flex flex-col items-center justify-center gap-3" key={'NOJAHAN_STATISTICS'+ i}>
                        <img src={statistics.image} loading="lazy" alt="nojahan-team"/>
                        <div className="flex flex-col items-center justify-center">
                            <Typo size="4xl" weight="bold" type="accent">
                                <RenderIf cond={statistics.id === "users"}>
                                    51,000+
                                </RenderIf>
                                <RenderIf cond={statistics.id === "teams"}>
                                    9,000+
                                </RenderIf>
                                <RenderIf cond={statistics.id === "groups"}>
                                    1,600+
                                </RenderIf>
                                <RenderIf cond={statistics.id === "organizations"}>
                                    2,000+
                                </RenderIf>
                            </Typo>
                            <Typo size="xs" type="secondary" className="text-center">
                                {statistics.title}
                            </Typo>
                        </div>
                    </div>
                ))}
            </div>
            <div className="px-4">
                <img src="/images/intro-map.png" alt="map" className="mx-auto"/>
            </div>
        </section>
    );
};

export default IntroRow07;
