import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";
import {RoadMapIcon} from "../../icon";
import Typo from "../../typo";
import {useAuthContext} from "../../../../feature/Auth/provider/AuthProvider";
import {useEffect} from "react";

const RoadMapStep= ({
  icon,
  index,
  currentIndex,
  isLast = false,
  title,
  onClick,
    inPage,
    freeze
}) => {
    const {dashboard} = useAuthContext()
  return (
    <div className="flex min-w-[70px] flex-col">
      <motion.div
        onClick={() => onClick?.(index)}
        className="m-4 h-14 cursor-pointer"
        animate={{
          y: index === currentIndex ? -20 : 0,
        }}
        transition={{
          duration: 0.5,
          repeat: index === currentIndex ? Infinity : 0,
          repeatType: "reverse",
        }}
      >
        <RoadMapIcon icon={icon} />
      </motion.div>
      <div className="flex items-center">
        <div
          className={twMerge(
            "flex-1 border-t-4 border-dashed border-white",
            index === 0 && "invisible",
            index <= currentIndex && "border-solid border-purple-1 "
          )}
        />
        <div
          className={twMerge(
            "h-4 w-4 rounded-full border-2 border-purple-1 bg-white",
            (index < currentIndex) && "bg-purple-1",
              (inPage ==="home" ? dashboard?.missionRunningCount > 0 : freeze) && "bg-purple-1"
          )}
        />
        <div
          className={twMerge(
            "flex-1 border-t-4 border-dashed border-white",
            isLast && "invisible",
            index < currentIndex && "border-solid border-purple-1 "
          )}
        />
      </div>
      <motion.div
        animate={{
          x: index === currentIndex ? [10, -10, 5, -5, 0] : 0,
          scale: index === currentIndex ? [1.3, 1] : 1,
        }}
        transition={{ duration: 0.5, repeat: Infinity, repeatDelay: 2 }}
      >
        <Typo
          onClick={() => onClick?.(index)}
          as="h2"
          className={twMerge("mt-4 text-center", index === currentIndex ? "cursor-pointer " : "cursor-auto")}
          size="xs"
          type={(index < currentIndex) || isLast ? "accent" : "secondary"}
          weight={index < currentIndex ? "semi-bold" : "normal"}
        >
          {title}
        </Typo>
      </motion.div>
    </div>
  );
};

export default RoadMapStep;
