import useQueryString from "../../core/hooks/useQueryString";
import {useEffect, useMemo} from "react";
import JoinRequests from "../../core/components/join-requests";
// import ParentRequests from "./components/parent-requests";
import TabView from "../../core/components/tab-view";
import RenderIf from "../../core/components/render-if";
import ScoreList from "./components/score-list";
import NotificationList from "./components/notification-list";
import __ from "../../core/utils/translate/translate";
import MainLayout from "../../layout/mainLayout/mainLayout";
import RootLayout from "../../layout/root";
import MainFooter from "../../layout/mainLayout/mainFooter";

const NotificationPage = () => {
    // const { setSimpleHeaderTitle } = useSimpleHeader();

    const { queryStringFN } = useQueryString();

    const memoTabs = useMemo(() => {
        return [
            {id: "all", title: __("all")},
            {id: "voting", title: __("voting")},
            {id: "score", title: __("score")},
            {id: "experience", title: __("experience")},
            {id: "team", title: __("team")},
            {id: "organization", title: __("organization")},
            {id: "report", title: __("report")},
            {id: "exam", title: __("exam")},
            // {id: "parent", title: __("parents")},
        ];
    }, []);

    const getActiveIndex = () => {
        return queryStringFN.get("name");
    };

    const handleChangeTab = (tab) => {
        queryStringFN.init({ name: tab.id });
    };

    useEffect(() => {
        // setSimpleHeaderTitle(__("title"));
    }, []);

    return (
        <RootLayout title={__("Bell")} navigateTo={-1}>
            <div className="flex flex-col gap-8 pt-24">
                {/*<JoinRequests/>*/}
                {/*<ParentRequests/>*/}
                <TabView tabs={memoTabs} activeTab={getActiveIndex()} onClick={handleChangeTab}>
                    <RenderIf
                        cond={["all", "voting", "team", "organization", "report", "exam", "parent"].includes(
                            queryStringFN.get("name", "all")
                        )}
                        falsyElement={<ScoreList/>}
                    >
                        <NotificationList/>
                    </RenderIf>
                </TabView>
            </div>
            <MainFooter />
        </RootLayout>
    );
};

export default NotificationPage;
