import {generalRequest} from '../services'

export async function getMyTeams(data) {
    return generalRequest.get('student/teams', data)
}


export async function leftMyTeams(team_user_id) {
    return generalRequest.delete('student/team/' + team_user_id)
}

export async function apiJoinTeam(data) {
    return generalRequest.post('student/team/join', data)
}

export async function apiTeamDetail(teamId) {
    return generalRequest.get(`student/team/${teamId}`)
}

export async function apiCreateTeam(data) {
    return generalRequest.post('student/team', data)
}
export async function apiUpdateTeam(teamId,data) {
    return generalRequest.put(`student/team/${teamId}`, data)
}
export async function apiChangeHead(teamId,newHeadUserId){
    return generalRequest.post(`student/team/change_head/${teamId}/${newHeadUserId}`)
}
export async function getMyTeamsList(data) {
    return generalRequest.get('student/teams/organization', data)
}

export async function apiListTeams(config ={}) {
    const query = new URLSearchParams(config);
    const str = query.toString();
    return generalRequest.get('student/teams/organization?'+ str, config)
}