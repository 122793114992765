import {useRef, useState} from "react";
import {DescriptionTitleModal} from "../description-modal";
import {InputLabel, TextInput} from "../../../../../../../../core/components/input";
import Button from "../../../../../../../../core/components/button";
import RenderIf from "../../../../../../../../core/components/render-if";
import Modal from "../../../../../../../../core/components/modal";
import handleError from "../../../../../../../../core/helper/handelError";
import {useJoinTeam} from "../../../../../../api/team";
import {useGetTeams} from "../../../../../../../Auth/api/auth";
import {useNavigate} from "react-router-dom";
import __ from "../../../../../../../../core/utils/translate/translate";

const ProfilePersonJoinToTeam = ({onChange}) => {
    // const { setStatus } = useContext(CreateOrJoinTeamContext);
    const [modal, setModal] = useState("idle");
    const navigate = useNavigate()

    const refInputs = {
        inviteCode: useRef(null),
    };
    const {
        refetch
    } = useGetTeams({
        manual: true,
    })

    const {mutate: joinTeam, isPending, isError, error, isSuccess, data} = useJoinTeam();

    const handleJoin = () => {
        joinTeam({
            code: refInputs.inviteCode.current.getValue(),
        }, {
            onSuccess: (data) => {
                refetch();
                navigate('/app/profile?view=person')
            },
            onError: (error) => {
                handleError(error)
            }
        })
    }

    return (
        <>
            <div>
                <InputLabel label={__("Invite code")}/>
                <div className="flex items-center rounded-xl border border-purple-4 bg-white">
                    <TextInput
                        name={__("Invite code")}
                        required
                        maxLength={12}
                        ref={refInputs.inviteCode}
                        className="flex-1"
                        inputClassName="border-none"
                        dir="ltr"
                        type="number"
                        inputMode="numeric"
                    />
                    <div className="border-r border-purple-4 px-2">-T</div>
                </div>
            </div>
            <div className="flex w-full flex-wrap items-center justify-center gap-2">
                <Button onClick={handleJoin} isLoading={isPending} scale="fit">
                    {__("Join team")}
                </Button>
                <Button type="light" onClick={() => onChange("without-team")} scale="fit">
                    {__("Not now!")}
                </Button>
                {/*isError*/}
                <RenderIf cond={false}>
                    <Button type="secondary" onClick={() => setModal("alert")} scale="fit">
                        {__("Guidance")}
                    </Button>
                </RenderIf>
            </div>

            <Modal isOpen={modal === "alert"} onClose={() => setModal("idle")} title={__("Guidance")}>
                <DescriptionTitleModal/>
            </Modal>
        </>
    );
};

export default ProfilePersonJoinToTeam;
