import {convertDigitsToWords} from "persian-helpers";
import {useParams} from "react-router-dom";
import {twMerge} from "tailwind-merge";
import GameAttribute from "../attribute";
import {useState} from "react";
import Typo from "../../../../core/components/typo";
import RenderIf from "../../../../core/components/render-if";
import {useGetMission} from "../../api/mission";
import __ from "../../../../core/utils/translate/translate";

const GameAttributes = () => {
    const {id} = useParams();
    // const [gameData, setGameData] = useState();
    // const { data: gameData } = useGameGet({ props: { uuid: uuid! } });
    const {data: gameData} = useGetMission({id});
    if (!gameData) return <></>;

    return (
        <div className="flex flex-col gap-4">
            <div className="grid grid-cols-2 items-start gap-y-4 md:grid-cols-3 xl:grid-cols-1">
                {/*<GameAttribute*/}
                {/*    cond={!!gameData.data?.startedAt}*/}
                {/*    icon="calendar"*/}
                {/*    value={}*/}
                {/*    value={t("startedAt", {*/}
                {/*        startedAt: gameData.data?.startedAt?.format("jDD jMMMM jYYYY"),*/}
                {/*    })}*/}
                {/*    description={t("atHour", {*/}
                {/*        hour: gameData.data?.startedAt?.toShamsi().format("HH:mm"),*/}
                {/*    })}*/}
                {/*/>*/}

                {/*<GameAttribute*/}
                {/*    cond={!!gameData.data?.expiredAt}*/}
                {/*    icon="calendar"*/}
                {/*    value={t("expiredAt", {*/}
                {/*        expiredAt: gameData.data?.expiredAt?.format("jDD jMMMM jYYYY"),*/}
                {/*    })}*/}
                {/*    description={t("atHour", {*/}
                {/*        hour: gameData.data?.expiredAt?.toShamsi().format("HH:mm"),*/}
                {/*    })}*/}
                {/*/>*/}

                <GameAttribute
                    cond={gameData.data?.score && gameData.data?.score > 0}
                    icon="score"
                    value={`${gameData.data?.score} ${__("Score")} `}
                />
                <GameAttribute
                    cond={gameData.data?.experience && gameData.data?.experience > 0 }
                    icon="xp"
                    value={`${gameData.data?.experience} ${__("Experience")} `}
                />
                <GameAttribute
                    cond={!!gameData.data?.settings?.find(item => item?.key === "teacher")?.value}
                    icon="two-user"
                    value={`${gameData.data?.settings?.find(item => item?.key === "teacher")?.value}`}
                />
                <GameAttribute
                    cond={!!gameData.data?.settings?.find(item => item?.key === "duration")?.value}
                    icon="calendar"
                    value={`${gameData.data?.settings?.find(item => item?.key === "duration")?.value} ${__("Minute")} `}
                />
                <GameAttribute
                    cond={gameData.data?.steps.length > 0}
                    icon="chart"
                    value={`${convertDigitsToWords(gameData.data?.steps.length)} ${__("step")}`}
                />
                <GameAttribute
                    cond={gameData.data?.type}
                    label={__("Type")}
                    icon="attachment"
                    value={`${gameData.data?.type === 'team' ? __("A team"): __("Self")}`}
                />

                <GameAttribute
                    cond={gameData.data?.capacity && gameData.data?.type !== 'team'}
                    label={__("Total capacity")}
                    icon="people"
                    value={`${gameData.data?.capacity}${__("Person")}`}
                />
                <GameAttribute
                    cond={gameData.data?.capacity && gameData.data?.type === 'team'}
                    label={__("Total capacity")}
                    icon="people"
                    value={`${gameData.data?.capacity}${__("team")}`}
                />

                <div
                    className={twMerge(
                        "col-span-2 md:col-span-3 xl:col-span-1",
                        ['self', 'team'].includes(gameData.data?.type) ? "hidden" : "visible"
                    )}
                >
                    <GameAttribute
                        cond={gameData.data?.type !== 'team' && gameData?.data?.capacity}
                        label={__("Remaining capacity")}
                        icon="people"
                        value={`${gameData.data?.capacity - gameData.data?.players}${__("person")}`}
                        // value={t("remainCapacityPerson", {
                        //     remainCapacity: gameData.data?.capacity - gameData.data?.players,
                        // })}
                    />
                    <GameAttribute
                        cond={gameData.data?.type === 'team' && gameData?.data?.capacity}
                        label={__("Remaining capacity")}
                        icon="people"
                        value={`${gameData.data?.capacity - gameData.data?.players} ${__("team")}`}
                        // value={t("remainCapacityTeam", {
                        //     remainCapacity: gameData.data?.capacity - gameData.data?.players,
                        // })}
                    />
                </div>
            </div>
            {/*<Typo>{gameData.data?.contentRate}</Typo>*/}
            {/*<RenderIf cond={gameData.data?.dependencies.length > 0}>*/}
            {/*    <Typo type="secondary" size="sm">*/}
            {/*        پیش‌نیاز‌ها:*/}
            {/*    </Typo>*/}
            {/*    {gameData.data?.dependencies.map((dependency, i) => (*/}
            {/*        <Typo type="accent" size="sm" key={i}>*/}
            {/*            {dependency.name}*/}
            {/*        </Typo>*/}
            {/*    ))}*/}
            {/*</RenderIf>*/}
        </div>
    );
};

export default GameAttributes;
