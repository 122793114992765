import React, {Fragment, useEffect, useState} from 'react';
import { Heart, MessageCircle } from 'lucide-react';
import RootLayout from "../../layout/root";
import {Link} from "react-router-dom";
import OrganizationCard from "../organization/components/organizationCard";
import {useListOrganization} from "../organization/api";
import useQueryString from "../../core/hooks/useQueryString";
import {useListExplore} from "./api";
import LoadMore from "../../core/components/loadmore";
import FileView from "../../core/components/file-view";
import FileViewExplore from "../../core/components/file-view-explore";
import Search from "../../core/components/search";
import Skeleton from "../../core/components/skeleton";
import MainLayout from "../../layout/mainLayout/mainLayout";
import ExploreFilter from "./components/filter";
import RenderIf from "../../core/components/render-if";
import {MiniLoading} from "../../core/components/loading";

function ExplorePage(props) {
    const {queryStringFN} = useQueryString();
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [lastPostRef, setLastPostRef] = useState()

    const {
        data: listExplore,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isFetching,
    } = useListExplore({
        page: 1,
        perPage: 8,
        search: queryStringFN.get("name") ?? '',
        sort: queryStringFN.get("sort") ?? '',
        order:"desc"
    });


    return (
        <MainLayout>
            <div className={'flex flex-col gap-8 px-2 lg:px-4  py-20'}>
                <div className="max-w-screen-sm mx-auto sm:max-w-7xl px-0 sm:px-4">
                    <div className={'mb-4'}>
                        <ExploreFilter/>
                    </div>
                    <RenderIf cond={!!listExplore && !(listExplore?.pages?.at(0)?.data?.length > 0)}>
                        <div className="flex flex-col items-center gap-1">
                            <img src="/images/whatsup-header.svg"
                                 className="grayscale h-64" alt={'whatsUp'}/>
                            <p className="font-normal text-base text-purple-1" dir="rtl">هیچ خبری نیست!</p>
                        </div>
                    </RenderIf>
                    <RenderIf cond={!listExplore}>
                        <div className="flex justify-center my-8">
                            <MiniLoading />
                        </div>
                    </RenderIf>
                    <div className="grid grid-cols-3 gap-[2px] sm:gap-1">
                        {listExplore?.pages.map((data, i) => (
                            <Fragment key={'fragmentListExplore' + i}>
                            {data?.data?.map((post, index) => (
                                    <div
                                        key={'explore' + post?.id + '-' + index}
                                        className="relative aspect-square group"
                                    >
                                        <Link
                                            to={`${post.id}`}
                                            // state={{backgroundLocation: location}}
                                            className="block w-full h-full"
                                        >
                                            <FileViewExplore detail={post?.fileDetail}
                                                             className={'w-full aspect-square object-cover'}
                                            />
                                            {/*<img*/}
                                            {/*    src={post?.fileDetail?.url}*/}
                                            {/*    alt={`Post ${post.id}`}*/}
                                            {/*    className="w-full h-full object-cover"*/}
                                            {/*    loading="lazy"*/}
                                            {/*/>*/}
                                            <div
                                                className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-40 transition-all duration-200 flex items-center justify-center opacity-0 group-hover:opacity-100">
                                                <div className="hidden sm:flex items-center space-x-6 text-white">
                                                    <div className="flex items-center">
                                                        <MessageCircle
                                                            className="h-6 w-6 fill-white stroke-white mr-2"/>
                                                        <span className="font-semibold mx-2">{post?.totalComment ?? 0}</span>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <Heart className="h-6 w-6 fill-white stroke-0 mr-2"/>
                                                        <span className="font-semibold mx-2">{post?.totalLike ?? 0}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </Fragment>
                        ))}
                    </div>
                    <div className={'p-4'}>
                        <LoadMore
                            auto
                            fetchNextPage={fetchNextPage}
                            hasNextPage={hasNextPage === true}
                            isFetchingNextPage={isFetchingNextPage}
                        />
                    </div>
                </div>
            </div>
        </MainLayout>
    );
}

export default ExplorePage;