import React, {Fragment} from 'react';
import LoadMore from "../../../../core/components/loadmore";
import useQueryString from "../../../../core/hooks/useQueryString";
import OrganizationCard from "../organizationCard";
import {useNavigate} from "react-router-dom";
import {useJoinOrganisationWithSearch, useListOrganization} from "../../api";
import handleError from "../../../../core/helper/handelError";
import RenderIf from "../../../../core/components/render-if";
import {MiniLoading} from "../../../../core/components/loading";

export default function OrganizationList(props) {
    const {queryStringFN} = useQueryString();
    const {mutate: joinOrganisation, isPending, isError, error, isSuccess, data} = useJoinOrganisationWithSearch()
    const joinOrgan = (code, organizationId) => {
        joinOrganisation({
            code: code,
            organizationId: organizationId
        }, {
            onSuccess: (data) => {
                window.location.href = "/app/profile"
            },
            onError: (error) => {
                handleError(error)
            }
        })
    }

    const {
        data: organizationList,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isFetching,
    } = useListOrganization({
        page: 1,
        perPage: 8,
        search: queryStringFN.get("name") ?? '',
    });

    const fullName = function (firstName, lastName){
        let x = ''
        if(!!firstName)
        {
            x += firstName
        }

        if(!!lastName)
        {
            x += ' ' + lastName
        }

        return x.trim()

    }
    return (
        <>
            <RenderIf cond={!organizationList}>
                <MiniLoading />
            </RenderIf>
            {organizationList?.pages.map((data,i)=>(
                <Fragment key={'fragmentListOrganization' + i}>
                    {data?.data?.map((organization, i) => (
                        <OrganizationCard
                            key={organization?.id + 'organization' + 'list' + i}
                            image={organization?.logoDetail}
                            onClick={() => joinOrgan(organization?.inviteCode, organization?.id)}
                            name={organization?.name}
                            slogan={organization?.settingsPretty?.slogan}
                            description={organization?.settingsPretty?.description}
                            province={organization?.provinceDetail?.name}
                            city={organization?.cityDetail?.name}
                            owner={fullName(organization?.ownerDetail?.firstName,organization?.ownerDetail?.lastName)}
                        />
                    ))}
                </Fragment>
            ))}
            <LoadMore
                auto
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage === true}
                isFetchingNextPage={isFetchingNextPage}
            />

        </>
    );
}