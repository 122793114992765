import {motion} from "framer-motion";
import {useState} from "react";
import {twMerge} from "tailwind-merge";
import {steps} from "./steps";
import ScrollView from "../../../../../../core/components/scroll-view";
import Typo from "../../../../../../core/components/typo";
import RenderIf from "../../../../../../core/components/render-if";
import IconButton from "../../../../../../core/components/icon-button";
import {CommonIcon} from "../../../../../../core/components/icon";
import RoadMapStep from "../../../../../../core/components/road-map-step/center";
import __ from "../../../../../../core/utils/translate/translate";

const ProfileTeamRoadMap = ({team, freeze}) => {

    const [isCopy, setIsCopy] = useState(false);

    const getDoneIndex = () => {
        if (team?.freeze === true) {
            return 3
        }
        return 1;
    };
    const handleCopyToClipboard = (value) => {
        navigator.clipboard.writeText(value);
        setIsCopy(true);
    };

    return (
        <div className="relative z-[1] flex w-full flex-col items-center gap-8">
            <Typo as="h1" size="xl" weight="bold" type="accent">
                {__("Team status")}
            </Typo>

            <ScrollView className="-mx-8">
                {steps.map((step, i) => (
                    <RoadMapStep
                        key={'RoadMapStep' + 'teamView' + step?.id + '=' + i}
                        index={i}
                        inPage={`team`}
                        freeze={freeze}
                        currentIndex={getDoneIndex()}
                        isLast={i === steps.length - 1}
                        icon={step.icon}
                        title={step.title}
                    />
                ))}
            </ScrollView>

            <RenderIf cond={team}>
                <div>
                    <Typo as="h2" size="sm" weight="medium">
                        {__("Invite your friends to the team")}
                    </Typo>
                    <Typo as="p" type="secondary" weight="light" size="xs">
                        {__("You can invite your friends to the team by sending the invitation code below.")}
                    </Typo>
                </div>
                <div className="flex w-full flex-col items-end gap-1 md:w-fit">
                    <div className="flex w-full items-center gap-2 rounded-xl bg-purple-4 p-1">
                        <IconButton
                            onClick={() => handleCopyToClipboard(team?.inviteCode.split("-").at(1) ?? "")}
                            className="bg-white shadow-xl"
                        >
                            <CommonIcon icon="document-copy"/>
                        </IconButton>

                        <Typo className="flex-1 overflow-x-auto px-2">{team?.inviteCode}</Typo>
                    </div>

                    <motion.div
                        animate={{scale: isCopy ? 1 : 0.3, opacity: isCopy ? 1 : 0}}
                        initial={{scale: 0.9, opacity: 0}}
                    >
                        <Typo
                            type="on-accent"
                            className={twMerge("rounded-full bg-green p-1 px-2", isCopy ? "block" : "invisible")}
                            size="xs"
                        >
                            {__("Copied!")}
                        </Typo>
                    </motion.div>
                </div>
            </RenderIf>
        </div>
    );
};

export default ProfileTeamRoadMap;
