import NewsBox from "./components/news-box";
import HomeNewsBoxLoading from "./components/news-box/loading";
import {useGetPosts} from "../../api/home";
import Title from "../../../../core/components/title";
import RenderIf from "../../../../core/components/render-if";
import ScrollView from "../../../../core/components/scroll-view";
import TitleBar from "../../../../core/components/title-bar";
import {AllCard} from "../../../../core/components/card";
import {useNavigate} from "react-router-dom";
import __ from "../../../../core/utils/translate/translate";

const HomeNews = ({title, tag, type, position}) => {

    const navigate = useNavigate();
    let config = {}
    if(tag) { config.tag = tag; }
    if(type) { config.type = type; }
    if(position) { config.position = position; }
    config.per_page = 5;

    const {data} = useGetPosts(config);

    if (!!data && data.data?.length === 0) return <></>;

    return (
        <div className="flex flex-1 shrink-0 flex-col gap-2 overflow-hidden px-4">
            <Title
                title={title}
                // path={(data?.meta?.total ?? 0) > 15 ? "/test" : undefined}
            />

            <ScrollView className="-mx-4 gap-4 px-4">

                {data?.data?.map((news, i) => (
                    <NewsBox key={'newsHome'+ news?.id + '-' + i} title={news.title} cover={news.coverDetail} id={news.id} url={news.url}/>
                ))}
                <RenderIf cond={data?.meta?.total > 5}>
                    <AllCard
                        className={'bg-white/20 text-black'}
                        hiddenIcon={true}
                        onClick={() => {
                        navigate('/app/news')
                    }}>
                        <span className={'text-black/35'}>
                            {__("Load more")}
                        </span>
                    </AllCard>
                </RenderIf>

                <RenderIf cond={!data}>
                    {Array.from({length: 5}).map((_, i) => (
                        <HomeNewsBoxLoading key={'HomeNewsBoxLoading' + i}/>
                    ))}

                </RenderIf>
            </ScrollView>
        </div>
    );
};

export default HomeNews;
