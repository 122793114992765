import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {useAuthContext} from "../../../Auth/provider/AuthProvider";
import VerticalBox from "../../../../core/components/box/vertical";
import Avatar from "../../../../core/components/avatar";
import Typo from "../../../../core/components/typo";
import RenderIf from "../../../../core/components/render-if";
import IconButton from "../../../../core/components/icon-button";
import {CommonIcon} from "../../../../core/components/icon";
import Modal from "../../../../core/components/modal";
import {TextArea, TextInput} from "../../../../core/components/input";
import Button from "../../../../core/components/button";
import {useUpdateTeam} from "../../api/team";
import {toast} from "react-toastify";
import handleError from "../../../../core/helper/handelError";
import __ from "../../../../core/utils/translate/translate";

const ProfileCommunityBox = ({ bio, name, avatar, id, type,refetch }) => {
  const { id: uuidParam } = useParams();
  const { team } = useAuthContext();

  const [modal, setModal] = useState("idle");

  const refInputs = {
    name: useRef(null),
    description: useRef(null),
  };
  const {mutate: updateTeam} = useUpdateTeam()

  const handleUpdate = () =>{
    updateTeam(
        {
          teamId: id,
          data: {
            name: refInputs.name.current.getValue() ?? '',
            description: refInputs.description.current.getValue() ?? '',
          }
        },
        {
          onSuccess: (data) => {
            refetch()
            setModal("idle")
            toast.success(data?.message)
          },
          onError: (error)=>handleError(error)
        }
    )
  }


  const handleNavigate = () => {

  };

  return (
    <>
      <VerticalBox className="w-full shrink-0 p-4" icon="quote">
        <div className="flex w-full items-center gap-4">
          <Avatar name={name} image={avatar} />
          <Typo as="h2" weight="bold" className="ui-clamp-1 flex-1">
            {name}
          </Typo>
          <RenderIf cond={false}>
            <IconButton onClick={handleNavigate} className="bg-white shadow">
              <CommonIcon icon="eye" />
            </IconButton>
          </RenderIf>
          <RenderIf cond={team?.teamRole === "head"}>
            <IconButton onClick={() => setModal("edit")} className="bg-white shadow">
              <CommonIcon icon="edit" />
            </IconButton>
          </RenderIf>
        </div>
        <Typo as="p" className="ui-clamp-3">
          {bio}
        </Typo>
      </VerticalBox>
      <Modal
        isOpen={modal === "edit" && type === 1}
        title={__("Edit team profile")}
        onClose={() => setModal("idle")}
        className="flex flex-col gap-4 pb-4"
      >
        <TextInput
          name={__("Team name")}
          label={__("Team name")}
          maxLength={64}
          required
          rules={[{ rule: "minLength", cond: 2 }]}
          ref={refInputs.name}
          value={name}
        />
        <TextArea
          name={__("Description")}
          label={__("Description")}
          maxLength={200}
          ref={refInputs.description}
          value={bio}
        />
        <div className="flex items-center gap-4">
          <Button scale="full" onClick={()=>{handleUpdate()}} isLoading={false}>
            {__("Edit")}
          </Button>
          <Button scale="full" type="light" onClick={() => setModal("idle")}>
            {__("Refuse")}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ProfileCommunityBox;
