import {createBrowserRouter, Navigate} from 'react-router-dom'
import {LoginScreen} from '../feature/Auth/LoginScreen'
import PrivateRoute from './PrivateRoot'
import {TestScreen} from '../feature/Test'
import {LandingScreen} from "../feature/landing";
import AuthLayout from "../layout/AuthLayout";
import RulesScreen from "../feature/Auth/rulesScreen";
import PrivacyScreen from "../feature/Auth/privacyScreen";
import OtpScreen from "../feature/Auth/otpScreen";
import GenderScreen from "../feature/Auth/genderScreen";
import {HomeScreen} from "../feature/home";
import NewsPage from "../feature/news";
import {ProfileScreen} from "../feature/profile";
import ProfileEditPage from "../feature/profile/edit";
import MissionsPage from "../feature/missions";
import ClubPage from "../feature/club";
import MissionPage from "../feature/mission";
import MissionStepPage from "../feature/mission/mission-step";
import AwardsPage from "../feature/awards";
import AwardDetailPage from "../feature/awards/award";
import LoginAs from "../feature/Auth/LoginAs";
import NewsListPage from "../feature/news/list";
import NotificationPage from "../feature/notification";
import MissionVotingPage from "../feature/mission/voting";
import GameReportCard from "../feature/mission/report-card";
import Language from "../feature/Auth/Language";
import {LoginByPasswordScreen} from "../feature/Auth/LoginByPasswordScreen";
import {RecoveryPasswordScreen} from "../feature/Auth/RecoveryPasswordScreen";
import OrganizationsPage from "../feature/organization";
import ExplorePage from "../feature/explore";
import ReportView from "../feature/explore/[id]";
import OrganizationJoinPage from "../feature/organizationPage";
import TeamsPage from "../feature/teams";

const router = createBrowserRouter([
    {
        path: `/`,
        // element: <LandingScreen/>,
        element: <Navigate to="/app" replace/>
    },
    {
        path: `/landing`,
        element: <LandingScreen/>,
    },
    {
        path: `login-as`,
        element: <LoginAs/>,
    },
    {
        path: `language`,
        element: <Language/>,
    },
    {
        path: `o/:username`,
        element: <OrganizationJoinPage/>,
    },
    {
        path: '/app',
        element: <PrivateRoute/>,
        children: [
            {
                index: true,
                element: <HomeScreen/>,
            },
            {
                path: `notification`,
                element: <NotificationPage />,
            },
            {
                path: `missions`,
                element: <MissionsPage/>,
            },
            {
                path: `missions/:id`,
                element: <MissionPage/>,
            },
            {
                path: `missions/:missionId/:runnerId/voting`,
                element: <MissionVotingPage/>,
            },
            {
                path: `missions/:id/:runnerId/step/:stepId`,
                element: <MissionStepPage/>,
            },
            {
                path: `missions/:id/report-card`,
                element: <GameReportCard/>,
            },
            {
                path: `club`,
                element: <ClubPage/>,
            },
            {
                path: `awards`,
                element: <AwardsPage/>,
            },
            {
                path: `award/:id`,
                element: <AwardDetailPage/>,
            },
            {
                path: `organizations`,
                element: <OrganizationsPage/>,
            },
            {
                path: `teams`,
                element: <TeamsPage/>,
            },
            {
                path: `explore`,
                element: <ExplorePage/>,
            },
            {
                path: `explore/:id`,
                element: <ReportView/>,
            },
            {
                path: `news`,
                element: <NewsListPage/>,
            },
            {
                path: `news/:id`,
                element: <NewsPage/>,
            },
            {
                path: `profile`,

                children: [
                    {
                        index: true,
                        element: <ProfileScreen/>,
                    },
                    {
                        path: `edit`,
                        element: <ProfileEditPage/>,
                    }
                ]
            },
            {
                path: `test`,
                element: <TestScreen/>,
            }
        ],
    },
    {
        path: '/authenticate',
        element: <AuthLayout/>,
        children: [
            {
                path: `passwd`,
                element: <LoginByPasswordScreen/>,
            },
            {
                path: `recovery`,
                element: <RecoveryPasswordScreen/>,
            },
            {
                path: `login/*`,
                element: <LoginScreen/>,
            },
            {
                path: `otp/:mobile/:otpToken`,
                element: <OtpScreen/>,
            },
            {
                path: `gender`,
                element: <GenderScreen/>,
            }, {
                path: `rules`,
                element: <RulesScreen/>,
            },
            {
                path: `privacy`,
                element: <PrivacyScreen/>,
            },
        ],
    },

    {
        path: '*',
        element: <div className={'absolute  flex justify-center items-center text-6xl'}>404</div>,
    },
])

export default router
