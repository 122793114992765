import Spinner from "../../spinner";

const MiniLoading = ({type = 'accent'}) => {
    return (
        <div className="p-10">
            <Spinner type={type}/>
        </div>
    );
};

export default MiniLoading;
