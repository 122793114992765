import React from "react";
import Typo from "../../../typo";

const QuizViewQuestion = ({index, question}) => {
    return (
        <div className="flex items-start gap-2  border-b border-b-purple-1 p-2">
            <Typo
                className="flex h-4 w-4 items-center justify-center rounded-full bg-purple-4"
                type="accent"
                size="3xl"
                weight="bold"
            >
                {index}
            </Typo>
            <Typo weight="bold">{question}</Typo>
        </div>
    );
};

export default QuizViewQuestion;
