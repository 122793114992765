import Modal from "../../../../../../core/components/modal";
import Typo from "../../../../../../core/components/typo";
import Button from "../../../../../../core/components/button";
import {clearTokensAndRedirect} from "../../../../../Auth/services/tokenService";
import __ from "../../../../../../core/utils/translate/translate";


const LogoutModal = ({isOpen, onClose}) => {


    return (
        <Modal
            className="flex flex-col gap-4"
            isOpen={isOpen}
            onClose={onClose}
            title={__("Sign out")}
        >
            <Typo>{__("Do you really want to sign out?")}</Typo>
            <div className="flex gap-4">
                <Button onClick={() => {
                    clearTokensAndRedirect()
                }} isLoading={false}>
                    {__("Yes")}
                </Button>
                <Button type="light" onClick={() => onClose?.()}>
                    {__("No")}
                </Button>
            </div>
        </Modal>
    );
};

export default LogoutModal;
