
import Typo from "../../typo";
import __ from "../../../utils/translate/translate";

const CommentNotFond= ({
                            isPrivate = false,
                            userUUID,
                            defaultText = __("Mission not found!"),
                        }) => {
    return (
        <div className="flex flex-col items-center gap-1">
            {/*<img src="/images/home-bg.png" className="grayscale" />*/}
            <Typo className={'my-8'}>دیدگاهی ثبت نشده</Typo>
        </div>
    );
};

export default CommentNotFond;
