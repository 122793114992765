import {useInfiniteQuery, useMutation} from "@tanstack/react-query";
import {apiDeleteComment, apiListComments, apiPostComment, apiUpdateComment} from "../../../../data/api/comment";

export const useListComments = (args, config) => {
    return useInfiniteQuery({
        queryKey: ['comments', args],
        queryFn: ({pageParam = 1}) => apiListComments({...args, page: pageParam}),
        ...config,
        getNextPageParam: lastPage =>
            lastPage.meta.currentPage < lastPage.meta.lastPage ? lastPage.meta.currentPage + 1 : undefined,
    })
}
export const usePostComment = () => {
    return useMutation({
        mutationFn: (data) => apiPostComment(data)
    })
}
export const useUpdateComment = () => {
    return useMutation({
        mutationFn: ({id, data}) => apiUpdateComment(id,data)
    })
}
export const useDeleteComment = () => {
    return useMutation({
        mutationFn: ({id}) => apiDeleteComment(id),
    })
}