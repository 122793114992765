import {SwiperSimpleCarousel} from "../../core/components/swiper";
import {SwiperSlide} from "swiper/react";
import Skeleton from "../../core/components/skeleton";

const GameLoading = () => {
  return (
    <div className="lg:px-52 flex flex-col gap-8 px-4 py-20">
      <div className="sm:hidden">
        <SwiperSimpleCarousel className="aspect-4/3 w-full xs:w-3/4">
          <SwiperSlide className="w-full">
            <Skeleton className="h-full w-full rounded-xl" />
          </SwiperSlide>
        </SwiperSimpleCarousel>
      </div>
      <div className="hidden gap-4 sm:flex">
        {Array.from({ length: 3 }).map((_, i) => (
          <Skeleton key={'missionLoadingSkeleton'+'-'+i} className="aspect-4/3 w-96 rounded-xl" />
        ))}
      </div>

      <div className="ga-4 flex items-center justify-between">
        <Skeleton className="h-3 w-1/4" />
        <div className="flex items-center gap-1">
          <Skeleton className="h-7 w-16 rounded-full" />
          <Skeleton className="h-10 w-10 rounded-full" />
        </div>
      </div>

      <div className="grid gap-y-8 xl:grid-cols-3">
        <div className="grid grid-cols-2 gap-x-4 gap-y-4 xl:order-2 xl:grid-cols-1 xl:border-r xl:border-purple-3 xl:p-3">
          {Array.from({ length: 3 }).map((_, i) => (
            <div key={'gameLoadingSkeleton'+'-'+i} className="flex items-center gap-2">
              <Skeleton className="h-4 w-4" />
              <Skeleton className="h-2 w-full xl:w-1/2" />
            </div>
          ))}
        </div>
        <div className="xl:order-1 xl:col-span-2 xl:pl-12">
          <div className="flex flex-col gap-2">
            <Skeleton className="h-2 w-full" />
            <Skeleton className="h-2 w-full" />
            <Skeleton className="h-2 w-full" />
            <Skeleton className="h-2 w-1/2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameLoading;
