import { twMerge } from "tailwind-merge";
import Typo from "../../typo";

const Badge= ({
  children,
  className,
  size = "2xs",
  weight = "bold",
  type = "primary",
}) => {
  const getBackgroundColor = () => {
    if (type === "primary") return "bg-orange-1";
    if (type === "secondary") return "bg-purple-1";
    else return "bg-purple-4";
  };

  const getTextColor = () => {
    if (type === "primary") return "on-accent";
    if (type === "secondary") return "on-accent";
    else return "accent";
  };

  if (children == 0) return <></>;
  return (
    <Typo
      type={getTextColor()}
      size={size}
      weight={weight}
      className={twMerge("flex h-4 w-4 items-center justify-center rounded-full ", className, getBackgroundColor())}
    >
      {children}
    </Typo>
  );
};

export default Badge;
