import {Link, useNavigate} from "react-router-dom";
import {useAuthContext} from "../../../../../Auth/provider/AuthProvider";
import useQueryString from "../../../../../../core/hooks/useQueryString";
import RenderIf from "../../../../../../core/components/render-if";
import Typo from "../../../../../../core/components/typo";
import AwardMainCardLoading from "../../../award-card/main/loading";
import AwardMainCard from "../../../award-card/main";
import {useGetAwards} from "../../../../api/award";
import __ from "../../../../../../core/utils/translate/translate";

const AwardsListList = () => {
    const navigate = useNavigate();
    const {organisation} = useAuthContext();
    // const { t } = useTranslation("page.awards");
    const {queryStringFN} = useQueryString();

    const {
        data: awardsData,
        error: awardsError,
        isLoading: awardsLoading,
        isSuccess: awardsSuccess,
        isError: awardsIsError,
    } = useGetAwards()
    //
    // const {
    //   data: awardsData,
    //   fetchNextPage,
    //   hasNextPage,
    //   isFetchingNextPage,
    // } = useAwardGetAllInfinite({
    //   props: {
    //     perPage: 8,
    //     genders: [gender],
    //     type: queryStringFN.get("type", undefined),
    //     name: queryStringFN.get("name"),
    //     referenceType: club ? EnumArwardReferenceType.CLUB : null,
    //     referencePK: club?.uuid,
    //     orderCreatedAt: "desc",
    //   },
    // });

    // const memoType = useMemo(() => ENUM_AWARD_TYPE, []);

    return (
        <div className="flex flex-col gap-8 pb-8 xl:px-52">
            <RenderIf cond={!awardsData?.data.length}>
                <Typo as={'div'}>
                    {__("Award not found!")}
                    <RenderIf cond={!organisation}>
                        <Typo>{__("You are not a member of any community yet. To receive the prize, you must first enter the center")}</Typo>
                    </RenderIf>
                    <RenderIf cond={!!organisation}>
                        <Typo>{__("In this section, the centers awards are displayed. Your club has not registered any awards yet")}</Typo>
                    </RenderIf>
                </Typo>

            </RenderIf>
            <div className="grid gap-x-4 gap-y-4 md:grid-cols-2 2xl:grid-cols-4">


                <RenderIf cond={!awardsData}>
                    {Array.from({length: 4}).map((_, i) => (
                        <AwardMainCardLoading key={'awardMainCardLoading' + 'awardListList' + i}/>
                    ))}
                </RenderIf>

                {awardsData?.data?.map((award, i) => (
                    <Link to={'/app/award/'+ award.id} key={'awardMainCard' + award?.id + '-' + i}>
                    <AwardMainCard
                        XP={award?.experience}
                        image={award?.fileDetail}
                        onClick={() => navigate('/app/award/' + award.id)}
                        score={award?.score}
                        title={award?.title}
                        summary={award?.summary}
                        type={award?.type?.toString()}
                        plan={award.plan?.name}
                    />
                    </Link>
                ))}
            </div>
            {/*<LoadMore*/}
            {/*    fetchNextPage={fetchNextPage}*/}
            {/*    hasNextPage={hasNextPage === true}*/}
            {/*    isFetchingNextPage={isFetchingNextPage}*/}
            {/*/>*/}
        </div>
    );
};

export default AwardsListList;
