import React, {Fragment} from 'react';
import LoadMore from "../../../../core/components/loadmore";
import useQueryString from "../../../../core/hooks/useQueryString";
import {useJoinTeamsWithSearch, useListTeams} from "../../api";
import handleError from "../../../../core/helper/handelError";
import RenderIf from "../../../../core/components/render-if";
import {MiniLoading} from "../../../../core/components/loading";
import {fullName} from "../../../../core/helper/fullName";
import TeamCard from "../teamCard";

export default function TeamList(props) {
    const {queryStringFN} = useQueryString();
    const {mutate: join, isPending, isError, error, isSuccess, data} = useJoinTeamsWithSearch()
    const joinTeam = (code) => {
        join({
            code: code,
        }, {
            onSuccess: (data) => {
                window.location.href = "/app/profile"
            },
            onError: (error) => {
                handleError(error)
            }
        })
    }

    const {
        data: teamList,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isFetching,
    } = useListTeams({
        page: 1,
        perPage: 8,
        search: queryStringFN.get("name") ?? '',
    });


    return (
        <>
            <RenderIf cond={!teamList}>
                <MiniLoading />
            </RenderIf>
            {teamList?.pages.map((data,i)=>(
                <Fragment key={'fragmentListTeams' + i}>
                    {data?.data?.map((team, i) => (
                        <TeamCard
                            key={team?.id + 'team' + 'list' + i}
                            team={team}
                            onClick={() => joinTeam(team?.inviteCode)}
                            freeze={team?.freeze}
                        />
                    ))}
                </Fragment>
            ))}
            <LoadMore
                auto
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage === true}
                isFetchingNextPage={isFetchingNextPage}
            />

        </>
    );
}