import {useState} from "react";
import Spinner from "../../../../../../../../core/components/spinner";
import Typo from "../../../../../../../../core/components/typo";
import {useAuthContext} from "../../../../../../../Auth/provider/AuthProvider";
import RenderIf from "../../../../../../../../core/components/render-if";
import Button from "../../../../../../../../core/components/button";
import __ from "../../../../../../../../core/utils/translate/translate";

const ProfilePersonCheckTeam = () => {
    const [canDeleteRequest, setCanDeleteRequest] = useState(false);
    const {team} = useAuthContext();


    const handleRefetch = () => {

    };

    return (
        <div
            className="absolute inset-0 z-[2] flex flex-col items-center justify-center gap-4 rounded-3xl bg-white bg-opacity-50 p-2 backdrop-blur-sm">
            <Typo className="text-center" as="h1" size="xl" weight="bold">
                     تیم <span className={`px-1 underline`}>{team?.team?.name ?? "..."}</span> {__("checking your request!")}
            </Typo>
            {/*<RenderIf cond={!false} falsyElement={<Spinner type="accent"/>}>*/}
            {/*    <Typo onClick={handleRefetch} className="hover:underline" size="xs">*/}
            {/*        برای چک کردن وضعیت درخواستت اینجا کلیک کن!*/}
            {/*    </Typo>*/}
            {/*</RenderIf>*/}

            <RenderIf cond={canDeleteRequest}>
                <div className="flex items-center gap-2">
                    <Typo type="warning" className="bg-yellow/20" size="xs">
                        {__("Your request has not been approved yet!")}
                    </Typo>
                    <Button
                        size="xs"
                        scale="fit"
                        isLoading={false}
                        onClick={() => {
                        }}
                    >
                        {__("Cancel the request")}
                    </Button>
                </div>
            </RenderIf>
        </div>
    );
};

export default ProfilePersonCheckTeam;
