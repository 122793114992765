import MainLayout from "../../layout/mainLayout/mainLayout";
import {Suspense, useEffect, useState} from "react";
import {MiniLoading} from "../../core/components/loading";
import HomeNews from "./components/news";
import HomeSlider from "./components/slider";
import VerticalBox from "../../core/components/box/vertical";
import HomeAdvanture from "./components/adventure";
import HomeBottomSection from "./components/bottom-section";
import HomeStatistics from "./components/statistics";
import HomeNewMissions from "./components/new-missions";
import HomeNewActivities from "./components/new-activities";
import __ from "../../core/utils/translate/translate";
import NewNotification from "../../core/components/modal/newNotification";
import HomeCampaign from "./components/home campaign";
import RenderIf from "../../core/components/render-if";
import {useJoinOrganisation} from "../profile/api/organisation";
import {LockOnOrganizationPageId} from "../../core/constants/constants";
import {useAuthContext} from "../Auth/provider/AuthProvider";
import {useNavigate} from "react-router-dom";
import {useGetOrganisations} from "../Auth/api/auth";

export const HomeScreen = () => {
    const {user} = useAuthContext();
    const {mutate: joinOrganisation} = useJoinOrganisation();
    const [reFetch, setReFetch] = useState(false)
    const navigate = useNavigate();

    const {
        data: organisationData,
        error: organisationError,
        isLoading: organisationLoading,
        isSuccess: organisationSuccess,
        isError: organisationIsError,
    } = useGetOrganisations({
        enabled: reFetch,
    })

    const joinOrgan = (organizationId) => {
        joinOrganisation({
            organisationId: organizationId,
        }, {
            onSuccess: (data) => {

                setReFetch(true)
                localStorage.removeItem(LockOnOrganizationPageId);
                // navigate('/app/profile')
            },
            onError: (error) => {

            }
        })
    }

    useEffect(() => {
        if(!!user && localStorage.getItem(LockOnOrganizationPageId)) {
            joinOrgan(localStorage.getItem(LockOnOrganizationPageId))
        }
    }, [user]);

    return (
        <MainLayout>
            <section className="flex flex-col gap-8 pt-16">
                <div
                    className="flex flex-col h-18 mx-4 gap-1 items-start justify-center rounded-xl border border-dashed  border-shark-500 p-1 px-4 text-sm text-shark-500">
                    <div>

                        {/*<a href="tel:02191007858">{__("Nojahan support number 021-91007858")}</a>*/}
                    </div>

                    <div>
                        <a target={'_blank'}
                           href="https://eitaa.com/nojahan_poshtiban">{__("Our support number in Eitaa messengers @nojahan_poshtiban")}
                            <i className={'icon-link mx-2 font-bold'}></i>
                        </a>
                    </div>


                </div>
                <Suspense fallback={<MiniLoading/>}>
                    <HomeNews title={__("Nojahaniha")} position={'nojahan'}/>
                </Suspense>
                <Suspense fallback={<MiniLoading/>}>
                    <HomeSlider type={'slider'}/>
                </Suspense>
                <RenderIf cond={false}>
                    <Suspense fallback={<MiniLoading/>}>
                        <HomeCampaign data={[]} type={'campaign'} />
                    </Suspense>
                </RenderIf>
            </section>
            {/*<VerticalBox withBackground className="pt-2 mt-8">*/}
                {/*<Suspense fallback={<MiniLoading/>}>*/}
                {/*    <HomeAdvanture/>*/}
                {/*</Suspense>*/}

                {/*<Suspense fallback={<MiniLoading/>}>*/}
                {/*    <HomeStatistics/>*/}
                {/*</Suspense>*/}

                <Suspense fallback={<MiniLoading/>}>
                    <div className={'my-4'}>
                        <HomeNewMissions/>
                    </div>
                </Suspense>
                {/*<Suspense fallback={<MiniLoading/>}>*/}
                {/*    <HomeNewActivities/>*/}
                {/*</Suspense>*/}
                <Suspense fallback={<MiniLoading/>}>
                    <HomeNews title={__("Latest news")} type={'news'}/>
                </Suspense>

                <Suspense fallback={<MiniLoading/>}>
                    <HomeBottomSection/>
                </Suspense>
            {/*</VerticalBox>*/}
            <NewNotification/>
        </MainLayout>
    )
}
