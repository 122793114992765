import { useState } from "react";
import {CommonIcon} from "../../../../icon";
import ErrorReportModal from "../../../../modal/error-report";

const CommentCardErrorReport = ({ relatedId }) => {
  const [modal, setModal] = useState("idle");

  return (
    <>
      <CommonIcon
        icon="flag"
        className="h-4 w-4 cursor-pointer"
        stroke="#707489"
        onClick={() => setModal("error-report")}
      />
      <ErrorReportModal
        isOpen={modal === "error-report"}
        onClose={() => setModal("idle")}
        related={"comment"}
        relatedId={relatedId}
      />
    </>
  );
};

export default CommentCardErrorReport;
