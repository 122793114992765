import Typo from "../../../../core/components/typo";
import { Link } from "react-router-dom";
import __ from "../../../../core/utils/translate/translate";


const Section02 = () => {
    return (
        <section className="relative mx-auto flex min-h-[256px] w-full items-center justify-center lg:min-h-[420px]">
            <img src="/images/intro-background.svg" alt="background" className="absolute left-0 -top-24 lg:left-0"/>
            <div className="relative z-[1] max-w-3xl px-4">
                <Typo as="h1" className="text-center" size="xl" type="primary" weight="bold">
                    {__("Dont think big job,do it!")}
                </Typo>
                <Typo as="p" className="text-center lg:text-lg">
                    {__("If you want to save the world around you and make the world a better place by taking big steps, this is for you! Enter now")}
                    <Link to={'/app'}>
                        <Typo as="span" type="accent" weight="bold" className={'mx-1'}>
                            {__("Nojahan")}
                        </Typo>
                    </Link>
                    {__("Go and choose your own special mission!")}
                </Typo>
            </div>
        </section>
    );
};

export default Section02;
