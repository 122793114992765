import Skeleton from "../../core/components/skeleton";

const NewsPageLoading = () => {
    return (<div className="mx-auto flex w-full flex-col gap-12 p-4 py-20 xl:px-52">
        <div className="flex flex-col gap-4">
            <Skeleton className="w-full aspect-3/2 rounded-3xl max-w-lg" />
            <Skeleton className="w-32 h-2" />
            <Skeleton className="w-48 h-3" />

            <div className="flex flex-col gap-1">
                <Skeleton className='w-full h-2' />
                <Skeleton className='w-full h-2' />
                <Skeleton className='w-1/2 h-2' />
            </div>
        </div>
    </div>);
}

export default NewsPageLoading;