import ActivitiesFilter from "./components/filter";
import ClubActivitiesList from "./components/list";
import ClubActivitiesNewList from "./components/new-list";
import RenderIf from "../../../../core/components/render-if";
import {useAuthContext} from "../../../Auth/provider/AuthProvider";
import __ from "../../../../core/utils/translate/translate";

const ClubActivities = () => {
  const { organisation } = useAuthContext();

  return (
    <div className="flex flex-col gap-8">
      {/*<RenderIf cond={!!organisation}>*/}
      {/*  <div className="-mx-4">*/}
      {/*    <ClubActivitiesNewList />*/}
      {/*  </div>*/}
      {/*</RenderIf>*/}
      <div className="xl:px-52">
        <ActivitiesFilter title={!!organisation ? 'دوره‌های کانون' : 'دوره‌های نوجهان'} />
      </div>
      <div className="xl:px-52">
        <ClubActivitiesList commonGame={!organisation} />
      </div>
    </div>
  );
};

export default ClubActivities;
