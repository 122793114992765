import {useQuery} from "@tanstack/react-query";
import {apiGetMissionRunners} from "../../../data/api/missionRunner";

export const useGetMissionRunners = (missionType) => {

    return useQuery({
        queryKey: ['mission_runners', missionType],
        queryFn: () => apiGetMissionRunners({
            params: {
                mission_type: missionType
            }
        }),
    })
}