import React from 'react';
import Button from "../../../../../../core/components/button";

export default function LinkBtn({notification,refetch,data}) {
    const handleClick = () =>{
        if (notification?.url){
            window.location.href = notification?.url;
        }
    }

    return (
        <>
            <Button size="sm" scale="fit" onClick={handleClick}>
                {data?.title}
            </Button>
        </>
    );
}