import {Link, Outlet, useNavigate} from "react-router-dom";
import RenderIf from "../core/components/render-if";
import Typo from "../core/components/typo";
import React, {Suspense} from "react";
import {MiniLoading} from "../core/components/loading";
import __ from "../core/utils/translate/translate";
import Button from "../core/components/button";

function AuthLayout() {
    const navigate = useNavigate();
    return (
        <div className="flex h-[100svh] flex-col bg-purple-6 md:flex-row">
            <div className="relative flex-[1] overflow-hidden">
                <img
                    src="/images/auth-boy.png"
                    alt="background"
                    className="h-full w-full overflow-hidden  object-cover md:rounded-l-3xl"
                    loading="lazy"
                />
                {/*<RenderIf cond={location.pathname !== ROUTES.AUTHENTICATE.INDEX()}>*/}
                {/*    <IconButton onClick={() => navigate(-1)} className="absolute left-4 top-4 bg-purple-6 md:hidden">*/}
                {/*        <CommonIcon icon="chevron"/>*/}
                {/*    </IconButton>*/}
                {/*</RenderIf>*/}
            </div>
            <div className="relative flex flex-[2] items-center justify-center md:p-8">
                <div
                    className="-mt-6 h-[calc(100%_+_1.5rem)] max-h-[70vh] w-full max-w-[480px] overflow-y-auto rounded-t-3xl bg-white md:min-h-[480px] lg:rounded-b-3xl">
                    <div className="flex h-full flex-col gap-6 p-8">
                        <Link to={'/'}>
                            <img src="/images/logo.svg" alt="logo" className="max-w-[72px]" loading="lazy"/>
                        </Link>
                        <Typo as="h1" weight="bold">
                            {__("Welcome to Nojahan!")}
                        </Typo>
                        <Suspense fallback={<MiniLoading/>}>
                            <Outlet/>
                        </Suspense>
                        <RenderIf cond={location.pathname === "/authenticate" || location.pathname === "/authenticate/"}>
                            <div className="flex flex-col items-center gap-2">
                                <Button onClick={() => navigate('/app')}>
                                    {__("Continue")}
                                </Button>
                            </div>
                        </RenderIf>
                        {/*<RenderIf*/}
                        {/*    cond={*/}
                        {/*        ![*/}
                        {/*            ROUTES.AUTHENTICATE.RULES(),*/}
                        {/*            ROUTES.AUTHENTICATE.PRIVACY(),*/}
                        {/*            ROUTES.AUTHENTICATE.GENEDR(),*/}
                        {/*            ROUTES.AUTHENTICATE.PLAN(),*/}
                        {/*        ].includes(location.pathname)*/}
                        {/*    }*/}
                        {/*>*/}
                        {/*    <AuthenticateFooter/>*/}
                        {/*</RenderIf>*/}

                    </div>
                </div>
            </div>
        </div>
    )

}
export default AuthLayout

