import moment from "moment-jalaali";

export default class DateTimeHelper {
    constructor(_dateTime, _format = "YYYY-MM-DD", _isUTC = false) {
        moment.loadPersian({dialect: "persian-modern"});
        this.date =
            _isUTC && _dateTime !== undefined
                ? moment.utc(_dateTime, _format)
                : _isUTC && _dateTime === undefined
                    ? moment.utc()
                    : !_isUTC && _dateTime !== undefined
                        ? moment(_dateTime, _format)
                        : moment();
    }

    toShamsi = () => {
        this.date.utcOffset(3.5);
        return this;
    };

    toGeorgian = () => {
        this.date.utcOffset(0);
        return this;
    };

    moment = () => {
        return this.date;
    };

    format = (_format) => {
        return this.date.format(_format);
    };

    toDate = () => {
        return this.date.toDate();
    };

    diff = (dateTime = moment()) => {
        return this.date.diff(dateTime, "days");
    };

    toDuration = () => {
        return this.date.fromNow();
    };

    static now = () => {
        return new DateTimeHelper(undefined, "YYYY-MM-DD HH:mm:ss");
    };

    static nowUTC = () => {
        return new DateTimeHelper(undefined, "YYYY-MM-DD HH:mm:ss", true);
    };

    static getLastYears = (n = 10) => {
        const arr = [];
        const nowYear = Number(moment().format("jYYYY"));
        for (let i = 0; i <= n; i++) arr.push({id: nowYear - i, title: `${nowYear - i}`});
        return arr;
    };

    static getMonths = () => {
        return [
            {id: 1, title: "فروردین"},
            {id: 2, title: "اردیبهشت"},
            {id: 3, title: "خرداد"},
            {id: 4, title: "تیر"},
            {id: 5, title: "مرداد"},
            {id: 6, title: "شهریور"},
            {id: 7, title: "مهر"},
            {id: 8, title: "آبان"},
            {id: 9, title: "آذر"},
            {id: 10, title: "دی"},
            {id: 11, title: "بهمن"},
            {id: 12, title: "اسفند"},
        ];
    };

    static getDays = (monthIndex = 1) => {
        const arr = [];
        for (let i = 1; i <= 31; i++) arr.push({id: i, title: `${i}`});
        if (monthIndex <= 6) return arr;
        arr.pop();
        return arr;
    };
}