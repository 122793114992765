import React, {useEffect} from 'react'
import ReactDOM from 'react-dom/client'
import './core/assets/css/index.css'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import './core/assets/icons/FontAwesome.Pro.6.5.2/css/all.css'
import {LayoutProvider} from './core/providers/LayoutProvider'
import {AuthProvider} from './feature/Auth/provider/AuthProvider'
import {RouterProvider} from 'react-router-dom'
import router from './router/router'
import './core/styles/otailwind.css'
import './core/styles/reset.css'
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sentryInit from "./utils/helper/sentry";
import GoogleAnalyticsInit from "./utils/helper/googleanalytics";
import ClarityIdentify from "./utils/helper/clarity";

import {firebaseFire} from "./utils/helper/utils-firebase";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 3,
        },
    },
})



sentryInit()
GoogleAnalyticsInit();
if ("serviceWorker" in navigator) {
    navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
             // Service Worker Registered
        })
        .catch((error) => {});
}

firebaseFire().then(r => {})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
        <QueryClientProvider client={queryClient}>
            <ToastContainer
                rtl={true}
                position="bottom-left"
                toastClassName="rounded-lg text-sm shadow-none"
                bodyClassName="flex items-start"
                icon={false}
            />

            <AuthProvider>
                <ClarityIdentify />
                <LayoutProvider>
                    <RouterProvider router={router}/>
                </LayoutProvider>
            </AuthProvider>

            <ReactQueryDevtools initialIsOpen={false}/>
        </QueryClientProvider>
    ,
)

