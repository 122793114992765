import useQueryString from "../../../../core/hooks/useQueryString";
import Typo from "../../../../core/components/typo";
import Search from "../../../../core/components/search";
import ScrollView from "../../../../core/components/scroll-view";
import {SimpleBadge} from "../../../../core/components/badge";


const ExploreFilter = ({title}) => {
    const {queryStringFN} = useQueryString();

    const handleOnClick = (sort = undefined) => {
        queryStringFN.remove("sort");
        if (!!sort) queryStringFN.set("sort", sort);
    };

    const getActiveIndex = (sort) => {
        if(!sort && !queryStringFN.get("sort"))
        {
            return true;
        }
        return queryStringFN.get("sort") === sort;

    };

    return (
        <div className="flex flex-col gap-4">
            <Typo as="h1" weight="semi-bold" className="ui-clamp-1 flex-1 text-black">
                {title}
            </Typo>
            <Search placeholder="جست‌وجو کن..."/>
            <ScrollView className="gap-4 px-4">

                <div className="flex flex-wrap gap-2">
                    <SimpleBadge isActive={getActiveIndex()} onClick={() => handleOnClick()}
                                 className="whitespace-nowrap">
                        همه
                    </SimpleBadge>
                    <SimpleBadge
                        isActive={getActiveIndex('newest')}
                        onClick={() => handleOnClick('newest')}
                        className="whitespace-nowrap"
                    >
                        جدیدترین
                    </SimpleBadge>
                    <SimpleBadge
                        isActive={getActiveIndex('likes')}
                        onClick={() => handleOnClick('likes')}
                        className="whitespace-nowrap"
                    >
                        محبوب‌ ترین
                    </SimpleBadge>
                    <SimpleBadge
                        isActive={getActiveIndex('views')}
                        onClick={() => handleOnClick('views')}
                        className="whitespace-nowrap"
                    >
                        پربازدید ترین
                    </SimpleBadge>
                    <SimpleBadge
                        isActive={getActiveIndex('comments')}
                        onClick={() => handleOnClick('comments')}
                        className="whitespace-nowrap"
                    >
                        بیشترین نظر
                    </SimpleBadge>
                </div>
            </ScrollView>
        </div>
    );
};

export default ExploreFilter;
