import {useNavigate} from "react-router-dom";
import {useAuthContext} from "../../../Auth/provider/AuthProvider";
import RenderIf from "../../../../core/components/render-if";
import HorizontalBox from "../../../../core/components/box/horizontal";
import {AllCard, SimpleGameCard, SimpleGameCardLoading} from "../../../../core/components/card";
import {useState} from "react";
import {useGetMissions} from "../../api/home";
import __ from "../../../../core/utils/translate/translate";

const HomeNewMissions = () => {
    const PER_PAGE = 8;

    const {education, club} = useAuthContext();
    const navigate = useNavigate();

    const {data} = useGetMissions({type: 'mission'});

    return (
        <HorizontalBox background="purple-4" title={'ماموریت‌های من'}
                       image="/images/cp-box-new-missions.svg">
            {data?.data?.map((mission, i) => (
                    <SimpleGameCard
                        key={'SimpleGameCard' + 'newMissions' + mission?.id + '-' + i}
                        id={mission.id}
                        type={mission.type}
                        score={mission.score}
                        XP={mission.experience}
                        cover={mission.coverDetail}
                        title={mission.title}
                        onClick={() => {
                        }}
                    />
                ))}
            <RenderIf cond={!data}>
                {Array.from({length: 7}).map((_, i) => (
                    <SimpleGameCardLoading key={'SimpleGameCardLoading' + 'newMissions' + i}/>
                ))}
            </RenderIf>
            <RenderIf cond={(data?.meta?.total ?? 0) > PER_PAGE}>
                <AllCard onClick={() => {
                  navigate('/app/missions?view=missions')
                }}>
                    {__("Load more")}
                </AllCard>
            </RenderIf>
        </HorizontalBox>
    );
};

export default HomeNewMissions;
