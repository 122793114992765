import React, {useRef} from 'react'
import {usePostAuthenticate, useSendOtp} from './api/auth'
import {TextInput} from "../../core/components/input";
import Button from "../../core/components/button";
import AuthenticateFooter from "./components/footer";
import handleError from "../../core/helper/handelError";
import {Link, useNavigate} from "react-router-dom";
import { toast } from "react-toastify";
import __ from "../../core/utils/translate/translate";
import Typo from "../../core/components/typo";
import {saveTokens} from "./services/tokenService";

export function LoginByPasswordScreen() {

    const navigate = useNavigate();
    let mobileRef = useRef(null);
    let passwordRef = useRef(null);
    const {mutate: checkPassword, isPending, isError, error, isSuccess, data} = usePostAuthenticate()
    const submitForm = () => {
        if(!mobileRef.current.getValue())
        {
          toast.error(__("Please enter your mobile number!"))
          mobileRef.current.focus()
          return;
        }

        if(!passwordRef.current.getValue())
        {
            toast.error(__("Please enter your password!"))
            passwordRef.current.focus()
            return;
        }

        checkPassword({mobile: mobileRef.current.getValue(), password: passwordRef.current.getValue()}, {
            onSuccess: (data) => {
                saveTokens({
                    accessToken: data.data?.accessToken, refreshToken: data.data?.refreshToken, rememberMe: true
                });
                navigate('/app', {replace: true})
            }, onError: (error) => {
                handleError(error);
            }
        })
    }

    return (
        <>
            <TextInput
                name={__("Mobile number")}
                label={__("Enter your mobile number.")}
                placeholder="09xx-xxx-xxxx"
                inputMode="tel"
                dir="ltr"
                autoFocus
                maxLength={11}
                rules={[
                    {rule: "size", cond: 11},
                    {rule: "startsWith", cond: "09"},
                ]}
                required={true}
                ref={mobileRef}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        submitForm();
                    }
                }}
            />
            <TextInput
                name={__("Password")}
                label={__("Enter your password.")}

                type={'password'}
                dir="ltr"
                required={true}
                ref={passwordRef}
            />
            <div>
                <Typo
                    as="span"
                    size="xs"
                    weight=""
                    type="accent"
                    className={'mx-1'}
                    onClick={() => navigate('/authenticate/recovery')}
                >
                    {__("Forget password?")}
                </Typo>

            </div>
            <div>
                <Typo
                    as="span"
                    size="xs"
                    weight=""
                    type="accent"
                    className={'mx-1'}
                    onClick={() => navigate('/authenticate/login')}
                >
                    {__("Login by OTP")}
                </Typo>

            </div>


            <div className="flex-1"/>
            <div className="flex flex-col items-center gap-2">
                <Button onClick={submitForm} isLoading={isPending}>
                    {__("Continue")}
                </Button>
            </div>
            <AuthenticateFooter/>
        </>
    )
}


