import MissionList from "./components/mission-list";
import __ from "../../../../core/utils/translate/translate";
const MissionsMyMissionsList = () => {
  return (
    <div className="sm:px-36 md:px-20 lg:px-1  xl:px-52">
      <MissionList
        title={__("In progress missions")}
        mode={'doing'}
        showEmptyIcon
      />

      <MissionList
        title={__("Finished missions")}
        mode={'done'}
      />
    </div>
  );
};

export default MissionsMyMissionsList;
