import {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {twMerge} from "tailwind-merge";
import ScrollView from "../../../../../core/components/scroll-view";
import RoadMapStepRight from "../../../../../core/components/road-map-step/right";
import {useGetMission} from "../../../api/mission";

const GameStepRoadMap = ({missionStep, missionStepsData}) => {
    const {id} = useParams();

    const {data: missionData} = useGetMission({id});
    const refRoadMap = useRef(null);

    // const { data: gameStep } = useGameStepGet({
    //   props: {
    //     id: Number(id),
    //   },
    // });

    // const [gameStep, setGameStep] = useState([]);

    // const { data: gameStepsData } = useGameStepGetAll({
    //   props: { gameUUID: uuid },
    // });



    useEffect(() => {
        if (!missionStep) return;
        if (!refRoadMap.current) return;
        refRoadMap.current.scrollTo(0, -missionStep.sort * 160, "smooth");
    }, [missionStepsData, refRoadMap.current]);

    return (
        <ScrollView
            className={twMerge(
                "-mx-4 px-4 md:-mx-52 md:px-52",
                !missionStepsData || missionStepsData?.length <= 1 ? "hidden" : "flex"
            )}
            ref={refRoadMap}
        >
            {missionStepsData?.map((step, i) => (
                <RoadMapStepRight
                    key={'RoadMapStepRight' + step?.id + '-' + i}
                    currentIndex={missionStep.index}
                    index={i}
                    title={step.title}
                    isLast={(i + 1) === missionStepsData?.length}
                />
            ))}
        </ScrollView>
    );
};

export default GameStepRoadMap;
