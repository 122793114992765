import {useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import JoinToClubCanceRequest from "./components/cancel-request";
import VerticalBox from "../../../../../../core/components/box/vertical";
import Spinner from "../../../../../../core/components/spinner";
import RenderIf from "../../../../../../core/components/render-if";
import Typo from "../../../../../../core/components/typo";
import {InputLabel, TextInput} from "../../../../../../core/components/input";
import Button from "../../../../../../core/components/button";
import {useAuthContext} from "../../../../../Auth/provider/AuthProvider";
import {useJoinOrganisation} from "../../../../api/organisation";
import handleError from "../../../../../../core/helper/handelError";
import {useGetOrganisations} from "../../../../../Auth/api/auth";
import __ from "../../../../../../core/utils/translate/translate";
import {Tooltip} from "antd";
import {CommonIcon} from "../../../../../../core/components/icon";
import {TooltipColor} from "../../../../../../core/constants/constants";

const ProfilePersonJoinToClub = () => {
    const {uuid} = useParams();
    const navigate = useNavigate();
    const {organisation} = useAuthContext();

    const [canDeleteRequest, setCanDeleteRequest] = useState(false);

    const refInputs = {
        inviteCode: useRef(null),
    };


    const {mutate: joinOrganisation, isPending, isError, error, isSuccess, data} = useJoinOrganisation();

    const joinOrgan = () => {
        joinOrganisation({
            code: refInputs.inviteCode.current.getValue(),
        }, {
            onSuccess: (data) => {

            },
            onError: (error) => {
                handleError(error)
            }
        })
    }
    const {
        data: organisationData,
        error: organisationError,
        isLoading,
        isSuccess: organisationSuccess,
        isError: organisationIsError,
    } = useGetOrganisations({
        enabled: isSuccess,
    })

    // if (!false)
    //     return (
    //         <VerticalBox
    //             withBackground
    //             className="flex rounded-b-3xl p-8 shadow-[0px_4px_20px_0px_rgba(0,0,0,0.2)] md:w-[32rem]"
    //             containerClassName="items-center"
    //         >
    //             <Spinner type="accent"/>
    //         </VerticalBox>
    //     );


    return (
        <RenderIf cond={organisation?.status !== "accepted"}>
            <VerticalBox
                withBackground
                className="flex rounded-b-3xl p-8 shadow-[0px_4px_20px_0px_rgba(0,0,0,0.2)] md:w-[32rem]"
                containerClassName="items-center"
            >

                <RenderIf cond={!!organisation && organisation?.status === 'pending'}>
                    <div className="p-4">
                        <Typo
                            className="text-center"
                            as="h1"
                            size="xl"
                            weight="bold"
                        >{`${__("Reviewing the request by")} ${organisation?.organization?.name}`}</Typo>
                    </div>

                    <RenderIf cond={!false} falsyElement={<Spinner type="accent"/>}>
                        <div className="flex items-center gap-2">
                            <Typo onClick={() => {
                                    navigate(0)
                            }} className="hover:underline" size="xs">
                                {__("Click here to check the request status")}
                            </Typo>
                            <Typo>|</Typo>
                            <JoinToClubCanceRequest/>
                        </div>
                    </RenderIf>

                    <RenderIf cond={canDeleteRequest}>
                        <div className="flex items-center gap-2">
                            <Typo type="warning" className="bg-yellow/20" size="xs">
                                {__("Your request has not been approved yet!")}
                            </Typo>
                        </div>
                    </RenderIf>
                </RenderIf>

                <RenderIf
                    cond={!organisation || (!!organisation && organisation?.status === 'reject')}
                >
                    <div className="flex flex-col items-center gap-4 p-4">
                        <Typo
                            weight="bold" className={'flex'}>{__("To make the first team, you must be a member of a organization!")}
                            <Tooltip className={'my-auto'} color={TooltipColor()} title={'کانون‌ یعنی هر مدرسه‌، هیئت، پایگاه و یا هر مجموعه‌فرهنگی ورزشی که از زیرساخت نوجهان استفاده میکنه. وقتی به یک کانون می‌پیوندی، می‌تونی توی دوره‌ها و ماموریت‌هایش شرکت کنی، با بقیه اعضای کانون تیم بشی و وارد رقابت‌های هیجان‌انگیزش بشی. آماده‌ای عضو یک کانون بشی و کلی تجربه جدید کسب کنی؟ '}>
                                <i><CommonIcon icon={'info-badge'}/> </i>
                            </Tooltip>
                        </Typo>
                        <Button onClick={()=> navigate('/app/organizations')} scale="fit">
                            جست‌وجو در لیست کانون‌ها
                        </Button>
                        <div className="flex flex-col gap-2 rounded-xl pt-4">
                            <Typo type="accent" size="sm">
                                اگر کد عضویت دارید، با وارد کردن آن، درخواست عضویت خود را ثبت کنید.
                            </Typo>
                        </div>
                        <div>
                            <div className="flex gap-1">
                                <InputLabel required={true} label={__("Invite code")}/>
                                <Tooltip title={'هر کانون یه شناسه‌ی اختصاصی داره. با وارد کردنش، می‌تونی درخواست عضویت بدی و عضو کانون بشی'} color={TooltipColor()}>
                                    <i><CommonIcon icon={'info-badge'}/> </i>
                                </Tooltip>
                            </div>
                            <div className="flex items-center rounded-xl border border-purple-4 bg-white">
                                <TextInput
                                    required
                                    maxLength={12}
                                    inputMode="numeric"
                                    name={__("Invite code")}
                                    ref={refInputs.inviteCode}
                                    className="flex-1"
                                    inputClassName="border-none"
                                    dir="ltr"
                                    type="number"
                                />
                                <div className="border-r border-purple-4 px-2">-K</div>
                            </div>
                        </div>
                        <Button onClick={joinOrgan} isLoading={isPending || isLoading} scale="fit">
                            ارسال درخواست عضویت
                        </Button>
                    </div>
                </RenderIf>
            </VerticalBox>
        </RenderIf>

    );
};

export default ProfilePersonJoinToClub;
