import {useRef, useState} from "react";
import {TextArea} from "../../input";
import RenderIf from "../../render-if";
import Typo from "../../typo";
import Button from "../../button";
import __ from "../../../utils/translate/translate";
import {usePostComment} from "../api/comment";
import handleError from "../../../helper/handelError";

const SendComment = ({ id, type }) => {

  const refInputs = {
    comment: useRef(null),
  };
  let data = {}
  if (type === "news"){
      data = {post_id: id}
  }
  if (type === "mission"){
      data = {mission_id: id}
  }


  const { mutate, isLoading, isSuccess } = usePostComment();

  const handleSend = ()=> {
    // if (!Input.validateForm(refInputs)) return;
    mutate(
    {
              comment: refInputs.comment.current.getValue(),
                ...data
            },
        {
            onSuccess: () => {
                refInputs.comment.current.reset();
            },
            onError: (error) => {
                handleError(error)
            }
        }
    );
  };

  return (
    <div className="flex flex-col items-end gap-2">
      <TextArea
        className="w-full"
        name={__("send.input")}
        label={__("Write your comment")}
        required
        rules={[{ rule: "minLength", cond: 2 }]}
        maxLength={2000}
        ref={refInputs.comment}
      />
      <RenderIf
        cond={!isSuccess}
        falsyElement={
          <Typo size="xs" type="success" className="rounded-full bg-green/10 px-2">
              {__("Your comment created successfully.")}
          </Typo>
        }
      >
        <Button onClick={handleSend} isLoading={isLoading} scale="fit">
          {__("Send comment")}
        </Button>
      </RenderIf>
    </div>
  );
};

export default SendComment;
