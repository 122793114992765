import React from "react";
import {twMerge} from "tailwind-merge";
import RenderIf from "../../render-if";
import ScrollView from "../../scroll-view";
import Typo from "../../typo";
import {Tooltip} from "antd";
import {CommonIcon} from "../../icon";
import {TooltipColor} from "../../../constants/constants";

const HorizontalBox = ({background, title, image, paper = false, children, className,tooltip}) => {
    const getBackgroundColor = () => {
        if (background === "orange") return "bg-linear-orange-45";
        else if (background === "purple") return "bg-linear-purple-7";
        else if (background === "purple-4") return "bg-purple-4";
        else return "bg-linear-rose-80";
    };

    const getTextType = () => {
        if (background === "orange") return "on-accent";
        else if (background === "purple") return "on-accent";
        else if (background === "purple-4") return "accent";
        else return "on-accent";
    };

    return (
        <div className="w-full pr-4">
            <div
                className={twMerge(
                    "relative rounded-r-3xl py-6 shadow-[1px_4px_15px_0px_rgba(0,0,0,0.1)_inset]",
                    getBackgroundColor(),
                    className
                )}
            >
                <RenderIf cond={paper}>
                    <img
                        src="/images/cp-box-papers.svg"
                        alt="papers"
                        className="absolute right-0 top-0 h-full w-full object-cover blur-[2px]"
                        loading="lazy"
                    />
                </RenderIf>
                <Typo as="h1" size="xl" type={getTextType()} weight="bold" className="sm:block md:hidden mr-8 mb-4">
                    <div className={'flex'}>
                        {title}
                        <RenderIf cond={!!tooltip}>
                            <Tooltip color={TooltipColor()} title={tooltip} className={'flex'}>
                                <i className={'px-1'}><CommonIcon icon={'info-badge'}/></i>
                            </Tooltip>
                        </RenderIf>
                    </div>
                </Typo>
                <div className={'hidden md:block'}>

                    <img src={image} alt="cover" className="absolute right-4 max-w-[140px] top-2" loading="lazy"/>
                </div>
                <ScrollView className="relative z-[1] min-h-[14rem] gap-4 px-6">
                    <div className="flex md:w-48 shrink-0 flex-col items-center self-end">
                        <Typo as="h1" size="xl" type={getTextType()} weight="bold" className="hidden md:block max-w-[9rem] text-center">
                            <div className={'flex'}>
                                {title}
                                <RenderIf cond={!!tooltip}>
                                    <Tooltip color={TooltipColor()} title={tooltip} className={'flex'}>
                                        <i className={'px-1'}><CommonIcon icon={'info-badge'}/></i>
                                    </Tooltip>
                                </RenderIf>
                            </div>
                        </Typo>
                    </div>
                    {children}
                </ScrollView>
            </div>
        </div>
    );
};

export default HorizontalBox;
