import { useLocation, useNavigate } from 'react-router-dom'

export function useNavigateTo() {
  const navigate = useNavigate()

  const location = useLocation()

  function navigateTo(nextPath, stateData = null) {
    if (location.pathname !== nextPath) {

      navigate(nextPath, { state: stateData })
    } else {

    }
  }

  return navigateTo
}

export function useNavigatePop() {
  const navigate = useNavigate()

  function navigatePop() {


    navigate(-1)
  }

  return navigatePop
}
