import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {CommonIcon} from "../../../../core/components/icon";
import RenderIf from "../../../../core/components/render-if";
import Skeleton from "../../../../core/components/skeleton";
import Typo from "../../../../core/components/typo";
import RenderSwitch from "../../../../core/components/render-switch";
import Spinner from "../../../../core/components/spinner";
import IconButton from "../../../../core/components/icon-button";
import {useGetMission} from "../../api/mission";

const GameLessons = ({passedSteps, runnerId}) => {
    const {id, stepId} = useParams();
    const navigate = useNavigate();
    const [current, setCurrent] = useState(0);
    const [loading, setLoading] = useState(0);
    const {data: lessonsData} = useGetMission({id});

    const handleNavigateToLesson = (lesson) => {
        let children = lesson.children.sort((a, b) => a.sort - b.sort);
        navigate(`/app/missions/${id}/${runnerId}/step/${children[0].id}`);
    };
    useEffect(() => {
        if (!passedSteps || !runnerId) return;
        setCurrent(passedSteps.length === 0 ? 1 : passedSteps.length);
    }, [passedSteps]);


    return (
        <div className="grid gap-x-4 gap-y-4 lg:grid-cols-3">
            <RenderIf cond={!lessonsData}>
                {Array.from({length: 3}).map((_, i) => (
                    <Skeleton key={'missionGameLessens' + '_'+i} className="h-16 rounded-xl"/>
                ))}
            </RenderIf>
            {lessonsData?.data?.steps.map((lesson, i) => (
                <div key={'stepGameLessens'+ lesson?.id +'-'+i} className="flex items-center gap-4 rounded-xl bg-white p-4">
                    <Typo
                        className="flex h-4 w-4 items-center justify-center rounded bg-purple-4"
                        weight="bold"
                        type="accent"
                        size="4xl"
                    >
                        {i + 1}
                    </Typo>
                    <Typo as="h2" weight="bold" className="flex-1">
                        {lesson?.title}
                    </Typo>
                    <RenderSwitch
                        conds={[
                            lesson.sort === loading,
                            lesson.sort <= (current ?? 0),
                            lesson.sort > (current ?? 0),
                        ]}
                    >
                        {[
                            <Spinner type="accent" key={0}/>,
                            <IconButton onClick={() => handleNavigateToLesson(lesson)} className="bg-purple-3" key={1}>
                                <CommonIcon icon="chevron" stroke="#FFF"/>
                            </IconButton>,
                            <IconButton onClick={() => null} className="bg-purple-5" key={2}>
                                <CommonIcon icon="lock"/>
                            </IconButton>,
                        ]}
                    </RenderSwitch>
                </div>
            ))}
        </div>
    );
};

export default GameLessons;
