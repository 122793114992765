import Skeleton from "../../../../../core/components/skeleton";

const AwardMainCardLoading = () => {
    return (
        <div className="flex gap-2 rounded-3xl bg-white p-2 shadow">
            <Skeleton className="aspect-square h-36 rounded-3xl"/>
            <div className="flex flex-1 flex-col gap-2">
                <Skeleton className="h-3 w-2/3"/>
                <Skeleton className="mt-auto h-6 w-20 rounded-full"/>
                <Skeleton className="h-8 w-full rounded-full"/>
            </div>
        </div>
    );
};

export default AwardMainCardLoading;
