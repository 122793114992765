import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import GameReportCardLoading from "./components/loading";
import Typo from "../../../core/components/typo";
import __ from "../../../core/utils/translate/translate";
import GameReportInfoCard from "./components/info-card";
import RenderIf from "../../../core/components/render-if";
import GameReportCardRate from "./components/rate";
import SendComment from "../../../core/components/comment/send";
import RootLayout from "../../../layout/root";
import {useGetMissionRunner} from "../api/missionRunner";
import {useAuthContext} from "../../Auth/provider/AuthProvider";
import MainFooter from "../../../layout/mainLayout/mainFooter";

const GameReportCard = () => {
  const { id } = useParams();

    const {data} = useGetMissionRunner({missionId: id})
    const {user} = useAuthContext()

  // const { data: gameData } = useGameGet({ props: { uuid: uuid } });

  // const { data: reportsData } = useReportCardGetAll({
  //   props: {
  //     gameUUID: uuid,
  //     teamUUID: gameData?.data.type === EnumGameType.TEAM ? team?.uuid : undefined,
  //     userUUID: gameData?.data.type !== EnumGameType.TEAM ? userUUID : undefined,
  //   },
  //   enabled: !!gameData,
  // });

  // useEffect(() => {
    // if (!gameData) setSimpleHeaderTitle(t("report.loading"));
    // else setSimpleHeaderTitle(t("report.title", { name: gameData.data.nameWithType }));
  // }, [gameData]);

  if (!data) return <GameReportCardLoading />;
    const getType = (type) =>{
        if (type === "team"){
            return __(`Team mission`)
        } else
            if (type === "self"){
              return __(`Self mission`)
            } else
        if (type === "course"){
            return __(`course`)
        } else
        if (type === "camp"){
            return __(`camp`)
        } else {
            return type
        }
    }

  return (
      <RootLayout navigateTo={`/app`}>
          <div className="flex flex-col items-center gap-8 px-4 py-20">
              <img src={"/images/report-mission-header.png"} alt="header" className="max-w-[18rem]"/>

              <div className="flex flex-col items-center gap-1">
                  <Typo as="h1" weight="bold">
                      {data?.data?.mission?.title}
                  </Typo>
              </div>

              <div className="grid gap-x-4 gap-y-4 md:grid-cols-2">
                  {/*<GameReportInfoCard*/}
                  {/*    title={__("report.place", {*/}
                  {/*        rank: reportsData?.data[0].rank,*/}
                  {/*        totalRank: reportsData?.data[0].totalRank,*/}
                  {/*    })}*/}
                  {/*    description={__("report.placeIn", {*/}
                  {/*        type: reportsData?.data[0].game?.typeStr,*/}
                  {/*    })}*/}
                  {/*    description2={*/}
                  {/*        <Typo as="p" size="xs">*/}
                  {/*            {__("report.placeDescription", {type: gameData?.data.typeStr})}*/}
                  {/*        </Typo>*/}
                  {/*    }*/}
                  {/*    type="rank"*/}
                  {/*    gender={gameData?.data.gender}*/}
                  {/*    gameType={gameData?.data.type}*/}
                  {/*/>*/}
                  <RenderIf cond={data?.data?.mission?.type === "team"}>
                      <GameReportInfoCard
                          title={data?.data?.mission?.score + " " +__('Score')}
                          description={__("Report card") + ' ' + getType(data?.data?.mission?.type) +' ' + data?.data?.mission?.title}
                          // description2={
                          //     <Typo size="xs" type="danger">
                          //         {__("Lose Score:")}
                          //     </Typo>
                          // }
                          type="score"
                          gender={user?.gender}
                          gameType={data?.data?.mission?.type}
                      />
                  </RenderIf>

                  <RenderIf cond={data?.data?.mission?.type !== "team"}>
                      <GameReportInfoCard
                          title={data?.data?.mission?.experience + " " +__('Experience')}
                          description={__("Report card") + ' ' + getType(data?.data?.mission?.type) +' ' + data?.data?.mission?.title}
                          // description2={
                          //     <Typo size="xs" type="danger">
                          //         {__("Lose Score:")}
                          //     </Typo>
                          // }
                          type="score"
                          gender={user?.gender}
                          gameType={data?.data?.mission?.type}
                      />
                  </RenderIf>
              </div>

              {/*<GameReportCardRate/>*/}

              <div className="w-full">
                  <SendComment id={id} type={"mission"}/>
              </div>
          </div>
          <MainFooter/>
      </RootLayout>
  );
};

export default GameReportCard;
