import {useEffect, useState} from "react";
import {useAuthContext} from "../../../../../Auth/provider/AuthProvider";
import VerticalBox from "../../../../../../core/components/box/vertical";
import Typo from "../../../../../../core/components/typo";
import Button from "../../../../../../core/components/button";
import Member from "../../../../../../core/components/member";
import {twMerge} from "tailwind-merge";
import {useChangeHead} from "../../../../api/team";
import {toast} from "react-toastify";
import handleError from "../../../../../../core/helper/handelError";
import __ from "../../../../../../core/utils/translate/translate";

const ProfileTeamChangeHead = ({users}) => {
    const {team} = useAuthContext();
    const [selected, setSelected] = useState(-1);
    const {mutate} = useChangeHead()


    const handleChange = () =>{
        mutate(
            {
                teamId: team?.teamId,
                newHeadUserId: selected
            },
            {
                onSuccess:(data)=>{
                    toast.success(data?.message);
                },
                onError:(e)=>{
                    handleError(e)
                }
            }
        )
    }


    if (team?.teamRole !== "head" || users?.length < 2) return <></>;

    return (
        <VerticalBox icon="two-user" containerClassName="p-4">
            <Typo as="h2" weight="bold" size="xl" className="w-full text-center" type="accent">
                {__("Change team head")}
            </Typo>
            <div className="grid w-full md:grid-cols-2 xl:grid-cols-3">
                {users?.filter((teamUser) => teamUser?.teamRole && ['player'].includes(teamUser?.teamRole)).map((teamUser, i) => {
                        let displayName = ''
                        if(teamUser?.organizationUser?.displayName) {
                            displayName = teamUser?.organizationUser?.displayName
                        }
                        else
                        {
                            if(teamUser?.user?.firstName) {displayName += teamUser?.user?.firstName;}
                            if(teamUser?.user?.lastName) {displayName += ' ' +  teamUser?.user?.lastName;}
                            if(!teamUser?.user?.firstName && !teamUser?.user?.lastName){
                                displayName += __("No name")
                            }
                        }
                    return <Member
                      key={'member' + teamUser?.id + '-' + i}
                      description={teamUser?.typeStr}
                      name={displayName}
                      className={twMerge(
                        selected === teamUser?.userId ? "rounded-full border border-purple-1" : "border border-transparent"
                      )}
                      avatar={teamUser?.organizationUser?.user?.avatarDetail?.url}
                      avatarClassName={twMerge(selected === teamUser?.userId ? "grayscale-0" : "grayscale")}
                      onClick={() => setSelected(teamUser?.userId)}
                    />
                }
                )}
            </div>
            <div className="flex w-full justify-end">
                <Button onClick={handleChange} isLoading={false} disabled={selected === -1} scale="fit">
                    {__("Change team head")}
                </Button>
            </div>
        </VerticalBox>
    );
};

export default ProfileTeamChangeHead;
