import {generalRequest} from '../services'

export async function getMyOrganisationList(data) {
    return generalRequest.get('student/organization', data)
}

export async function apiListOrganization(config ={}) {
    const query = new URLSearchParams(config);
    const str = query.toString();
    return generalRequest.get('student/organizations?'+ str, config)
}
export async function apiGetOrganization(username) {
    return generalRequest.get('student/organizations/'+ username)
}