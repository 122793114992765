import { twMerge } from "tailwind-merge";
import Skeleton from "../../skeleton";
import RenderIf from "../../render-if";

const GameCardLoading = ({ isSpacial = false, className }) => {
  return (
    <div className={twMerge("flex w-full shrink-0 flex-col gap-3 rounded-3xl bg-white p-3", className)}>
      <div className="flex items-center justify-between gap-2">
        <div className="flex w-1/3 items-center gap-1">
          <Skeleton className="h-8 w-8 shrink-0 rounded-full" />
          <Skeleton className="h-3 flex-1" />
        </div>
        <Skeleton className="h-7 w-16 rounded-full" />
      </div>
      <Skeleton className="aspect-4/3 w-full" />
      <div className="flex items-center justify-between gap-2">
        <Skeleton className="h-6 w-1/2" />
        <Skeleton className="h-6 w-10 rounded-full" />
      </div>
      <div className="flex items-center justify-between gap-2">
        <Skeleton className="h-3 w-1/2" />
        <Skeleton className="h-3 w-1/2" />
      </div>
      <RenderIf cond={isSpacial}>
        <div className="flex items-center  gap-2">
          <Skeleton className="h-7 w-16 rounded-full" />
          <Skeleton className="h-7 w-16 rounded-full" />
        </div>
      </RenderIf>
      <Skeleton className="mt-auto h-10 w-full rounded-full" />
    </div>
  );
};

export default GameCardLoading;
