import {twMerge} from "tailwind-merge";
import Typo from "../../typo";

const SimpleBadge = ({
                         children,
                         isActive,
                         className,
                         onClick,
                         inActiveColor = "bg-white/50",
                         inActiveTextColor = "white",
                     }) => {
    return (
        <Typo
            size="sm"
            className={twMerge(
                "rounded-full px-4 py-1",
                className,
                isActive ? "bg-orange-1 text-white" : `${inActiveColor} ${inActiveTextColor}`
            )}
            onClick={onClick}
        >
            {children}
        </Typo>
    );
};

export default SimpleBadge;
