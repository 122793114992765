import {useMutation, useQuery} from "@tanstack/react-query";
import {apiDoneStep, apiGetMission} from "../../../data/api/mission";


export const useGetMission = ({id, config}) => {
    return useQuery({
        queryKey: ['mission', id],
        queryFn: () => apiGetMission(id),
        ...config,
    })
}
export const useDoneStep = () => {
    return useMutation({
        mutationFn: ({missionId, stepId, subStepId}) =>
            apiDoneStep(missionId, stepId, subStepId),
    })
}