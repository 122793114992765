const ResponsiveFooter = ({ children }) => {
  return (
    <>
      <div className="fixed bottom-0 left-0 right-0 z-[2] w-full rounded-t-3xl bg-white p-3 shadow-[0px_4px_20px_0px_rgba(0,0,0,0.2)] md:hidden">
        {children}
      </div>
      <div className="hidden md:block md:w-fit">{children}</div>
    </>
  );
};

export default ResponsiveFooter;
