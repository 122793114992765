import moment from "moment-jalaali";

export const getFirstLetters = (value, splitBy = " ", joinBy = " ", length = 2) => {
    return value
        ?.split(splitBy)
        ?.map((x) => x[0])
        ?.slice(0, length)
        ?.join(joinBy);
};

export const getDaysOfMonth = (month) => {
    const arr = [];
    for (let i = 1; i <= 31; i++) arr.push({id: i.toString().padStart(2, "0"), title: i.toString().padStart(2, "0")});
    if (month <= 6) return arr;
    arr.pop();
    return arr;
};

export const getMonth = () => {
    return [
        {id: "01", title: "فروردین"},
        {id: "02", title: "اردیبهشت"},
        {id: "03", title: "خرداد"},
        {id: "04", title: "تیر"},
        {id: "05", title: "مرداد"},
        {id: "06", title: "شهریور"},
        {id: "07", title: "مهر"},
        {id: "08", title: "آبان"},
        {id: "09", title: "آذر"},
        {id: "10", title: "دی"},
        {id: "11", title: "بهمن"},
        {id: "12", title: "اسفند"},
    ];
};

export const getYears = (fromNYearAgo = 33) => {
        const arr = [];
        const nowYear = Number(moment().format("jYYYY"));
        for (let i = 0; i <= fromNYearAgo; i++) arr.push({id: (nowYear - i).toString(), title: `${nowYear - i}`});
        return arr;
    }
;

export const toSelectItem = (arr, key = "id", value = "name") => {
        return arr.map((record) => ({id: record[key], title: record[value]}));
    }
;

export const isValidIranianNationalCode = (input) => {
    const MAX_LEN = 10;
    const MIN_LEN = 8;
    const falsyNationalCode = [
        "0000000000",
        "1111111111",
        "2222222222",
        "3333333333",
        "4444444444",
        "5555555555",
        "6666666666",
        "7777777777",
        "8888888888",
        "9999999999",
    ];

    if (input.length < MIN_LEN || input.length > MAX_LEN) return false;
    let nationalCode = input;
    if (nationalCode.length < MAX_LEN) nationalCode = nationalCode.padStart(MAX_LEN, "0");
    if (falsyNationalCode.includes(nationalCode)) return false;

    let chArray = Array.from(input.substring(0, MAX_LEN - 1));
    let sum = chArray.reduce((sum, value, i) => sum + parseInt(value) * (MAX_LEN - i), 0);
    let mod = sum % 11;
    let controlValue = parseInt(nationalCode[MAX_LEN - 1]);
    return (mod < 2 && controlValue === mod) || (mod >= 2 && controlValue === 11 - mod);
};

export const toMBString = (fileSize) => {
    return fileSize > 1024 * 1024
        ? `${Math.round(fileSize / (1024 * 1024))} مگابایت`
        : `${Math.round(fileSize / 1024)} کیلوبایت`;
};

export const convertToEnNumber = (number) => {
    // convert persian digits [۰۱۲۳۴۵۶۷۸۹]
    var e = "۰".charCodeAt(0);
    number = number.replace(/[۰-۹]/g, (t) => {
        return t.charCodeAt(0) - e;
    });

    // convert arabic indic digits [٠١٢٣٤٥٦٧٨٩]
    e = "٠".charCodeAt(0);
    number = number.replace(/[٠-٩]/g, (t) => {
        return t.charCodeAt(0) - e;
    });
    return number;
};
