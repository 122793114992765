import ProfilePersonCreateTeam from "./components/create";
import ProfilePersonIdleTeam from "./components/idle";
import ProfilePersonJoinToTeam from "./components/join";
import ProfilePersonCheckTeam from "./components/pending";
import {useAuthContext} from "../../../../../Auth/provider/AuthProvider";
import RenderIf from "../../../../../../core/components/render-if";
import VerticalBox from "../../../../../../core/components/box/vertical";
import {useEffect, useState} from "react";
import Button from "../../../../../../core/components/button";

const ProfilePersonCreateOrJoinTeam = () => {

    const {user, organisation, team} = useAuthContext();
    const [status, setStatus] = useState(!team ? "without-team" : team.status === "accepted" ? "accepted" : "pending");

    useEffect(() => {
        setStatus(!team ? "without-team" : team.status === "accepted" ? "accepted" : "pending")
    }, [team]);
    return (
        <RenderIf cond={organisation?.status === "accepted" && status !== "accepted"}>
            <VerticalBox
                withBackground
                className="flex min-h-[16rem] rounded-b-3xl shadow-[0px_4px_20px_0px_rgba(0,0,0,0.2)] md:w-[32rem]"
                containerClassName="items-center"
            >
                <RenderIf cond={true}>
                    <div className="flex flex-col items-center gap-8 p-4">
                        <RenderIf cond={status === "without-team"}>
                            <ProfilePersonIdleTeam onChange={(status) => {
                                setStatus(status)
                            }}/>
                        </RenderIf>
                        <RenderIf cond={status === "create-team" &&  organisation?.organization?.settingPretty?.allowStudentCreateTeam !== 'no'}>
                            <ProfilePersonCreateTeam onChange={(status) => {
                                setStatus(status)
                            }}/>
                        </RenderIf>

                        <RenderIf cond={status === "join-to-team"}>
                            <ProfilePersonJoinToTeam onChange={(status) => {
                                setStatus(status)
                            }}/>
                        </RenderIf>

                        <RenderIf cond={["checking", "pending"].includes(status)}>
                            <ProfilePersonCheckTeam/>
                        </RenderIf>
                    </div>
                </RenderIf>
            </VerticalBox>
        </RenderIf>
    );
};

export default ProfilePersonCreateOrJoinTeam;
