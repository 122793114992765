import {twMerge} from "tailwind-merge";
import Spinner from "../spinner";

const IconButton = ({
                        onClick,
                        className,
                        children,
                        size = "base",
                        isLoading = false,
                        type = "primary",
                    }) => {
    const getSize = () => {
        if (size === "base") return "w-10 h-10";
        else if (size === "sm") return "w-8 h-8";
        else return "w-6 h-6";
    };

    const getBackgroundColor = () => {
        if (type === "primary") return "bg-purple-6";
        else if (type === "accent") return "bg-linear-purple-7";
        else return "bg-transparent";
    };

    return (
        <div
            className={twMerge(
                " flex aspect-square cursor-pointer items-center justify-center rounded-full",
                getSize(),
                getBackgroundColor(),
                className
            )}
            onClick={onClick}
        >
            {isLoading ? <Spinner type={type === "primary" ? "accent" : "primary"} size={size}/> : children}
        </div>
    );
};

export default IconButton;
