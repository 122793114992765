import {useNavigate} from "react-router-dom";
import {STEPS} from "./steps";
import {useState} from "react";
import useScreenSize from "../../../../core/hooks/useScreenSize";
import {useAuthContext} from "../../../Auth/provider/AuthProvider";
import Typo from "../../../../core/components/typo";
import ScrollView from "../../../../core/components/scroll-view";
import RoadMapStep from "../../../../core/components/road-map-step/center";
import ProfileNotCompleteModal from "../../../../core/components/modal/profile-not-complete";
import * as PropTypes from "prop-types";
import __ from "../../../../core/utils/translate/translate";

function JoinToClubModal(props) {
    return null;
}

JoinToClubModal.propTypes = {
    onClose: PropTypes.func,
    isOpen: PropTypes.bool
};
const HomeAdvanture = () => {
    const { user, organisation, team, dashboard} = useAuthContext();

    const {screenSize} = useScreenSize();
    const navigate = useNavigate();

    const [modal, setModal] = useState("idle");


    const getDoneIndex = () => {
        const userComplete = user?.firstName && user?.lastName
        if (!userComplete) return 1;
        else if (userComplete && !organisation) return 2;
        else if (userComplete && !!organisation && !team) return 3;
        else if (userComplete && !!organisation && !!team) return 4;
        else return 0;
    };



    return (
        <>
            <div className="relative z-[1]  flex w-screen flex-col items-center gap-8">
                <Typo as="h1" size="xl" weight="bold" type="accent" className="px-4">
                    {__("Get ready to move")}
                </Typo>
                <div className="flex w-full justify-center">
                    <ScrollView className="-mx-4 px-4">
                        {STEPS.map((step, i) => (
                            <RoadMapStep
                                key={'RoadMapStep' + step?.id + '-' +i}
                                inPage={`home`}
                                index={i}
                                currentIndex={getDoneIndex()}
                                isLast={i === STEPS.length - 1}
                                icon={step.icon}
                                title={step.title}
                                onClick={() => navigate(step.link)}
                            />
                        ))}
                    </ScrollView>
                </div>
                <Typo as="p" className="max-w-2xl px-8 text-center">
                    {__("If you want to save the world around you and make the world a better place by taking big steps, this is for you!")}
                    <Typo
                        as={["xs", "sm"].includes(screenSize) ? "p" : "span"}
                        className="mx-auto w-fit bg-purple-1 px-2"
                        type="on-accent"
                        weight="semi-bold"
                    >
                        {__("Dont think big job,do it!")}
                    </Typo>
                </Typo>
            </div>
            {/*<ProfileNotCompleteModal isOpen={modal === "complete-profile"}/>*/}
            {/*<JoinToClubModal isOpen={modal === "join-to-club"} onClose={() => setModal("idle")}/>*/}
        </>
    );
};

export default HomeAdvanture;
