import { useParams } from "react-router-dom";
import {SwiperSimpleCarousel} from "../../../../core/components/swiper";
import {useState} from "react";
import FileView from "../../../../core/components/file-view";
import {SwiperSlide} from "swiper/react";
import ScrollView from "../../../../core/components/scroll-view";
import {useGetMission} from "../../api/mission";

const GameSlider = ({slider}) => {

  if (!slider) return <></>;
  if (!slider?.children) return <></>;



  return (
    <>
      <div className="sm:hidden">
        <SwiperSimpleCarousel loop pagination navigation className="w-full xs:w-3/4">
          {slider?.children.map((slide, i) => (
            <SwiperSlide key={'sliderChildren'+slide?.id+'_'+i} className="w-full overflow-hidden rounded-xl">
              <FileView {...slide.fileDetail} thumbnail={undefined} controls className="h-full w-full bg-cover" />
            </SwiperSlide>
          ))}
        </SwiperSimpleCarousel>
      </div>
      <div className="hidden sm:block">
        <ScrollView className="-mx-4 gap-4 px-4">
          {slider?.children.map((slide, i) => (
            <FileView
              key={'slider'+slide?.id+'_'+i}
              {...slide.fileDetail}
              thumbnail={undefined}
              controls
              className="max-h-[16rem] w-fit overflow-hidden rounded-xl"
            />
          ))}
        </ScrollView>
      </div>
    </>
  );
};

export default GameSlider;
