import { useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";
import VerticalBox from "../box/vertical";
import ScrollView from "../scroll-view";

const TabView = ({ tabs, activeTab, onClick, children, className, containerClassName }) => {
  const refScrollView = useRef(null);

  useEffect(() => {
    if (refScrollView.current)
      refScrollView.current.scrollTo(
        0,
        -Math.max(
          tabs.findIndex((tab) => tab.id === activeTab),
          0
        ) * 80,
        "smooth"
      );
  }, [refScrollView.current]);

  return (
    <VerticalBox className={twMerge("relative -mt-5 w-full", containerClassName)}>
      <ScrollView
        ref={refScrollView}
        className="absolute right-0 top-2.5 z-[1] mx-[2.5%] w-fit max-w-[95%] whitespace-nowrap rounded-full bg-white p-1.5"
      >
        {tabs.map((tab, i) => (
          <div
            key={'tab.title' + 'tabView' + i}
            className={twMerge(
              "cursor-pointer rounded-full p-2 px-6 md:px-10 shrink-0",
              activeTab === tab.id ? "bg-purple-3 text-white" : "bg-white text-purple-1"
            )}
            onClick={() => (activeTab !== tab.id ? onClick(tab) : null)}
          >
            {tab.title}
          </div>
        ))}
      </ScrollView>
      <div className={twMerge("w-full px-4 py-14 pt-20", className)}>{children}</div>
    </VerticalBox>
  );
};

export default TabView;
