import {useEffect, useState} from "react";

const useScreenSize = () => {
    const handleWindowResize = () => {
        if (window.innerWidth <= 360) return "xs";
        else if (window.innerWidth <= 640) return "sm";
        else if (window.innerWidth <= 768) return "md";
        else if (window.innerWidth <= 1024) return "lg";
        else if (window.innerWidth <= 1280) return "xl";
        else if (window.innerWidth <= 1536) return "2xl";
        else return "3xl";
    };
    const [screenSize, setScreenSize] = useState(handleWindowResize());

    useEffect(() => {
        window.addEventListener("resize", () => {
            setScreenSize(handleWindowResize());
        });
        return () =>
            window.removeEventListener("resize", () => {
                setScreenSize(handleWindowResize());
            });
    }, []);
    return {screenSize};
};

export default useScreenSize;
