import {generalRequest} from "../services";
import config from "tailwindcss/defaultConfig";

export async function apiListComments(config = {}) {
    const query = new URLSearchParams(config);
    const str = query.toString();
    return generalRequest.get(`student/comments?`+ str, config)
}
export async function apiPostComment(data) {
    return generalRequest.post(`student/comment`, data)
}
export async function apiDeleteComment(id) {
    return generalRequest.delete(`student/comment/${id}`, config)
}
export async function apiUpdateComment(id, data) {
    return generalRequest.put(`student/comment/${id}`, data)
}