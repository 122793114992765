import { Link, useNavigate } from "react-router-dom";
import {twMerge} from "tailwind-merge";
import Button from "../../../../core/components/button";
import {useScrollDirection} from "../../../../core/hooks/useScrollDirection";
import __ from "../../../../core/utils/translate/translate";

const LandingHeader = () => {
    const scrollDirection = useScrollDirection();

    return (
        <header
            className={twMerge(
                "fixed top-0 z-[10] flex w-full items-center justify-between rounded-b-3xl bg-white px-4 py-2.5 shadow-[0px_4px_30px_0px_rgba(3,64,160,0.15)] transition-all",
                scrollDirection === "down" ? "-top-full" : "top-0"
            )}
        >
            <img src="/images/logo.svg" alt="logo" className="max-h-[24px]" loading="lazy"/>
            <Link to={'/app'}>
                <Button type="light" size="sm" scale="fit" onClick={() => {}}>
                    {__("Enter the Nojahan")}
                </Button>
            </Link>
        </header>
    );
};

export default LandingHeader;
