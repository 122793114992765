import useQueryString from "../../../../core/hooks/useQueryString";
import {Fragment} from "react";
import RenderIf from "../../../../core/components/render-if";
import Typo from "../../../../core/components/typo";
import Spinner from "../../../../core/components/spinner";
import LoadMore from "../../../../core/components/loadmore";
import {useListNotification} from "../../api";
import Notification from "../notif";
import __ from "../../../../core/utils/translate/translate";

const NotificationList = () => {
  const { queryStringFN } = useQueryString();


  const {
    data: notifData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
      refetch
  } = useListNotification({type: queryStringFN.get("name", 'all')});


  return (
    <>
      <RenderIf cond={!isLoading && notifData?.pages.at(0)?.data.length === 0}>
        <Typo>{__("You have not received a message yet!")}</Typo>
      </RenderIf>
      {notifData?.pages.map((group, iG) => (
        <div key={'notificationList' + 'notification' + iG} className="-mx-4">
          {group.data.map((notif, i) => (
                <Notification notification={notif} key={'notification'+ notif?.id + '-' + i} refecth={refetch} />
          ))}
        </div>
      ))}

      <RenderIf cond={isLoading}>
        <Spinner type="accent" size="xs" />
      </RenderIf>

      <div className="pt-8">
        <LoadMore
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage === true}
          isFetchingNextPage={isFetchingNextPage}
        />
      </div>
    </>
  );
};

export default NotificationList;
