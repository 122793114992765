import React, {useEffect, useRef, useState} from 'react';
import {X, Send,LoaderCircle} from "lucide-react";
import {getJalaliDate} from "../../../../core/utils/date";
import {fullName} from "../../../../core/helper/fullName";
import {CommonIcon} from "../../../../core/components/icon";
import ErrorReportModal from "../../../../core/components/modal/error-report";
import DropDownBox from "../../../../core/components/drop-down-box";
import {useAuthContext} from "../../../Auth/provider/AuthProvider";
import Typo from "../../../../core/components/typo";
import RenderIf from "../../../../core/components/render-if";
import {TextArea, TextInput} from "../../../../core/components/input";
import {useDeleteComment, useUpdateComment} from "../../../../core/components/comment/api/comment";
import {toast} from "react-toastify";
import handleError from "../../../../core/helper/handelError";
import {twMerge} from "tailwind-merge";
import Button from "../../../../core/components/button";

function CommentExp({comment}) {
    const [isOpen, setIsOpen] = useState(false)
    const [commentContent, setCommentContent] = useState('')
    const refDropBox = useRef(null);
    const [editing, setEditing] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(true)
    const {user} = useAuthContext()
    const {mutate: mutateUpdate }= useUpdateComment()
    const {mutate: mutateDelete} = useDeleteComment()

    useEffect(() => {
        setCommentContent(comment?.comment)
    }, [comment]);

    const updateComment = function (){
        setEditing(false)
        mutateUpdate({
            id: comment?.id,
            data: {
                comment: commentContent
            }
        }, {
            onSuccess: (data)=>{

            }
        })
    }

    const deleteComment = function (){
        setLoading(true)
        setVisible(false)
        mutateDelete({
            id: comment?.id,
        },{
            onSuccess: (data)=>{
                toast.success(data?.message)
                setLoading(false)
                setDeleteModal(false)
            },onError: (err)=>{
                handleError(err)
                setLoading(false)
                setDeleteModal(false)
            }
        })
    }

    const handleDropBoxItemClick = (_item) => {
        if (_item.id === "report") setIsOpen(true);
        else if (_item.id === "edit") setEditing(true);
        else if (_item.id === "delete") setDeleteModal(true);
    };
    const items = comment?.user?.id === user?.id ?[
        {
            id: "edit",
            title: 'ویرایش',
            icon: <CommonIcon
                icon="edit"
                className="h-4 w-4 cursor-pointer"
                stroke="#707489"
            />,
        },
        {
            id: "delete",
            title: 'حذف',
            icon: <CommonIcon
                icon="trash"
                className="h-4 w-4 cursor-pointer"
                stroke="#707489"
            />,
            titleClassName: "text-red",
            className:'hover:bg-action-danger/10'
        }
    ] : [
        {
            id: "report",
            title: 'گزارش خطا',
            icon: <CommonIcon
                icon="flag"
                className="h-4 w-4 cursor-pointer"
                stroke="#707489"
            />,
        },
    ]
    if (visible === false){
        return null
    }

    if (visible === true) {
        return (
            <div className={'relative'}>
                <div className="flex items-start space-x-3 p-2">
                    <div className="w-8 h-8 rounded-full bg-gray-200">
                        <img className={'rounded-full'}
                             src={comment?.user?.avatarDetail?.url ?? '/images/avatarDefault.png'}
                             alt={comment?.user?.id}/>
                    </div>
                    <div className="flex-1">
                        <p className={'max-w-3xl break-words pl-4 pr-2'}>
                    <span
                        className="font-semibold mr-2">{fullName(comment.user?.firstName, comment.user?.lastName)} </span>
                            <RenderIf cond={!editing}>
                                {commentContent}
                            </RenderIf>
                            <RenderIf cond={!!editing}>
                                <div className={'flex justify-between max-w-3xl'}>
                                    <div className={'w-full'}>
                                        <Typo size={'xs'}>در حال ویرایش دیدگاه...</Typo>
                                        <TextArea
                                            name="text"
                                            placeholder={'دیدگاه خودتو ثبت کن ...'}
                                            className="max-w-3xl font-light "
                                            value={commentContent}
                                            onChange={(e) => setCommentContent(e.target.value)}
                                            inputMode="search"
                                        />
                                    </div>
                                    <button
                                        className={`hover:text-blue-400 ml-2 ${!commentContent && 'opacity-50'}`}
                                        disabled={!commentContent}
                                        onClick={() => updateComment()}
                                    >
                                        <Send size={20} strokeWidth={1.5} className={'m-4'}/>
                                    </button>
                                </div>
                            </RenderIf>
                        </p>
                        <div
                            className="flex items-center space-x-3 mt-1 text-sm text-gray-500">
                            <span>{getJalaliDate(comment.createdAt)}</span>
                            {/*<span>{comment.likes} likes</span>*/}
                            {/*<button className="font-semibold">Reply</button>*/}
                        </div>
                    </div>
                    <div className={'relative'}>
                        {deleteModal && <ul
                            className={twMerge("absolute z-[2] flex flex-col rounded-xl bg-white shadow-lg overflow-hidden left-0 top-0")}
                        >
                            <div
                                className={twMerge("text-sm text-center text-primary")}>مطمئنی که میخوای حذف کنی؟</div>
                            <div className={'flex gap-1 mx-2 mb-1'}>
                                <Button className={'w-12'} size={'xs'}  type={'default'} onClick={()=> setDeleteModal(false)}>خیر</Button>
                                <Button className={'w-12'} size={'xs'} onClick={()=> deleteComment()} isLoading={loading} >بله</Button>
                            </div>
                        </ul>}
                    </div>
                    <RenderIf cond={comment?.myStatus === 'pending'}>
                        <LoaderCircle size={16} strokeWidth={1.5} stroke={"#707489"} />
                    </RenderIf>
                    <RenderIf cond={!!editing}>
                        <X size={20} className={'hover:text-blue-400'}
                           strokeWidth={1.5}
                           onClick={()=> setEditing(false)}
                        />
                    </RenderIf>

                    <RenderIf cond={!editing && comment?.myStatus !== "pending"}>
                        <CommonIcon
                            icon="more"
                            className="h-4 w-4 cursor-pointer"
                            stroke="#707489"
                            onClick={() => refDropBox.current.toggleVisibility()}
                        />
                    </RenderIf>
                    <DropDownBox
                        ref={refDropBox}
                        initOpen={false}
                        className="left-0 top-0"
                        onChange={handleDropBoxItemClick}
                        items={items}
                    />
                    <ErrorReportModal
                        isOpen={isOpen}
                        onClose={() => setIsOpen(false)}
                        related={"comment"}
                        relatedId={comment?.id}
                    />
                    {/*<button className="text-gray-400 hover:text-gray-600">*/}
                    {/*    <Heart className="h-3 w-3"/>*/}
                    {/*</button>*/}
                </div>
            </div>
        );
    }
}

export default CommentExp;