import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { convertToEnNumber, getDaysOfMonth, getMonth, getYears, toSelectItem } from "../../../utils/helper";
import Typo from "../../../core/components/typo";
import Avatar from "../../../core/components/avatar";
import {InputLabel, SelectInput, TextArea, TextInput, validateForm} from "../../../core/components/input";
import { useAuthContext } from "../../Auth/provider/AuthProvider";
import RootLayout from "../../../layout/root";
import Button from "../../../core/components/button";
import ResponsiveFooter from "../../../core/components/footer";
import { useGetLocations } from "../api/location";
import handleError from "../../../core/helper/handelError";
import { useUploadFile } from "../api/file";
import DateTimeHelper from "../../../utils/date-time-helper";
import { useGetCurrentUser, useUpdateProfile } from "../../Auth/api/auth";
import { EDUCATIONS } from "../../../core/constants/constants";
import { toast } from "react-toastify";
import __ from "../../../core/utils/translate/translate";

const ProfileEditPage = () => {
  const [month, setMonth] = useState(1);
  const [provinceID, setProvinceID] = useState(0);
  const [cityId, setCityId] = useState(0)


  const [provincesData, setProvincesData] = useState([]);
  const [citiesData, setCitiesData] = useState([]);
  const [isFirstTime, setIsFirstTime] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const [avatar, setAvatar] = useState();
  const [avatarId, setAvatarId] = useState();
  const refInputs = {
    firstName: useRef(null),
    lastName: useRef(null),
    nationalCode: useRef(null),
    education: useRef(null),
    province: useRef(null),
    city: useRef(null),
    yearOfBirthdate: useRef(null),
    monthOfBirthdate: useRef(null),
    dayOfBirthdate: useRef(null),
    address: useRef(null),
    biography: useRef(null),
  };
  const {
    data: locationsProvinces,
    error: locationsErrorProvinces,
    isLoading: locationsIsLoadingProvinces,
    isSuccess: locationsIsSuccessProvinces,
    isError: locationsIsErrorProvinces,
  } = useGetLocations({ parentId: 1, enable: true });
  const {
    data: locationsCity,
    error: locationsErrorCity,
    isLoading: locationsIsLoadingCity,
    isSuccess: locationsIsSuccessCity,
    isError: locationsIsErrorCity,
  } = useGetLocations({ parentId: provinceID, enable: !!(provinceID !== 0 || cityId) });


  const memoYears = useMemo(() => getYears(), []);
  const memoMonth = useMemo(() => getMonth(), []);
  const memoDays = useMemo(() => getDaysOfMonth(month), [month]);
  const memoProvinces = useMemo(() => toSelectItem(provincesData?.data ?? []), [provincesData?.data]);
  const memoCities = useMemo(() => toSelectItem(citiesData?.data ?? []), [citiesData?.data]);

  const {
    mutate: uploadFile,
    isPendingUpload,
    isErrorUpload,
    errorUpload,
    isSuccessUpload,
    dataUpload,
  } = useUploadFile();


  useEffect(() => {
    if(user?.provinceDetail?.id)
    {
      setProvinceID(user.provinceDetail.id)
    }

    if(user?.cityDetail?.id)
    {
      setCityId(user.cityDetail.id)
    }

  }, [user]);


  const handleUploadAvatar = (file) => {

      uploadFile(file, {
        onSuccess: (data) => {

          setAvatar(data.data?.data.url);

          setAvatarId(data.data?.data.id);
          handleUpdate({avatar: data.data?.data.id});

        },
        onError: (error) => {
          handleError(error);
        },
      });

    }
  ;


  const { mutate: updateProfile, isPending, isError, error, isSuccess, data } = useUpdateProfile();
  const { data: currentUser, refetch: refetchUserData } = useGetCurrentUser({ manual: true });
  const handleUpdate = (data = {}) => {
    // if (!validateForm(refInputs)) return;
    if (!data?.avatar){
      if (
          !refInputs.firstName.current.getValue() ||
          !refInputs.lastName.current.getValue() ||
          !refInputs.nationalCode.current.getValue() ||
          !refInputs.education.current.getItem()?.id ||
          !refInputs.province.current.getItem()?.id ||
          !refInputs.city.current.getItem()?.id ||
          !refInputs.yearOfBirthdate.current.getItem()?.id
      ){
        toast.error(__("It is necessary to complete all fields"))
        return
      }
    }


    const birthdate = new DateTimeHelper(
      `${refInputs.yearOfBirthdate.current.getItem()?.id}/${refInputs.monthOfBirthdate.current.getItem()
        ?.id}/${refInputs.dayOfBirthdate.current.getItem()?.id}`,
      "jYYYY/jMM/jDD",
      false,
    );




    updateProfile({
      avatar: data?.avatar ?? avatarId,
      firstName: refInputs.firstName.current.getValue(),
      lastName: refInputs.lastName.current.getValue(),
      nationalCode: convertToEnNumber(refInputs.nationalCode.current.getValue()),
      education: refInputs.education.current.getItem()?.id,
      provinceID: refInputs.province.current.getItem()?.id,
      cityID: refInputs.city.current.getItem()?.id,
      birth_date: refInputs.yearOfBirthdate.current.getItem()?.id ? birthdate.format("YYYY-MM-DD") : undefined,
      address: refInputs.address.current.getValue(),
      biography: refInputs.biography.current.getValue(),
    }, {
      onSuccess: (data) => {
        refetchUserData();
        toast.success(__("Your profile has been successfully saved"));
      },
      onError: (error) => {
        handleError(error);
      },
    });
  };


  useEffect(() => {
    if (!user) return;
    if ((user?.nationalCode?.length ?? 0) <= 0) setIsFirstTime(true);
    setProvinceID(user?.provinceDetail?.id ?? 0);
    setCityId(user?.cityDetail?.id ?? 0);
    refInputs.firstName.current.setValue(user?.firstName);
    refInputs.lastName.current.setValue(user?.lastName);
    refInputs.nationalCode.current.setValue(user?.nationalCode);
    if (user?.avatarDetail?.id) {
      setAvatarId(user?.avatarDetail?.id);
    }
    const birthdate = new DateTimeHelper(
      `${user?.birthDate}`,
      "YYYY-MM-DDTHH:mm:ss",
      true,
    );
    if (birthdate.format("YYYY-MM-DD") !== "Invalid date") {
      const tempBirthdate = birthdate?.toShamsi().format("jYYYY/jMM/jDD").split("/");
      if ((tempBirthdate?.length ?? 0) > 0) {
        refInputs.dayOfBirthdate.current.setItemByID(tempBirthdate?.at(2));
        refInputs.monthOfBirthdate.current.setItemByID(tempBirthdate?.at(1));
        refInputs.yearOfBirthdate.current.setItemByID(tempBirthdate?.at(0));
      }
    }

    refInputs.address.current.setValue(user?.address);
    refInputs.education.current.setItemByID(user?.education);
    refInputs.biography.current.setValue(user?.biography);
  }, [user]);

  useEffect(() => {
    if (!user) return;
    setProvinceID(user?.provinceDetail?.id ?? 0);
    refInputs.province.current.setItemByID(user?.province?.id);
  }, [provincesData]);

  useEffect(() => {
    if (!user) return;
    // refInputs.city?.current?.reset();
    refInputs.city.current.setItemByID(cityId);
  }, [citiesData, cityId]);

  useEffect(() => {
    refInputs.city?.current?.reset();
  }, [refInputs.province?.current?.getItem()?.id]);


  return (
    <RootLayout title={__("Edit profile")}>
      <div className="relative flex flex-col gap-8 py-20">
        <div className="mx-auto grid w-full max-w-5xl gap-x-8 gap-y-4 px-8 md:grid-cols-2">
          <Avatar
            name={user?.lastName??""}
            size="xl"
            isEditable
            onCropComplete={handleUploadAvatar}
            image={avatar ?? user?.avatarDetail?.url}
            avatarClassName="border-4 border-purple-2"
            isLoading={isPendingUpload}
          />
          <div className="hidden md:block" />
          <TextInput
            name={__("First name")}
            label={__("First name")}
            required
            ref={refInputs.firstName}
            maxLength={200}
            rules={[
              { rule: "minLength", cond: 2 },
              // {
              //     rule: "regex",
              //     cond: regexs.persianCharacter.pattern,
              //     message: regexs.persianCharacter.message,
              // },
            ]}
          />
          <TextInput
            name={__("Last name")}
            label={__("Last name")}
            required
            ref={refInputs.lastName}
            maxLength={400}
            rules={[
              { rule: "minLength", cond: 2 },
              // {
              //     rule: "regex",
              //     cond: regexs.persianCharacter.pattern,
              //     message: regexs.persianCharacter.message,
              // },
            ]}
          />

          <TextInput
            name={__("National code")}
            label={__("National code")}
            inputMode="numeric"
            required
            ref={refInputs.nationalCode}
            maxLength={10}
            rules={[
              { rule: "size", cond: 10 },
              { rule: "nationalCode", cond: true },
            ]}

          />

          <SelectInput
            name={__("Education level")}
            label={__("Education level")}
            required
            data={[
              ...Object.keys(EDUCATIONS).map(key => {
                return { title: EDUCATIONS[key], id: key };
              }),
            ]}
            ref={refInputs.education}
          />

          <SelectInput
            name={__("Province")}
            label={__("Province")}
            required
            ref={refInputs.province}
            itemID={provinceID}
            data={toSelectItem(locationsProvinces?.data ?? [])}
            // loading={isProvincesLoading}
            loading={false}
            onChange={(item) => {
              setProvinceID(item.id);
            }}
          />
          <SelectInput
            name={__("City")}
            label={__("City")}
            required
            ref={refInputs.city}
            itemID={cityId}
            data={toSelectItem(locationsCity?.data ?? [])}
            loading={false}
            onChange={(item) => {
              setCityId(item.id);
            }}
          />
        </div>
        <div className="mx-auto grid w-full max-w-5xl gap-x-8 gap-y-4 px-8 md:grid-cols-2">
          <div className="border-t border-dashed border-t-purple-4 pt-4 lg:col-span-2">
            <Typo weight="bold">{__("More information")}</Typo>
            <Typo type="secondary" size="xs">
              {__("Your information remain confidential at the your organization and Nojahan")}
            </Typo>
          </div>

          <div className="flex flex-col gap-2">
            <InputLabel label={__("Birth date")} required />
            <div className="flex items-center gap-2">
              <SelectInput
                name={__("Day")}
                data={memoDays}
                ref={refInputs.dayOfBirthdate}
                required
              />
              <SelectInput
                name={__("Month")}
                data={memoMonth}
                ref={refInputs.monthOfBirthdate}
                onChange={(item) => setMonth(item.id)}
                required
              />
              <SelectInput
                name={__("Year")}
                data={memoYears}
                ref={refInputs.yearOfBirthdate}
                required
              />
            </div>
          </div>

          <TextArea
            name={__("Address")}
            label={__("Address")}
            className="md:col-span-2"
            maxLength={200}
            rules={[{ rule: "minLength", cond: 2 }]}
            ref={refInputs.address}
          />

          <TextArea
            name={__("About me")}
            label={__("About me")}
            className="md:col-span-2"
            maxLength={200}
            rules={[{ rule: "minLength", cond: 2 }]}
            ref={refInputs.biography}
          />

          <ResponsiveFooter>
            <Button onClick={handleUpdate} isLoading={isPending}>
              {__("Save information")}
            </Button>
          </ResponsiveFooter>
        </div>
      </div>
    </RootLayout>
  );
};

export default ProfileEditPage;
