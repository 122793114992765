import React, {createContext, useContext, useMemo, useState} from 'react'

const AuthContext = createContext({})

export function useAuthContext() {
    return useContext(AuthContext)
}

export function AuthProvider({children}) {
    const [user, setUser] = useState({})
    const [organisation, setOrganisation] = useState();
    const [team, setTeam] = useState();
    const [dashboard, setDashboard] = useState();
    const [teamUser, setTeamUser] = useState([])
    const [youHaveNewMessage, setYouHaveNewMessage] = useState(false)

    const value = useMemo(() => ({
        user, setUser, organisation, setOrganisation, team, setTeam, dashboard, setDashboard,teamUser,setTeamUser,youHaveNewMessage,setYouHaveNewMessage
    }), [user, setUser, organisation, setOrganisation, team, setTeam, dashboard,teamUser,setTeamUser,youHaveNewMessage,setYouHaveNewMessage],)

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
