import {useMutation} from "@tanstack/react-query";
import {apiJoinOrganisation, apiLeftOrganisation} from "../../../data/api/organisationUser";

export const useLeftOrganisation = () => {
    return useMutation({
        mutationFn: ({organisationUserId}) =>
            apiLeftOrganisation(organisationUserId),
    })
}
export const useJoinOrganisation = () => {
    return useMutation({
        mutationFn: ({organisationId, code}) =>
            apiJoinOrganisation({
                organization_id: organisationId,
                invite_code: code ? `K-${code}` : ''
            }),
    })
}