import React from 'react';
import Button from "../../../../../../core/components/button";
import {useNavigate} from "react-router-dom";

export default function ViewMissionBtn({notification,refetch,data}) {
    const navigate = useNavigate();
    const handleClick = () => {
        if (data?.id){
            navigate(`/app/missions/${data?.id}`);
        }
    }

    return (
        <>
            <Button size="sm" type={'light'} scale="fit" onClick={handleClick}>
                {data?.title}
            </Button>
        </>
    );
}