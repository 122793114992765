import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";
import RenderIf from "../../render-if";
import Typo from "../../typo";

const LinearProgressbar = ({
  value,
  max = 100,
  className = "",
  hiddenOnZero = false,
  withPercent = false
}) => {
  const percent = `${Math.ceil((value / max) * 100)}%`;

  if (hiddenOnZero && value === 0) return <></>;

  return (
    <div className={twMerge("flex items-center gap-2", className)}>
      <div className="w-full rounded-full bg-white p-0.5">
        <motion.div
          className="h-1.5 rounded-full bg-orange-1"
          initial={{ width: 0 }}
          animate={{ width: percent }}
          transition={{ duration: 1 }}
        />
      </div>
      <RenderIf cond={withPercent}>
        <Typo as="p" size="2xs" weight="bold">
          {percent}
        </Typo>
      </RenderIf>
    </div>
  );
};

export default LinearProgressbar;
