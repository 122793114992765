import React from 'react';
import BaseNotification from "../base";
import ButtonsNotification from "../buttons";
import {CommonIcon} from "../../../../core/components/icon";

export default function Notification({notification,refecth}) {
    return (
        <BaseNotification
            notification={notification}
            buttons={<ButtonsNotification
                        notification={notification}
                        buttonData={notification?.actions}
                        refecth={refecth}
            />}
        >
            {notification?.message}
        </BaseNotification>

    );
}