import React, {useEffect, useState} from 'react';
import {Send, Smile} from "lucide-react";
import {usePostComment} from "../../../../core/components/comment/api/comment";
import {TextArea} from "../../../../core/components/input";

function SendCommentEXP({missionRunnerReportId, setComment}) {
    const [newComment, setNewComment] = useState("")
    const { mutate, isLoading, isSuccess } = usePostComment();

    const sendComment = function (){
        setComment(newComment)
        setNewComment("")
        mutate(
            {
                comment: newComment,
                missionRunnerReportId: missionRunnerReportId,
            },
            {
                onSuccess: () => {
                    setNewComment("");
                },
                onError: (error) => {
                    // handleError(error)
                }
            }
        );
    }

    return (
        <div className="flex fixed bottom-0 right-0 left-0 items-center mt-4 pt-4 border-t border-gray-200 gap-2 p-4 bg-white">
            <Smile className="h-6 w-6 mr-2"/>
            <TextArea
                name="comment"
                rows={1}
                placeholder={'دیدگاه خودتو ثبت کن ...'}
                className="max-w-3xl font-light "
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                inputMode="search"
            />
            <button
                className={`hover:text-blue-400 font-semibold ml-2 ${!newComment && 'opacity-50'}`}
                disabled={!newComment}
                onClick={() => sendComment()}
            >
                <Send/>
            </button>
        </div>
    );
}

export default SendCommentEXP;