import React from "react";
import {twMerge} from "tailwind-merge";
import AudioPlayer from "../audio-player";
import {CommonIcon} from "../icon";
import RenderIf from "../render-if";

const FileViewExplore = ({
  url,
  mime = "",
  contents = "",
  className,
  iconClassName,
  fileClassName,
  imageClassName,
  controls = false,
  thumbnail,
    detail
}) => {
  const onError = (event) => {
    event.currentTarget.src = "https://dl.nojahan.ir/minio/shared_images/game-cover.png";
  };

  return (
    <>
      <RenderIf cond={detail? detail?.mime.startsWith("image") : mime.startsWith("image")}>
        <img
          src={detail ? detail.url : url}
          alt="cover"
          className={twMerge("aspect-4/3 h-full w-full object-cover", className, imageClassName)}
          onError={onError}
        />
      </RenderIf>
      <RenderIf cond={detail? detail?.mime.startsWith("video") : mime.startsWith("video")}>
        <video
          src={detail ? detail.url : url}
          autoPlay={false}
          controls={true}
          className={twMerge("aspect-video h-full w-full bg-black object-contain", className)}
        />
      </RenderIf>
      <RenderIf cond={detail ? detail?.mime.startsWith("text/uri-list") : mime?.startsWith("text/uri-list") && contents?.search("arvan") !== -1}>
        <div className={twMerge("flex aspect-video h-full w-full items-center justify-center bg-black", className)}>
          <iframe
            className={twMerge("h-full w-full", className)}
            id="ir1p"
            title="arvan"
            src={`https://player.arvancloud.ir/?config=${contents}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </RenderIf>
      <RenderIf cond={detail? detail?.mime.startsWith("application") : mime.startsWith("application")}>
        <a
          target="_blank"
          href={detail ? detail.url : url}
          className={twMerge(
            "bg-primary-light flex aspect-4/3 items-center justify-center p-2",
            className,
            fileClassName
          )}
        >
          <CommonIcon icon="document" className={iconClassName} />
        </a>
      </RenderIf>
      <RenderIf cond={detail? detail?.mime.startsWith("audio") : mime.startsWith("audio")}>
        <div className={twMerge("bg-primary-light flex flex-col justify-center gap-1.5 p-4", className)}>
            <img src={'/images/circleequ.jpg'} alt={'test'}/>
          <AudioPlayer source={detail ? detail.url : url} controls={controls} />
        </div>
      </RenderIf>
    </>
  );
};

export default FileViewExplore;
