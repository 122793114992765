import React from 'react';
import Typo from "../../../../core/components/typo";
import FileView from "../../../../core/components/file-view";
import RenderIf from "../../../../core/components/render-if";
import Button from "../../../../core/components/button";
import {ScoreBadge} from "../../../../core/components/badge";
import JoinTeamModal from "../JoinModal";
import {twMerge} from "tailwind-merge";

export default function TeamCard({onClick, name, team}) {
    return (
        <div className="flex flex-col sm:flex-row md:flex-row  gap-2 rounded-lg bg-white p-2 shadow">
            <RenderIf cond={!!team?.logoDetail}>
                <div className="relative aspect-square h-full max-h-36 shrink-0 overflow-hidden rounded-3xl">
                    <div className="absolute right-2 top-2 flex hidden flex-wrap items-center gap-2">
                        {/*<Typo className="rounded-full bg-black/75 px-1" size="2xs" type="on-accent">*/}
                        {/*    {province}*/}
                        {/*</Typo>*/}
                    </div>
                    <FileView {...team?.logoDetail} thumbnail={undefined} controls className="h-full w-full bg-cover"/>
                </div>
            </RenderIf>
            <div className="flex flex-1 flex-col items-start gap-2">
                <div>
                    <Typo size="sm" weight="bold" className="ui-ui-clamp-2 pt-2">
                        {team?.name}
                    </Typo>
                    <RenderIf cond={!!team?.description}>
                        <Typo size="xs" type="secondary">
                            {team?.description}
                        </Typo>
                    </RenderIf>
                </div>
                {/*<div className="mt-auto flex mr-auto ml-1 mb-1 align-left gap-2">*/}
                {/*    <Button size="xs" onClick={() => onClick()} containerClassName="mt-auto">*/}
                {/*    درخواست عضویت*/}
                {/*    </Button>*/}
                {/*</div>*/}
            </div>
            <JoinTeamModal onClick={onClick} name={name} freeze={team?.freeze} />
        </div>
    );
}