import React, {useEffect} from 'react';
import RenderIf from "../../../core/components/render-if";
import GameSlider from "../components/slider";
import QuizView from "../../../core/components/quiz-view";
import StepSchedule from "./components/step-schedule";
import StepGuide from "./components/step-guide";
import StepDescription from "./components/step-description";
import GameStepRequirements from "./components/requirements";
import FileView from "../../../core/components/file-view";
import {twMerge} from "tailwind-merge";
import Typo from "../../../core/components/typo";
import {useAuthContext} from "../../Auth/provider/AuthProvider";
import {fullName} from "../../../core/helper/fullName";

function ContentViewer({content, hiddenTitle = false,missionType}) {
    const {team, teamUser} = useAuthContext()
    return (

        <>
            <RenderIf cond={content?.subtype === 'slider' || content?.type === 'slide'}>
                <div
                    className={twMerge("flex flex-col gap-8 px-4 py-4")}>
                    <GameSlider slider={content}/>
                </div>
            </RenderIf>
            <RenderIf cond={content?.type === 'quiz'}>
                <QuizView content={content}/>
            </RenderIf>
            <RenderIf cond={content?.type === "schedule"}>
                <StepSchedule items={content?.json} title={content?.title}/>
            </RenderIf>
            <RenderIf cond={content?.type === "guide"}>
                <StepGuide gameStepFileData={content?.fileDetail} content={content}/>
            </RenderIf>
            <RenderIf cond={['content', 'intro', 'video', 'audio', 'file'].includes(content?.type)}>
                <StepDescription title={hiddenTitle ? null : content?.title}
                                 description={content?.content} file={content?.fileDetail}/>
            </RenderIf>
            <RenderIf cond={['report', 'simple_report', 'role_playing'].includes(content?.type)}>
                <RenderIf cond={team?.teamRole !== "head" && missionType === 'team'}>
                    <div className={'grid gap-x-4 gap-y-4 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3'}>
                        <Typo size="sm" className="rounded-full bg-purple-5 p-1 px-4 text-center" type="accent">
                            فقط سرتیم میتونه گزارش ارسال کنه
                            <Typo type={'accent'} size={'sm'} >
                                سرتیم شما: {teamUser?.map(x => {
                                if (x?.teamRole === "head") {
                                    return fullName(x?.user?.firstName, x?.user?.lastName) + ' ';
                                } else {
                                    return null;
                                }
                            })}
                            </Typo>
                        </Typo>
                    </div>
                </RenderIf>
                <GameStepRequirements content={content} missionType={missionType}/>
            </RenderIf>
            <RenderIf cond={content?.subtype === "arvancloud"}>
                <div
                    className="grid gap-x-4 gap-y-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 items-start ">
                    <FileView {...content?.fileDetail} contents={content?.json?.arvancloudConfigUrl}
                              thumbnail={undefined} controls
                              className="h-full w-full bg-cover rounded-xl"/>
                </div>
            </RenderIf>
        </>

    );
}

export default ContentViewer;