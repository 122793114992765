import {Link} from "react-router-dom";
import Typo from "../typo";
import RenderIf from "../render-if";
import {CommonIcon} from "../icon";
import __ from "../../utils/translate/translate";

const Title = ({title, path, pathName = __("View all")}) => {
    return (
        <div className="flex items-center justify-between gap-2">
            <Typo as="h1" weight="bold" size="lg">
                {title}
            </Typo>
            <RenderIf cond={!!path}>
                <Link className="flex items-center gap-0.5" to={path}>
                    <Typo as="p" type="accent" size="sm">
                        {pathName}
                    </Typo>
                    <CommonIcon icon="chevron" className="h-4 w-4"/>
                </Link>
            </RenderIf>
        </div>
    );
};

export default Title;
