import {useAuthContext} from '../feature/Auth/provider/AuthProvider'
import {useNavigateTo} from '../core/hooks/useNavigate'
import {useEffect} from 'react'
import {getAccessToken} from '../feature/Auth/services/tokenService'
import {Outlet} from 'react-router-dom'
import {useGetCurrentUser, useGetDashboard, useGetOrganisations, useGetTeams} from '../feature/Auth/api/auth'
import handleError from '../core/helper/handelError'
import {FullLoading} from "../core/components/loading";
import {setActiveOrganisation} from "../feature/Auth/services/organisationService";
import __ from "../core/utils/translate/translate";
import {useGetTeamDetail} from "../feature/profile/api/team";
import {firebaseFireDatabase} from "../utils/helper/utils-db-firebase";

export default function PrivateRoute() {
    const {user, setUser, setOrganisation, setTeam, setDashboard, team,setTeamUser, setYouHaveNewMessage} = useAuthContext()
    const navigateTo = useNavigateTo()
    const token = getAccessToken()
    // const [api, contextHolder] = notification.useNotification()

    const handleApiError = (error) => {
        const {message, statusCode} = handleError(error)
        // api.error({
        //   message: JSON.stringify(statusCode),
        //   description: JSON.stringify(message),
        //   duration: 5,
        // })
    }

    useEffect(() => {
        firebaseFireDatabase()
    }, []);

    const {
        data: userData,
        error: userError,
        isLoading: userLoading,
        isSuccess: userSuccess,
        isError: userIsError,
    } = useGetCurrentUser({
        enabled: !!token,
    })
    const {
        data: organisationData,
        error: organisationError,
        isLoading: organisationLoading,
        isSuccess: organisationSuccess,
        isError: organisationIsError,
    } = useGetOrganisations({
        enabled: !!userData,
    })
    const {
        data: teamData,
        error: teamError,
        isLoading: teamLoading,
        isSuccess: teamSuccess,
        isError: teamIsError,
    } = useGetTeams({
        enabled: !!organisationData,
    })

    const {
        data: dashboardData,
        error: dashboardError,
        isLoading: dashboardLoading,
        isSuccess: dashboardSuccess,
        isError: dashboardIsError,
    } = useGetDashboard({
        enabled: !!userData,
    })
    const {
        data : teamUserData,
        error,
        isLoading,
        isSuccess,
        isError,
        refetch
    } = useGetTeamDetail(team?.team?.id, team !== undefined)



    useEffect(() => {
        setUser(null)
        if (!token) {
            navigateTo(`/authenticate/login`)
        }
    }, [])

    useEffect(() => {
        if (!userData?.data) return;
        setUser(userData?.data)
        if (!userData.data?.gender) {
            navigateTo(`/authenticate/gender`)
        }
    }, [userData])

    useEffect(() => {
        if (!organisationData?.data) return;
        if (organisationData.data?.length > 0) {
            setOrganisation(organisationData.data[0])
            setActiveOrganisation(organisationData.data[0])
        }

    }, [organisationData]);
    useEffect(() => {
        if (!dashboardData?.data) return;
        setDashboard(dashboardData.data)
        const timeout = setTimeout(() => {
            if (dashboardData?.data?.countNotification > 0){
                setYouHaveNewMessage(true)
            }
        }, 5000)

        return () => clearTimeout(timeout)
    }, [dashboardData]);

    useEffect(() => {
        if (!teamData?.data) return;
        if (teamData.data?.length > 0)
            setTeam(teamData.data[0])
    }, [teamData])

    useEffect(() => {
        if (!teamUserData?.data) return;
        setTeamUser(teamUserData?.data?.users)
    }, [teamUserData]);


    if (userLoading || organisationLoading || teamLoading) {
        return (
            <>
                <FullLoading/>
            </>
        )
    }
    if (userError || organisationError || teamError) {
        const {message: messageUserError, statusCode: statusCodeUserError} = handleError(userError)


        return (
            <>
                <div className="absolute w-full h-full flex flex-col justify-center items-center gap-4">
                    {userIsError && (
                        <>
                            {' '}
                            <span> {__("Problem receiving user information")}</span>
                            <button
                                className="z-30"
                                onClick={() => {
                                    window.location.href = '/app'
                                }}
                            >
                                {__("Retry")}
                            </button>
                        </>
                    )}
                    {organisationError && (
                        <>
                            {' '}
                            <span>{__("Problem in receiving organization information")}</span>
                            <button
                                className="z-30"
                                onClick={() => {
                                    window.location.href = '/app'
                                }}
                            >
                                {__("Retry")}
                            </button>
                        </>
                    )}
                    {
                        teamError && (
                            <>
                                {' '}
                                <span> {__("Problem getting team information")}</span>
                                <button
                                    className="z-30"
                                    onClick={() => {
                                        window.location.href = '/app'
                                    }}
                                >
                                    {__("Retry")}
                                </button>
                            </>
                        )
                    }

                </div>
            </>
        )
    }
    return (
        <>
            {(userSuccess && user) && (
                <Outlet/>
            )}
        </>
    )
}
