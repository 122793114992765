import {Fragment, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import GameStepRoadMap from "./components/roadmap";
import {twMerge} from "tailwind-merge";
import {useDoneStep, useGetMission} from "../api/mission";
import RootLayout from "../../../layout/root";
import RenderIf from "../../../core/components/render-if";
import TextToHTML from "../../../core/components/text-to-html";
import {useGetMissionRunnerDetail} from "../api/missionRunner";
import ResponsiveFooter from "../../../core/components/footer";
import Button from "../../../core/components/button";
import handleError from "../../../core/helper/handelError";
import {toast} from "react-toastify";
import ContentViewer from "./content-viewer";
import __ from "../../../core/utils/translate/translate";

const MissionStepPage = function () {
    const {id, stepId, runnerId} = useParams();

    const {data: missionData, isLoading} = useGetMission({id});
    const {data: missionRunnerData} = useGetMissionRunnerDetail({runnerId});
    const [steps, setSteps] = useState([]);

    const [parentStep, setParentStep] = useState();
    const [childStep, setChildStep] = useState();
    const [currentStep, setCurrentStep] = useState();
    const [isFirstStep, setIsFirstStep] = useState(false)
    const [missionType, setMissionType] = useState('')

    useEffect(() => {
        if (!missionData) return;
        setMissionType(missionData?.data?.type)
        missionData?.data?.steps.forEach((step, i) => {
            if (missionData?.data?.type === "course") {
                step?.children.forEach((child, j) => {
                    if (j === 0 && parseInt(stepId) === parseInt(child.id)) {
                        setIsFirstStep(true)
                    }

                    if (child.id === parseInt(stepId)) {
                        setChildStep(child);
                        setParentStep(step);
                        child.index = j
                        setCurrentStep(child);
                        setSteps(step?.children.sort((a, b) => a.sort - b.sort))
                    }
                });

            } else {

                if (i === 0 && parseInt(stepId) === parseInt(step.id)) {
                    setIsFirstStep(true)
                }
                if (step?.id === parseInt(stepId)) {
                    setParentStep(step);
                    setCurrentStep({...step, index: i});
                    setSteps(missionData?.data?.steps.sort((a, b) => a?.sort - b?.sort));
                }
            }

        });

    }, [stepId, missionData]);
    const navigate = useNavigate();


    const handlePrevStep = () => {
        let index = steps.findIndex(step => step.id === currentStep.id);
        if (index === 0) return;
        navigate(`/app/missions/${id}/${runnerId}/step/${steps[index - 1].id}`, {replace: true})
    };

    const {
        mutate: setDoneStep,
        isPending: doneLoading,
        isSuccess: doneSuccess,
        isError: doneIsError,
    } = useDoneStep();

    const handleNextStep = () => {
        setDoneStep({missionId: id, stepId: parentStep?.id, subStepId: childStep?.id ?? ""}, {
            onSuccess: (data) => {

                const action = data?.data?.stepGuide?.action ?? null
                const missionId = data?.data?.stepGuide?.missionId ?? null
                const nextStepId = data?.data?.stepGuide?.nextStepId ?? null
                const nextSubStepId = data?.data?.stepGuide?.nextSubStepId ?? null
                const stepMessage = data?.data?.stepGuide?.stepMessage ?? null

                if (stepMessage) {
                    toast.warn(stepMessage)
                }
                if (action === "done_mission") {
                    navigate(`/app/missions/${missionId}/report-card`)
                } else {
                    if (missionId && runnerId) {
                        if (nextSubStepId) {
                            navigate(`/app/missions/${missionId}/${runnerId}/step/${nextSubStepId}`, {replace: true})
                        } else {
                            navigate(`/app/missions/${missionId}/${runnerId}/step/${nextStepId}`, {replace: true})
                        }
                    } else {
                        navigate('/app')
                    }

                }

                // need to fix this
                // let index = steps.findIndex(step => step.id === currentStep.id, {replace: true});
                // if (steps.length === index + 1) {
                //     navigate(`/app/missions/${id}`)
                // } else {
                //     navigate(`/app/missions/${id}/${runnerId}/step/${steps[index + 1].id}`, {replace: true})
                // }
            }, onError: (error) => {
                handleError(error)
            }
        });
    };


    if (!currentStep) return <></>;


    return (
        <RootLayout title={`${parentStep?.title} ${childStep ? `(${childStep?.title})` : ''}`}
                    navigateTo={`/app/missions/${id}`}>
            <div
                className={twMerge("flex flex-col gap-8 px-4 py-20")}>
                <GameStepRoadMap missionStep={currentStep} missionStepsData={steps}/>
                <RenderIf cond={!!currentStep?.description}>
                    <TextToHTML>{currentStep?.description}</TextToHTML>
                </RenderIf>

                {currentStep?.contents?.map((content, i) => (
                    <ContentViewer content={content} key={content?.id + 'mission-content' + i} missionType={missionType}/>
                ))}


                <ResponsiveFooter>
                    <div className="flex justify-between gap-2">
                        <RenderIf cond={!isFirstStep || currentStep.sort > 1}>
                            <Button onClick={handlePrevStep} type="light">
                                {__('Prev step')}
                            </Button>
                        </RenderIf>

                        <Button onClick={handleNextStep} isLoading={doneLoading}>
                            {__('Next step')}
                        </Button>
                    </div>
                </ResponsiveFooter>
            </div>
        </RootLayout>
    );
};

export default MissionStepPage;
