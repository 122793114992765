import Typo from "../../../../core/components/typo";
import __ from "../../../../core/utils/translate/translate";

const Section03 = () => {

  return (
    <section className="relative">
      <div className="relative z-[1] h-24 w-full  rounded-[50%] bg-white md:h-48">
        <Typo className="text-center" size="xl" weight="bold">
            {__("Become a Nojahan right now!")}
        </Typo>
      </div>
      <div className="-my-12  w-full bg-linear-purple-7 md:-my-24">
        <img src="/images/intro-app.png" alt="app" className="relative z-[2] mx-auto " />
      </div>
      <div className="relative z-[3] flex h-24 w-full flex-col items-center gap-4 rounded-[50%] bg-white px-4 pt-12 md:h-48 md:px-52">
        <Typo className="text-center">
            {__("By installing the Nojohan application, enter a new world where many young people are experiencing new things and great events.")}
        </Typo>
        <a
          href={"https://cafebazaar.ir/app/org.chromium.webapk.a379d93916c5dacf5_v2?ref=share"}
          className=" rounded-full bg-linear-purple-7 p-2 px-4 text-white"
          target="_blank"
        >
            {__("Download from Cafe Bazar")}
        </a>
      </div>
    </section>
  );
};

export default Section03;
