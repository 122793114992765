import {motion} from "framer-motion";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import Button from "../../core/components/button";
import AuthGenderItem from "./components/gender";
import {useGetCurrentUser, useUpdateGender} from "./api/auth";
import handleError from "../../core/helper/handelError";
import __ from "../../core/utils/translate/translate";
import Typo from "../../core/components/typo";

const GenderScreen = () => {
    const navigate = useNavigate();

    const [activeGender, setActiveGender] = useState("");
    const ListVariants = {
        hidden: {scale: 1},
        visible: {
            scale: 1,
            transition: {
                when: "beforeChildren",
                delay: 0.1,
                staggerChildren: 0.2,
            },
        },
    };
    const {mutate: updateProfile, isPending, isError, error, isSuccess, data} = useUpdateGender()
    const {
        isLoading: userLoading,
        refetch: refetchUser,
    } = useGetCurrentUser({
        manual: true,
    })
    const handleUpdate = () => {
        if (activeGender === "") return;
        updateProfile({gender: activeGender}, {
            onSuccess: (data) => {
                refetchUser().then(() => {
                    navigate('/app', {replace: true})
                }).catch((error) => {
                    handleError(error)
                });
            }, onError: (error) => {
                handleError(error)
            }

        });
    };

    return (
        <>
            <motion.div className="flex flex-col gap-2" variants={ListVariants} initial="hidden" animate="visible">
                <AuthGenderItem
                    gender="female"
                    active={activeGender === "female"}
                    onClick={() => setActiveGender("female")}
                />
                <AuthGenderItem
                    gender="male"
                    active={activeGender === "male"}
                    onClick={() => setActiveGender("male")}
                />
                <Typo size={'sm'} className={'bg-purple-4 rounded-lg p-1 text-purple-2 text-center'}>جنسیت خود را با دقت انتخاب کنید؛ این گزینه قابل تغییر نیست.
                </Typo>
            </motion.div>
            <div className="flex-1"/>
            <div className="flex flex-col items-center gap-2">
                <Button onClick={handleUpdate} isLoading={isPending || userLoading}>
                    {__("Continue")}
                </Button>
            </div>
        </>
    );
};

export default GenderScreen;
