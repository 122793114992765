import { Link, useNavigate } from "react-router-dom";
import {SwiperCardCarousel} from "../../../../core/components/swiper";
import {SwiperSlide} from "swiper/react";
import Typo from "../../../../core/components/typo";
import RenderIf from "../../../../core/components/render-if";
import Button from "../../../../core/components/button";
import __ from "../../../../core/utils/translate/translate";

const Section01 = () => {

    // const {isAuth} = useAuth();
    const navigate = useNavigate();

    return (
        <section className="grid grid-cols-1 gap-x-4 gap-y-4 px-4 lg:grid-cols-2 lg:px-52 mt-6">
            <div>
                <div className="flex flex-col gap-4 px-4 ">
                    <div className="flex gap-1">
                        <Typo as="h1" size="2xl" weight="bold">
                            {__("Nojahan")}؛
                        </Typo>
                        <Typo as="h1" size="2xl">
                            {__("Focused on teenager")}
                        </Typo>
                    </div>

                    <Typo as="p" type="secondary" className="text-justify lg:text-lg">
                        {__("Here you are going to become a special agent and shake the world with your hands! Are you ready to start the most amazing mission of your life?")}
                    </Typo>
                </div>
                <RenderIf cond={!false}>
                    <Link to={'/app'}>
                        <Button onClick={() => {
                        }} containerClassName="mt-6" scale="fit">
                            {__("Enter the Nojahan")}
                        </Button>
                    </Link>

                </RenderIf>
            </div>
            {/* slider  */}
            <div>
                <SwiperCardCarousel loop perSlideOffset={8}>
                    {Array.from({length: 4}).map((_, i) => (
                        <SwiperSlide key={'SwiperSlide' + i} className="rounded-xl">
                            <img
                                alt="slids"
                                loading="lazy"
                                src={i % 2 === 0 ? "/images/intro-blue-slide.png" : "/images/intro-red-slide.png"}
                                className="mx-auto aspect-video w-full max-w-md rounded-xl"
                            />
                        </SwiperSlide>
                    ))}
                </SwiperCardCarousel>
            </div>
        </section>
    );
};

export default Section01;
