import React from "react";
import {Link} from "react-router-dom";
import {CommonIcon} from "../components/icon";
import {clearTokensAndRedirect} from "../../feature/Auth/services/tokenService";


export default function HumberMenu() {
    const menuList =
        [
            {
                icon: 'home',
                href: '/app',
                title: 'خانه'
            },
            {
                break: true,
            },
            {
                icon: 'mission',
                href: '/app/missions?view=missions',
                title: 'ماموریت'
            },
            {
                icon: 'ravi',
                href: '/app/explore',
                title: 'جهان‌نما'
            },
            {
                icon: 'club',
                href: '/app/club?view=activities',
                title: 'یادگیری'
            },
            {
                icon: 'award',
                href: '/app/awards',
                title: 'جوایز'
            },
            {
                break: true,
            },
            {
                icon: 'profile',
                href: '/app/profile',
                title: 'پروفایل'
            },
            {
                break: true,
            },
            {
                icon: 'logout',
                href: '',
                onClick: clearTokensAndRedirect,
                title: 'خروج از حساب کاربری'
            },
            // {
            //     icon: '',
            //     href: '/abc',
            //     title: 'abc'
            // },
        ]

    return (
        <nav className="bg-gray-50">
            <div className=" ">
                <div className="flex justify-end">
                    <div className="pl-4 relative z-40 cursor-pointer pr-3 my-auto">
                        <label htmlFor="mobile-menu">
                            <input className="peer hidden" type="checkbox" id="mobile-menu"/>
                            <span
                                className="absolute left-0 z-50 block h-[2px] w-7  bg-black  content-[''] before:absolute before:top-[-0.35rem] before:z-50 before:block before:h-full before:w-full before:bg-black before:transition-all before:duration-200 before:ease-out before:content-[''] after:absolute after:bottom-[-0.35rem] after:right-0 after:block after:h-full after:w-full after:bg-black after:transition-all after:duration-200 after:ease-out after:content-[''] peer-checked:bg-transparent before:peer-checked:top-0 before:peer-checked:w-full before:peer-checked:rotate-45 before:peer-checked:transform after:peer-checked:bottom-0 after:peer-checked:w-full after:peer-checked:-rotate-45 after:peer-checked:transform"
                            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                            <span
                                className="fixed inset-0 z-40 hidden h-full w-full bg-black/50 backdrop-blur-sm peer-checked:block"
                            >
                &nbsp;
              </span>
                            <div
                                className="fixed right-0 top-0 z-40 h-full w-full translate-x-full overflow-y-auto overscroll-y-none transition duration-500 peer-checked:translate-x-0"
                            >
                                <menu
                                    className="float-right min-h-full w-[70%] md:w-[40%] lg:w-[30%] bg-white px-6 pt-12 shadow-2xl"
                                >
                                    <div className="flex flex-col gap-4">
                                        {menuList.map((menu, i) => (
                                            <>
                                                {menu.break ? <hr/> :
                                                    <span
                                                        key={'menu-humber' + i}
                                                        className="flex gap-2 items-start text-sm h-full transition border-b border-transparent
                                                cursor-pointer hover:border-teal-600">
                                                <CommonIcon icon={menu.icon}></CommonIcon>
                                                        {menu.onClick ? <div className={'cursor-pointer text-gray-800'} onClick={menu.onClick}>{menu.title}</div>: <Link className="text-gray-800" to={menu.href}>{menu.title}</Link>}

                                            </span>}


                                            </>
                                        ))}
                                    </div>
                                </menu>
                            </div>
                        </label>
                    </div>


                </div>
            </div>
        </nav>
    )
}