import {motion} from "framer-motion";
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {twMerge} from "tailwind-merge";
import {InputLabel} from "..";
import Typo from "../../typo";
import IconButton from "../../icon-button";
import RenderIf from "../../render-if";
import {CommonIcon} from "../../icon";
import FileView from "../../file-view";
import {useUploadFile} from "../../../../feature/profile/api/file";
import handleError from "../../../helper/handelError";
import __ from "../../../utils/translate/translate";
import Button from "../../button";
import {AllowedFileSize} from "../../../constants/constants";
import {toMBString} from "../../../../utils/helper";
import fileSizeModal from "../fileModal";
import FileSizeModal from "../fileModal";
import {useAuthContext} from "../../../../feature/Auth/provider/AuthProvider";

const FileInput = (
    {
        scale = "full",
        label = "",
        initValue = undefined,
        required = false,
        name,
        liveValidate = false,
        onChange,
        inputClassName,
        className,
        error,
        accept = "*/*",
        afterUpload,
        missionType,
        setProgress
    },
    ref
) => {
    const {team} = useAuthContext()
    const [fileSize, setFileSize] = useState('')
    const [file, setFile] = useState(initValue);

    const CanUpload = ((missionType === 'team' && team?.teamRole === 'head') || missionType !== 'team')

    const {
        mutate: uploadFile,
        isPending :isPendingUpload,
        isErrorUpload,
        errorUpload,
        isSuccessUpload,
        dataUpload,
    } = useUploadFile();

    const refInput = useRef(null);
    const [initial, setInitial] = useState(true)

    useEffect(() => {
        if (initial){
            setInitial(false)
            return;
        }
        afterUpload()
    }, [file]);

    const handleChange = async (event) => {
        setFileSize(event.target?.files?.item(0)?.size)
        if (parseInt(event.target?.files?.item(0)?.size) > AllowedFileSize){
            return;
        } else {
            setProgress(100)
            uploadFile(event?.target?.files?.item(0), {
                onSuccess: (data) => {
                    onChange?.(data?.data?.data);
                    setFile(data?.data?.data);
                },
                onError: (error) => {
                    handleError(error);
                },
            });
        }

    };

    const getHint = () => {
        if (accept === "image/*") return __("Image");
        else if (accept === "audio/*") return __("Voice");
        else if (accept === "video/*") return __("Video");
        else return __("File");
    };

    useImperativeHandle(ref, () => ({
        getFile() {
            return file;
        },
        focus() {
            refInput.current.focus();
        },
        clear() {
            refInput.current.value = "";
        },
    }));

    return (
        <div className={twMerge("flex flex-col gap-2", scale === "fit" ? "w-fit" : "w-full", className)}>
            <InputLabel label={label} required={required}/>
            <motion.div
                animate={{
                    x: error?.length > 0 ? [20, -20, 15, -15, 10, -10, 0] : [],
                }}
                onClick={() => {
                    if (CanUpload){
                        refInput.current.click()
                    }
                }}
                transition={{duration: 0.5}}
                className={twMerge(`relative aspect-4/3 w-full overflow-hidden rounded-3xl bg-purple-5 ${CanUpload ? 'cursor-pointer' : 'cursor-auto'}`, inputClassName)}
            >
                {!!file && (
                    <FileView
                        mime={file?.mime}
                        thumbnail={undefined}
                        url={file.url}
                        className="aspect-4/3 rounded-3xl opacity-50 "
                        controls
                        iconClassName="w-20 h-20"
                    />
                )}
                {!file && (
                    <Typo
                        weight="bold"
                        className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center text-9xl opacity-10"
                    >
                        {getHint()}
                    </Typo>
                )}

                <RenderIf cond={CanUpload}>
                    <IconButton
                        isLoading={isPendingUpload}
                        className={`absolute right-1/2 top-1/2 -translate-y-1/2 translate-x-1/2 bg-purple-4 px-1 'cursor-pointer'`}
                    >
                        <CommonIcon icon="document-upload"/>
                        <Typo size={'sm'} className={'text-purple-2'}>بارگذاری</Typo>
                    </IconButton>
                </RenderIf>
                <Typo size="xs" className="absolute top-0 left-0 p-4 rounded-full text-center " type="accent">
                    حداکثر حجم مجاز: {toMBString(AllowedFileSize)}
                </Typo>
            </motion.div>
            <RenderIf cond={error?.length > 0}>
                <motion.div animate={{y: 0, opacity: 1}} initial={{y: -20, opacity: 0}}>
                    <Typo as="p" size="xs" type="danger">
                        {error}
                    </Typo>
                </motion.div>
            </RenderIf>
            <RenderIf cond={!!fileSize && parseInt(fileSize) > AllowedFileSize}>
                <Typo size="xs" className="rounded-full bg-purple-5 p-1 px-2 w-full flex justify-between text-center text-red-500" type="accent">
                    <span className={'my-auto'}>
                        حجم فایل ارسالی شما بیشتر از
                        {toMBString(AllowedFileSize)}  است.
                    </span>
                    <div className={'max-w-xs'}>
                        <FileSizeModal />
                    </div>
                </Typo>
            </RenderIf>
            <input hidden type="file" onChange={handleChange} ref={refInput} accept={accept}/>
        </div>
    );
};

export default forwardRef(FileInput);
