import ProfileTeamLeft from "./components/left";
import ProfileTeamTeammate from "./components/teammate";
import Spinner from "../../../../core/components/spinner";
import RenderIf from "../../../../core/components/render-if";
import VerticalBox from "../../../../core/components/box/vertical";
import ProfileTeamRoadMap from "./components/road-map";
import JoinRequests from "../../../../core/components/join-requests";
import ProfileCommunityBox from "../community-box";
import {useAuthContext} from "../../../Auth/provider/AuthProvider";
import {useGetTeamDetail} from "../../api/team";
import ProfileTeamChangeHead from "./components/change-head";
import {useEffect} from "react";

const ProfileTeamView = ({uuid}) => {
    const {team} = useAuthContext();

    const {
        data,
        error,
        isLoading,
        isSuccess,
        isError,
        refetch
    } = useGetTeamDetail(team?.team?.id, team !== undefined)

    if (isLoading) return <Spinner type="accent"/>;

    return (
        <>
            <JoinRequests/>

            <RenderIf cond={!!data}>
                <ProfileCommunityBox
                    id={data?.data?.id}
                    name={data?.data?.name}
                    bio={data?.data?.description}
                    avatar={data?.data?.image?.thumbnail}
                    type={1}
                    refetch={refetch}
                />
            </RenderIf>

            <ProfileTeamTeammate team={data?.data}/>

            <RenderIf cond={!!data}>
                <VerticalBox
                    withBackground
                    className="rounded-b-3xl shadow-[0px_4px_20px_0px_rgba(0,0,0,0.2)]"
                    containerClassName="p-4 px-8"
                >
                    <ProfileTeamRoadMap team={data?.data} freeze={team?.team?.freeze === true}/>
                </VerticalBox>
            </RenderIf>

            <ProfileTeamChangeHead users={data?.data?.users}/>

            <ProfileTeamLeft team={data?.data}/>
        </>
    );
};

export default ProfileTeamView;
