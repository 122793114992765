import {useRef, useState} from "react";
import Modal from "../modal";
import Typo from "../typo";
import Button from "../button";
import AvatarEditor from "react-avatar-editor";
import __ from "../../utils/translate/translate";

const AvatarCropper = ({image, isOpen, onClose, onCropComplete}) => {


    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);

    const ref = useRef(null);

    const haneleOnCropComplete = () => {
        if (ref.current) {
            const dataUrl = ref.current.getImage().toDataURL();

            // onCropComplete?.(dataUrl);
            fetch(dataUrl).then((response) => {
              response.blob().then((response) => {

                onCropComplete?.(response);
              });
            });
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <div className="flex flex-col items-center gap-4">
                <AvatarEditor image={image} scale={scale} rotate={rotate} border={20} width={256} height={256}
                              ref={ref}/>
                <div className="grid grid-cols-4 gap-x-4 gap-y-4">
                    <Typo size="xs" type="secondary">
                        {__("Magnification")}
                    </Typo>
                    <input
                        type="range"
                        min={1}
                        max={3}
                        step={0.1}
                        value={scale}
                        onChange={(e) => setScale(Number(e.target.value))}
                        className="col-span-3"
                    />
                    <Typo size="xs" type="secondary" className="w-14">
                        {__("Rotation")}
                    </Typo>
                    <input
                        type="range"
                        min={0}
                        max={360}
                        step={1}
                        value={rotate}
                        onChange={(e) => setRotate(Number(e.target.value))}
                        className="col-span-3"
                    />
                </div>
                <Button onClick={haneleOnCropComplete}>{__("Crop")}</Button>
            </div>
        </Modal>
    );
};

export default AvatarCropper;
