import {Link} from "react-router-dom";
import FileView from "../../../../../core/components/file-view";
import Typo from "../../../../../core/components/typo";
import {CommonIcon} from "../../../../../core/components/icon";
import __ from "../../../../../core/utils/translate/translate";

const NewsCard = ({image, summary, title, createdAt, url,id}) => {

    return (
        <Link to={url?.length > 0 ? url : `/app/news/${id}`}>
            <div className="overflow-hidden rounded-3xl bg-white shadow-[0px_4px_4px_0px_rgba(0,0,0,0.10)]">
                <div className="aspect-3/2 w-full overflow-hidden">
                    <FileView {...image} thumbnail={undefined} controls className="!aspect-3/2 object-cover"/>
                </div>
                <div className="flex h-[136px] flex-col gap-2 p-4">
                    <Typo as="h1" weight="semi-bold" className="ui-clamp-1">
                        {title}
                    </Typo>
                    <Typo as="p" className="ui-clamp-2" size="xs" type="secondary">
                        {summary}
                    </Typo>
                    <div className="flex-1"/>
                    <div className="flex items-center justify-between">
                        <Typo as="p" size="xs" type="secondary">
                            {__("Published in")} {createdAt}
                        </Typo>

                        <div className="flex items-center">
                            <Typo as="span" size="sm" type="accent">
                                {__("Continue")}
                            </Typo>
                            <CommonIcon icon="chevron" className="w-4"/>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default NewsCard;
