import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Typo from "../../../core/components/typo";
import TextToHTML from "../../../core/components/text-to-html";
import Avatar from "../../../core/components/avatar";
import LinearProgressbar from "../../../core/components/progressbar/linear";
import ResponsiveFooter from "../../../core/components/footer";
import RenderSwitch from "../../../core/components/render-switch";
import RenderIf from "../../../core/components/render-if";
import Spinner from "../../../core/components/spinner";
import Button from "../../../core/components/button";
import RootLayout from "../../../layout/root";
import {useGetMissionRunner, useSendVote} from "../api/missionRunner";
import handleError from "../../../core/helper/handelError";
import {useAuthContext} from "../../Auth/provider/AuthProvider";
import __ from "../../../core/utils/translate/translate";

const MissionVotingPage = () => {
    const { missionId,runnerId } = useParams();
    const [vote, setVote] = useState(false)
    const [teamGameVotesData, setTeamGameVotesData] = useState()
    const {user} = useAuthContext()
    const navigate = useNavigate()
    const {mutate: sendVote} = useSendVote()


    const {
        data: runnerData,
        error: runnerError,
        isLoading: runnerLoading,
        isSuccess: runnerSuccess,
        isError: runnerIsError,
        refetch: refetchRunner,
    } = useGetMissionRunner({
        missionId: missionId
    });

    useEffect(() => {
        if (runnerData?.data?.votes?.length > 0){
            voted()
        }
    }, [runnerData]);
    const voted = () =>{
        runnerData?.data?.votes?.map((item) =>{
            if (item?.user?.id === user?.id){
                setVote(true)
            }
        })
    }

    const handleVote = (vote) =>{
        let myVote = ''
        if (vote === true){
            myVote = 1
        } else if (vote === false){
            myVote = 0
        }
        sendVote(
            {runnerId:runnerId,vote: myVote},
            {
                onSuccess: ()=>{
                    refetchRunner()
                },
                onError: (error)=>{
                    handleError(error)
                }
            }
        )
    }

    if (['signup', 'ready', 'doing'].includes(runnerData?.data?.status)){
        navigate(`/app/missions/${missionId}`, {replace : true})
    }
    return (
        <RootLayout navigateTo={`/app/missions/${missionId}`}>
            <div className="flex flex-col gap-8 px-4 py-20">
                <div>
                    <Typo as="p" weight="light" >
                        {__("Please agree or disagree to do this mission.")}
                    </Typo>
                    <Typo as="h1" weight="bold">
                        {runnerData?.data?.mission?.title}
                    </Typo>
                    <TextToHTML className="ui-clamp-5">{runnerData?.data?.mission?.description}</TextToHTML>
                </div>

                <div className="flex items-end gap-4">
                    <Typo className="w-24">{__("Agreed vote")}</Typo>
                    <div className="w-full">
                        <div className="flex">
                            {teamGameVotesData?.data
                                .filter((vote) => vote.confirm)
                                .map((vote, i) => (
                                    <Avatar
                                        className="-mr-4"
                                        avatarClassName="border-2 border-white shadow"
                                        size="2xs"
                                        key={i}
                                        name={vote.user?.fullName ?? ""}
                                        image={vote.user?.avatar?.thumbnail}
                                    />
                                ))}
                        </div>
                        <LinearProgressbar
                            withPercent
                            value={runnerData?.data?.voting?.percentAccepted ?? 0}
                            max={100}
                            className="flex-1"
                        />
                    </div>
                </div>

                <div className="flex items-end gap-4">
                    <Typo className="w-24">{__("Disagree vote")}</Typo>
                    <div className="w-full">
                        <div className="flex">
                            {teamGameVotesData?.data
                                .filter((vote) => !vote.confirm)
                                .map((vote, i) => (
                                    <Avatar
                                        className="-mr-4"
                                        avatarClassName="border-2 border-white shadow"
                                        size="2xs"
                                        key={i}
                                        name={vote.user?.fullName ?? ""}
                                        image={vote.user?.avatar?.thumbnail}
                                    />
                                ))}
                        </div>
                        <LinearProgressbar
                            withPercent
                            value={runnerData?.data?.voting?.percentRejected ?? 0}
                            max={100}
                            className="flex-1"
                        />
                    </div>
                </div>

                <ResponsiveFooter>
                    <RenderSwitch
                        conds={[
                            runnerData?.data?.status === "pending_mentor", //0
                            runnerData?.data?.status !== "voting_rejected" && vote === true, //1
                            runnerData?.data?.status === "voting", //2
                            runnerData?.data?.status === "mentor_rejected", //3
                            ['signup', 'ready', 'doing'].includes(runnerData?.data?.status), //4
                            false, //5
                            runnerData?.data?.status === "voting_rejected", //6
                        ]}
                    >
                        {[
                            <Typo className="rounded-full bg-purple-5 p-1 px-2 text-center" type="accent" key={0}>
                                {__("Awaiting mentor approval")}
                            </Typo>,
                            <Typo className="rounded-full bg-purple-5 p-1 px-2 text-center" type="accent" key={1}>
                                {__("You already voted!")}
                            </Typo>,
                            <RenderIf
                                key={2}
                                cond={true}
                                falsyElement={
                                    <div className="flex justify-center">
                                        <Spinner type="accent"/>
                                    </div>
                                }
                            >
                                <div className="flex items-center gap-2">
                                    <Button onClick={() => handleVote(true)}>
                                        {__("Agree")}
                                    </Button>
                                    <Button
                                        type="light"
                                        onClick={() => handleVote(false)}
                                    >
                                        {__("Not agree")}
                                    </Button>
                                </div>
                            </RenderIf>,
                            <Typo className="rounded-full bg-red/10 p-1 px-2 text-center" type="danger" key={3}>
                                {__("Mentor disapproval")}
                            </Typo>,
                            <Typo className="rounded-full bg-green/10 p-1 px-2 text-center" type="success" key={4}>
                                {__("Consent of members")}
                            </Typo>,
                            <Typo className="rounded-full bg-purple-5 p-1 px-2 text-center" type="accent" key={5}>
                                {__("Disagreement of members")}
                            </Typo>,
                            <Typo className="rounded-full bg-red/10 p-1 px-2 text-center" type="danger" key={6}>
                                {__("Disagreement of members")}
                            </Typo>,
                        ]}
                    </RenderSwitch>
                </ResponsiveFooter>
            </div>
        </RootLayout>
    );
};

export default MissionVotingPage;
